import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { QrButtonComponent } from '@app/components/qr-button/qr-button.component';
import { QrIconButtonComponent } from '@app/components/qr-icon-button/qr-icon-button.component';
import { Constants } from '@base/src/app/core/constants/constants';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    QrButtonComponent,
    QrIconButtonComponent,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
})
export class ForgottenPasswordPage implements OnInit {
  configButtonContinue = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Continuar',
    isDisabled: true,
  };

  configButtonChangePassword = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Cambiar contraseña',
    isDisabled: true,
  };

  configButtonSeePassword = {
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  showPassword = false;
  passwordError = true;
  emailUser = '';
  successEmailSent = false;
  successPasswordChanged = false;
  loading = false;
  errorServiceLogin = false;
  errorServiceLoginMessage = '';

  // --- Formulario paso 1 ---
  firstForm: FormGroup;
  email = new FormControl('', [Validators.required, Validators.email]);
  // --- Formulario paso 2 ---
  formRecovery: FormGroup;
  emailRecovery = new FormControl('', [Validators.required]);
  code = new FormControl('', Validators.required);
  password = new FormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.pattern(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/),
  ]);

  constructor(
    form1: FormBuilder,
    form2: FormBuilder,
    private router: Router
  ) {
    this.firstForm = form1.group({
      email: this.email,
    });
    this.formRecovery = form2.group({
      emailRecovery: this.emailRecovery,
      code: this.code,
      password: this.password,
    });
  }

  ngOnInit(): void {
    this.formRecovery.controls['emailRecovery'].disable();

    //Suscribirse a los cambios en el formulario
    this.firstForm.valueChanges.subscribe(() => {
      this.onchangesDetectedForm1();
    });
    this.formRecovery.valueChanges.subscribe(() => {
      this.onchangesDetectedForm2();
    });
  }

  onSeePassword(): void {
    this.showPassword = !this.showPassword;
  }

  onchangesDetectedForm1(): void {
    const correctFields =
      !this.email.hasError('required') && !this.email.hasError('email');

    this.configButtonContinue = {
      ...this.configButtonContinue,
      isDisabled: !correctFields, //true: desactivado(gris), false: habilitado
    };
  }

  onchangesDetectedForm2(): void {
    const correctFields =
      !this.emailRecovery.hasError('required') &&
      !this.emailRecovery.hasError('email') &&
      !this.password.hasError('required') &&
      !this.password.hasError('pattern') &&
      !this.password.hasError('minlength') &&
      !this.code.hasError('required');

    this.configButtonChangePassword = {
      ...this.configButtonChangePassword,
      isDisabled: !correctFields, //true: desactivado(gris), false: habilitado
    };
  }

  getErrorMessage(field: string): string {
    let errorMsg = '';

    switch (field) {
      case 'email':
        if (this.email.hasError('required')) errorMsg = 'Ingresá un email';
        if (this.email.hasError('email'))
          errorMsg = 'Ingresá un email con formato válido';
        break;

      case 'password':
        if (this.password.hasError('required'))
          errorMsg = 'Ingresá una contraseña';
        if (
          this.password.hasError('pattern') ||
          this.password.hasError('minlength')
        ) {
          errorMsg = 'Usá un mínimo de 6 caracteres con números y letras';
          this.passwordError = true;
        }
        break;

      case 'code':
        if (this.code.hasError('required'))
          errorMsg = 'Ingresá el código para validar tu email';
        break;

      default:
        errorMsg = 'Error';
        break;
    }

    return errorMsg;
  }

  onSubmitEmail(): void {
    this.emailUser = this.firstForm.get('email')?.value;
    this.errorServiceLogin = false;
    this.errorServiceLoginMessage = '';
    this.loading = true;

    // Send confirmation code to user's email
    Auth.forgotPassword(this.emailUser)
      .then(() => {
        this.successEmailSent = true;
        this.loading = false;
        this.formRecovery.controls['emailRecovery'].setValue(this.emailUser);
      })
      .catch(err => {
        console.log(err);
        this.successEmailSent = false;
        this.loading = false;
        const typeError = err.message || JSON.stringify(err);
        this.errorServiceLogin = true;

        switch (typeError) {
          case 'Cannot reset password for the user as there is no registered/verified email or phone_number.':
            this.errorServiceLoginMessage =
              'El email ingresado no se encuentra registrado';
            break;

          case 'Attempt limit exceeded, please try after some time.':
            this.errorServiceLoginMessage =
              'Ha superado el límite de intentos, pruebe más tarde';
            break;

          case 'Username/client id combination not found.':
            this.errorServiceLoginMessage =
              'El email ingresado no se encuentra registrado';
            break;

          default:
            this.errorServiceLoginMessage = typeError;
            break;
        }
      });
  }

  onChangePassword(): void {
    const code = this.formRecovery.get('code')?.value;
    const newPassword = this.formRecovery.get('password')?.value;
    Auth.forgotPasswordSubmit(this.emailUser, code, newPassword)
      .then(() => {
        this.successPasswordChanged = true;
        setTimeout(() => {
          this.router.navigate(['/iniciar-sesion']);
        }, 3000);
      })
      .catch(err => {
        console.log(err);
        const typeError = err.message || JSON.stringify(err);
        this.errorServiceLogin = true;
        this.errorServiceLoginMessage = typeError;
        this.successPasswordChanged = false;
      });
  }
}
