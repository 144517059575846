import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QRChip } from '@app/core/models/qr-chip.model';

@Component({
  selector: 'qr-chip',
  templateUrl: './qr-chip.component.html',
  styleUrls: ['./qr-chip.component.scss'],
  standalone: true,
  imports: [],
})
export class QRChipComponent {
  @Input() config?: QRChip;
  @Input() value: string = '';
  @Input() isSelected?: boolean = false;
  @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  select(): void {
    if (this.config && this.config?.canBeSelected) {
      this.isSelected = !this.isSelected;
      this.onChange.emit(this.isSelected);
    }
  }
}
