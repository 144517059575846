import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { QRIcon } from '@app/core/models/qr-icon.model';
import { QRInput } from '@app/core/models/qr-input.model';
import { Subject, Subscription } from 'rxjs';
import { QRIconComponent } from '../qr-icon/qr-icon.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

type InputValue = string | number | undefined;

@Component({
  selector: 'qr-input',
  standalone: true,
  imports: [QRIconComponent, CommonModule, FormsModule],
  templateUrl: './qr-input.component.html',
  styleUrl: './qr-input.component.scss',
})
export class QRInputComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() config: QRInput | undefined;
  @Input() value: InputValue;
  @Output()
  valueChange: EventEmitter<InputValue> = new EventEmitter<InputValue>();

  @Output() blurChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  realValue: number | string | undefined; // Valor real que se envia en el @Output
  visibleMaskValue: string | undefined; // Valor que es visible para el usuario final con formato, funciona como una máscara para el usuario, NO es el valor que se envia en @Ouput
  valueSubject: Subject<InputValue> = new Subject<
    string | number | undefined
  >();

  valueSubscription: Subscription | undefined;

  @Input() hasError?: boolean = false;
  @Input() isValid?: boolean = false;
  @Input() isDisabled?: boolean = false;

  @ViewChild('input', { static: false }) input: ElementRef | undefined;

  iconCheck: QRIcon = { name: QRIcon.NAME_CHECK, color: QRIcon.COLOR_PRIMARY };
  iconError: QRIcon = { name: QRIcon.NAME_ERROR, color: QRIcon.COLOR_RED };

  ngOnInit(): void {
    this.valueSubscription = this.valueSubject.subscribe(value => {
      this.valueChange.emit(value);
    });
  }

  ngAfterViewInit(): void {
    if (this.config?.isFocus) {
      this.input?.nativeElement.focus(); // Hace focus sobre el input (Te deja el input seleccionado listo para escribir)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['value']?.currentValue) {
      this.modelChange(changes?.['value']?.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.valueSubscription?.unsubscribe();
  }

  modelChange(VALUE: string | number): void {
    switch (this.config?.format) {
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER: // Números enteros.
        this.realValue = this.returnOnlyNumber(VALUE.toString());
        this.visibleMaskValue = VALUE.toString();
        this.valueSubject.next(this.realValue);
        break;
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS: // Números enteros con puntos de miles.
        this.realValue = this.returnOnlyNumber(VALUE.toString());
        this.formatVisibleMaskNumberWithDots(this.realValue);
        this.valueSubject.next(this.realValue);
        break;
      default:
        // Textos string.
        this.realValue = VALUE;
        this.visibleMaskValue = VALUE.toString();
        this.valueSubject.next(this.realValue);
        break;
    }
  }

  /*--------------------------------------------------------------------*/
  /*--------------------------------------------------------------------*/
  /*--------------------------------------------------------------------*/
  /*--------------------------------------------------------------------*/

  /*
  keydownChange: Se ejecuta al presionar una tecla
  */
  keydownChange(e: KeyboardEvent): void {
    switch (this.config?.format) {
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER:
        // Solo números enteros
        // Excepciones
        if (
          (e.key === 'a' && e.ctrlKey === true) || // Permitir: Ctrl+A
          (e.key === 'c' && e.ctrlKey === true) || // Permitir: Ctrl+C
          (e.key === 'v' && e.ctrlKey === true) || // Permitir: Ctrl+V
          (e.key === 'x' && e.ctrlKey === true) || // Permitir: Ctrl+X
          (e.key === 'a' && e.metaKey === true) || // Permitir+A (Mac)
          (e.key === 'c' && e.metaKey === true) || // Permitir: Cmd+C (Mac)
          (e.key === 'v' && e.metaKey === true) || // Permitir: Cmd+V (Mac)
          (e.key === 'x' && e.metaKey === true) || // Permitir: Cmd+X (Mac)
          e.key === 'Tab' || // Permitir: Tab
          e.key === 'Backspace' || // Permitir: Backspace
          e.key === 'Delete' || // Permitir: Delete
          e.key === 'ArrowLeft' || // Permitir: Flecha a la izquierda
          e.key === 'ArrowRight' // Permitir: Flecha a la derecha
        ) {
          return; // dejar que pase, no hago nada
        }
        // Asegurarse que es un número valido
        if (e.key === ' ' || isNaN(Number(e.key))) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        break;
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS:
        // Solo números enteros
        // Excepciones
        if (
          (e.key === 'a' && e.ctrlKey === true) || // Permitir: Ctrl+A
          (e.key === 'c' && e.ctrlKey === true) || // Permitir: Ctrl+C
          (e.key === 'v' && e.ctrlKey === true) || // Permitir: Ctrl+V
          (e.key === 'x' && e.ctrlKey === true) || // Permitir: Ctrl+X
          (e.key === 'a' && e.metaKey === true) || // Permitir+A (Mac)
          (e.key === 'c' && e.metaKey === true) || // Permitir: Cmd+C (Mac)
          (e.key === 'v' && e.metaKey === true) || // Permitir: Cmd+V (Mac)
          (e.key === 'x' && e.metaKey === true) || // Permitir: Cmd+X (Mac)
          e.key === 'Tab' || // Permitir: Tab
          e.key === 'Backspace' || // Permitir: Backspace
          e.key === 'Delete' || // Permitir: Delete
          e.key === 'ArrowLeft' || // Permitir: Flecha a la izquierda
          e.key === 'ArrowRight' // Permitir: Flecha a la derecha
        ) {
          return; // dejar que pase, no hago nada
        }
        // Asegurarse que es un número valido
        if (e.key === ' ' || isNaN(Number(e.key))) {
          e.preventDefault();
          e.stopPropagation();
          return;
        }
        break;

      default:
        break;
    }
    if (this.config?.limitNumberOfCharacters) {
      this.visibleMaskValue = this.visibleMaskValue?.slice(
        0,
        this.config.limitNumberOfCharacters - 1
      );
    }
  }

  /*
  pasteChange: Se ejecuta al pegar algo en el input
  */
  pasteChange(event: ClipboardEvent): void {
    switch (this.config?.format) {
      case QRInput.FORMAT_TYPE_NUMBER_INTEGER_WITH_DOTS:
        if (event && event.clipboardData) {
          event.preventDefault();
          const pastedInput: string = event.clipboardData
            .getData('text/plain')
            .replace(/\D/g, ''); // get a digit-only string
          document.execCommand('insertText', false, pastedInput);
        }
    }
  }

  /*
  returnOnlyNumber: Recibe un string y devuelve solo los valores numéricos
  */
  private returnOnlyNumber(value: string): number | undefined {
    let number: number | undefined = undefined;
    if (value !== undefined && value !== null && value.length != 0) {
      number = Number(value?.toString().replace(/\D/g, ''));
    }
    return number;
  }

  /*
	formatVisibleMaskNumberWithDots: Agregar a número puntos de miles, ingresa en formato de
  number y se devuelve como un string (Se usa para el valor visual [visibleMaskVale] que ve
  el usuario, funciona como una máscara visual para el usuario)
	*/
  private formatVisibleMaskNumberWithDots(number: number | undefined) {
    if (number !== null && number !== undefined) {
      this.visibleMaskValue = BigInt(number)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      this.visibleMaskValue = undefined;
    }
  }

  /*-------------------------------------------------*/
  /*-------------------------------------------------*/
  /*-------------------------------------------------*/
  /*-------------------------------------------------*/
}
