<!-- Diálogo: Tipo de propiedad -->
<qr-dialog
  [data]="dialogPropertyType"
  (onButtonLeft)="dialogPropertyTypeButtonLeft()"
  (onButtonRight)="dialogPropertyTypeButtonRight()"></qr-dialog>

<!-- Hero -->
<section
  id="hero"
  [class]="node"
  [style.background-image]="'url(' + imageCloudfrontAndCountry + '/assets/media/webp/home/bg-herobanner.webp' + ')'">
  <div id="hero-wrapper" [class]="node">
    <div class="search-slogan">
      <h1>{{ 'home.intro' | translate:locale.language }}</h1>
    </div>
    <div id="hero-search-form">
      <!-- Botonera -->
      <div id="buttons-container">
        <qr-three-buttons-group
          [config]="threeButtons"
          (eventClick)="threeButtonsClick($event)">
        </qr-three-buttons-group>
      </div>
      <!-- Buscador -->
      <qr-searchbar
        [style]="styleSearchBar"
        [isSynonymousSearchEnabled]="true"
        (onSearch)="search()">
      </qr-searchbar>
    </div>
  </div>
  <div id="shadow"></div>
</section>

<!-- The RE/MAX Collection -->
@if(node === 'ar' || node === 'uy'){

<section>
  <div class="card-special-container" id="the-remax-collection">
    <div class="card-special-wrapper">
      <!-- Imagenes -->
      <div class="card-special-image">
        <div
          class="image-1"
          [style.background-image]="'url(' + imageCloudfrontAndCountry + '/assets/media/webp/home/bg-remax-collection.webp' + ')'"></div>
      </div>
      <!-- Información -->
      <div class="card-special-info">
        <div class="card-special-info-wrapper">
          <qr-media-img
            [config]="logoTheREMAXCollection"
            class="logo-collection"></qr-media-img>
          <p>{{ 'home.collection-text' | translate:locale.language }}</p>
          <a
            id="the-remax-collection-button"
            href="/collection"
            class="button-href">
            <qr-button [config]="this.configButtonTheREMAXCollectionCard">
            </qr-button>
          </a>
        </div>
      </div>
    </div>
    <!--Fondo -->
    <div id="the-remax-collection-background"></div>
  </div>
</section>
}

<!-- Emprendimientos -->
@defer(on idle){ @if(node === 'ar'){
<section>
  <div class="card-special-container" id="emprendimientos">
    <div class="card-special-wrapper">
      <div class="card-special-image">
        <div
          class="image-2"
          [style.background-image]="'url(' + imageCloudfrontAndCountry + '/assets/media/webp/home/bg-remax-emprendimientos.webp' + ')'"></div>
      </div>
      <div class="card-special-info-2">
        <div class="card-special-info-wrapper">
          <h2 class="subheading-02-bold grey-nero" id="title">
            {{ 'home.emprendimientos-title' | translate:locale.language }}
          </h2>
          <p class="subheading-03-regular grey-silver grey-wolf" id="text">
            {{ 'home.emprendimientos-text' | translate:locale.language }}
          </p>
          <a
            id="the-remax-entrepreneurship-button"
            href="/emprendimientos"
            class="button-href">
            <qr-button [config]="this.configButtonEntrepreneurshipsCard">
            </qr-button>
          </a>
        </div>
      </div>
    </div>
    <div id="emprendimientos-background"></div>
  </div>
</section>
} @if(node === 'ar' || node === 'uy'){
<section id="miscellaneous" class="container">
  <!-- Franquicias -->
  <section>
    <qr-card-information [data]="dataFranchises"></qr-card-information>
  </section>
  <!-- Unite a la Red RE/MAX -->
  <section>
    <qr-card-information [data]="dataBecomeREMAXAgent"></qr-card-information>
  </section>
  <!-- Encuesta de calidad -->

  <section>
    <qr-card-information [data]="dataQualitySurvey"></qr-card-information>
  </section>
</section>
} } @defer(on viewport) { @if(node === 'ar'){
<section class="container">
  <qr-banner-listing [config]="bannerLendarWEBP"></qr-banner-listing>
  <qr-banner-listing [config]="bannerGarantorWEBP"></qr-banner-listing>

  <h2 class="title-section">
    {{'home.institutional-relations' | translate:locale.language}}
  </h2>
  <div id="institutional-relations">
    <div id="institutional-relations-swiper-container">
      <div id="institutional-relations-swiper-wrapper">
        <swiper-container
          appSwiper
          init="false"
          [config]="swiperConfig"
          class="swiper-wrapper">
          <swiper-slide id="logo-1">
            <qr-media-img
              [config]="instRelationCamaraDeComercio"></qr-media-img>
          </swiper-slide>
          <swiper-slide id="logo-2">
            <qr-media-img [config]="instRelationInvestBA"></qr-media-img>
          </swiper-slide>
          <swiper-slide id="logo-3">
            <qr-media-img
              [config]="instRelationAsocArgMarcasFranquicias"></qr-media-img>
          </swiper-slide>
          <swiper-slide id="logo-4">
            <qr-media-img [config]="instRelationEndeavorARG"></qr-media-img>
          </swiper-slide>
          <swiper-slide id="logo-5">
            <qr-media-img
              [config]="instRelationNationalAsocRealtors"></qr-media-img>
          </swiper-slide>
          <swiper-slide id="logo-6">
            <qr-media-img [config]="instRelationSepyme"></qr-media-img>
          </swiper-slide>
          <swiper-slide id="logo-7">
            <qr-media-img [config]="instRelationEmbraci2017"></qr-media-img>
          </swiper-slide>
          <swiper-slide id="logo-8">
            <qr-media-img
              [config]="instRelationFundacionFlorecioPerez"></qr-media-img>
          </swiper-slide>
          <swiper-slide id="logo-9">
            <qr-media-img
              [config]="instRelationEmbajadaEEUUenARG"></qr-media-img>
          </swiper-slide>
        </swiper-container>
      </div>
      <!-- Navegación de swiper -->
      <div class="swiper-button-prev2" matRipple></div>
      <div class="swiper-button-next2" matRipple></div>
    </div>
  </div>
</section>
<qr-disclaimer type="home" class="container"></qr-disclaimer>
} }@placeholder {
<span class="container"></span>
} @if(node === 'ec'){ @defer(on idle){
<section
  id="miscellaneous"
  class="container"
  [class.twoSections]="this.node === 'ec'">
  <!-- Franquicias -->
  <section>
    <qr-card-information [data]="dataFranchises"></qr-card-information>
  </section>
  <!-- Unite a la Red RE/MAX -->
  <section>
    <qr-card-information [data]="dataBecomeREMAXAgent"></qr-card-information>
  </section>
</section>
} @defer(on viewport){
<!-- Imperdibles de la red de oficinas adheridas a RE/MAX -->
<section>
  <div class="card-special-container" id="must-see">
    <div class="card-special-image">
      <div id="must-see-images-col-1">
        <div
          class="image-1"
          [style.background-image]="'url(' + imageCloudfrontAndCountry + '/assets/media/webp/home/bg-remax-imperdibles-01.webp' + ')'"></div>
        <div
          class="image-2"
          [style.background-image]="'url(' + imageCloudfrontAndCountry + '/assets/media/webp/home/bg-remax-imperdibles-02.webp' + ')'"></div>
        <div
          id="image-2-bg"
          [style.background-image]="'url(' + imageCloudfrontAndCountry + '/assets/media/webp/home/bg-remax-imperdibles-02.webp' + ')'"></div>
      </div>
      <div id="must-see-images-col-2">
        <div
          class="image-3"
          [style.background-image]="'url(' + imageCloudfrontAndCountry + '/assets/media/webp/home/bg-remax-imperdibles-03.webp' + ')'"></div>
      </div>
    </div>
    <div class="card-special-wrapper">
      <div class="card-special-info">
        <h3>{{ 'home.imperdibles' | translate:locale.language }}</h3>
        <p class="grey-wolf">
          {{ 'home.imperdibles-text' | translate:locale.language }}
        </p>
        <qr-button
          [config]="this.configButtonTheREMAXCollectionCard"
          (click)="onImperdibles()"
          id="must-see-button"></qr-button>
      </div>
    </div>
    <div id="must-see-background"></div>
  </div>
</section>
} @placeholder {
<span class="container"></span>} }

<!-------------------------------------->
<!-- Mientras carga los parametros -->
<!-------------------------------------->
<ng-template #loading>
  <mat-spinner diameter="24"></mat-spinner>
</ng-template>
