@if (!successEmailSent) {
  <div class="container-forgotten-password">
    <form [formGroup]="firstForm">
      <h2 class="subheading-03-bold">
        Paso 1: para recuperar tu contraseña, ingresá tu email
      </h2>
      <p class="subtitle">
        Ingresá tu email y te enviaremos un código para cambiarla
      </p>
      <div class="input-box">
        <mat-form-field class="qr-form-field" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput formControlName="email" name="email" />
          <mat-error>{{ getErrorMessage('email') }}</mat-error>
        </mat-form-field>
      </div>
      <div class="button-box">
        <qr-button
          [config]="this.configButtonContinue"
          (click)="onSubmitEmail()"></qr-button>
      </div>
      @if (loading) {
        <div class="loader-container">
          <mat-spinner diameter="24" color="#FFFFFF"></mat-spinner>
        </div>
      }
      @if (this.errorServiceLoginMessage) {
        <div class="text-error service-error">
          {{ this.errorServiceLoginMessage }}
        </div>
      }
    </form>
  </div>
}
@if (successEmailSent) {
  <div class="container-forgotten-password form-2">
    <form [formGroup]="formRecovery">
      <h2 class="subheading-03-bold">Paso 2: Cambiá tu contraseña</h2>
      <p class="subtitle">
        Ingresá el código que enviamos a tu email y la nueva contraseña
      </p>
      <div class="input-box">
        <mat-form-field class="qr-form-field" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="emailRecovery"
            name="emailRecovery" />
          <mat-error>{{ getErrorMessage('emailRecovery') }}</mat-error>
        </mat-form-field>
      </div>
      <div class="input-box">
        <mat-form-field class="qr-form-field" appearance="outline">
          <mat-label>Código</mat-label>
          <input matInput formControlName="code" name="code" />
          <mat-error>{{ getErrorMessage('code') }}</mat-error>
        </mat-form-field>
      </div>
      <div class="input-box">
        <mat-form-field class="qr-form-field" appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input
            matInput
            formControlName="password"
            name="password"
            [type]="showPassword ? 'text' : 'password'" />
          <mat-error>{{ getErrorMessage('password') }}</mat-error>
        </mat-form-field>
        <div class="see-password">
          <qr-icon-button
            [icon]="showPassword ? 'eye-cross-out' : 'eye'"
            (click)="onSeePassword()"
            [config]="configButtonSeePassword"></qr-icon-button>
        </div>
      </div>
      <div class="button-box">
        <qr-button
          [config]="this.configButtonChangePassword"
          (click)="onChangePassword()"></qr-button>
      </div>
      @if (loading) {
        <div class="loader-container">
          <mat-spinner diameter="24" color="#FFFFFF"></mat-spinner>
        </div>
      }
      @if (this.errorServiceLoginMessage) {
        <div class="text-error service-error">
          {{ this.errorServiceLoginMessage }}
        </div>
      }
    </form>
  </div>
}
@if (successPasswordChanged) {
  <div class="snackbar">Contraseña cambiada con éxito ✅</div>
}
