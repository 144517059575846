import { environment } from '@base/environments/environment';
import { MenuItem } from '@app/core/models/menu.model';
import { UtilsService } from '@app/services/utils.service';
import { Constants } from '@base/src/app/core/constants/constants';

const node = environment.node;
const urlFranchiseGlobal = UtilsService.urlFranchiseGlobal(node);

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: Constants.LABEL_MENU_ITEM_HOME,
    link: '/',
    activeIsContain: '/',
  },
  {
    id: 2,
    label: Constants.LABEL_MENU_ITEM_BUY,
    link: '/comprar-propiedades',
    alternative_links: [
      '/listings/buy?page=0&pageSize=21&sort=%2Bid&in:operationId=1',
    ],
    activeIsContain: '/comprar-propiedades',
  },
  {
    id: 3,
    label: Constants.LABEL_MENU_ITEM_SELL,
    link: '/vender',
    activeIsContain: '/vender',
  },
  {
    id: 4,
    label: Constants.LABEL_MENU_ITEM_RENT,
    link: '/alquilar-propiedades',
    alternative_links: [
      '/listings/rent?page=0&pageSize=21&sort=%2Bid&in:operationId=2',
    ],
    activeIsContain: '/alquilar-propiedades',
  },
  {
    id: 8,
    label: Constants.LABEL_MENU_ITEM_ENTREPRENEURSHIPS,
    link: '/emprendimientos',
    alternative_links: [
      '/listings/rent?page=0&pageSize=21&sort=%2Bid&in:operationId=2',
    ],
    activeIsContain: '/emprendimientos',
  },
  {
    id: 5,
    label: Constants.LABEL_MENU_ITEM_FRANCHISE,
    link: urlFranchiseGlobal,
  },
  {
    id: 6,
    label: Constants.LABEL_MENU_ITEM_BLOG,
    link: 'https://blog.remax.com.ar/',
  },
  {
    id: 7,
    label: Constants.LABEL_MENU_ITEM_CONTACT,
    link: '/contacto',
    activeIsContain: '/contacto',
  },
];
