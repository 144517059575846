<div class="dropdown">
  <div
    class="dropdown__wrapper"
    (click)="open()"
    [style.height.px]="config?.size"
    matRipple>
    @if (selected === undefined || selected.length === 0) {
      <span class="text">{{ config?.placeholder || '' }}</span>
    }
    @if (selected !== undefined) {
      <span class="text">{{ selected }}</span>
    }
  </div>

  <qr-icon class="dropdown__arrow" [config]="iconArrow"></qr-icon>

  <mat-select
    class="dropdown__list"
    #matSelect
    [style.height.px]="config?.size"
    [(ngModel)]="selected"
    (selectionChange)="selectChange()">
    @for (option of config?.options; track option) {
      <mat-option [value]="option">{{ option }}</mat-option>
    }
  </mat-select>
</div>
