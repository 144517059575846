import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import {
  ButtonWithIconConfig,
  QrButtonWithIconComponent,
} from '../qr-button-with-icon/qr-button-with-icon.component';
import { CommonModule } from '@angular/common';
import { QrIconButtonComponent } from '../qr-icon-button/qr-icon-button.component';
import { QRMediaImage } from '@app/core/models/qr-media-img';
import { QrMediaImgComponent } from '../qr-media-img/qr-media-img.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { QrNumberPipe } from '../../pipes/qr-number.pipe';
import { Favorite } from '../../core/interfaces/favorite.interface';

@Component({
  selector: 'app-user-favorite-item',
  templateUrl: './user-favorite-item.component.html',
  styleUrls: ['./user-favorite-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    QrButtonWithIconComponent,
    QrIconButtonComponent,
    QrMediaImgComponent,
    MatCheckboxModule,
    FormsModule,
    QrNumberPipe,
  ],
})
export class UserFavoriteItemComponent {
  @Input() favorite!: Favorite;
  @Output() onCheck: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() itemDeleted: EventEmitter<string> = new EventEmitter<string>();

  // SVG Favorite Photo Not Found
  favoritePhotoNotFoundSVG: QRMediaImage = {
    text: 'Favorite Photo Not Found',
    baseURL: '',
    docFolder: 'assets/media/svg/favorites',
    docName: 'favorite-photo-not-found',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Favorite Photo Not Found',
    width: '88px',
    height: 'auto',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  buttonDeleteMobile: ButtonWithIconConfig = {
    text: 'Eliminar',
    icon: 'deleteOutlined',
    height: 32,
    style: 'button-color-grey-border icon-color-grey',
    paddingHorizontal: 0,
    paddingVertical: 0,
  };

  buttonDelete = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  public deletedItem: boolean = false;

  onChecked(): void {
    this.onCheck.emit();
  }

  onDelete(): void {
    this.itemDeleted.emit(this.favorite?.listingId);
  }

  onNavigateProp(): void {
    if (this.favorite?.published) {
      window.open('/listings/' + this.favorite.slug, '_blank');
    }
  }
}
