import {
  Component,
  EventEmitter,
  Input,
  Output,
  Inject,
  PLATFORM_ID,
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/directive/swiper.directive';
import { Tab } from '@base/src/app/core/models/qr-tab.model';
import { QrIconComponent } from '../../qr-icon/qr-icon.component';

@Component({
  selector: 'qr-tabs',
  templateUrl: './qr-tabs.component.html',
  styleUrls: ['./qr-tabs.component.scss'],
  standalone: true,
  imports: [SwiperDirective, QrIconComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QRTabsComponent implements AfterViewInit, OnChanges {
  @Input() config: Tab = new Tab();
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  @Input() isDisabled: boolean = false;
  @Output() tabChange: EventEmitter<number> = new EventEmitter<number>();
  swiperTabs: SwiperOptions = {
    spaceBetween: 0,
    slidesPerView: 'auto',
    grabCursor: true,
  };

  randomID = Math.floor(Math.random() * 9999) + 1;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.defineTabsWidth();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.cdr.detectChanges();
      if (isPlatformBrowser(this.platformId)) {
        this.defineTabsWidth();
      }
    }
  }

  select(i: number): void {
    if (!this.isDisabled) {
      this.config.tabSelected = i;
      this.tabChange.emit(this.config.tabSelected);
    }
  }

  defineTabsWidth(): void {
    const tabsWidth: number[] = [];
    // Obtener la tab con mayor ancho
    for (let i = 0; i < this.config.tabs.length; i++) {
      const tabElement = document.getElementById(
        'tab-' + i + '-' + this.randomID
      );
      if (tabElement) {
        tabsWidth.push(tabElement?.getBoundingClientRect().width);
      }
    }
    const maxWidth: number = Math.ceil(
      tabsWidth.reduce((max, curren) => (max > curren ? max : curren), 0)
    ); // Tab con mayor ancho
    const paddingSpace = 32; // Espacios laterales
    for (let i = 0; i < this.config.tabs.length; i++) {
      const tabElement = document.getElementById(
        'tab-' + i + '-' + this.randomID
      );
      if (tabElement) {
        tabElement.style.setProperty(
          'width',
          maxWidth + paddingSpace + 'px',
          'important'
        ); // Definir el ancho de todas las tabs igual a la mas ancha
      }
    }
  }
}
