import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { QrMediaImgComponent } from '@app/components/qr-media-img/qr-media-img.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QrButtonComponent } from '@app/components/qr-button/qr-button.component';
import { QrIconButtonComponent } from '@app/components/qr-icon-button/qr-icon-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';
import { QRMediaImage } from '@base/src/app/core/models/qr-media-img';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-login-user',
  standalone: true,
  imports: [
    QrMediaImgComponent,
    MatProgressSpinnerModule,
    QrButtonComponent,
    QrIconButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './login-user.component.html',
  styleUrl: './login-user.component.scss',
})
export class LoginUserPage implements OnInit {
  // Icon Facebook Login
  iconFacebookLoginSVG: QRMediaImage = {
    text: 'Icon Facebook Login',
    baseURL: '',
    docFolder: 'assets/icons',
    docName: 'facebook-login',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Icon Facebook Login',
    width: '24px',
    height: '24px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  iconGoogleLoginSVG: QRMediaImage = {
    text: 'Icon Google Login',
    baseURL: '',
    docFolder: 'assets/icons',
    docName: 'google-login',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Icon Google Login',
    width: '24px',
    height: '24px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  configButtonContinue = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Continuar',
  };

  showPassword = false;
  loading = false;
  errorServiceLogin = false;
  errorServiceLoginMessage = '';

  configButtonSeePassword = {
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  myFormGroup: UntypedFormGroup;
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  password = new UntypedFormControl('', Validators.required);

  constructor(
    fb: UntypedFormBuilder,
    private router: Router,
    private titleService: Title,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.myFormGroup = fb.group({
      email: this.email,
      password: this.password,
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Iniciar sesión | RE/MAX');
  }

  onSeePassword(): void {
    this.showPassword = !this.showPassword;
  }

  getErrorMessage(field: string): string {
    let errorMsg = '';
    switch (field) {
      case 'email':
        if (this.email.hasError('required')) errorMsg = 'Ingresá un email';
        if (this.email.hasError('email'))
          errorMsg = 'Ingresá un email con formato válido';
        break;

      case 'password':
        if (this.password.hasError('required'))
          errorMsg = 'Ingresá una contraseña';
        break;

      default:
        break;
    }
    return errorMsg;
  }

  public onLogin(): void {
    this.loading = true;

    //Si todos los campos son correctos
    if (!this.email.invalid && !this.password.invalid) {
      const userData = {
        username: this.myFormGroup.get('email')?.value,
        password: this.myFormGroup.get('password')?.value,
      };

      Auth.signIn(userData)
        .then(() => {
          this.loading = false;
          if (isPlatformBrowser(this.platformId)) {
            const URL: string = localStorage.getItem('returnUrl') || '/';
            window.location.href = URL;
            localStorage.removeItem('returnUrl');
          }
        })
        .catch(error => {
          const typeError = error.message || JSON.stringify(error);
          this.loading = false;
          this.errorServiceLogin = true;

          switch (typeError) {
            case 'Incorrect username or password.':
              this.errorServiceLoginMessage =
                'El mail o la contraseña son incorrectos';
              break;
            case 'User does not exist.':
              this.errorServiceLoginMessage =
                'El usuario con el que intenta iniciar sesión no existe';
              break;
            case 'User is not confirmed.':
              this.errorServiceLoginMessage =
                'Confirme su email para continuar';
              break;
            default:
              this.errorServiceLoginMessage = 'Ha ocurrido un error';
              break;
          }
        });
    } else {
      this.loading = false;
      this.errorServiceLogin = true;
      this.errorServiceLoginMessage = 'Complete los campos correctamente';
    }
  }

  public onLoginFacebook(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('authProvider', 'Facebook');
    }
    Auth.federatedSignIn({
      customProvider: 'Facebook',
    });
  }

  public onLoginGoogle(): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('authProvider', 'Google');
    }
    Auth.federatedSignIn({ customProvider: 'Google' });
  }
}
