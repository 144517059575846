import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { Constants } from '@base/src/app/core/constants/constants';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'qr-pagination',
  templateUrl: './qr-pagination.component.html',
  styleUrls: ['./qr-pagination.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class QrPaginationComponent implements OnInit, OnDestroy {
  paginateData: any;
  pages: any;
  @Output() onPagination = new EventEmitter<number>(); // Envia al componente padre la nueva pagina seleccionada
  @Input() actualPage: number = 0; // Pagina actual seleccionada
  @Input() itemsArray: number = 0; // Cantidad total de items (NO PAGINAS)
  @Input() pageSize: number = 0; // Cantidad de items per pagina
  @Input() dinamicComponent?: boolean | undefined; //True: para usar este componente sin la page desde la url sino dinamicamente ( para cuando no es necesario indexar la paginacion)

  public isDisable = false;
  totalPages: number = 0;
  configButton = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  //	constructor() {}
  export = this.paginate;
  private subscription: Subscription | null = null;

  @Input() set disabled$(value: Observable<boolean>) {
    if (this.subscription === undefined) {
      this.subscription = value?.subscribe(data => {
        this.isDisable = data;
      });
    }
  }

  // OnInit llamo a la funcion paginate() para generar el primer paginado.
  ngOnInit(): void {
    this.paginateData = this.paginate(
      this.itemsArray,
      Number(this.actualPage),
      this.pageSize
    );
    this.pages = this.paginateData.pages;
  }

  //
  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription = null;
  }

  // Cuando el usuario clickea una pagina le paso el dato mediante la variable P
  onPageChange(p: any): void {
    if (this.isDisable == false) {
      if (p !== this.actualPage) {
        this.paginateData = this.paginate(this.itemsArray, p, this.pageSize);
        this.pages = this.paginateData.pages;
        if (!this.dinamicComponent) {
          this.isDisable = true;
          this.actualPage = p + 1;
        } else {
          this.actualPage = p;
        }
        this.onPagination.emit(this.actualPage);
      }
    }
  }

  //
  nextPage() {
    if (this.isDisable == false) {
      this.paginateData = this.paginate(
        this.itemsArray,
        this.actualPage,
        this.pageSize
      );
      this.pages = this.paginateData.pages;
      if (!this.dinamicComponent) {
        this.isDisable = true;
        this.actualPage = this.actualPage + 2;
      } else {
        this.actualPage = this.actualPage + 1;
      }
      this.onPagination.emit(this.actualPage);
    }
  }

  //
  previousPage() {
    if (this.isDisable == false) {
      this.paginateData = this.paginate(
        this.itemsArray,
        this.actualPage,
        this.pageSize
      );
      this.pages = this.paginateData.pages;
      if (!this.dinamicComponent) {
        this.isDisable = true;
      } else {
        this.actualPage = this.actualPage - 1;
      }
      this.onPagination.emit(this.actualPage);
    }
  }

  // Funcion estandar de paginado

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  paginate(
    totalItems: number, // Total de items a paginar (ITEMS, no paginas)
    currentPage: number, // Pagina actual para hacer los calculos
    pageSize: number, // Numero de items por pagina (Esto solo afecta para los calculos)
    maxPages = 5 // Maximo de paginas a mostrar
  ) {
    // calculate total pages
    this.totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 0) {
      currentPage = 0;
    } else if (currentPage > this.totalPages) {
      currentPage = this.totalPages;
    }

    let startPage: number, endPage: number;
    if (this.totalPages <= maxPages) {
      // Si las paginas para paginar son menor a la cantidad maxima que seteamos arriba
      // mostramos todas.
      startPage = 0;
      endPage = this.totalPages;
    } else {
      // Cuando las paginas a mostrar son mas del maximo seteado en la funcion
      //, calculamos start y end de las paginas a mostrar en paginador
      const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
      const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2);
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // pagina actual cerca del principio
        startPage = 0;
        endPage = maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= this.totalPages) {
        // pagina actual cerca de la ultima
        startPage = this.totalPages - maxPages + 0;
        endPage = this.totalPages;
      } else {
        // pagina actual cerca del medio
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    // calculos de principio y final
    const startIndex = currentPage * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);

    // Pages es nuestro array de paginas para iterar en el html
    const pages = Array.from(Array(endPage - startPage).keys()).map(
      i => startPage + i
    );

    // retorno del objeto con todas las variables

    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: this.totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
