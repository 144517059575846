import { Component, OnInit } from '@angular/core';
import { Constants } from '../../../core/constants/constants';
import {
  QRDialogConfig,
  QrDialogComponent,
} from '../../../components/qr-dialog/qr-dialog.component';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '@base/environments/environment';
import { UtilsService } from '@app/services/utils.service';
import { QrButtonComponent } from '@app/components/qr-button/qr-button.component';
import { QrCardInformationComponent } from '@app/components/qr-card-information/qr-card-information.component';

@Component({
  selector: 'app-more-than-moving',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
  standalone: true,
  imports: [QrButtonComponent, QrCardInformationComponent, QrDialogComponent],
})
export class MoreThanMovingPage implements OnInit {
  // Lectura del Nodo del país
  readonly node = environment.node;

  // URL de las franquicias
  urlFranchiseGlobal = UtilsService.urlFranchiseGlobal(this.node);

  configButton = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Buscar propiedades',
  };

  configButtonProperty = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'home.know-more',
  };

  configButtonOffers = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'home.know-more',
  };

  dataPublishProp: any = {
    title: 'Publicá tu Propiedad',
    text: 'Si querés vender o alquilar tu propiedad, contá con un equipo RE/MAX, estamos para ayudarte.',
    buttonText: 'home.know-more',
    backgroundImage: '/assets/icons/publish-property.svg',
    buttonURL: '',
    isBackgroundColor: true,
    noUrl: true,
  };

  dataDigitalFirm: any = {
    title: 'Firma electrónica RE/MAX',
    text: 'Ya estamos operando con el nuevo sistema de firma electrónica de documentos.',
    buttonText: 'home.know-more',
    backgroundImage: '/assets/images/more-than-moving/electronic-sign.svg',
    buttonURL: '',
    isBackgroundColor: true,
    noUrl: true,
  };

  dataVirtualVisits: any = {
    title: 'Visitas Virtuales',
    text: 'Hoy podemos seguir visitando propiedades en forma virtual. ¡Un equipo RE/MAX te ayudará a hacerlo!',
    buttonText: 'home.know-more',
    buttonURL: '/be-agent',
    backgroundImage: '/assets/icons/virtual-tours.svg',
    isBackgroundColor: true,
    noUrl: true,
  };

  dataPropValue: any = {
    title: 'Valor de tu Propiedad',
    text: 'Con un equipo RE/MAX podés conocer el valor de tu propiedad, desde casa.',
    buttonURL: '/be-agent',
    buttonText: 'home.know-more',
    backgroundImage: '/assets/icons/value-of-property.svg',
    isBackgroundColor: true,
    noUrl: true,
  };

  dataVirtualOffer: any = {
    title: 'Reservas / Ofertas Virtuales',
    text: 'Hoy, vía e-mail, podés reservar tu próxima casa con un equipo RE/MAX.',
    buttonURL: '/be-agent',
    buttonText: 'home.know-more',
    backgroundImage: '/assets/icons/virtual-reservations-offers.svg',
    isBackgroundColor: true,
    noUrl: true,
  };

  dataFranchise: any = {
    title: 'Franquicias',
    text: 'Hay una franquicia RE/MAX para vos también. Enterate cómo podés empezar.',
    buttonURL: this.urlFranchiseGlobal,
    buttonText: 'home.know-more',
    backgroundImage: '/assets/icons/franchises.svg',
    isBackgroundColor: true,
  };

  dataBlog: any = {
    title: 'Blog RE/MAX',
    text: 'Conocé nuestro blog oficial.',
    buttonURL: 'https://blog.remax.com.ar/',
    buttonText: 'home.know-more',
    backgroundImage: '/assets/icons/blog.svg',
    isBackgroundColor: true,
  };

  dialogData: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: 'Mucho más que mudarte',
    configButtonLeft: {
      style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'list.clean-filter',
      id: 'clean-filters',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'list.apply',
      id: 'apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'more-than-moving',
    isTopbarVisible: true,
    isBotbarVisible: false,
  };

  dialogPublishProperty = {};

  constructor(
    private titleService: Title,
    private metaTagService: Meta
  ) {}

  ngOnInit() {
    this.titleService.setTitle('Mucho mas que mudarte');
    this.metaTagService.updateTag({
      name: 'description',
      content:
        'En RE/MAX estamos trabajando para que, desde casa, podamos seguir alcanzando sueños juntos.',
    });
  }

  openFilters(item: string): void {
    this.dialogData.isDialogOpen = true; // Diálogo visible
    switch (true) {
      case item == 'publish-prop':
        this.dialogData.backgroundImage = [
          '/assets/images/more-than-moving/publishprop-1.png',
          '/assets/images/more-than-moving/publishprop-2.png',
          '/assets/images/more-than-moving/publishprop-3.png',
          '/assets/images/more-than-moving/publishprop-4.png',
        ];
        break;
      case item == 'virtual-visit':
        this.dialogData.backgroundImage = [
          '/assets/images/more-than-moving/virtualvisit-1.png',
          '/assets/images/more-than-moving/virtualvisit-2.png',
          '/assets/images/more-than-moving/virtualvisit-3.png',
          '/assets/images/more-than-moving/virtualvisit-4.png',
        ];
        break;
      case item == 'virtual-offer':
        this.dialogData.backgroundImage = [
          '/assets/images/more-than-moving/reservas-ofertas-virtuales-1.jpg',
          '/assets/images/more-than-moving/reservas-ofertas-virtuales-2.jpg',
          '/assets/images/more-than-moving/reservas-ofertas-virtuales-3.jpg',
          '/assets/images/more-than-moving/reservas-ofertas-virtuales-4.jpg',
        ];
        break;
      case item == 'prop-value':
        this.dialogData.backgroundImage = [
          '/assets/images/more-than-moving/valor-de-tu-propiedad-1.jpg',
          '/assets/images/more-than-moving/valor-de-tu-propiedad-2.jpg',
          '/assets/images/more-than-moving/valor-de-tu-propiedad-3.jpg',
          '/assets/images/more-than-moving/valor-de-tu-propiedad-4.jpg',
        ];
        break;
      default:
        break;
    }

    this.dialogData = Object.assign({}, this.dialogData);
  }

  openDigitalFirm() {
    window.open('https://www.youtube.com/watch?v=GiP_iV3PMT0', '_blank');
  }
}
