import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
declare let google: any;

@Component({
  selector: 'qr-marker-google',
  templateUrl: './qr-marker-google.component.html',
  styleUrls: ['./qr-marker-google.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class QrMarkerGoogleComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() marker: any = null;
  @Input() isZoomClose: boolean = true;
  @Input() map: any = null;
  @Output() markerClick: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('content', { read: ElementRef }) template!: ElementRef;

  overlayView: any;

  ngOnChanges(changes: any): void {
    if (
      changes.currentValue?.marker?.lat ||
      changes.currentValue?.marker?.lng
    ) {
      this.overlayView.latitude = this.marker.lat;
      this.overlayView.longitude = this.marker.lng;
      this.destroy().then(() => this.load());
    }
  }

  ngOnDestroy() {
    this.destroy();
  }

  ngAfterViewInit(): void {
    this.load();
  }

  load(): void {
    this.overlayView = new google.maps.OverlayView();
    this.overlayView.iconUrl = ' ';
    this.overlayView.latitude = this.marker.lat;
    this.overlayView.longitude = this.marker.lng;
    this.overlayView.visible = false;
    const elm = this.template.nativeElement.children[0];

    const restore = (div: any) => {
      this.template.nativeElement.appendChild(div);
    };

    this.overlayView.remove = function () {
      if (!this.div) return;
      this.div.parentNode.removeChild(this.div);
      restore(this.div);
      delete this.div;
    };

    this.overlayView.getDiv = function () {
      return this.div;
    };

    this.overlayView.draw = function () {
      if (!this.div) {
        this.div = elm;
        const panes = this.getPanes();
        if (!panes || !panes.overlayImage) return;

        panes.overlayImage.appendChild(elm);
      }

      const latlng = new google.maps.LatLng(this.latitude, this.longitude);

      const proj = this.getProjection();
      if (!proj) return;

      const point = proj.fromLatLngToDivPixel(latlng);

      if (point) {
        elm.style.left = point.x - 10 + 'px';
        elm.style.top = point.y - 20 + 'px';
      }
    };

    elm.addEventListener('click', (event: any) => {
      this.handleTap();
      event.stopPropagation();
    });

    this.overlayView.setMap(this.map);
  }

  destroy(): Promise<void> {
    return new Promise<void>(resolve => {
      if (this.overlayView) {
        delete this.overlayView;
        this.overlayView.remove();
        resolve();
      }
    });
  }

  handleTap() {
    this.markerClick.emit(this.marker.id);
  }
}
