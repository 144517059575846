import { Component, Input } from '@angular/core';
import { SkeletonLoaderComponent } from '@app/components/ui/skeleton-loader/skeleton-loader.component';
import { Constants } from '@base/src/app/core/constants/constants';

@Component({
  selector: 'listing-list-loader',
  templateUrl: './listing-list-loader.component.html',
  styleUrls: ['./listing-list-loader.component.scss'],
  standalone: true,
  imports: [SkeletonLoaderComponent],
})
export class ListingListLoaderComponent {
  @Input() viewMode: string = Constants.LISTINGS_VIEW_MODE_LIST;

  readonly LISTINGS_VIEW_MODE_LIST = Constants.LISTINGS_VIEW_MODE_LIST;
  readonly LISTINGS_VIEW_MODE_GRID = Constants.LISTINGS_VIEW_MODE_GRID;
  readonly LISTINGS_VIEW_MODE_MAP = Constants.LISTINGS_VIEW_MODE_MAP;
}
