import { CommonModule, DOCUMENT } from '@angular/common';
import {
  StateKey,
  makeStateKey,
  Component,
  OnInit,
  OnDestroy,
  TransferState,
  Inject,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@base/environments/environment';
import {
  IButtonConfig,
  QrButtonComponent,
} from '@base/src/app/components/qr-button/qr-button.component';
import { Constants } from '@base/src/app/core/constants/constants';
import { SearchDomain } from '@base/src/app/domain/search.domain';
import { Associate } from '@base/src/app/core/models/associate.model';
import { Icon } from '@base/src/app/core/models/icon.model';
import { IQrPage } from '@base/src/app/core/models/IQrPage';
import { IQrResponse } from '@base/src/app/core/models/IQrResponse';
import { Office } from '@base/src/app/core/models/office.model';
import { QrFilter } from '@base/src/app/core/models/qr-filter';
import { QrFilterCustom } from '@base/src/app/core/models/qr-filter-custom';
import { Tab } from '@base/src/app/core/models/qr-tab.model';
import { AssociateService } from '@base/src/app/services/associate.service';
import { ListingAndEntrepreneurshipService } from '@base/src/app/services/listing-and-entrepreneurship.service';
import { SEOUtilsService } from '@base/src/app/services/seo-utils.service';
import {
  L10nTranslationService,
  L10N_LOCALE,
  L10nLocale,
  L10nTranslationModule,
} from 'angular-l10n';
import { Subscription, Subject, takeUntil } from 'rxjs';
import { QrCardInfoAgentComponent } from '../../../components/qr-card-info-agent/qr-card-info-agent.component';
import { QrCardInfoBrokerComponent } from '../../../components/qr-card-info-broker/qr-card-info-broker.component';
import { QrBiographyCardComponent } from '../../../components/qr-biography-card/qr-biography-card.component';
import { QrCertificatesCardComponent } from '../../../components/qr-certificates-card/qr-certificates-card.component';
import { QRTabsComponent } from '../../../components/ui/qr-tabs/qr-tabs.component';
import { QrCardPropertyComponent } from '../../../components/qr-card-property/qr-card-property.component';
import { SkeletonLoaderComponent } from '../../../components/ui/skeleton-loader/skeleton-loader.component';
import { SwiperDirective } from '../../../directive/swiper.directive';
import { ListingAndEntrepreneurship } from '@base/src/app/core/interfaces/listing-and-entrepreneurship.interface';
import { IQrFilterType } from '@base/src/app/core/enum/IQrFilterType';

const AGENT_KEY: StateKey<Associate> = makeStateKey('agent-detail.agent');

@Component({
  selector: 'app-agent-detail',
  templateUrl: './agent-detail.page.html',
  styleUrls: ['./agent-detail.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    QrCardInfoAgentComponent,
    QrCardInfoBrokerComponent,
    QrBiographyCardComponent,
    QrCertificatesCardComponent,
    QRTabsComponent,
    QrCardPropertyComponent,
    SkeletonLoaderComponent,
    QrButtonComponent,
    SwiperDirective,
    L10nTranslationModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AgentDetailPage implements OnInit, OnDestroy {
  public node: string = environment.node;
  public listingsAndEntrepreneurship: ListingAndEntrepreneurship[] = [];
  public auctioneers: Associate[] = [];
  public associate!: Associate;
  public actualPage: number = 0;
  public totalPages!: number;
  public totalItems!: number;
  public isListingsAndEntrepreneurshipLoading: boolean = true;
  public isOfficeProperties: boolean = false;
  public configButtonViewMoreProperties!: IButtonConfig;

  readonly LISTINGS_VIEW_MODE_GRID = Constants.LISTINGS_VIEW_MODE_GRID;

  readonly ICON_HOUSE: Icon = {
    name: Icon.NAME_HOUSE,
    color: Icon.COLOR_GREY_WOLF,
  };

  readonly ICON_APARTMENTS_IN_BUILDING: Icon = {
    name: Icon.NAME_APARTMENTS_IN_BUILDING,
    color: Icon.COLOR_GREY_WOLF,
  };

  configSwiperFeatured: any = {
    paginationClickable: true,
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slidesPerView: 'auto',
    spaceBetween: 12,
    watchOverflow: true,
  };

  configButtonViewMore: IButtonConfig = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text:
      this.translation.translate('general.view-more') || 'general.view-more',
    id: 'viewMore',
    selected: false,
    isDisabled: false,
    changeStyleOnSelected: false,
    styleOnSelected: null,
  };

  tabWithIcon: Tab = {
    tabs: [],
    size: Tab.SIZE_48_PX,
    tabSelected: 0,
  };

  private destroy$: Subject<void> = new Subject<void>();
  private office: Office = new Office();
  private hasEntrepreneurships: boolean = true;
  private translationSubscription: Subscription | undefined;

  constructor(
    private route: ActivatedRoute,
    private state: TransferState,
    private associateService: AssociateService,
    private translation: L10nTranslationService,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    public searchDomain: SearchDomain,
    public seoUtilsService: SEOUtilsService,
    private listingAndEntrepreneurshipService: ListingAndEntrepreneurshipService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit(): void {
    this.seoUtilsService.setCanonicalTag();
    this.load();
    this.translationChange();
  }

  ngOnDestroy(): void {
    this.translationSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public tabChange(tabSelected: number): void {
    this.tabWithIcon.tabSelected = tabSelected;
    this.actualPage = 0;
    this.loadResults();
  }

  public viewMore(): void {
    this.isListingsAndEntrepreneurshipLoading = true;
    this.actualPage++;
    this.loadResults();
  }

  public goToListingByOfficeid(): void {
    if (this.office.internalId !== null) {
      this.setSearchDomain();
      this.searchDomain.windowLocationHref();
    }
  }

  private load(): void {
    // this.associate = this.state.get<Associate>(AGENT_KEY, null);
    const AGENT_SLUG: string = this.route.snapshot.params['id'];
    this.associateService
      .findBySlug(AGENT_SLUG)
      ?.then((response: IQrResponse<Associate>) => {
        if (response.code === 200) {
          this.setMetaTags(response.data);
          this.associate = response.data;
          this.state.set<Associate>(AGENT_KEY, this.associate);
          this.checkHasEntrepreneurships();
          this.loadResults();
          this.loadAuctioneer(this.associate.office.id);
        } else if (response.code === 500) {
          this.router.navigateByUrl('/');
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  private translationChange(): void {
    this.translationSubscription = this.translation.onChange().subscribe({
      next: () => {
        this.configButtonViewMoreProperties = {
          style: Constants.BUTTON_COLOR_GREY_BORDER,
          height: Constants.BUTTON_HEIGHT_48PX,
          text: this.translation.translate('general.view-more'),
          id: 'viewMoreProperties',
          selected: false,
          isDisabled: false,
          changeStyleOnSelected: false,
          styleOnSelected: null,
        };
      },
    });
  }

  private checkHasEntrepreneurships(): void {
    const FILTER: QrFilter = new QrFilter(this.actualPage, 6, [], []);
    const AGENT_ID_FILTER: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      'associateId',
      this.associate.id
    );
    const ENTREPRENEURSHIP_FILTER: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      'entrepreneurship',
      true
    );
    FILTER.addFilter(AGENT_ID_FILTER);
    FILTER.addFilter(ENTREPRENEURSHIP_FILTER);
    const QUERY: string = FILTER.toQueryStringBe();

    this.listingAndEntrepreneurshipService
      .getParams<string, IQrResponse<IQrPage>>(QUERY)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<IQrPage>) => {
          this.hasEntrepreneurships =
            response.code === 200 && response.data.totalItems > 0;
          this.updateTabsConfig();
        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
      });
  }

  private loadResults(): void {
    const FILTER: QrFilter = new QrFilter(this.actualPage, 6, [], []);
    const AGENT_ID_FILTER: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      'associateId',
      this.associate.id
    );
    const ENTREPRENEURSHIP_FILTER: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      'entrepreneurship',
      false
    );
    FILTER.addFilter(AGENT_ID_FILTER);
    FILTER.addFilter(ENTREPRENEURSHIP_FILTER);
    const QUERY: string = FILTER.toQueryStringBe();

    if (this.tabWithIcon.tabSelected === 0) {
      this.listingAndEntrepreneurshipService
        .getParams<string, IQrResponse<IQrPage>>(QUERY) // Especificamos el tipo de respuesta esperado
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: IQrResponse<IQrPage>) => {
            this.handleResultsResponse(response);
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
        });
    } else {
      const FILTER: QrFilter = new QrFilter(this.actualPage, 6, [], []);
      const AGENT_ID_FILTER: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.eq,
        'associateId',
        this.associate.id
      );
      const ENTREPRENEURSHIP_FILTER: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.eq,
        'entrepreneurship',
        true
      );
      FILTER.addFilter(AGENT_ID_FILTER);
      FILTER.addFilter(ENTREPRENEURSHIP_FILTER);
      const QUERY: string = FILTER.toQueryStringBe();

      this.listingAndEntrepreneurshipService
        .getParams<string, IQrResponse<IQrPage>>(QUERY) // Especificamos el tipo de respuesta esperado
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: IQrResponse<IQrPage>) => {
            this.handleResultsResponse(response);
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
        });
    }
  }

  private handleResultsResponse(response: IQrResponse<IQrPage>): void {
    if (response.code == 200) {
      this.isListingsAndEntrepreneurshipLoading = false;
      this.totalPages = response.data.totalPages;
      this.totalItems = response.data.totalItems;
      const RESULTS: IQrPage = response.data;
      if (this.actualPage === 0) {
        this.listingsAndEntrepreneurship = RESULTS.data;
      } else {
        this.listingsAndEntrepreneurship = [
          ...this.listingsAndEntrepreneurship,
          ...RESULTS.data,
        ];
      }
      if (this.totalItems == 0 && this.associate?.office) {
        // El agente no tiene propiedades ni emprendimientos, se solicita cargar las propiedades de la oficina del agente.
        this.loadPropertiesAndEntrepreneurshipByOfficeID(
          this.associate.officeId
        );
        this.isOfficeProperties = true;
      }
    } else {
      console.warn(response);
    }
  }

  private updateTabsConfig(): void {
    const updatedTabWithIcon: Tab = {
      ...this.tabWithIcon,
      tabs: [
        { text: 'Propiedades', icon: this.ICON_HOUSE },
        ...(this.hasEntrepreneurships
          ? [
              {
                text: 'Emprendimientos',
                icon: this.ICON_APARTMENTS_IN_BUILDING,
              },
            ]
          : []),
      ],
      size: Tab.SIZE_48_PX,
      tabSelected: 0,
    };

    this.tabWithIcon = { ...updatedTabWithIcon };
  }

  private loadPropertiesAndEntrepreneurshipByOfficeID(officeID: string) {
    const FILTER: QrFilter = new QrFilter(this.actualPage, 6, [], []);
    const OFFICE_FILTER: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      IQrFilterType.officeId,
      officeID
    );
    FILTER.addFilter(OFFICE_FILTER);
    const QUERY: string = FILTER.toQueryStringBe();

    this.listingAndEntrepreneurshipService
      .getParams<string, IQrResponse<IQrPage>>(QUERY)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: IQrResponse<IQrPage>) => {
          if (response.code === 200) {
            this.totalPages = response.data.totalPages;
            this.totalItems = response.data.totalItems;

            const RESULTS: IQrPage = response.data;
            if (this.actualPage === 0) {
              this.listingsAndEntrepreneurship = RESULTS.data;
            } else {
              this.listingsAndEntrepreneurship = [
                ...this.listingsAndEntrepreneurship,
                ...RESULTS.data,
              ];
            }
          }
        },
        (error: any) => {
          console.error('Error occurred:', error);
        }
      );
  }

  private loadAuctioneer(id: string) {
    this.associateService
      .findByIdAuctioneerOffice(id)
      .then((response: IQrResponse<Associate[]>) => {
        if (response.code === 200) {
          this.auctioneers = response.data;

          // Cambia el rol de STAFF a AGENT
          for (let i = 0; i < this.auctioneers.length; i++) {
            const AUCTIONEER: Associate = this.auctioneers[i];
            if (AUCTIONEER.role === Constants.USER_ROLE_STAFF) {
              AUCTIONEER.role = Constants.USER_ROLE_AGENT;
            }
          }

          if (this.associate) {
            const INDEX: number = this.auctioneers.findIndex(
              e => e.id === this.associate.id
            );
            if (INDEX > -1) {
              this.auctioneers.splice(INDEX, 1);
            }
          } else {
            console.warn(
              'Associate is undefined. Cannot find associated auctioneer.'
            );
          }
        }
      })
      .catch(error => {
        console.error('Error fetching auctioneers:', error);
      });
  }

  private setMetaTags(data: any): void {
    const title: string =
      data.name + ' | Integrante del equipo de ' + data.office.name;
    const description: string =
      'Conocé el perfil de ' +
      data.name +
      ', integrante del equipo de ' +
      data.office.name +
      ' en ' +
      data.office.address +
      '. Conocé todas las propiedades y encontrá tu próximo inmueble en RE/MAX';
    this.titleService.setTitle(title);
    this.metaTagService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaTagService.updateTag({
      property: 'og:image',
      content: data.photo,
    });
    this.metaTagService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: description,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: this.document.location.href,
    });
    this.metaTagService.updateTag({
      name: 'robots',
      content: 'index, follow',
    });
    this.seoUtilsService.setCanonicalTag();
  }

  private setSearchDomain(): void {
    this.office.internalId = this.associate.office.internalId;
    this.office.name = this.associate.office.name;
    this.searchDomain.resetAll();
    this.searchDomain.filterOperation = [1, 2, 3]; // trae venta y alquiler
    this.searchDomain.filterOfficeId = this.associate.officeId;
    this.searchDomain.officeName = this.office.name;
  }
}
