<div class="favorites-container" id="favorites-container">
  <h2 class="subheading-03-bold">Favoritos</h2>
  @if (favoritesList.length > 0) {
    <div class="checkbox-all-container">
      <mat-checkbox
        color="primary"
        [(ngModel)]="checkboxDeleteAll"
        (ngModelChange)="confirmAllDelete()">
      </mat-checkbox>
      <div
        class="btn-selection-deleted"
        [ngClass]="{ hightlight: checkboxDeleteAll || someSelected }"
        (click)="onDeleteSelection()">
        Eliminar favoritos seleccionados
      </div>
    </div>
  }
  <div class="items-container">
    @if (!loader && favoritesList.length > 0) {
      <div>
        @for (item of favoritesList; track item) {
          <app-user-favorite-item
            [ngClass]="{ 'on-delete': item.animationDeleted }"
            [favorite]="item"
            (onCheck)="onSelectItem()"
            (itemDeleted)="onDeleteItem($event)"></app-user-favorite-item>
        }
      </div>
    }
    @if (!loader && favoritesList.length === 0) {
      <div class="empty">
        Aún no hay ninguna propiedad en favoritos.
        <a class="button-container" href="/">
          <qr-button [config]="this.configButtonEmptyAndExplore"></qr-button>
        </a>
      </div>
    }
    @if (loader) {
      <div class="loader-container">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
    }
  </div>
  <!-- Paginación -->
  @if (this.visiblePagination) {
    <div id="pagination">
      <qr-pagination
        [disabled$]="isLoading"
        [actualPage]="this.page"
        [itemsArray]="this.totalItems"
        [pageSize]="this.pageSize"
        [dinamicComponent]="true"
        (onPagination)="pagination($event)">
      </qr-pagination>
    </div>
  }
</div>
