import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../core/constants/constants';
@Pipe({
  name: 'metersToHectare',
  standalone: true,
})
export class MetersToHectarePipe implements PipeTransform {
  transform(value: number | null, isUnitVisible = true): string {
    let s: string;
    if (value) {
      s =
        (value / Constants.ONE_HECTARE_TO_METERS).toString() +
        this.isUnitVisible(isUnitVisible);
    } else {
      s = '0' + this.isUnitVisible(isUnitVisible);
    }
    return s;
  }

  isUnitVisible(isUnitVisible: boolean): string {
    let unit = '';
    if (isUnitVisible) {
      unit = ' ' + Constants.HECTARES;
    }
    return unit;
  }
}
