<div
  id="consumer-page-container"
  fxLayout="column"
  fxLayoutAlign="flex-start flex-start">
  <div class="title">
    <h1>{{ 'consumer.title' | translate:locale.language | titlecase }}</h1>
  </div>

  <div class="banner-customer-information">
    <qr-media-img
      [config]="bannerCustomerInformation"
      class="img-customer-information"></qr-media-img>
  </div>

  <div class="title">
    <h2>{{ 'consumer.searchingFor' | translate:locale.language }}</h2>
    <h3>
      Te contamos los detalles que debés tener en cuenta al momento de alquilar
      en la Ciudad de Buenos Aires. ¡Con RE/MAX mantenete informado!
    </h3>
  </div>

  <div class="w-100 group-buttons" fxFill>
    <div class="btn-container" fxLayoutAlign="center center">
      <button aria-label="Casas en venta" fxLayoutAlign="center center">
        Casas en venta
      </button>
    </div>
    <div class="btn-container" fxLayoutAlign="center center">
      <button aria-label="Casas en alquiler" fxLayoutAlign="center center">
        Casas en alquiler
      </button>
    </div>
    <div class="btn-container" fxLayoutAlign="center center">
      <button aria-label="Departamentos en venta" fxLayoutAlign="center center">
        Departamentos en venta
      </button>
    </div>
    <div class="btn-container" fxLayoutAlign="center center">
      <button
        aria-label="Departamentos en alquiler"
        fxLayoutAlign="center center">
        Departamentos en alquiler
      </button>
    </div>
  </div>

  <div class="text-container">
    <div class="info-text">
      <h4>
        Pago de Honorarios Profesionales por la intervención de un Corredor
        Matriculado
      </h4>
      <p>
        El 4,15% (+ IVA) sobre el valor total del contrato de locación (conf.
        Art. 57 Ley 2340 CABA). Para los casos de departamentos o casas en
        alquiler con destino habitacional en los que el inquilino sea una
        persona física, el pago de honorarios queda únicamente a cargo del
        propietario*.
      </p>
    </div>
    <div class="info-text">
      <h4>Depósito en garantía</h4>
      <p>
        En los casos de locación con destino a vivienda, el propietario no podrá
        exigir al inquilino una cantidad mayor del importe equivalente a un mes
        de alquiler por cada año de contrato; del mismo modo tampoco podrá
        exigir el pago de alquileres anticipados por períodos mayores a un mes
        (conf. Art. 1196 Código Civil y Comercial de la Nación
        <a href="http://www.codigocivilonline.com.ar/articulo-1196/"
          >http://www.codigocivilonline.com.ar/articulo-1196/</a
        >. El contrato de locación de inmueble con destino a vivienda tiene un
        plazo mínimo legal de 2 años (conf. Art. 1198 del Código Civil y
        Comercial de la Nación
        <a href="http://www.codigocivilonline.com.ar/articulo-1198/"
          >http://www.codigocivilonline.com.ar/articulo-1198/</a
        >. Es decir que para un contrato de alquiler tradicional de 2 años de
        duración, el propietario no podrá exigirte más que el importe
        equivalente a 2 meses de alquiler en concepto de depósito. Puede pedirte
        menos (por ej. 1 mes), pero no más que eso.
      </p>
    </div>
    <div class="info-text">
      <h4>Gastos de informes y certificados de la garantía</h4>
      <p>
        Hay que tener en cuenta que no podrán cobrarse al locatario gastos de
        gestoría sobre los informes y certificados. Esto, siempre y cuando se
        trate de locaciones con destino habitacional de personas fisicas*. Los
        informes/certificados que generalmente se solicitan al momento de
        concretar un alquiler son:
      </p>
    </div>
    <div class="info-text items">
      <h4>A. Informe/certificado de Dominio:</h4>
      <p>
        Permite conocer la situación jurídica de un inmueble según información
        del Registro de la Propiedad competente. Incluye información acerca de
        quién es el titular del inmueble (a nombre de quién o quiénes está), la
        existencia de embargos, hipotecas, servidumbres, derechos reales en
        general, afectación a bien de familia y otras posibles situaciones.
      </p>
    </div>
    <div class="info-text items">
      <h4>B. Informe de frecuencia:</h4>
      <p>
        El certificado de frecuencia de pedidos brinda información acerca de
        cuántas veces fue solicitada una matrícula de un inmueble en los últimos
        90 días. Una frecuencia alta (es decir, si se han pedido informes varias
        veces sobre el mismo inmueble recientemente) puede ser indicio de que se
        trate de una "garantía comprada".
      </p>
    </div>
    <div class="info-text items" id="last">
      <h4>C. Informe/certificado de Inhibición:</h4>
      <p>
        El informe de inhibición informa si los titulares del dominio se
        encuentran inhibidos para disponer libremente de sus bienes.
      </p>
    </div>
    <div class="info-text">
      <p>
        Los valores al público de dichos informes y certificados en el mostrador
        del Registro de la Propiedad Inmueble (Venezuela 1135, CABA) se
        encuentran publicados en la página web oficial del organismo:
        <a href="http://www.dnrpi.jus.gov.ar/tabla_valores/tabla_valores.php"
          >http://www.dnrpi.jus.gov.ar/tabla_valores/tabla_valores.php</a
        >. Se debe tener en cuenta que para obtener todos los informes
        necesarios se recurre a servicios arancelados de gestoría que aumentarán
        el gasto final que se deba realizar.
      </p>
    </div>
    <div class="info-text">
      <h4>Gastos de escribanía</h4>
      <p>
        Suele solicitarse que las firmas, tanto del inquilino como del garante,
        estén certificados por escribano. El precio de las certificaciones de
        firmas dependerá del escribano interviniente. Todos los gastos
        realizados deberán ser debidamente justificados.
      </p>
    </div>
    <div class="info-text">
      <h4>Publicidad</h4>
      <p>
        Todas las oficinas en las que se preste servicio de corretaje
        inmobiliario deberán tener carteles visibles donde se determine la
        siguiente leyenda: "Se encuentra prohibido cobrar comisiones
        inmobiliarias y gastos de gestoría de informes a los inquilinos que sean
        personas físicas. Para los casos de alquiler de vivienda, el monto
        máximo de comisión que se le puede requerir a los propietarios será el
        equivalente al cuatro con quince centésimos por ciento (4,15%) del valor
        total del respectivo contrato"*
      </p>
    </div>
    <div class="info-text">
      <p>
        También, en los casos de publicar avisos web promocionando propiedades
        para alquiler para vivienda, se deberá acompañar la publicación con la
        siguiente leyenda: "Para los casos de alquiler de vivienda, el monto
        máximo de comisión que se le puede requerir a los propietarios será el
        equivalente al cuatro con quince centésimos por ciento (4,15%) del valor
        total del respectivo contrato. Se encuentra prohibido cobrar a los
        inquilinos que sean personas físicas comisiones inmobiliarias y gastos
        de gestoría de informes".*
      </p>
    </div>
    <div class="info-text">
      <p>
        * Estas regulaciones se aplican sólo a los alquileres en la Ciudad
        Autónoma de Buenos Aires.
      </p>
    </div>
    <div class="info-text">
      <h4>Enlaces útiles</h4>
    </div>
    <div class="info-text">
      <a
        href="https://blog.remax.com.ar/index.php/2020/06/14/nueva-ley-de-alquileres-sus-puntos-principales/"
        >Nueva Ley de Alquileres: sus puntos principales - RE/MAX Argentina y
        Uruguay
      </a>
    </div>
    <div class="info-text">
      <a href="http://www.dnrpi.jus.gov.ar/"
        >Registro de la Propiedad Inmueble de Capital Federal
      </a>
    </div>
    <div class="info-text">
      <a
        href="http://colegioinmobiliario.org.ar/noticia/924/alquiler-de-vivienda-unica..asp"
        >Alquiler de Vivienda Única - Colegio Único de Corredores Inmobiliarios
        de la Ciudad de Buenos Aires y
      </a>
    </div>
    <div class="info-text">
      <a
        href="http://colegioinmobiliario.org.ar/preguntas_frecuentes_detalle.asp?id=10"
        >Preguntas Frecuentes - Colegio Único de Corredores Inmobiliarios de la
        Ciudad de Buenos Aires
      </a>
    </div>
    <div class="info-text">
      <a href="http://www.defensoria.org.ar/"
        >Defensoría del Pueblo de la Ciudad de Buenos Aires
      </a>
    </div>
    <div class="info-text">
      <a
        href="http://servicios.infoleg.gob.ar/infolegInternet/anexos/235000-239999/235975/norma.htm"
        >Código Civil y Comercial de la Nación
      </a>
    </div>
  </div>
</div>
