<div class="multimedia-button-container">
  <div
    matRipple
    aria-hidden="true"
    (click)="photoSelected()"
    id="photos-container"
    class="flex-row button-div flex-center-center"
    [ngClass]="{
      active: style.photo === 'selected',
      disabled: style.photo === 'disabled',
    }">
    <div
      class="icon"
      [ngClass]="{
        unselected: style.photo === 'unselected' || style.photo === 'disabled',
      }"
      id="photo-icon"></div>
    <p class="text">Fotos</p>
  </div>
  <div
    matRipple
    aria-hidden="true"
    (click)="videoSelected()"
    id="video-container"
    class="flex-row button-div flex-center-center"
    [ngClass]="{
      active: style.video === 'selected',
      disabled: style.video === 'disabled',
    }">
    <div
      class="icon"
      [ngClass]="{
        unselected: style.video === 'unselected' || style.video === 'disabled',
      }"
      id="video-icon"></div>
    <p class="text">Video</p>
  </div>
  <!-- <div
    matRipple
    aria-hidden="true"
    (click)="p360Selected()"
    id="photo360-container"
    class="flex-row button-div flex-center-center"
    [ngClass]="{
      active: style.p360 === 'selected',
      disabled: style.p360 === 'disabled',
    }">
    <div
      class="icon"
      [ngClass]="{
        unselected: style.p360 === 'unselected' || style.p360 === 'disabled',
      }"
      id="photo360-icon"></div>
    <p class="text">Fotos 360°</p>
  </div> -->
  <div
    matRipple
    aria-hidden="true"
    id="photo360-container"
    class="flex-row button-div flex-center-center disabled">
    <div class="icon unselected" id="photo360-icon"></div>
    <p class="text">Fotos 360°</p>
  </div>
  <div
    matRipple
    aria-hidden="true"
    (click)="tourSelected()"
    id="tour-container"
    class="flex-row button-div flex-center-center"
    [ngClass]="{
      active: style.tour === 'selected',
      disabled: style.tour === 'disabled',
    }">
    <div
      class="icon"
      [ngClass]="{
        unselected: style.tour === 'unselected' || style.tour === 'disabled',
      }"
      id="tour-virtual-icon"></div>
    <p class="text">Tour Virtual</p>
  </div>
</div>
