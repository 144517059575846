import { Component, Input } from '@angular/core';

@Component({
  selector: 'qr-skeleton-loader',
  templateUrl: './qr-skeleton-loader.component.html',
  styleUrls: ['./qr-skeleton-loader.component.scss'],
  standalone: true,
  imports: [],
})
export class QrSkeletonLoaderComponent {
  @Input() type: string = '';
}
