export class QRButton {
  // Jerarquía
  public static readonly HIERARCHY_PRIMARY_SOLID = 'button--primary-solid';
  public static readonly HIERARCHY_PRIMARY_BORDER = 'button--primary-border';
  public static readonly HIERARCHY_TONAL_BORDER = 'button--tonal-border';
  public static readonly HIERARCHY_TONAL = 'button--tonal';
  public static readonly HIERARCHY_GREY_BORDER = 'button--grey-border';
  public static readonly HIERARCHY_GREY = 'button--grey';
  public static readonly HIERARCHY_GREEN_SOLID = 'button--green-solid';
  // Tamaños
  public static readonly SIZE_48_PX = 48;
  public static readonly SIZE_40_PX = 40;
  public static readonly SIZE_32_PX = 32;
  id?: number | string;

  // Type
  public static readonly TYPE_BUTTON: string = 'button';
  hierarchy!: string;

  public static readonly TYPE_SUBMIT: string = 'submit';
  size!: number;

  value?: string;

  icon?: string;

  iconOnly?: boolean = false;

  // Para botones redondos en donde solo se ve el icono sin texto
  iconTooltipOnly?: string;

  ariaLabel?: string;

  type?: string = QRButton.TYPE_BUTTON;
}
