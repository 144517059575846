<div id="landing-container">
  <div id="title-container">
    <h1>{{ title }}</h1>
  </div>
  @if (subtitle) {
  <div id="subtitle-container">
    <p [innerHtml]="subtitle"></p>
  </div>
  }
  <div id="banner-container">
    @if (!isDinamicButtonViewPropertyEnabled) {
    <a [href]="urlBanner1">
      <div
        [ngStyle]="{
		'background-image': 'url(' + bannerImg1 + ')'
	}"
        class="img-container"></div
    ></a>
    } @if (!isDinamicButtonViewPropertyEnabled) {
    <a [href]="urlBanner2">
      <div
        [ngStyle]="{
		'background-image': 'url(' + bannerImg2 + ')'
	}"
        class="img-container"></div
    ></a>
    } @if (!isDinamicButtonViewPropertyEnabled && urlBanner3) {
    <a [href]="urlBanner3">
      <div
        [ngStyle]="{
		'background-image': 'url(' + bannerImg3 + ')'
	}"
        class="img-container"></div
    ></a>
    }

    <!-- Departamentos en XXXXXX -->
    @if (isDinamicButtonViewPropertyEnabled) {
    <div class="banner-view-properties">
      <h2 class="banner-title">Departamentos en {{ this.city }}</h2>
      <a class="banner-button" [href]="urlBanner1" title="Ver propiedades"
        ><qr-button [config]="configButtonViewProperties"> </qr-button
      ></a>
      <div class="banner-shadow-button"></div>
      <div class="banner-shadow"></div>
      <div
        [ngStyle]="{
          'background-image': 'url(' + bannerImg1 + ')'
}"
        class="banner-background"></div>
    </div>
    }
    <!-- Casas en XXXXXX -->
    @if (isDinamicButtonViewPropertyEnabled) {
    <div class="banner-view-properties">
      <h2 class="banner-title">Casas en {{ this.city }}</h2>
      <a class="banner-button" [href]="urlBanner2" title="Ver propiedades"
        ><qr-button [config]="configButtonViewProperties"> </qr-button
      ></a>
      <div class="banner-shadow-button"></div>
      <div class="banner-shadow"></div>
      <div
        [ngStyle]="{
          'background-image': 'url(' + bannerImg2 + ')'
}"
        class="banner-background"></div>
    </div>
    }
  </div>
  @if (hasButtons) {
  <div id="btn-container">
    <div class="btn" [ngClass]="{'width-button': !isFullButtons}">
      <a [href]="configHouseSale.url" [title]="configHouseSale.text">
        <qr-button [config]="configHouseSale"></qr-button
      ></a>
    </div>
    <div class="btn" [ngClass]="{'width-button': !isFullButtons}">
      <a [href]="configHouseRent.url" [title]="configHouseRent.text">
        <qr-button [config]="configHouseRent"></qr-button
      ></a>
    </div>
    <div class="btn" [ngClass]="{'width-button': !isFullButtons}">
      <a [href]="configAptSale.url" [title]="configAptSale.text">
        <qr-button [config]="configAptSale"></qr-button
      ></a>
    </div>
    <div class="btn" [ngClass]="{'width-button': !isFullButtons}">
      <a [href]="configAptRent.url" [title]="configAptRent.text">
        <qr-button [config]="configAptRent"></qr-button
      ></a>
    </div>
    @if (isFullButtons) {
    <div class="btn">
      <a [href]="configLand.url" [title]="configLand.text">
        <qr-button [config]="configLand"></qr-button
      ></a>
    </div>
    } @if (isFullButtons) {
    <div class="btn">
      <a [href]="configLandSale.url" [title]="configLandSale.text">
        <qr-button [config]="configLandSale"></qr-button
      ></a>
    </div>
    }
  </div>
  } @if (this.isRosario || this.isMontevideo) {
  <div id="drop-down-container">
    <div class="item-section" (click)="toggleSections(1)">
      <div class="item-dropdown">
        @if (this.isRosario) {
        <h3>¿Existen inmobiliarias de RE/MAX en Rosario?</h3>
        } @if (this.isMontevideo) {
        <h3>¿Existen inmobiliarias de RE/MAX en Montevideo, Uruguay?</h3>
        }
        <qr-icon-button
          [ngClass]="{ icon: this.isSectionOneOpen }"
          icon="arrow-drop-down"
          [config]="this.configButtonExpand">
        </qr-icon-button>
      </div>
    </div>
    <div class="sectionOpen" [ngClass]="{ open: isSectionOneOpen }">
      @if (this.isRosario) {
      <h4>Si, contamos con inmobiliarias RE/MAX en Rosario</h4>
      } @if (this.isMontevideo) {
      <h4>Si, contamos con inmobiliarias RE/MAX en Montevideo, Uruguay</h4>
      }
    </div>
    <div class="item-section" (click)="toggleSections(2)">
      <div class="item-dropdown">
        @if (this.isRosario) {
        <h3>¿Dónde encontrar inmobiliarias en Rosario Centro?</h3>
        } @if (this.isMontevideo) {
        <h3>¿Dónde encontrar inmobiliarias en Pocitos?</h3>
        }
        <qr-icon-button
          [ngClass]="{ icon: this.isSectionTwoOpen }"
          icon="arrow-drop-down"
          [config]="this.configButtonExpand">
        </qr-icon-button>
      </div>
    </div>
    <div class="sectionOpen" [ngClass]="{ open: isSectionTwoOpen }">
      @if (this.isRosario) {
      <ul>
        <li>
          <a href="https://www.remax.com.ar/exclusivo"
            ><h4>RE/MAX Exclusivo</h4></a
          >
        </li>
        <li>
          <a href="https://www.remax.com.ar/monumento"
            ><h4>RE/MAX Monumento</h4></a
          >
        </li>
        <li>
          <a href="https://www.remax.com.ar/point"><h4>RE/MAX Point</h4></a>
        </li>
        <li>
          <a href="https://www.remax.com.ar/profesional"
            ><h4>RE/MAX Profesional</h4></a
          >
        </li>
        <li>
          <a href="https://www.remax.com.ar/select"><h4>RE/MAX Select</h4></a>
        </li>
      </ul>
      } @if (this.isMontevideo) {
      <ul>
        <li>
          <a href="https://www.remax.com.uy/focus"><h4>RE/MAX Focus</h4></a>
        </li>
        <li>
          <a href="https://www.remax.com.uy/unico"> <h4>RE/MAX Único</h4></a>
        </li>
      </ul>
      }
    </div>
    <div class="item-section" (click)="toggleSections(3)">
      <div class="item-dropdown">
        @if (this.isRosario) {
        <h3>¿Dónde encontrar inmobiliarias en Rosario Zona Norte?</h3>
        } @if (this.isMontevideo) {
        <h3>¿Dónde encontrar inmobiliarias en Carrasco?</h3>
        }
        <qr-icon-button
          [ngClass]="{ icon: this.isSectionThreeOpen }"
          icon="arrow-drop-down"
          [config]="this.configButtonExpand">
        </qr-icon-button>
      </div>
    </div>
    <div class="sectionOpen" [ngClass]="{ open: isSectionThreeOpen }">
      @if (this.isRosario) {
      <ul>
        <li>
          <a href="https://www.remax.com.ar/forum"><h4>RE/MAX Forum</h4></a>
        </li>
      </ul>
      } @if (this.isMontevideo) {
      <ul>
        <li>
          <a href="https://www.remax.com.uy/costa"><h4>RE/MAX Costa</h4></a>
        </li>
      </ul>
      }
    </div>
    <div class="item-section" (click)="toggleSections(4)">
      <div class="item-dropdown">
        @if (this.isRosario) {
        <h3>¿Dónde encontrar inmobiliarias en Rosario Zona Noroeste?</h3>
        } @if (this.isMontevideo) {
        <h3>¿Dónde encontrar inmobiliarias en Palermo?</h3>
        }
        <qr-icon-button
          [ngClass]="{ icon: this.isSectionFourOpen }"
          icon="arrow-drop-down"
          [config]="this.configButtonExpand">
        </qr-icon-button>
      </div>
    </div>
    <div class="sectionOpen" [ngClass]="{ open: isSectionFourOpen }">
      @if (this.isRosario) {
      <ul>
        <li>
          <a href="https://www.remax.com.ar/vip"><h4>RE/MAX Vip</h4></a>
        </li>
        <li>
          <a href="https://www.remax.com.ar/terra"><h4>RE/MAX Terra</h4></a>
        </li>
      </ul>
      } @if (this.isMontevideo) {
      <ul>
        <li>
          <a href="https://www.remax.com.uy/360"><h4>RE/MAX 360</h4></a>
        </li>
        <li>
          <a href="https://www.remax.com.uy/conecta"><h4>RE/MAX Conecta</h4></a>
        </li>
      </ul>
      }
    </div>
  </div>
  } @for (item of text; track item) {
  <div id="info-container">
    <h2>{{ item.title }}</h2>
    @for (i of item.text; track i) {
    <p [innerHtml]="i"></p>
    }
  </div>
  }
  <div id="disclaimer-container">
    <p>{{ disclaimer }}</p>
  </div>
</div>
