import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Constants } from '../../../core/constants/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociateService } from '../../../services/associate.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { SearchDomain } from '@app/domain/search.domain';
import { environment } from '@base/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import {
  PhotoPanorama,
  QrPhotoPanoramaComponent,
} from '@app/components/qr-photo-panorama/qr-photo-panorama.component';
import {
  L10nLocale,
  L10nTranslationService,
  L10N_LOCALE,
  L10nTranslationModule,
} from 'angular-l10n';
import { Listing } from '@app/core/models/listing.model';
import { UtilsService } from '@app/services/utils.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EntrepreunershipService } from '@app/services/entrepreunership.service';
import { EntrepreunershipBySlugService } from '@app/services/entrepreneurship-by-slug.service';
import { EntrepreunershipByIdService } from '@app/services/entrepreneurship-by-id.service';
import { BreadcrumbService } from '@app/services/breadcrumb.service';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { QrTagComponent } from '@app/components/qr-tag/qr-tag.component';
import { QrDisclaimerComponent } from '@app/components/qr-disclaimer/qr-disclaimer.component';
import { QrMultimediaButtonsComponent } from '@app/components/qr-multimedia-buttons/qr-multimedia-buttons.component';
import { QrVideoContainerComponent } from '@app/components/qr-video-container/qr-video-container.component';
import { QrPhotosDetailComponent } from '@app/components/qr-photos-detail/qr-photos-detail.component';
import { QrCardDetailsEmprendimientosComponent } from '@base/src/app/components/qr-card-details-emprendimientos/qr-card-details-emprendimientos.component';
import { QrCardDetailsPropComponent } from '@base/src/app/components/qr-card-details-prop/qr-card-details-prop.component';
import { CardContactDetailsComponent } from '@base/src/app/components/card-contact-details/card-contact-details.component';
import { QrIconButtonComponent } from '@base/src/app/components/qr-icon-button/qr-icon-button.component';
import { QrCardInfoOfficeComponent } from '@base/src/app/components/qr-card-info-office/qr-card-info-office.component';
import { QrCardInfoPropComponent } from '@base/src/app/components/qr-card-info-prop/qr-card-info-prop.component';
import { Entrepreneurship } from '@base/src/app/core/models/entrepreneurship.model';
import { Associate } from '@base/src/app/core/models/associate.model';
import { QrMapGoogleComponent } from '@base/src/app/components/qr-map-google/qr-map-google.component';

@Component({
  selector: 'app-entrepreneurship-detail.page',
  templateUrl: './entrepreneurship-detail.page.html',
  styleUrls: ['./entrepreneurship-detail.page.scss'],
  standalone: true,
  imports: [
    QrTagComponent,
    CommonModule,
    L10nTranslationModule,
    QrDisclaimerComponent,
    QrMultimediaButtonsComponent,
    QrVideoContainerComponent,
    QrPhotosDetailComponent,
    QrCardDetailsEmprendimientosComponent,
    QrCardDetailsPropComponent,
    CardContactDetailsComponent,
    QrIconButtonComponent,
    QrCardInfoOfficeComponent,
    QrCardInfoPropComponent,
    QrPhotoPanoramaComponent,
    QrMultimediaButtonsComponent,
    QrMapGoogleComponent,
  ],
})
export class EntrepreneurshipDetailPage implements OnInit, OnDestroy {
  public entrepreneurship!: Entrepreneurship;
  // Breadcrumb: Tipo de operación: URL
  public isStaticMapModeEnabled: boolean = true;
  public staticMapHeight: number | undefined = 0;
  public sanitizeMapStaticURL: SafeResourceUrl | null = null;
  public sanitizeMapEmbedURL: SafeResourceUrl | null = null;
  public country: string = '';
  public units: any;
  public isEntrepreneurship: boolean = true;
  public multimediaSelected: string = 'photo';
  public isBrowser: boolean;
  public isCustomAgent: boolean = false;
  public dataToShare = { title: '', text: '', url: '' };
  public found: boolean = true;
  public isPropertyNotAvailable: number = 0;
  public url: string = '';
  public agent: Associate = new Associate();
  multimediaStyle = {
    photo: 'selected',
    video: 'unselected',
    p360: 'disabled',
    tour: 'disabled',
  };

  unitsTypes: any = {
    departamentos: [],
    casas: [],
    ph: [],
    hotel: [],
    edificio: [],
    otros: [],
    oficina: [],
    local: [],
    terrenos_y_lotes: [],
    campo: [],
    fondo_de_comercio: [],
    cochera: [],
    galpon: [],
    quinta: [],
    consultorio: [],
    chacra: [],
    deposito: [],
    departamento: [],
  };

  configButtonExpand = {
    style: Constants.BUTTON_COLOR_GREY_BASIC,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  photos360Config: PhotoPanorama = {
    isOpen: false,
    photos: [],
  };

  relatedProperties: Listing[] = [];
  isRelatedPropertiesLoading = true;
  coords: { lng: any; lat: any; id: string }[] = [];
  centerMap: { lng: any; lat: any } = { lng: 0, lat: 0 };
  breadcrumbOperationTypeText: string = '';
  loading: boolean = false;
  breadcrumbOperationTypeURL: string = '';
  private destroy$: Subject<void> = new Subject<void>();

  private auctioneers: Associate[] = [];

  private slugToRedirect: string = '';

  private slugToAssociate: string = '';

  private virtualTourLink: string = '';

  constructor(
    private route: ActivatedRoute,
    private associateService: AssociateService,
    private entrepreunershipService: EntrepreunershipService,
    private entrepreunershipBySlugService: EntrepreunershipBySlugService,
    private entrepreunershipByIdService: EntrepreunershipByIdService,
    public searchDomain: SearchDomain,
    private titleService: Title,
    private metaTagService: Meta,
    private domSanitize: DomSanitizer,
    private http: HttpClient,
    private utils: UtilsService,
    private translation: L10nTranslationService,
    private router: Router,
    public breadcrumbService: BreadcrumbService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.country = environment.node;
    if (!this.entrepreneurship) {
      const slug = this.route.snapshot.params['slug'];
      const customAssociate = this.route.snapshot.queryParams['associate'];
      if (!slug && this.slugToRedirect) {
        let url = '/emprendimientos/' + this.slugToRedirect;
        if (this.slugToAssociate) url += '?associate=' + this.slugToAssociate;
        if (this.isBrowser) window.location.replace(url);
      }

      if (slug) {
        this._fetchEntrep(slug, customAssociate);
        this._fetchUnits(slug);
      } else {
        const id = this.route.snapshot.params['id'];
        this._fetchEntrepById(id, customAssociate);
        this._fetchUnitsById(id);
      }
    } else {
      this.generateResources();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  generateMultimedia(): void {
    if (
      this.entrepreneurship.photos != null &&
      this.entrepreneurship.photos != undefined &&
      this.entrepreneurship.photos.length > 0
    ) {
      // Fotos
      this.multimediaStyle.photo = 'selected';
    } else {
      this.multimediaStyle.photo = 'disabled';
    }

    if (
      this.entrepreneurship.video != null &&
      this.entrepreneurship.video != undefined &&
      this.entrepreneurship.video.length > 0
    ) {
      // Video
      if (this.multimediaStyle.photo == 'disabled') {
        this.multimediaStyle.video = 'selected';
      } else {
        this.multimediaStyle.video = 'unselected';
      }
    } else if (this.entrepreneurship) {
      this.entrepreneurship.video = null;
      this.multimediaStyle.video = 'disabled';
    }

    if (
      this.entrepreneurship?.virtualTours != null &&
      this.entrepreneurship.virtualTours != undefined &&
      this.entrepreneurship.virtualTours.length > 0
    ) {
      this.multimediaStyle.tour = 'unselected';
      if (this.entrepreneurship.virtualTours.length > 1) {
        for (
          let index = 0;
          index < this.entrepreneurship.virtualTours.length;
          index++
        ) {
          if (this.entrepreneurship.virtualTours[index].type != 'youtube') {
            this.virtualTourLink =
              this.entrepreneurship.virtualTours[index].value;
          }
        }
      } else {
        this.virtualTourLink = this.entrepreneurship.virtualTours[0].value;
      }
    } else {
      this.multimediaStyle.tour = 'disabled';
    }

    if (
      this.entrepreneurship?.photos360 != null &&
      this.entrepreneurship.photos360 != undefined &&
      this.entrepreneurship.photos360.length > 0
    ) {
      this.entrepreneurship.photos360.forEach(s => {
        this.photos360Config.photos.push(
          environment.sitemapUrl + '/' + s.value
        );
      });
      this.multimediaStyle.p360 = 'unselected';
    } else {
      this.multimediaStyle.p360 = 'disabled';
    }
  }

  generateBrowserData(): void {
    if (this.entrepreneurship) {
      this.dataToShare.title = this.entrepreneurship.title;
      this.dataToShare.text = this.entrepreneurship.description;
      this.dataToShare.url = this.url;
    }
  }

  multimediaSelect(event: any): void {
    if (event == '360') {
      this.photos360Config.isOpen = true;
      return;
    }
    this.multimediaSelected = event;
    if (this.multimediaSelected == 'tour' && this.virtualTourLink) {
      window.open(this.virtualTourLink, '_blank');
    }
  }

  /*
  backToListings: Volver al listado (Mobile)
  */
  backToentrepreneurships(): void {
    if ('referrer' in document) {
      const urlDocumentReferrer: string = document.referrer; // URL anterior
      const urlLocationOrigin: string = window.location.origin; // URl actual base sin secciones o parametros
      if (urlDocumentReferrer.includes(urlLocationOrigin)) {
        /*
        * Si la URL anterior es una URL de RE/MAX deberia ser la de listado
        de resultados con los filtros, por lo que se usa esa para ir para atras
        */
        history.back();
      } else {
        /*
         * No hay una pagina para ir para atras que sea de RE/MAX.
         * Esto puede ser cuando se llega desde Google o entrando directamente al enlance
         * Por lo que se envia a una pagina de listado de resultados con el tipo de operación y tipo de propiedad
         */
        if (this.entrepreneurship?.geo) {
          window.location.href = this.breadcrumbService.urls.base || '/';
        } // Similar a clickear en desktop en el breadcrumb de tipo de propiedad
      }
    }
  }

  /*
  upperCaseFirstLetters: Transforma a mayúscula la primer letra de cada palabra de un string
  */
  upperCaseFirstLetters(s: string): string {
    return s.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  /*
  buildMapCoords: Se generan los datos de la marca donde esta la propiedad y el centrado del mapa dinámico/estático
  */
  buildMapCoords(): void {
    this.coords = [
      {
        lng: this.entrepreneurship?.location.coordinates[0],
        lat: this.entrepreneurship?.location.coordinates[1],
        id: this.entrepreneurship?.id || '',
      },
    ];
    this.centerMap = {
      lng: this.entrepreneurship?.location.coordinates[0],
      lat: this.entrepreneurship?.location.coordinates[1],
    };
  }

  /*
  getSanitizeMapStaticURL: Generar URL del mapa estático
  */
  getSanitizeMapStaticURL(): void {
    if (
      this.entrepreneurship &&
      this.entrepreneurship.location &&
      this.entrepreneurship.location.coordinates[0] &&
      this.entrepreneurship.location.coordinates[1]
    ) {
      const url: string =
        'https://maps.googleapis.com/maps/api/staticmap?size=800x400&maptype=roadmap' +
        '&markers=color:red|' +
        this.entrepreneurship.location.coordinates[1] +
        ',' +
        this.entrepreneurship.location.coordinates[0] +
        '&zoom=16&scale=2&key=' +
        environment.googleAPIKey;

      this.http
        .post('/signing-google-maps', {
          url: url,
        })
        .subscribe((res: any) => {
          this.sanitizeMapStaticURL =
            this.domSanitize.bypassSecurityTrustResourceUrl(res.url);
        });
    } else {
      console.log('Error: generating sanitizeMapStaticURL');
    }
  }

  /*
  enableEmbedMap: Ocultar mapa estático y mostrar mapa embed
  */
  enableEmbedMap(): void {
    this.isStaticMapModeEnabled = false;
    this.staticMapHeight =
      document.getElementById('map-static-img')?.offsetHeight; // Obtener altura del mapa estático que se le aplicara al mapa embed
  }

  getSanitizeMapEmbedURL(): void {
    if (
      this.entrepreneurship?.location?.coordinates?.length > 0 &&
      this.entrepreneurship?.location?.coordinates[0] &&
      this.entrepreneurship.location.coordinates[1]
    ) {
      this.sanitizeMapEmbedURL =
        this.domSanitize.bypassSecurityTrustResourceUrl(
          'https://www.google.com/maps/embed/v1/place?q=' +
            this.entrepreneurship.location.coordinates[1] +
            ',' +
            this.entrepreneurship.location.coordinates[0] +
            '&key=' +
            environment.googleAPIKey
        );
    } else {
      console.log('Error: generatin static map');
    }
  }

  setMetaTags(data: any): void {
    this.generateCanonicalUrl();
    let stageString = '';
    if (data.entrepreneurshipStage.value == 'in_construction') {
      stageString = 'construcción';
    } else {
      stageString = this.translation.translate(
        'entrepreneurship.' + data.entrepreneurshipStage.value
      );
    }
    const titleFormat =
      'Emprendimiento en ' +
      stageString +
      ' entre ' +
      data.minTotalRooms +
      ' y ' +
      data.maxTotalRooms +
      ' ambientes' +
      [data.displayAddress ? ' en ' + data.displayAddress : ''] +
      [data.geo && data.geo.label ? ', ' + data.geo.label : ''];

    const descriptionFormat = `${data.description.substring(0, 151)}...`;

    this.titleService.setTitle(titleFormat);
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Mirá este emprendimiento en RE/MAX: ' + descriptionFormat,
    });

    if (data.photos && data.photos.length > 0) {
      this.metaTagService.updateTag({
        property: 'og:image',
        content: this.utils.generateImageWithSize(
          data.photos[0].value,
          '360x200'
        ),
      });
    }
    this.metaTagService.updateTag({
      property: 'og:title',
      content: titleFormat,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: 'Mirá este emprendimiento en RE/MAX: ' + descriptionFormat,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: this.document.location.href,
    });
    if (data.geo && data.geo.label) {
      this.metaTagService.updateTag({
        name: 'keywords',
        content:
          data.title + ', ' + data.displayAddress + ', ' + data.geo.label,
      });
    } else {
      this.metaTagService.updateTag({
        name: 'keywords',
        content: data.title + ', ' + data.displayAddress,
      });
    }
  }

  generateCanonicalUrl(): void {
    let countryDomain = '';
    let URL = '';

    switch (environment.node) {
      case Constants.NODE_URUGUAY:
        countryDomain = 'uy';
        break;
      case Constants.NODE_ECUADOR:
        countryDomain = 'ec';
        break;
      default:
        countryDomain = 'ar';
        break;
    }

    if (this.route.snapshot.params['slug']) {
      // URL Slug
      URL =
        'https://www.remax.com.' +
        countryDomain +
        '/emprendimientos/' +
        this.route.snapshot.params['slug'];
    } else if (this.route.snapshot.params['mlsid']) {
      // URL MLS-ID
      URL =
        'https://www.remax.com.' +
        countryDomain +
        '/entrepreneurships/' +
        this.route.snapshot.params['id'];
    }
    this.updateCanonicalUrl(URL);
  }

  updateCanonicalUrl(url: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  handleBreadcrumb(): void {
    this.breadcrumbService.publication = this.entrepreneurship;
    this.breadcrumbService.publicationType =
      Constants.PUBLICATION_TYPE_ENTREPRENEURSHIP;
    this.breadcrumbService.generateAllURLs().then(() => {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.setAttribute('id', BreadcrumbService.SEO_BREADCRUMB_SCRIPT_ID);
      script.textContent = this.breadcrumbService.scriptCode;
      this.renderer.appendChild(this.document.head, script);
    });
  }

  private generateResources(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getSanitizeMapStaticURL();
      this.getSanitizeMapEmbedURL();
      this.generateBrowserData();
      this.generateMultimedia();
      this.handleBreadcrumb();
    }
  }

  private _fetchEntrep(slug: string, customAssociate: string) {
    this.entrepreunershipBySlugService
      .getData<Entrepreneurship>(slug)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<Entrepreneurship>) => {
          switch (response.code) {
            case 200:
              this.processEntrepreneurshipData(response.data, customAssociate);
              this.generateResources();
              break;
            case 500:
              this.router.navigateByUrl('/');
              break;
            case 601:
              this.isPropertyNotAvailable = 601; // Venta de propiedad ya no disponible
              break;
            case 602:
              this.isPropertyNotAvailable = 602; // Alquiler de propiedad ya no disponible
              break;
            case 603:
              this.isPropertyNotAvailable = 603; // Alquiler temporario de propiedad ya no disponible
              break;
            default:
              console.warn(response);
              break;
          }
        },
      });
  }

  private _fetchEntrepById(id: string, customAssociate: string) {
    this.entrepreunershipService
      .getData<Entrepreneurship>(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<Entrepreneurship>) => {
          switch (response.code) {
            case 200:
              this.processEntrepreneurshipData(response.data, customAssociate);
              this.generateResources();
              break;
            case 500:
              this.router.navigateByUrl('/');
              break;
            case 601:
              this.isPropertyNotAvailable = 601; // Venta de propiedad ya no disponible
              break;
            case 602:
              this.isPropertyNotAvailable = 602; // Alquiler de propiedad ya no disponible
              break;
            case 603:
              this.isPropertyNotAvailable = 603; // Alquiler temporario de propiedad ya no disponible
              break;
            default:
              console.warn(response);
              break;
          }
        },
        error: error => {
          console.error('Error occurred:', error);
        },
      });
  }

  private _fetchUnits(slug: string) {
    this.entrepreunershipBySlugService
      .getData<Listing[]>(
        slug + '/' + EntrepreunershipByIdService.URL_PATHS_UNITS
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<Listing[]>) => {
          if (response.code == 200) {
            response.data.forEach((e: any) => {
              if (e.type.value.includes('departamento')) {
                this.unitsTypes.departamentos.push(e);
              } else if (e.type.value.includes('casa')) {
                this.unitsTypes.casas.push(e);
              } else {
                this.unitsTypes[e.type.value].push(e);
              }
            });
            this.units = Object.assign({}, this.unitsTypes);
          }
        },
        error: error => {
          console.error('Error occurred:', error);
        },
      });
  }

  private _fetchUnitsById(id: string) {
    this.entrepreunershipByIdService
      .getData<Listing[]>(
        id + '/' + EntrepreunershipByIdService.URL_PATHS_UNITS
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<Listing[]>) => {
          if (response.code == 200) {
            response.data.forEach((e: any) => {
              if (e.type.value.includes('departamento')) {
                this.unitsTypes.departamentos.push(e);
              } else if (e.type.value.includes('casa')) {
                this.unitsTypes.casas.push(e);
              } else {
                this.unitsTypes[e.type.value].push(e);
              }
            });
            this.units = Object.assign({}, this.unitsTypes);
          }
        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
      });
  }

  private processEntrepreneurshipData(
    entrepreneurship: Entrepreneurship,
    customAssociate: string
  ) {
    this.entrepreneurship = entrepreneurship;
    this.setMetaTags(this.entrepreneurship);

    this.breadcrumbOperationTypeText = 'entrepreneurship.entrepreneurship';
    this.breadcrumbOperationTypeURL = '/emprendimientos';

    this.found = true;
    this.loading = false;

    if (customAssociate != null) {
      this.isCustomAgent = true;
      this._fetchDataAgentByInternalId(customAssociate);
    } else {
      if (entrepreneurship.associate) {
        this.agent = entrepreneurship.associate;
        this._fetchDataAuctioneer(entrepreneurship.associate);
      }
    }

    this.handleBreadcrumb();
  }

  private async _fetchDataAgentByInternalId(customAssociateInternalId: string) {
    try {
      const result: IQrResponse<Associate> =
        await this.associateService.findByInternalId(customAssociateInternalId);

      if (result.code == 200) {
        this.agent = result.data;
        this._fetchDataAuctioneer(result.data);
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private _fetchDataAuctioneer(agent: Associate) {
    if (!this.auctioneers) {
      this.loadAuctioneerInfo(agent.office.id);
    }
  }

  private async loadAuctioneerInfo(id: string): Promise<void> {
    try {
      const result: IQrResponse<Associate[]> =
        await this.associateService.findByIdAuctioneerOffice(id);
      if (result.code == 200) {
        this.auctioneers = result.data;
        const index = this.auctioneers.indexOf(
          this.auctioneers.find(e => e.id === this.agent.id) as Associate
        );
        if (index > -1) {
          this.auctioneers.splice(index, 1);
        }
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  // SE USARÁ EN EL PROXIMO SPRINT / NO BORRAR
  // generatePrefooter(): void {

  //   const coordinatesLatitud: string = this.entrepreneurship.location.coordinates[0];
  //   const coordinatesLongitud: string = this.entrepreneurship.location.coordinates[1];
  //   const radioProperty = '3000';

  //   const NEW_UBICATION =
  //     'withinradius-' +
  //     coordinatesLatitud +
  //     ':' +
  //     coordinatesLongitud +
  //     ':' +
  //     radioProperty;

  //   const entrepreneurshipType = this.entrepreneurship.type.replace('_', '-');

  //   // Tomamos la cantidad de ambientes de la propiedad
  //   const totalRoomSwitch = this.entrepreneurship.maxTotalRooms.toString();
  //   let totalRoomsFilter = '';
  //   switch (totalRoomSwitch) {
  //     case '1':
  //       totalRoomsFilter = 'de-1-ambiente-de-2-ambientes';
  //       break;
  //     case '2':
  //       totalRoomsFilter = 'de-1-ambiente-de-2-ambientes-de-3-ambientes';
  //       break;
  //     case '3':
  //       totalRoomsFilter = 'de-2-ambientes-de-3-ambientes-de-4-ambientes';
  //       break;
  //     case '4':
  //       totalRoomsFilter = 'de-3-ambientes-de-4-ambientes-de-5-ambientes';
  //       break;
  //     case '5':
  //       totalRoomsFilter = 'de-4-ambientes-de-5-ambientes-de-mas-6-ambientes';
  //       break;
  //     default:
  //       if (totalRoomSwitch != '0') {
  //         totalRoomsFilter = 'de-mas-6-ambientes';
  //       }
  //       break;
  //   }

  //   // Tomamos el precio de la propiedad
  //   const CURRENCY_PRICE = this.entrepreneurship.maxCurrency.value.toLocaleLowerCase();
  //   const ACTUAL_PRICE = this.entrepreneurship.maxPrice;

  //   const LESS_PRICE = ACTUAL_PRICE * 0.85;
  //   const MORE_PRICE = ACTUAL_PRICE * 1.15;

  //   const LESS_PRICE_ROUNDED = Math.ceil(LESS_PRICE);
  //   const MORE_PRICE_ROUNDED = Math.ceil(MORE_PRICE);

  //   let prefooterResultService =
  //     entrepreneurshipType +
  //     '-' +
  //     totalRoomsFilter +
  //     '-de-precio-' +
  //     CURRENCY_PRICE +
  //     '-' +
  //     LESS_PRICE_ROUNDED +
  //     '-' +
  //     MORE_PRICE_ROUNDED;

  //   // Sanitizamos la url y reemplazamos los caracteres especiales por "guiones medios"
  //   prefooterResultService = prefooterResultService.replace(/ /g, '-');
  //   prefooterResultService = prefooterResultService.replace(
  //     /[ !@#$%^&*()_+{}\[\];<>,?~\\\/'"`|]/g,
  //     '-'
  //   );
  //   prefooterResultService = prefooterResultService.replace(/--/g, '-');
  //   prefooterResultService = prefooterResultService.replace(/-y-/g, '-'); // Terrenos y lotes
  //   prefooterResultService += '-en-' + NEW_UBICATION;

  //   this.prefooterService
  //     .get(prefooterResultService)
  //     .then((response) => {
  //       this.relatedProperties = response.data.data;
  //       const elementoAEliminar = this.route.snapshot.params['slug']; // Supongamos que deseas eliminar el elemento con valor 40
  //       this.relatedProperties = this.relatedProperties.filter(
  //         (objeto) => objeto.slug !== elementoAEliminar
  //       );

  //       this.isRelatedPropertiesLoading = false;
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
}
