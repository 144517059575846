import { Component, Inject } from '@angular/core';
import {
  IButtonConfig,
  QrButtonComponent,
} from '@app/components/qr-button/qr-button.component';
import { Constants } from '@base/src/app/core/constants/constants';
import {
  L10nLocale,
  L10nTranslationService,
  L10N_LOCALE,
  L10nTranslationModule,
} from 'angular-l10n';
import { Router } from '@angular/router';
import { QRMediaImage } from '@app/core/models/qr-media-img';
import { QrMediaImgComponent } from '@app/components/qr-media-img/qr-media-img.component';
@Component({
  selector: 'app-404',
  templateUrl: './404.page.html',
  styleUrls: ['./404.page.scss'],
  standalone: true,
  imports: [QrButtonComponent, L10nTranslationModule, QrMediaImgComponent],
})
export class Error404Page {
  // SVG Error 404
  error404SVG: QRMediaImage = {
    text: 'Error 404',
    baseURL: '',
    docFolder: 'assets/media/svg/404',
    docName: 'error-404',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Error 404',
    width: '119px',
    height: '38px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Botón: Ir al inicio
  configButtonBackHome: IButtonConfig = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: this.translation.translate('messages.go_home') || 'messages.go_home',
    id: 'backHome',
    selected: false,
    isDisabled: false,
    changeStyleOnSelected: false,
    styleOnSelected: null,
  };

  constructor(
    private translation: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private readonly router: Router
  ) {}

  goHome(): void {
    this.router.navigate(['/']);
  }
}
