import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Listing } from '@app/core/models/listing.model';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import {
  ButtonWithIconConfig,
  QrButtonWithIconComponent,
} from '../qr-button-with-icon/qr-button-with-icon.component';
import { UtilsService } from '../../services/utils.service';
import { Entrepreneurship } from '@app/core/models/entrepreneurship.model';
import { Constants } from '@base/src/app/core/constants/constants';
import { MetersToHectarePipe } from '../../pipes/meters-to-hectare.pipe';
import { QrNumberPipe } from '../../pipes/qr-number.pipe';
import { QrSkeletonLoaderComponent } from '../qr-skeleton-loader/qr-skeleton-loader.component';

@Component({
  selector: 'qr-card-details-prop',
  templateUrl: './qr-card-details-prop.component.html',
  styleUrls: ['./qr-card-details-prop.component.scss'],
  standalone: true,
  imports: [
    L10nTranslationModule,
    MetersToHectarePipe,
    CommonModule,
    QrNumberPipe,
    QrSkeletonLoaderComponent,
    QrButtonWithIconComponent,
  ],
})
export class QrCardDetailsPropComponent implements OnInit {
  @Input() data!: Listing | Entrepreneurship;
  @Input() isEntrepreneurship: boolean = false;

  buttonViewMore: ButtonWithIconConfig = {
    text: 'Ver más',
    icon: 'plus',
    height: 32,
    style: 'button-color-grey-nero icon-color-primary',
    paddingHorizontal: 0,
    paddingVertical: 0,
  };

  buttonLessView: ButtonWithIconConfig = {
    text: 'Ver menos',
    icon: 'less',
    height: 32,
    style: 'button-color-grey-nero icon-color-primary',
    paddingHorizontal: 0,
    paddingVertical: 0,
  };

  buttonViewLess: ButtonWithIconConfig = {
    text: 'Ocultar',
    icon: 'less',
    height: 32,
    style: 'button-color-grey-nero icon-color-primary',
    paddingHorizontal: 0,
    paddingVertical: 0,
  };

  hideButton = false;
  showExtraFeatures = false;
  showExtraDescription = false;

  yearBuiltCalc = 0;

  features: any[] = [];

  readonly PROPERTY_TYPE_CAMPO = Constants.PROPERTY_TYPE_CAMPO;
  readonly PROPERTY_TYPE_CHACRA = Constants.PROPERTY_TYPE_CHACRA;
  readonly HECTARES = Constants.HECTARES;

  public toggleExtraFeatures(): void {
    this.showExtraFeatures = !this.showExtraFeatures;
  }

  public toggleExtraDescription(): void {
    this.showExtraDescription = !this.showExtraDescription;
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: any,
    public utilsService: UtilsService
  ) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const elem = document.getElementById('last');
        if (elem && elem.clientHeight < 88) {
          this.hideButton = true;
        }
      }, 1);
    }

    this.generateFeatures(this.data.features);

    if (!this.isEntrepreneurship) {
      this.data = this.data as Listing; // Pasamos la data como LISTING
      if (this.data && this.data.yearBuilt) {
        this.yearBuiltCalc = this.utilsService.getAntiquity(
          this.data.yearBuilt
        );
      }
    }
  }

  generateFeatures(array: any) {
    const titles: any[] = [];
    const data: any[] = [];
    array.forEach((element: any) => {
      if (!titles.includes(element.category)) {
        titles.push(element.category);
        data.push({ value: element.category, isTitle: true });
      }
      data.push(element);
    });
    this.features = data;
  }
}
