import { Icon } from './icon.model';

export class Tab {
  tabs: TabOption[] = [];
  tabSelected: number = 0;
  size: number = Tab.SIZE_32_PX;
  // Tamaños
  public static SIZE_48_PX = 48;
  public static SIZE_40_PX = 40;
  public static SIZE_32_PX = 32;
}

export interface TabOption {
  text?: string;
  id?: number | string;
  icon?: Icon;
}
