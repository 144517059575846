@if (!loadingDataUser) {
  <form [formGroup]="myFormGroup">
    <!-- Primer box de datos -->
    <h2 class="subheading-03-bold">Datos personales</h2>
    <div class="input-box">
      <mat-form-field class="qr-form-field" appearance="outline">
        <mat-label>Nombre</mat-label>
        <input matInput formControlName="firstName" name="firstName" />
        <mat-error>El campo no puede quedar vacío</mat-error>
      </mat-form-field>
    </div>
    <div class="input-box">
      <mat-form-field class="qr-form-field" appearance="outline">
        <mat-label>Apellido</mat-label>
        <input matInput formControlName="lastName" name="lastName" />
        <mat-error>El campo no puede quedar vacío</mat-error>
      </mat-form-field>
    </div>
    <!-- Segunda box de datos -->
    <h2 class="subheading-03-bold mrg-top">Datos de contacto</h2>
    <div class="input-box">
      <mat-form-field class="qr-form-field" appearance="outline">
        <input
          matInput
          formControlName="email"
          name="email"
          [disabled]="true" />
        <mat-error>error</mat-error>
      </mat-form-field>
    </div>
    <p class="subtitle optional">Opcional</p>
    <div class="input-box phone">
      <div class="phone-prefix-box">
        <app-countries-phone-list
          class="select-list-input-grey"
          [selected]="userData.prefixPhone"
          (selectedChange)="
            changePrefixMobile($event)
          "></app-countries-phone-list>
      </div>
      <mat-form-field class="qr-form-field" appearance="outline">
        <mat-label>Teléfono celular</mat-label>
        <input matInput formControlName="phone" name="phone" />
        @if (false) {
          <mat-error>error</mat-error>
        }
      </mat-form-field>
    </div>
    <div class="text-warning phone">Formato teléfono: 1162765648</div>
  </form>
  <div class="button-box">
    <qr-button
      [config]="this.configButtonContinue"
      (click)="updateUser()"></qr-button>
  </div>
} @else {
  <div class="loader-container">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
}

@if (successSave) {
  <div class="snackbar">Sus datos se han actualizado con éxito ✅</div>
}
