import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Constants } from '@base/src/app/core/constants/constants';
import { environment } from '@base/environments/environment';
import { Countries } from '@app/core/models/countries-phones.model';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-countries-phone-list',
  templateUrl: './countries-phone-list.component.html',
  styleUrls: ['./countries-phone-list.component.scss'],
  standalone: true,
  imports: [MatSelectModule, MatTooltipModule, ReactiveFormsModule],
})
export class CountriesPhoneListComponent implements OnChanges, OnInit {
  options: any;
  @Input() selected: any;
  @Output() selectedChange: EventEmitter<any> = new EventEmitter<any>();
  tooltip = '';
  myFormGroup: UntypedFormGroup;
  prefix = new UntypedFormControl('', []);
  node = environment.node;

  constructor(
    public countries: Countries,
    fb: UntypedFormBuilder
  ) {
    this.myFormGroup = fb.group({
      prefix: this.prefix,
    });
  }

  ngOnInit(): void {
    this.options = this.countries.getList();

    this.options.sort((a: any, b: any) =>
      a.country_en.localeCompare(b.country_en, 'ar')
    );

    this.options.forEach((e: any) => {
      e.img =
        '../../../assets/images/flag-icons-main/' + e.country_code + '.svg';
      e.phone_code = e.phone_code.toString();
    });

    //Argentina en primer lugar
    switch (this.node) {
      case Constants.NODE_ARGENTINA:
        this.options = this.firstElement(
          this.options,
          'Argentina',
          'country_en'
        );
        break;
      case Constants.NODE_URUGUAY:
        this.options = this.firstElement(this.options, 'Uruguay', 'country_en');
        break;
      default:
        break;
    }

    if (!this.selected) {
      this.tooltip = '';
    } else {
      const item = this.options.find((e: any) => e.phone_code == this.selected);
      this.tooltip = item.country_en + ' (+' + item.phone_code + ')';
    }

    //Suscribirse a los cambios en el formulario
    this.myFormGroup.valueChanges.subscribe(() => {
      this.changeSelection();
    });
  }

  ngOnChanges(): void {
    if (this.selected) {
      this.myFormGroup.controls['prefix'].setValue(this.selected);
    }
  }

  changeSelection(): void {
    this.selected = this.prefix.value;
    //     this.myFormGroup.controls['prefix'].setValue(this.selected);
    const item = this.options.find((e: any) => e.phone_code == this.selected);
    if (item) {
      this.tooltip = item.country_en + ' (+' + item.phone_code + ')';
      const prefix = '(+' + item.phone_code + ')';
      this.selectedChange.emit(prefix);
    }
  }

  firstElement(array: any, firstElement: any, key: any): any {
    const r: any[] = [];
    array.forEach((e: any) => {
      if (e[key] === firstElement) r.unshift(e);
      else r.push(e);
    });
    return r;
  }
}
