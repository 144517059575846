import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QRIcon } from '@app/core/models/qr-icon.model';
import { QRTextarea } from '@app/core/models/qr-textarea.model';
import { QRIconComponent } from '../qr-icon/qr-icon.component';
type TextareaValue = string | number | undefined;

@Component({
  selector: 'qr-textarea',
  templateUrl: './qr-textarea.component.html',
  styleUrls: ['./qr-textarea.component.scss'],
  standalone: true,
  imports: [CommonModule, QRIconComponent],
})
export class QRTextareaComponent {
  @Input() config?: QRTextarea;
  @Input() value: TextareaValue;
  @Input() isValid?: boolean = false;
  @Input() isDisabled?: boolean = false;
  @Input() hasError?: boolean = false;
  @Output() blurChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() textareaChange: EventEmitter<string | number | undefined> =
    new EventEmitter<string | number | undefined>();

  iconCheck: QRIcon = { name: QRIcon.NAME_CHECK, color: QRIcon.COLOR_PRIMARY };
  iconError: QRIcon = { name: QRIcon.NAME_ERROR, color: QRIcon.COLOR_RED };

  change(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value: TextareaValue = input.value;
    this.textareaChange.emit(value);
  }
}
