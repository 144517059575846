import {
  Component,
  OnInit,
  Input,
  Inject,
  PLATFORM_ID,
  OnChanges,
} from '@angular/core';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';
import { Associate } from '@app/core/models/associate.model';
import { Listing } from '@app/core/models/listing.model';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@base/environments/environment';
import { UtilsService } from '@app/services/utils.service';
import { PictureSource } from '@app/core/models/picture-source';
import { Constants } from '@base/src/app/core/constants/constants';
import { PictureSourceComponent } from '../ui/picture-source/picture-source.component';
import { Icon } from '../../core/models/icon.model';
import { Phone } from '../../core/models/phone.model';
import { AssociatePhone } from '../../core/models/associate-phone.model';
import { QrIconComponent } from '../qr-icon/qr-icon.component';

@Component({
  selector: 'qr-card-info-agent',
  templateUrl: './qr-card-info-agent.component.html',
  styleUrls: ['./qr-card-info-agent.component.scss'],
  standalone: true,
  imports: [PictureSourceComponent, QrIconComponent],
})
export class QrCardInfoAgentComponent implements OnInit, OnChanges {
  public NODE: string = environment.node;
  @Input() public data!: Associate;
  @Input() public property!: Listing;

  public readonly PHOTO_PICTURE_SOURCE: PictureSource = {
    text: Constants.FOLDER_AGENTS,
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  public agentResizedImage: string | undefined;

  public readonly NODE_ECUADOR: string = Constants.NODE_ECUADOR;
  public readonly USER_AGENT_TITLE_AGENT_LICENSED_BROKER: string =
    Constants.USER_AGENT_TITLE_AGENT_LICENSED_BROKER;

  public readonly ICON_PHONE: Icon = {
    name: Icon.NAME_PHONE,
    color: Icon.COLOR_GREY_NERO,
  };

  public readonly ICON_WHATSAPP: Icon = {
    name: Icon.NAME_WHATSAPP,
    color: Icon.COLOR_GREY_NERO,
  };

  public readonly ICON_EMAIL: Icon = {
    name: Icon.NAME_EMAIL,
    color: Icon.COLOR_GREY_NERO,
  };

  public messageContact: string =
    'Hola, quisiera que me contactes para obtener más información sobre algunas de tus propiedades disponibles';

  public phoneWhatsAppFormat: string | undefined;
  public phoneWhatsAppAPIFormat: string | undefined;
  public readonly PHONE_TYPE_MOBILE: string = Phone.TYPE_MOBILE;
  public readonly PHONE_TYPE_WORK: string = Phone.TYPE_WORK;
  private isBrowser: boolean;
  private readonly PHONE_PREFIX_ARGENTINA: string = Phone.PREFIX_ARGENTINA;
  private readonly PHONE_PREFIX_ARGENTINA_PLUS_9: string =
    Phone.PREFIX_ARGENTINA_PLUS_9;

  private readonly PHONE_PREFIX_ARGENTINA_PLUS_SPACE_9: string =
    Phone.PREFIX_ARGENTINA_PLUS_SPACE_9;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: any,
    private utilsService: UtilsService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.resizePhotosWebp();
    this.generatePhoneWhatsAppFormat();
  }

  ngOnChanges(): void {
    this.generateMessage();
    this.generateMetatags();
  }

  private generateMessage(): void {
    if (this.property && this.isBrowser) {
      this.messageContact =
        'Hola, te contacto por la siguiente propiedad: ' +
        this.property?.title +
        ' ' +
        window.location.origin +
        '/' +
        this.property.internalId;
    }
  }

  private generateMetatags(): void {
    if (this.data && !this.property) {
      let title =
        this.data.name + ' | Integrante del equipo de ' + this.data.office.name;
      let description =
        'Conocé a ' +
        this.data.name +
        ', integrante del equipo de ' +
        this.data.office.name +
        ' en ' +
        this.data.office.address +
        '.';
      if (this.data?.license) {
        title =
          this.data.name + ' - CPI parte del equipo ' + this.data.office.name;
        description =
          'Conocé a ' +
          this.data.name +
          ', Corredor Público Inmobiliario integrante del equipo de ' +
          this.data.office.name +
          ' en ' +
          this.data.office.address +
          '.';
      }
      this.utilsService.setPageMetaTags(title, description);
    }
  }

  private resizePhotosWebp(): void {
    if (this.data?.rawPhoto == null) {
      this.agentResizedImage = '';
      return;
    }
    this.agentResizedImage = this.utilsService.generateImageWithSize(
      this.data.rawPhoto,
      '180x180'
    );
  }

  private generatePhoneWhatsAppFormat(): void {
    if (this.data) {
      const PHONE: AssociatePhone | undefined = this.data?.phones?.find(
        phone => phone.type == this.PHONE_TYPE_MOBILE
      );
      if (PHONE?.value) {
        if (
          PHONE.value.includes(this.PHONE_PREFIX_ARGENTINA) &&
          !PHONE.value.includes(this.PHONE_PREFIX_ARGENTINA_PLUS_9) &&
          !PHONE.value.includes(this.PHONE_PREFIX_ARGENTINA_PLUS_SPACE_9)
        ) {
          this.phoneWhatsAppFormat = PHONE.value;
          this.phoneWhatsAppFormat = this.phoneWhatsAppFormat.replace(
            this.PHONE_PREFIX_ARGENTINA,
            this.PHONE_PREFIX_ARGENTINA_PLUS_SPACE_9
          );
        } else {
          this.phoneWhatsAppFormat = PHONE.value;
        }
      }
      if (this.phoneWhatsAppFormat) {
        this.phoneWhatsAppAPIFormat = this.phoneWhatsAppFormat.replace(
          /\(|\)|\s+/g,
          ''
        ); // Eliminar espacios y paréntesis.
      }
    }
  }
}
