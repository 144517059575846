import { ContactStatus } from './contact-status.model';

export class ContactSell {
  id: string = '';
  name: string = '';
  phone: string = '';
  email: string = '';
  message: string = '';
  ubication: string = '';
  typeSell: number = 0;
  rooms: number = 0;
  status: ContactStatus = new ContactStatus();
  localidadProvincia: string = '';
  description: string = '';
}
