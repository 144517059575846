import { Component, Inject, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { Constants } from '@base/src/app/core/constants/constants';
import { QRMediaImage } from '@app/core/models/qr-media-img';
import { UtilsService } from '@app/services/utils.service';
import { environment } from '@base/environments/environment';
import { Router } from '@angular/router';
import { QrMediaImgComponent } from '@app/components/qr-media-img/qr-media-img.component';
import { CommonModule } from '@angular/common';
import { QrButtonComponent } from '@app/components/qr-button/qr-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.page.html',
  styleUrls: ['./consumer.page.scss'],
  standalone: true,
  imports: [
    L10nTranslationModule,
    QrMediaImgComponent,
    CommonModule,
    QrButtonComponent,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatFormFieldModule,
  ],
})
export class ConsumerPage implements OnInit {
  // Banner Información al consumidor
  bannerCustomerInformation: QRMediaImage = {
    text: 'Banner Información al consumidor',
    baseURL: '',
    docFolder: 'assets/media/jpg/consumer-information',
    docName: 'banner-consumer-information',
    docType: Constants.DOCTYPE_JPG,
    alt: 'Banner Información al consumidor',
    width: '100%',
    height: 'auto',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private utils: UtilsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (environment.node != Constants.NODE_ARGENTINA) {
      this.router.navigate(['/']); // CONSUMER solo habilitado en ARGENTINA
    }

    const title = 'Información Al Consumidor';
    const description =
      'Te contamos los detalles que debés tener en cuenta al momento de realizar una operación en la Ciudad de Buenos Aires. ¡Con RE/MAX mantenete informado!';

    this.utils.setPageMetaTags(title, description);
  }
}
