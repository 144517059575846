<nav>
  <!-- Topbar: Desktop -->
  <div class="flex-row container flex-space-between-center hide-lt-md">
    <div class="remax-logo">
      <a href="" id="logo-a">
        <qr-media-img id="logo" [config]="logoREMAXCountrySVG"></qr-media-img>
      </a>
    </div>

    <div class="nav-actions">
      <ul>
        @for (item of menuItems; track item) {
          <li
            [ngClass]="{ 'is-active': item.link && selectedLinks[item.link] }">
            <!-- Enlaces externos -->
            @if (item.link?.includes('http')) {
              <a [href]="item.link" target="_blank" role="button">
                {{ item.label | translate: locale.language }}
              </a>
            } @else {
              <!-- Enlaces internos -->
              <a
                [ngClass]="
                  item.link && selectedLinks[item.link]
                    ? 'selected'
                    : 'unselected'
                "
                [href]="item.link"
                role="button">
                {{ item.label | translate: locale.language }}
              </a>
            }
            <!-- Línea -->
            @if (shouldShowLine(item.activeIsContain)) {
              <div class="line"></div>
            }
          </li>
        }
      </ul>
    </div>
    @if (utilsService.isVisibleUserRegister(node)) {
      <div class="account-menu">
        @if (userLoged && loading) {
          <div class="skeleton-custom">
            <div class="user-img"></div>
            <div class="user-name"></div>
          </div>
        }
        @if (!userLoged && loading) {
          <skeleton-loader class="skeleton-loader-btn-login"></skeleton-loader>
        }
        @if (!userLoged && !loading) {
          <div (click)="onLogin()" class="unlogged">
            <qr-button [config]="this.configButtonLogin"></qr-button>
          </div>
        }
        @if (userLoged && !loading) {
          <div
            class="account-menu-button"
            [matMenuTriggerFor]="menu"
            matRipple
            aria-hidden="true"
            (click)="menuAccountOpen = true">
            <qr-icon-button
              icon="person-outlined"
              [config]="configButtonProfile"></qr-icon-button>
            <p>
              {{ this.userData.name ? this.userData.name : 'Nombre' }}
              {{ this.userData.lastname ? this.userData.lastname : 'Apellido' }}
            </p>
            <qr-media-img
              [config]="iconArrowRightSVG"
              class="arrow"
              [ngClass]="{ open: menuAccountOpen }"></qr-media-img>
          </div>
          <!-- Contenido del Menú -->
          <mat-menu #menu="matMenu" (closed)="menuAccountOpen = false">
            <a
              [href]="'/favoritos'"
              [ngClass]="{ selected: isRouteActive('/favoritos') }">
              <qr-media-img [config]="iconFavoriteOutlineSVG"></qr-media-img>
              Favoritos
            </a>
            <a
              [href]="'/busquedas'"
              [ngClass]="{ selected: isRouteActive('/busquedas') }">
              <qr-media-img [config]="iconSaveSearchOutlineSVG"></qr-media-img>
              Búsquedas
            </a>
            <a
              (click)="onAccountSettings()"
              [ngClass]="{ selected: isRouteActive('/cuenta/datos') }">
              <qr-media-img [config]="iconPersonOutlineSVG"></qr-media-img>
              Cuenta
            </a>
            <a (click)="onLogout()" aria-hidden="true">
              <qr-media-img [config]="iconLogoutSVG"></qr-media-img>
              Cerrar sesión
            </a>
          </mat-menu>
        }
      </div>
    }
  </div>

  <!-- Topbar: Mobile TODO: Unificar en una sola topbar que se adapte para no repetir codigo -->
  <div class="flex-row container flex-space-between-center hide-gt-sm">
    <div>
      <qr-icon-button
        icon="menu"
        (click)="openSideNav()"
        [config]="configButtonMenu"
        [ariaLabel]="'Menu mobile'"></qr-icon-button>
    </div>
    <div class="remax-logo">
      <a href="">
        <qr-media-img
          id="logo"
          [config]="logoREMAXHorizontalCountrySVG"></qr-media-img>
      </a>
    </div>
    <div>
      @if (node !== 'ec') {
        <qr-icon-button
          icon="person-outlined"
          (click)="openSideNavAccount()"
          [config]="configButtonProfile"
          [ariaLabel]="'Account sidebar'"></qr-icon-button>
      }
    </div>
  </div>
</nav>
