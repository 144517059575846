<div id="qr-certificates-container" class="flex-col">
  <div id="title"><h3>Certificaciones</h3></div>
  <div id="certificates" class="flex-row flex-start-start">
    @for (item of certificates; track item) {
      <div class="item-container-mobile">
        <img class="item-img" src="" alt="Certificate" loading="lazy" />
      </div>
    }
  </div>
</div>
