<!-- Galería de fotos: pantalla completa-->
<qr-photos-fullscreen
  [options]="configPhotosFullscreen"
  [photos]="photos"
  (onchange)="photosFullscreenChanged($event)">
</qr-photos-fullscreen>

<!-- Galería de fotos: dentro de la publicación -->
@if (photos && photos.length > 0) {
  <div class="swiper">
    <div class="swiper__wrapper">
      <swiper-container
        appSwiper
        #photosSwiper
        [config]="SWIPER_PHOTOS"
        init="false"
        (indexChange)="galleryIndexChange($event)">
        @for (photo of resizedImages; track photo) {
          <swiper-slide
            class="swiper__image"
            aria-hidden="true"
            (click)="openFullscreen()">
            <picture>
              <source
                media="(min-width: 1280px)"
                [attr.srcset]="photo.webpL"
                type="image/webp" />
              <source
                media="(min-width: 1px)"
                [attr.srcset]="photo.webpS"
                type="image/webp" />
              <source
                media="(min-width: 1280px)"
                [attr.srcset]="photo.jpgL"
                type="image/jpeg" />
              <source
                media="(min-width: 1px)"
                [attr.srcset]="photo.jpgS"
                type="image/jpeg" />
              <img
                alt=""
                [attr.src]="photo.jpgL"
                (error)="handleImageError($event)"
                class="swiper-lazy" />
            </picture>
            <div class="swiper-lazy-preloader"></div>
          </swiper-slide>
        }
      </swiper-container>
      <!-- Paginación -->
      <div class="swiper__pagination">
        <p>
          {{ configPhotosFullscreen.indexSelected + 1 }}/{{ photos.length }}
        </p>
      </div>
      <!-- Navegación de swiper -->
      <div class="swiper__prev" matRipple></div>
      <div class="swiper__next" matRipple></div>
    </div>
    <!-- Miniaturas para navegar (Thumbnails) -->
    <swiper-container
      class="swiper__thumbnails hide-lt-lg"
      appSwiper
      #thumbnailSwiper
      init="false"
      [config]="SWIPER_THUMBNAIL"
      (indexChange)="thumbnailsIndexChange($event)">
      @for (photo of resizedImages; track photo; let i = $index) {
        <swiper-slide class="swiper-slide">
          <picture>
            <source [attr.srcset]="photo.webpXS" type="image/webp" />
            <source [attr.srcset]="photo.jpgXS" type="image/jpeg" />
            <img
              aria-hidden="true"
              (click)="slideTo(i)"
              [id]="'thumbnail-' + i"
              [attr.src]="photo.jpgXS"
              (error)="handleImageError($event)"
              class="swiper-lazy swiper__thumbnail-img"
              alt="" />
          </picture>
          <div class="swiper-lazy-preloader"></div>
        </swiper-slide>
      }
    </swiper-container>
  </div>
} @else {
  <div class="no-photos">
    <div class="no-photos__illustration"></div>
    <p>No hay fotos disponibles</p>
  </div>
}
