<mat-sidenav-container id="layout-collection-wrapper">
  <!-- Menú: Mobile -->
  <mat-sidenav #drawer [mode]="'over'">
    <!-- Botón: Cerrar -->
    <qr-icon-button
      (click)="closeSideNav()"
      id="menu-mobile-button-close"
      icon="close"
      [config]="this.configMenuButtonClose">
    </qr-icon-button>
    <!-- Listado de secciones -->
    <mat-nav-list>
      @for (item of menuItems; track item) {
        <div (click)="closeSideNav()" class="mobile-nav-item" matRipple>
          @if (item.link === '/comprar-propiedades') {
            <a (click)="applyFilterAndRedirect()" role="button">
              {{ item.label | translate: locale.language }}
            </a>
          } @else {
            @if (!item.link?.includes('http')) {
              <a [href]="item.link" role="button">
                {{ item.label | translate: locale.language }}
              </a>
            }
            @if (item.link?.includes('http')) {
              <a [href]="item.link" target="_blank" role="button">
                {{ item.label | translate: locale.language }}
              </a>
            }
          }
        </div>
      }
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav #drawerProfile [mode]="'over'">
    <!-- Botón: Cerrar -->
    <qr-icon-button
      (click)="closeSideNav()"
      id="menu-mobile-button-close"
      icon="close"
      [config]="this.configMenuButtonClose">
    </qr-icon-button>
    <!-- Listado de secciones -->
    <mat-nav-list>
      @for (item of menuItems; track item) {
        <div (click)="closeSideNav()" class="mobile-nav-item" matRipple></div>
      }
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <app-collection-navbar
      (onOpenSideNav)="openSideNav()"></app-collection-navbar>
    <div class="main-content">
      <div class="page-content">
        <router-outlet></router-outlet>
      </div>
    </div>
    <app-footer [isCollection]="true"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
