import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Associate } from '@app/core/models/associate.model';
import { environment } from '@base/environments/environment';
import { Constants } from '@base/src/app/core/constants/constants';
import { PictureSource } from '@app/core/models/picture-source';
import { UtilsService } from '@app/services/utils.service';
import { PictureSourceComponent } from '../ui/picture-source/picture-source.component';

@Component({
  selector: 'qr-card-office-member',
  templateUrl: './qr-card-office-member.component.html',
  styleUrls: ['./qr-card-office-member.component.scss'],
  standalone: true,
  imports: [PictureSourceComponent],
})
export class QrCardOfficeMemberComponent implements OnInit, OnChanges {
  @Input() data: Associate | null = null;
  url: string = '';
  resizedImageWebp: string = '';
  imageBucketResizeUrl: string = environment.imageBucketResizeUrl;
  imageAgentError = false;

  // Picture and Source
  officesResizedImages: PictureSource = {
    text: Constants.FOLDER_OFFICES,
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  constructor(private utils: UtilsService) {}

  ngOnInit(): void {
    this.resizePhotosWebp();
  }

  ngOnChanges(): void {
    let title: string;
    switch (this.data?.title) {
      // USERS ROLES BROKER
      case Constants.USER_BROKER_TITLE_OFFICE_OWNER:
      case Constants.USER_BROKER_TITLE_OFFICE_OWNER_LICENSED_BROKER:
        title = Constants.USER_ROLE_BROKER; // Usamos el rol para crear la URL
        break;
      // USERS ROLES AGENT
      case Constants.USER_AGENT_TITLE_IN_TRAINING:
      case Constants.USER_AGENT_TITLE_AGENT:
      case Constants.USER_AGENT_TITLE_AGENT_LICENSED_BROKER:
        title = Constants.USER_ROLE_AGENT; // Usamos el rol para crear la URL
        break;
      // USERS ROLES STAFF
      case Constants.USER_STAFF_TITLE_ADMIN_STAFF:
      case Constants.USER_STAFF_TITLE_LICENSED_BROKER:
      case Constants.USER_STAFF_TITLE_MANAGER:
      case Constants.USER_STAFF_TITLE_ADMIN_MANAGER:
        title = Constants.USER_ROLE_AGENT; // Usamos el rol para crear la URL
        break;
      default:
        title = Constants.USER_ROLE_AGENT; // Usamos el rol para crear la URL
        break;
    }
    this.url = '/' + title.toLowerCase() + '/' + this.data?.slug.toLowerCase();
  }

  resizePhotosWebp(): void {
    if (this.data?.rawPhoto == null) {
      this.resizedImageWebp = '';
      return;
    }

    this.resizedImageWebp = this.utils.generateImageWithSize(
      this.data.rawPhoto,
      '180x180'
    );
  }
}
