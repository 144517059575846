import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-user-account',
  templateUrl: './user-account.component.html',
  styleUrls: ['./user-account.component.scss'],
  standalone: true,
  imports: [RouterModule],
})
export class UserAccountPage implements OnInit {
  contentVisible = true;
  isLoading = false;
  loggedinBySocialNetwork = true;

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('Mi cuenta | RE/MAX');
    this.currentUser();
  }

  async currentUser(): Promise<any> {
    this.isLoading = true;
    return await Auth.currentAuthenticatedUser().then(response => {
      if (response && response.username) {
        const username = response.username.toString().toLowerCase();
        this.loggedinBySocialNetwork =
          username.includes('facebook') || username.includes('google')
            ? true
            : false;
        this.isLoading = false;
      }
    });
  }

  onVisibleContent(): void {
    this.contentVisible = false;
    setTimeout(() => {
      this.contentVisible = true;
    }, 1);
  }
}
