@if (found && auctioneer) {
<div id="office-detail-container">
  <div id="banner">
    <div id="banner-photo"></div>
  </div>
  <div class="container">
    <div fxFlex fxlayout.gt-sm="row" fxLayout.lt-md="column" class="row">
      <!-- Columna 1 -->
      <!-- <div fxFlex fxFlex="34" fxFlex.lt-md="100" class="col-padding" id="first-column"> -->
      <div id="first-column">
        <!-- Broker -->
        @if (auctioneer) {
        <qr-card-info-agent [data]="auctioneer"></qr-card-info-agent>
        }
        <!-- Disclaimer (Argentina) -->
        @if (node === 'ar') {
        <qr-biography-card
          title="agentDetail.disclaimer"
          data="disclaimer.public-real-state-broker"
          class="biography-container">
        </qr-biography-card>
        }
        <!--
          <div *ngIf="this.auctioneer.certificates" class="biography-container">
            <qr-certificates-card [certificates]="this.auctioneer.certificates"></qr-certificates-card>
          </div>
          -->
        <!--
          <div class="biography-container" *ngIf="this.auctioneer.languages">
            <qr-card-languages [data]="this.auctioneer.languages"></qr-card-languages>
          </div>
          -->
      </div>
      <div id="second-column" layout="column" fxFlex fxFlex.gt-sm="100">
        <!-- Corredor público inmobiliario  -->
        @if (auctioneerOffices && auctioneerOffices.length>0) {
        <div>
          <h2 class="title-member">Corredor público inmobiliario</h2>
          <div class="office-members">
            @for (auctioneerOffice of auctioneerOffices; track auctioneerOffice;
            let i = $index) {
            <qr-card-office-member
              [data]="auctioneerOffice"></qr-card-office-member>
            }
          </div>
        </div>
        }
        <!-- Corredor asociado  -->
        @if (auctioneerAssociates && auctioneerAssociates.length>0) {
        <div>
          <h4 class="title-member">Corredor asociado</h4>
          <div class="office-members">
            @for (auctioneerAssociate of auctioneerAssociates; track
            auctioneerAssociate; let i = $index) {
            <qr-card-office-member
              [data]="auctioneerAssociate"></qr-card-office-member>
            }
          </div>
        </div>
        }
        <!-- Equipo  -->
        @if (teams && teams.length>0) {
        <div>
          <h2 class="title-member">Equipo</h2>
          <div class="team-staff-members">
            @for (team of teamsShort; track team; let i = $index) {
            <qr-card-office-member [data]="team"> </qr-card-office-member>
            }
          </div>
          @if (teamsShort && teamsShort.length > 3) {
          <qr-button
            class="more-button"
            [config]="configButtonAllFeatured"
            (eventClick)="onClickSeeAll()">
          </qr-button>
          }
        </div>
        }
        <!-- STAFF  -->
        @if (staffs && staffs.length>0) {
        <div>
          <h2 class="title-member">Staff administrativo</h2>
          <div class="team-staff-members">
            @for (staff of staffs; track staff; let i = $index) {
            <qr-card-office-member [data]="staff"> </qr-card-office-member>
            }
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</div>
}
