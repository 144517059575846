import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { GeoSearch } from '../geo-search/geo-search.model';

import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ItemParam } from '@app/core/models/ItemParam';
import { SearchDomain } from '../../domain/search.domain';
import { ParamsDomain } from '../../domain/params.domain';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { LocationsSearcherComponent } from '../locations-searcher/locations-searcher.component';
import { QrIconComponent } from '../qr-icon/qr-icon.component';
import { Icon } from '../../core/models/icon.model';

@Component({
  selector: 'qr-searchbar',
  templateUrl: './qr-searchbar.component.html',
  styleUrls: ['./qr-searchbar.component.scss'],
  standalone: true,
  imports: [
    MatProgressSpinnerModule,
    L10nTranslationModule,
    MatOptionModule,
    MatSelectModule,
    ReactiveFormsModule,
    LocationsSearcherComponent,
    QrIconComponent,
  ],
})
export class QrSearchbarComponent {
  public geoLocationFormControl: UntypedFormControl = new UntypedFormControl(); // Input de GeoLocation donde se detecta cambios
  @Input() public isSynonymousSearchEnabled = true; // Activar búsqueda con sinónimos
  propertyTypesFormControl = new UntypedFormControl(); // Input de tipo de propiedad donde se detecta cambios

  @Output() onSearch = new EventEmitter<any>(); // Data que se le envia al componente padre al clickear botón buscar

  @Input() style: string = '';

  listingTypes$: Promise<ItemParam[]>;
  friendlyListingTypes: ItemParam[];
  selectedFilterListingType: number[] = [];
  casaIds: number[] = [];
  departamentosIds: number[] = [];
  INNER_CASA_ID = 100;
  INNER_DEPARTAMENTO_ID = 101;

  readonly ICON_SEARCH: Icon = {
    name: Icon.NAME_SEARCH,
    color: Icon.COLOR_WHITE,
  };

  constructor(
    //	private geoSearchService: GeoSearchService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public paramsDomain: ParamsDomain,
    public searchDomain: SearchDomain
  ) {
    this.friendlyListingTypes = [];
    this.listingTypes$ = this.paramsDomain.getListingsTypes();

    this.listingTypes$.then(response => {
      // 👽 En la respuesta de listingTypes el dato "casa_comercial" se cambió el nombre a "house_comercial" ya que todos los datos con el nombre "casa" se agregan en ARG como un subfiltro temporal 👽

      const listingsTypesTmp: ItemParam[] = [];
      this.casaIds = [];
      this.departamentosIds = [];

      // 👽 El Filtro temporal sucede acá 👽
      listingsTypesTmp.push(new ItemParam(this.INNER_CASA_ID, 'casa', null, 5));

      listingsTypesTmp.push(
        new ItemParam(this.INNER_DEPARTAMENTO_ID, 'departamento', null, 1)
      );

      const filterTypes = response.filter(option => {
        if (option.value.includes('casa')) {
          this.casaIds.push(option.id);
          return false;
        }
        if (option.value.includes('departamento')) {
          this.departamentosIds.push(option.id);
          return false;
        } else {
          return true;
        }
        /*
        if (option.id == 12) {
          return false;
        } else {
          return true;
        }
        */
      });
      this.friendlyListingTypes = listingsTypesTmp.concat(filterTypes);
      this.friendlyListingTypes = this.friendlyListingTypes.sort(
        (a: ItemParam, b: ItemParam) => {
          if (a.order > b.order) {
            return 1;
          } else {
            return -1;
          }
        }
      );
    });
  }

  search(): void {
    this.onSearch.emit();
  }

  locationsSearcherChange(e: GeoSearch[]): void {
    if (e !== null) {
      this.searchDomain.filterGeoList = e;
    }
  }

  /*
  geoSelect: Al seleccionar una opción del autocomplete de ubicaciones
  */
  geoSelect(evt: GeoSearch[]) {
    if (evt !== null) {
      this.searchDomain.filterGeoList = evt;
    }
  }

  listingTypesSelected(matSelectChange: MatSelectChange): void {
    // Aca no actualizamos la logica por que van a pasar por la url y luego carga en la logica
    let selection: number[] = matSelectChange.value;
    const indexCasa = selection.findIndex(
      (option: number) => option == this.INNER_CASA_ID
    );
    if (indexCasa >= 0) {
      selection.splice(indexCasa, 1);
      selection = selection.concat(this.casaIds);
    }
    const indexDepartamento = selection.findIndex(
      (option: number) => option == this.INNER_DEPARTAMENTO_ID
    );

    if (indexDepartamento >= 0) {
      selection.splice(indexDepartamento, 1);
      selection = selection.concat(this.departamentosIds);
    }

    this.searchDomain.filterListingType = [...new Set(selection)];
    //this.listingTypeChanged.emit(matSelectChange.value);
  }
}
