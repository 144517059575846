@if (!registerSuccess) {
  <div class="container-user-register">
    <form [formGroup]="myFormGroup">
      <!-- Primer box de datos -->
      <h4>Ingresá tus datos para crear tu cuenta</h4>
      <div class="input-box">
        <mat-form-field class="qr-form-field" appearance="outline">
          <mat-label>Email</mat-label>
          <input
            matInput
            formControlName="email"
            name="email"
            autocomplete="new-email" />
          <mat-error>{{ getErrorMessage('email') }}</mat-error>
        </mat-form-field>
      </div>
      <div class="input-box">
        <mat-form-field class="qr-form-field" appearance="outline">
          <mat-label>Contraseña</mat-label>
          <input
            matInput
            formControlName="password"
            name="password"
            autocomplete="new-password"
            [type]="showPassword ? 'text' : 'password'" />
          <mat-error>{{ getErrorMessage('password') }}</mat-error>
        </mat-form-field>
        <div class="see-password">
          <qr-icon-button
            [icon]="showPassword ? 'eye-cross-out' : 'eye'"
            (click)="onSeePassword()"
            [config]="configButtonSeePassword"></qr-icon-button>
        </div>
      </div>
      @if (!this.passwordError) {
        <mat-hint class="text-warning">
          Usá un mínimo de 6 caracteres,números y letras
        </mat-hint>
      }
      <div class="line"></div>
      <!-- Segunda box de datos -->
      <h4>Datos</h4>
      <div class="input-box">
        <mat-form-field class="qr-form-field" appearance="outline">
          <mat-label>Nombre</mat-label>
          <input matInput formControlName="firstName" name="firstName" />
          <mat-error>{{ getErrorMessage('firstName') }}</mat-error>
        </mat-form-field>
      </div>
      <div class="input-box">
        <mat-form-field class="qr-form-field" appearance="outline">
          <mat-label>Apellido</mat-label>
          <input matInput formControlName="lastName" name="lastName" />
          <mat-error>{{ getErrorMessage('lastName') }}</mat-error>
        </mat-form-field>
      </div>
      <p class="optional-title">Opcional</p>
      <div class="input-box phone">
        <div class="phone-prefix-box">
          <app-countries-phone-list
            class="select-list-input-grey"
            [selected]="prefixPhone"
            (selectedChange)="
              changePrefixMobile($event)
            "></app-countries-phone-list>
        </div>
        <mat-form-field class="qr-form-field" appearance="outline">
          <mat-label>Teléfono celular</mat-label>
          <input matInput formControlName="phone" name="phone" />
        </mat-form-field>
      </div>
      <div class="text-warning phone">Formato teléfono: 1162765648</div>
    </form>

    <div class="msg-terms">
      <div class="flex">
        <div class="checkbox-container">
          <mat-checkbox
            color="primary"
            [(ngModel)]="checkboxTerms"
            (ngModelChange)="enableRegisterButton()">
          </mat-checkbox>
        </div>
        <div class="terms">
          <span>Al registrarme acepto los</span>
          <a href="/terminos-y-condiciones"> términos, condiciones</a>
          <span> y </span>
          <a href="/politica-de-privacidad">politicas de privacidad</a>
        </div>
      </div>
    </div>

    <div class="button-box">
      <qr-button
        [config]="this.configButtonContinue"
        (click)="onSubmit()"></qr-button>
    </div>
    @if (loading) {
      <div class="loader-container">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
    }
    @if (this.errorServiceLoginMessage) {
      <div class="text-error service-error">
        {{ this.errorServiceLoginMessage }}
      </div>
    }

    <p class="paragraph-01-regular sesion">
      Si tenés cuenta <a href="/iniciar-sesion">Iniciá sesión</a>
    </p>
  </div>
} @else {
  <div class="container-user-register">
    <div class="success-register">
      <div class="check-icon">
        <qr-media-img [config]="iconCheckSVG"></qr-media-img>
      </div>
      <h2 class="subheading-03-bold">Tu cuenta fue creada con éxito.</h2>
      <p class="paragraph-01-regular sesion">
        Revisá tu bandeja de entrada para confirmar tu email e
        <a href="/iniciar-sesion">Iniciar sesión</a>
      </p>
    </div>
  </div>
}
