import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import {
  L10N_LOCALE,
  L10nLocale,
  L10nTranslationModule,
  L10nTranslationService,
} from 'angular-l10n';
import { environment } from '@base/environments/environment';
import { UtilsService } from '../../services/utils.service';
import { FavoritesService } from '../../services/favorites.service';
import { Auth } from 'aws-amplify';
import { CountClicksContactsService } from '../../services/count-clicks-contacts.service';
import { ActivatedRoute } from '@angular/router';
import { QrTagComponent } from '../qr-tag/qr-tag.component';
import { QrIconButtonComponent } from '../qr-icon-button/qr-icon-button.component';
import { ButtonComponent } from '../ui/button/button.component';
import { PictureSourceComponent } from '../ui/picture-source/picture-source.component';
import { MetersToHectarePipe } from '../../pipes/meters-to-hectare.pipe';
import { QrNumberPipe } from '../../pipes/qr-number.pipe';
import { Subscription } from 'rxjs';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '../../directive/swiper.directive';
import { ListingAndEntrepreneurship } from '../../core/interfaces/listing-and-entrepreneurship.interface';
import { Associate } from '../../core/models/associate.model';
import { Constants } from '../../core/constants/constants';
import { Button } from '../../core/models/button.model';
import { Icon } from '../../core/models/icon.model';
import { PictureSource } from '../../core/models/picture-source';
import { Tag } from '../../core/models/tag.model';
import { FavoriteItem } from '../../core/interfaces/favorite-item.interface';

@Component({
  selector: 'qr-card-property',
  templateUrl: './qr-card-property.component.html',
  styleUrls: ['./qr-card-property.component.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    L10nTranslationModule,
    QrTagComponent,
    QrIconButtonComponent,
    ButtonComponent,
    PictureSourceComponent,
    CommonModule,
    MetersToHectarePipe,
    QrNumberPipe,
    SwiperDirective,
  ],
})
export class QrCardPropertyComponent implements OnInit, OnDestroy, OnChanges {
  public static readonly CARD_EVENT_OPEN_DIALOG_CREATE_ACCOUNT =
    'CARD_EVENT_OPEN_DIALOG_CREATE_ACCOUNT';

  public static readonly CARD_EVENT_CLOSE_CARD = 'CARD_EVENT_CLOSE_CARD';
  @Input() data!: ListingAndEntrepreneurship;

  @Input() dataPerson: Associate | null = null;
  public isEntrepreneurship: boolean = false;

  @Input() isAgentThumbnailVisible = true;
  @Input() isImageSwiper = true;
  @Input() isImageNotFound = true;

  @Input() isFavoriteBtnVisible: boolean | undefined;
  @Input() isDescriptionVisible = true;

  @Input() isViewList: boolean = true;
  @Input() isViewGrid: boolean = true;

  @Input() isWhatsAppBtnGrid = true;
  @Input() isWhatsAppBtnList = true;

  @Input() isOverlayMode = false;

  @Input() status: { isVisible: boolean } = { isVisible: false };

  @Output() cardChange: EventEmitter<string> = new EventEmitter<string>();

  uuidAssociateId: string = '';
  uuidInternalId: string = '';
  uuidListingTitle: string = '';
  uuidPhoneValue: string = '';
  uuidEmailValue: string = '';
  textInformationType: string = '';

  informationWhatsApp: string = '';
  urlContactWhatsApp: string = '';
  urlContactPhone: string = '';
  informationEmail: string = '';
  urlContactEmail: string = '';

  urlBaseRemax: string = environment.host;

  statusClassTags = '';
  stageClassTags = '';

  readonly LISTING_STATUS_ACTIVE: string = Constants.LISTING_STATUS_ACTIVE;

  isDesktop: boolean = isPlatformBrowser(this.platformId)
    ? window.innerWidth >= 600
    : false;

  readonly PROPERTY_TYPE_CAMPO: string = Constants.PROPERTY_TYPE_CAMPO;
  readonly PROPERTY_TYPE_CHACRA: string = Constants.PROPERTY_TYPE_CHACRA;

  buttonWhatsappSolidOnlyIcon: Button = {
    id: 'btn-green-solid',
    hierarchy: Button.HIERARCHY_GREEN_SOLID,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_WHATSAPP,
    iconOnly: true,
    iconTooltipOnly: 'Enviar WhatsApp',
    ariaLabel: 'Enviar WhatsApp',
  };

  buttonWhatsappSolidWithIcon: Button = {
    id: 'btn-green-solid',
    hierarchy: Button.HIERARCHY_GREEN_SOLID,
    size: Button.SIZE_40_PX,
    value: 'Whatsapp',
    icon: Icon.NAME_WHATSAPP,
    ariaLabel: 'WhatsApp',
  };

  buttonPhoneSolidOnlyIcon: Button = {
    id: 'btn-primary-border',
    hierarchy: Button.HIERARCHY_PRIMARY_BORDER,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_PHONE,
    iconOnly: true,
    iconTooltipOnly: 'Llamar ahora',
    ariaLabel: 'Llamar ahora',
  };

  buttonEmailSolidOnlyIcon: Button = {
    id: 'btn-primary-border',
    hierarchy: Button.HIERARCHY_PRIMARY_BORDER,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_EMAIL,
    iconOnly: true,
    iconTooltipOnly: 'Enviar un email',
    ariaLabel: 'Enviar un email',
  };

  buttonFavoriteSolidOnlyIcon: Button = {
    id: 'btn-grey-border',
    hierarchy: Button.HIERARCHY_GREY_BORDER,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_FAVORITE,
    iconOnly: true,
    iconTooltipOnly: 'Agregar a favoritos',
    ariaLabel: 'Agregar a favoritos',
  };

  hasPhotos: boolean = false;
  isBrowser: boolean = false;
  public propertyPhotos: { rawValue: string }[] = [];
  resizedImageWebp: string = '';
  imagePropetyError = false;
  imageAgentError = false;
  // FAVORITES
  favoriteResizedImageWebp: string = '';
  replaceFolderFavorites: string = '';
  public innerWidth: number = 0;
  // Ancho del browser
  node = environment.node;
  imageBucketResizeUrl: string = environment.imageBucketResizeUrl;
  configButtonFavorite = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    iconTooltipOnly: '',
  };

  configButtonClose: Button = {
    id: 'btn-tonal-border',
    hierarchy: Button.HIERARCHY_GREY_BORDER,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_CLOSE,
    iconOnly: true,
  };

  isFavorite = false;
  userLogged = false;
  // Picture and Source
  propertyPhotosPictureSource: PictureSource = {
    text: Constants.FOLDER_LISTINGS,
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_EAGER,
  };

  // Picture and Source
  agentResizedImages: PictureSource = {
    text: Constants.FOLDER_AGENTS,
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  // Picture and Source
  favoriteResizedImages: PictureSource = {
    text: Constants.FOLDER_FAVORITES,
    docType: 'image/' + Constants.DOCTYPE_WEBP,
    loading: PictureSource.LOADING_LAZY,
  };

  isPropertyPhotosLoading: boolean = true;
  isAgentPhotosLoading = true;
  randomNumber: number = Math.round(Math.random() * 100);
  swiperConfig: SwiperOptions = {
    navigation: {
      nextEl: '.button-next' + this.randomNumber,
      prevEl: '.button-prev' + this.randomNumber,
    },
    watchSlidesProgress: true,
    slidesPerView: 'auto',
    speed: 400,
    grabCursor: true,
  };

  buttonPrev: Button = {
    id: 'btn-prev',
    hierarchy: Button.HIERARCHY_GREY_BORDER,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_ARROW_LEFT,
    iconOnly: true,
  };

  buttonNext: Button = {
    id: 'btn-next',
    hierarchy: Button.HIERARCHY_GREY_BORDER,
    size: Button.SIZE_40_PX,
    icon: Icon.NAME_ARROW_RIGHT,
    iconOnly: true,
  };

  linkdetail = '';
  public isShortInfoCard: boolean = false;
  private infoCardSubscription: Subscription | null = null;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
    this.isDesktop = window.innerWidth >= 700; // Adjust the breakpoint as needed
  }

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    public utilsService: UtilsService,
    @Inject(PLATFORM_ID) private platformId: any,
    private favoritesService: FavoritesService,
    private countClicksContactsService: CountClicksContactsService,
    private route: ActivatedRoute
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
      this.catchWidthScreenToOpenTab(); // Confirmar que estamos en desktop
    }

    if (this.data) {
      this.getStatusTags();
      // Validamos primero el array
      if (!this.data.photos || this.data.photos?.length === 0) {
        this.isPropertyPhotosLoading = false;
        // También validamos que las fotos existan
      } else if (this.data.photos) {
        this.isAgentPhotosLoading = false;
      }
    }

    // Utilizamos la función de utils.services para validar el NODE en el que está.
    if (
      this.utilsService.isVisibleUserRegister(this.node) &&
      isPlatformBrowser(this.platformId)
    ) {
      //Mostrar botón de favoritos solo si esta logueado
      Auth.currentSession()
        .then(() => (this.userLogged = true))
        .catch(() => (this.userLogged = false));
    }

    this.urlShortInfoCard();
  }

  ngOnDestroy(): void {
    this.infoCardSubscription?.unsubscribe();
  }

  ngOnChanges(changes: any): void {
    if (changes.data) {
      const previousValue = changes.data.previousValue;
      const currentValue = changes.data.currentValue;

      if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
        this.data = currentValue;

        this.isEntrepreneurship = this.data.entrepreneurship;
        this.linkdetail = this.isEntrepreneurship
          ? '/emprendimientos/' + this.data?.slug
          : '/listings/' + this.data?.slug;

        const PHOTO_URL: string = this.data.associate.rawPhoto;
        if (PHOTO_URL) {
          this.resizedImageWebp = this.utilsService.generateImageWithSize(
            PHOTO_URL,
            '180x180'
          );
        }
      }

      if (this.data.photos) {
        this.resizePropertyPhotos();
      }
      if (this.data.photos)
        this.hasPhotos = this.data?.photos?.length > 0 || false;
      this.handleFavorites(changes);
    }
  }

  countClicksContacts(type: string, event: MouseEvent): void {
    event.stopPropagation(); // Se ejecuta solo este click y evita la apertura de la card

    this.uuidAssociateId = this.data.associate.id || '';
    this.uuidInternalId = this.data.internalId || '';
    this.uuidListingTitle = this.data.title;
    if (this.data.associate?.phones)
      this.uuidPhoneValue = this.data.associate.phones[0].value
        .replace(/[^\w\s]/g, '')
        .replace(/\s+/g, '');
    if (this.data.associate?.emails)
      this.uuidEmailValue = this.data.associate.emails[0].value.replace(
        /[()\s]/g,
        ''
      );

    this.textInformationType = this.isEntrepreneurship
      ? 'la siguiente unidad del emprendimiento'
      : 'la siguiente propiedad';

    if (
      type &&
      this.uuidAssociateId &&
      this.uuidPhoneValue &&
      this.uuidEmailValue
    ) {
      const URL_CLICK: string = type + '/' + this.uuidAssociateId;
      const URL_BASE_PROPERTY =
        this.urlBaseRemax +
        `${this.isEntrepreneurship ? '/entrepreneurship/' : '/'}` +
        this.uuidInternalId;

      // WHATSAPP
      // Concatenamos la información de Whatsapp - Info + Titulo de la propiedad + La URL Base remax.com.nodo + el MLSID que es el internalId
      this.informationWhatsApp =
        'Hola ' +
        this.data.associate.name +
        ', te contacto por ' +
        this.textInformationType +
        ': ' +
        this.uuidListingTitle +
        ' ' +
        URL_BASE_PROPERTY;

      // Creamos la url del link del whatsapp con la informationWhatsapp
      this.urlContactWhatsApp =
        'https://wa.me/' +
        this.uuidPhoneValue +
        '?text=' +
        this.informationWhatsApp;
      //------------------

      // TELEFONO
      this.urlContactPhone = 'tel:' + '+' + this.uuidPhoneValue;
      //------------------

      // EMAIL
      this.informationEmail =
        this.uuidEmailValue +
        '?subject=' +
        this.uuidListingTitle +
        '&body=Hola ' +
        this.data.associate.name +
        ', te contacto por ' +
        this.textInformationType +
        ': ' +
        this.uuidListingTitle +
        ' ' +
        URL_BASE_PROPERTY;

      this.urlContactEmail = 'mailto:' + this.informationEmail;
      //------------------

      // Pasamos la URL armada que viene desde el Back
      this.countClicksContactsService.get(URL_CLICK);
    }
  }

  getStatusTags(): void {
    if (!this.isEntrepreneurship) {
      switch (this.data.listingStatus.value) {
        case Constants.STATUS_TAGS_NEGOTIATION:
          this.statusClassTags = Tag.COLOR_PRIMARY;
          break;
        case Constants.STATUS_TAGS_RESERVED:
          this.statusClassTags = Tag.COLOR_SECONDARY;
          break;
      }
    } else {
      switch (this.data.estage?.value) {
        case Constants.STATUS_TAGS_PRESALE:
        case Constants.STATUS_TAGS_IN_CONSTRUCTION:
        case Constants.STATUS_TAGS_INPIT:
          this.stageClassTags = Tag.COLOR_PRIMARY;
          break;

        case Constants.STATUS_TAGS_FINISHED:
          this.stageClassTags = Tag.COLOR_GREEN;
          break;
      }
    }
  }

  public getShortMonth(completedMonth: string): string {
    return completedMonth.slice(0, 3);
  }

  openInNewTab(event: MouseEvent): void {
    if (this.innerWidth < 960) {
      // Mobile & tablet
      // Abrir detalle de propiedad en la misma pestaña
      window.location.href = this.linkdetail;

      // Chequeamos que el click derecho no se clickee, pero si el click izquierdo y la ruedita.
    } else if (event.button != 2) {
      // Desktop
      // Abrir detalle de propiedad en nueva pestaña
      const url = this.linkdetail;
      const newTab: any = window.open(url, '_blank');
      newTab.focus();
    }
  }

  close(): void {
    this.cardChange.emit(QrCardPropertyComponent.CARD_EVENT_CLOSE_CARD);
  }

  catchWidthScreenToOpenTab(): void {
    this.isDesktop = this.innerWidth >= 960;
  }

  public addFavorite(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.userLogged) {
      this.isFavorite = !this.isFavorite;
      this.updateFavoriteButtonConfig();

      if (this.isFavorite) {
        this.resizeFavoritePhoto();

        const FAVORITE_ITEM: FavoriteItem = {
          listingId: this.data.entityId,
          photo: this.favoriteResizedImageWebp,
          slug: this.data.slug,
          title: this.createFavoriteTitle(),
          address: this.data.displayAddress ?? '',
          currency: this.data.currency?.value ?? '',
          price: this.data.price,
        };

        this.favoritesService.addFavorite(FAVORITE_ITEM);
      } else {
        const FAVORITES = {
          listingIds: [this.data.entityId],
        };
        this.favoritesService.deleteFavoritesByListingId(FAVORITES);
      }
    } else {
      this.cardChange.emit(
        QrCardPropertyComponent.CARD_EVENT_OPEN_DIALOG_CREATE_ACCOUNT
      );
    }
  }

  private handleFavorites(changes: any): void {
    this.isFavorite = changes.data.currentValue.isFavorite;
    this.configButtonFavorite = {
      ...this.configButtonFavorite,
      style: this.isFavorite
        ? Constants.BUTTON_COLOR_PRIMARY_SOLID
        : Constants.BUTTON_COLOR_GREY_BORDER,
      iconTooltipOnly: this.isFavorite
        ? 'Quitar de favoritos'
        : 'Agregar a favoritos',
    };
  }

  private urlShortInfoCard(): void {
    this.infoCardSubscription = this.route.url.subscribe(url => {
      this.isShortInfoCard = url.some(
        segment =>
          segment.path === 'comprar-propiedades' ||
          segment.path === 'emprendimientos' ||
          segment.path === 'alquilar-propiedades' ||
          segment.path === 'listings' ||
          segment.path.includes('casas-en') ||
          segment.path.includes('departamentos-en') ||
          segment.path.includes('terrenos-en') ||
          segment.path.includes('apartamentos-en') ||
          segment.path.includes('/listings/buy') ||
          segment.path.includes('/listings/rent')
      );
    });
  }

  private resizePropertyPhotos(): void {
    const IS_WATER_MARK: boolean = environment.node == Constants.NODE_ECUADOR;
    this.propertyPhotos = [];
    this.data.photos?.slice(0, 5).forEach(photo => {
      if (photo.rawValue !== undefined) {
        const RESIZE_IMG: string = this.utilsService.generateImageWithSize(
          photo.rawValue,
          '360x200',
          Constants.DOCTYPE_WEBP,
          IS_WATER_MARK
        );
        this.propertyPhotos.push({ rawValue: RESIZE_IMG });
      }
    });

    if (this.propertyPhotos.length <= 5) {
      this.isPropertyPhotosLoading = false;
    }
  }

  private resizeFavoritePhoto(): void {
    const PHOTO: string =
      this.data.photos && this.data.photos.length > 0
        ? this.data.photos[0].rawValue
        : 'empty';

    this.replaceFolderFavorites = this.utilsService.generateImageWithSize(
      PHOTO,
      '160x120'
    );

    const FOLDER_COMBINED_FAVORITES_AND_LISTINGS =
      Constants.FOLDER_FAVORITES + '/' + Constants.FOLDER_LISTINGS; // Creamos una constante que diga 'favorites/listings'
    this.favoriteResizedImageWebp = this.replaceFolderFavorites.replace(
      Constants.FOLDER_LISTINGS,
      FOLDER_COMBINED_FAVORITES_AND_LISTINGS
    );
  }

  private updateFavoriteButtonConfig(): void {
    this.configButtonFavorite = {
      ...this.configButtonFavorite,
      style: this.isFavorite
        ? Constants.BUTTON_COLOR_PRIMARY_SOLID
        : Constants.BUTTON_COLOR_GREY_BORDER,
      iconTooltipOnly: this.isFavorite
        ? 'Quitar de favoritos'
        : 'Agregar a favoritos',
    };
  }

  private createFavoriteTitle(): string {
    return `${this.translation.translate(
      'property-type.' + this.data.type.value
    )} 
            ${this.translation.translate('property-type.for')} 
            ${this.translation.translate(
              'operations.' + this.data.operation.value
            )} 
            ${this.translation.translate('listing-detail.en')} 
            ${this.translation.translate(
              this.data.displayAddress + ', ' + this.data.geoLabel
            )}`;
  }
}
