import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Constants } from '../../../core/constants/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociateService } from '../../../services/associate.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Title, Meta } from '@angular/platform-browser';
import { SearchDomain } from '@app/domain/search.domain';
import { environment } from '@base/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import {
  PhotoPanorama,
  QrPhotoPanoramaComponent,
} from '@app/components/qr-photo-panorama/qr-photo-panorama.component';
import {
  L10nLocale,
  L10nTranslationService,
  L10N_LOCALE,
  L10nTranslationModule,
} from 'angular-l10n';
import { Listing } from '@app/core/models/listing.model';
import { UtilsService } from '@app/services/utils.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BreadcrumbService } from '@app/services/breadcrumb.service';
import { EntrepreunershipUnitsByIdService } from '@app/services/entrepreneurship-units-find-by-id.service';
import { EntrepreunershipUnitsBySlugService } from '@app/services/entrepreneurship-units-find-by-slug.service';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { QrDisclaimerComponent } from '@app/components/qr-disclaimer/qr-disclaimer.component';
import { QrCardInfoOfficeComponent } from '@app/components/qr-card-info-office/qr-card-info-office.component';
import { CardContactDetailsComponent } from '@app/components/card-contact-details/card-contact-details.component';
import { QrCardDetailsPropComponent } from '@app/components/qr-card-details-prop/qr-card-details-prop.component';
import { QrCardInfoPropComponent } from '@app/components/qr-card-info-prop/qr-card-info-prop.component';
import { QrVideoContainerComponent } from '@app/components/qr-video-container/qr-video-container.component';
import { QrPhotosDetailComponent } from '@app/components/qr-photos-detail/qr-photos-detail.component';
import { QrMultimediaButtonsComponent } from '@app/components/qr-multimedia-buttons/qr-multimedia-buttons.component';
import { QrIconButtonComponent } from '@app/components/qr-icon-button/qr-icon-button.component';
import { QrCardOfficeMemberComponent } from '@app/components/qr-card-office-member/qr-card-office-member.component';
import { Associate } from '@base/src/app/core/models/associate.model';

@Component({
  selector: 'app-unit-detail',
  templateUrl: './unit-detail.page.html',
  styleUrls: ['./unit-detail.page.scss'],
  standalone: true,
  imports: [
    CommonModule,
    QrDisclaimerComponent,
    QrCardInfoOfficeComponent,
    CardContactDetailsComponent,
    L10nTranslationModule,
    QrCardDetailsPropComponent,
    QrCardInfoPropComponent,
    QrPhotoPanoramaComponent,
    QrVideoContainerComponent,
    QrPhotosDetailComponent,
    QrMultimediaButtonsComponent,
    QrIconButtonComponent,
    QrCardOfficeMemberComponent,
  ],
})
export class UnitDetailPage implements OnInit, OnDestroy {
  multimediaStyle = {
    photo: 'selected',
    video: 'unselected',
    p360: 'disabled',
    tour: 'disabled',
  };

  multimediaSelected = 'photo';
  virtualTourLink: string = '';
  photosArray: string[] = [];
  isBrowser: boolean;
  isCustomAgent = false;
  dataToShare = { title: '', text: '', url: '' };
  loading = true;
  found = true;
  isPropertyNotAvailable: number = 0;
  placeholder = '';
  agentUrl: string = '';
  officeUrl: string = '';
  url: string = '';
  public listing!: Listing;
  agent: Associate = new Associate();
  configButtonExpand = {
    style: Constants.BUTTON_COLOR_GREY_BASIC,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  public staticMapHeight: number | undefined = 0;

  auctioneers: Associate[] = [];
  slugToRedirect: string | null = null;
  slugToAssociate: string | null = null;
  coords: any[] = [];
  centerMap: any;
  breadcrumbOperationTypeText: string = '';
  breadcrumbOperationTypeURL: string = '';
  isStaticMapModeEnabled = true;
  sanitizeMapStaticURL: SafeResourceUrl | null = null;
  sanitizeMapEmbedURL: SafeResourceUrl | null = null;
  country = environment.node;
  photos360Config: PhotoPanorama = {
    isOpen: false,
    photos: [],
  };

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private route: ActivatedRoute,
    private associateService: AssociateService,
    public searchDomain: SearchDomain,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private titleService: Title,
    private metaTagService: Meta,
    private domSanitize: DomSanitizer,
    private http: HttpClient,
    private utils: UtilsService,
    @Inject(DOCUMENT) private document: Document,
    private translation: L10nTranslationService,
    private router: Router,
    public breadcrumbService: BreadcrumbService,
    private renderer: Renderer2,
    private entrepreunershipUnitsByIdService: EntrepreunershipUnitsByIdService,
    private entrepreunershipUnitsBySlugService: EntrepreunershipUnitsBySlugService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (!this.listing) {
      const slug = this.route.snapshot.params['slug'];
      const customAssociate = this.route.snapshot.queryParams['associate'];
      if (!slug && this.slugToRedirect) {
        let url = '/unit/' + this.slugToRedirect;
        if (this.slugToAssociate) url += '?associate=' + this.slugToAssociate;
        if (this.isBrowser) window.location.replace(url);
      }
      if (slug) {
        this._fetchData(slug, customAssociate);
      } else {
        const id = this.route.snapshot.params['id'];
        this._fetchDataByMlsid(id);
      }
    } else {
      // Here is browser side
      this.generateResources();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  generateMultimedia(): void {
    if (
      this.listing.photos != null &&
      this.listing.photos != undefined &&
      this.listing.photos.length > 0
    ) {
      // Fotos
      this.multimediaStyle.photo = 'selected';
    } else {
      this.multimediaStyle.photo = 'disabled';
    }

    if (
      this.listing.video != null &&
      this.listing.video != undefined &&
      this.listing.video.length > 0
    ) {
      // Video
      if (this.multimediaStyle.photo == 'disabled') {
        this.multimediaStyle.video = 'selected';
      } else {
        this.multimediaStyle.video = 'unselected';
      }
    } else {
      this.listing.video = undefined;
      this.multimediaStyle.video = 'disabled';
    }

    if (
      this.listing.virtualTours != null &&
      this.listing.virtualTours != undefined &&
      this.listing.virtualTours.length > 0
    ) {
      this.multimediaStyle.tour = 'unselected';
      if (this.listing.virtualTours.length > 1) {
        for (let index = 0; index < this.listing.virtualTours.length; index++) {
          if (this.listing.virtualTours[index].type != 'youtube') {
            this.virtualTourLink = this.listing.virtualTours[index].value;
          }
        }
      } else {
        this.virtualTourLink = this.listing.virtualTours[0].value;
      }
    } else {
      this.multimediaStyle.tour = 'disabled';
    }

    if (
      this.listing.photos360 != null &&
      this.listing.photos360 != undefined &&
      this.listing.photos360.length > 0
    ) {
      this.listing.photos360.forEach(s => {
        this.photos360Config.photos.push(
          environment.sitemapUrl + '/' + s.value
        );
      });
      this.multimediaStyle.p360 = 'unselected';
    } else {
      this.multimediaStyle.p360 = 'disabled';
    }
  }

  generateBrowserData(): void {
    if (this.listing) {
      this.dataToShare.title = this.listing.title || '';
      this.dataToShare.text = this.listing.description || '';
      this.dataToShare.url = this.url;
    }
  }

  multimediaSelect(event: any): void {
    if (event == '360') {
      this.photos360Config.isOpen = true;
      return;
    }
    this.multimediaSelected = event;
    if (this.multimediaSelected == 'tour' && this.virtualTourLink) {
      window.open(this.virtualTourLink, '_blank');
    }
  }

  /*
	backToListings: Volver al listado (Mobile)
	*/
  backToListings(): void {
    if ('referrer' in document) {
      const urlDocumentReferrer: string = document.referrer; // URL anterior
      const urlLocationOrigin: string = window.location.origin; // URl actual base sin secciones o parametros
      if (urlDocumentReferrer.includes(urlLocationOrigin)) {
        /*
				* Si la URL anterior es una URL de RE/MAX deberia ser la de listado
				de resultados con los filtros, por lo que se usa esa para ir para atras
				*/
        history.back();
      } else {
        /*
         * No hay una pagina para ir para atras que sea de RE/MAX.
         * Esto puede ser cuando se llega desde Google o entrando directamente al enlance
         * Por lo que se envia a una pagina de listado de resultados con el tipo de operación y tipo de propiedad
         */
        if (this.listing.geo) {
          window.location.href = this.breadcrumbService.urls.propertyType || '';
        } // Similar a clickear en desktop en el breadcrumb de tipo de propiedad
      }
    }
  }

  generatePropertyText(text: string): string {
    let t: string;
    if (text.includes('departamento')) {
      t = 'departamento';
    } else if (text.includes('casa')) {
      t = 'casa';
    } else {
      t = text;
    }
    return t;
  }

  /*
	generatePropertyIDs: Breadcrumbs (Desktop) sirve para cuando es del subtipo de Departamento o 
	Casa lograr que la URL tenga todos los ids del tipo padre y no solo del subtipo
	*/
  generatePropertyIDs(id: number): string {
    let ids: string;
    if (id >= 1 && id <= 8) {
      // Subtipo de departamento
      ids = '1,2,3,4,5,6,7,8';
    } else if (id >= 9 && id <= 11) {
      ids = '9,10,11';
    } else {
      ids = id.toString();
    }
    return ids;
  }

  buildMapCoords(): void {
    this.coords = [
      {
        lng: this.listing.location?.coordinates[0],
        lat: this.listing.location?.coordinates[1],
        id: this.listing.id,
      },
    ];
    this.centerMap = {
      lng: this.listing.location?.coordinates[0],
      lat: this.listing.location?.coordinates[1],
    };
  }

  /*
  getSanitizeMapStaticURL: Generar URL del mapa estático
  */
  getSanitizeMapStaticURL(): void {
    if (
      this.listing?.location?.coordinates &&
      this.listing?.location?.coordinates?.length > 0 &&
      this.listing?.location?.coordinates[0] &&
      this.listing.location.coordinates[1]
    ) {
      const url: string =
        'https://maps.googleapis.com/maps/api/staticmap?size=800x400&maptype=roadmap' +
        '&markers=color:red|' +
        this.listing.location.coordinates[1] +
        ',' +
        this.listing.location.coordinates[0] +
        '&zoom=16&scale=2&key=' +
        environment.googleAPIKey;

      this.http
        .post('/signing-google-maps', {
          url: url,
        })
        .subscribe((res: any) => {
          this.sanitizeMapStaticURL =
            this.domSanitize.bypassSecurityTrustResourceUrl(res.url);
        });
    } else {
      console.log('Error: generating sanitizeMapStaticURL');
    }
  }

  /*
  enableEmbedMap: Ocultar mapa estático y mostrar mapa embed
  */
  enableEmbedMap(): void {
    this.isStaticMapModeEnabled = false;
    this.staticMapHeight =
      document.getElementById('map-static-img')?.offsetHeight; // Obtener altura del mapa estático que se le aplicara al mapa embed
  }

  getSanitizeMapEmbedURL(): void {
    if (
      this.listing?.location?.coordinates &&
      this.listing?.location?.coordinates?.length > 0 &&
      this.listing?.location?.coordinates[0] &&
      this.listing.location.coordinates[1]
    ) {
      this.sanitizeMapEmbedURL =
        this.domSanitize.bypassSecurityTrustResourceUrl(
          'https://www.google.com/maps/embed/v1/place?q=' +
            this.listing.location.coordinates[1] +
            ',' +
            this.listing.location.coordinates[0] +
            '&key=' +
            environment.googleAPIKey
        );
    } else {
      console.log('Error: generatin static map');
    }
  }

  setMetaTags(data: any): void {
    this.generateCanonicalUrl();
    let titleFormat =
      (this.translation.translate('property-type.' + data.type.value) ||
        data.type.value) +
      ' en ' +
      (this.translation.translate('operations.' + data.operation.value) ||
        data.operation.value) +
      ' ' +
      data.totalRooms +
      [data.totalRooms > 1 ? ' ambientes' : ' ambiente'] +
      [data.displayAddress ? ' en ' + data.displayAddress : ''] +
      [data.geo && data.geo.label ? ', ' + data.geo.label : ''];

    //Especificación para SEO
    if (data.type.value == Constants.PROPERTY_TYPE_DEPARTAMENTO_ESTANDAR) {
      titleFormat = titleFormat.replace(' estandar', '');
    }

    const descriptionFormat = `${data.description.substring(0, 151)}...`;

    this.titleService.setTitle(titleFormat);
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Mirá esta propiedad en RE/MAX: ' + descriptionFormat,
    });

    if (data.photos && data.photos.length > 0) {
      this.metaTagService.updateTag({
        property: 'og:image',
        content: this.utils.generateImageWithSize(
          data.photos[0].value,
          '360x200'
        ),
      });
    }
    this.metaTagService.updateTag({
      property: 'og:title',
      content: titleFormat,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: 'Mirá esta propiedad en RE/MAX: ' + descriptionFormat,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: this.document.location.href,
    });
    if (data.geo && data.geo.label) {
      this.metaTagService.updateTag({
        name: 'keywords',
        content:
          data.title + ', ' + data.displayAddress + ', ' + data.geo.label,
      });
    } else {
      this.metaTagService.updateTag({
        name: 'keywords',
        content: data.title + ', ' + data.displayAddress,
      });
    }
  }

  generateCanonicalUrl(): void {
    let countryDomain = '';
    let URL = '';

    switch (environment.node) {
      case Constants.NODE_URUGUAY:
        countryDomain = 'uy';
        break;
      case Constants.NODE_ECUADOR:
        countryDomain = 'ec';
        break;
      default:
        countryDomain = 'ar';
        break;
    }

    if (this.route.snapshot.params['slug']) {
      // URL Slug
      URL =
        'https://www.remax.com.' +
        countryDomain +
        '/units/' +
        this.route.snapshot.params['slug'];
    } else if (this.route.snapshot.params['mlsid']) {
      // URL MLS-ID
      URL =
        'https://www.remax.com.' +
        countryDomain +
        '/' +
        this.route.snapshot.params['mlsid'];
    }
    this.updateCanonicalUrl(URL);
  }

  updateCanonicalUrl(url: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  handleBreadcrumb(): void {
    this.breadcrumbService.publication = this.listing;
    this.breadcrumbService.publicationType =
      Constants.PUBLICATION_TYPE_LISTINGS;
    this.breadcrumbService.generateAllURLs().then(() => {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.setAttribute('id', BreadcrumbService.SEO_BREADCRUMB_SCRIPT_ID);
      script.textContent = this.breadcrumbService.scriptCode;
      this.renderer.appendChild(this.document.head, script);
    });
  }

  private generateResources(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getSanitizeMapStaticURL();
      this.getSanitizeMapEmbedURL();
      this.generateBrowserData();
      this.generateMultimedia();
      this.handleBreadcrumb();
    }
  }

  private _fetchDataByMlsid(id: string) {
    this.entrepreunershipUnitsByIdService
      .getData<Listing>(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<Listing>) => {
          if (response.code == 200) {
            this.setMetaTags(response.data);
            this.generateResources();
          } else if (response.code == 500) {
            this.router.navigateByUrl('/');
          }
        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
      });
  }

  private _fetchData(slug: string, customAssociate: string) {
    this.entrepreunershipUnitsBySlugService
      .getData<Listing>(slug)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<Listing>) => {
          switch (response.code) {
            case 200:
              this.processListingData(response.data, customAssociate);
              this.generateResources();
              break;
            case 500:
              this.router.navigateByUrl('/');
              break;
            case 601:
              this.isPropertyNotAvailable = 601; // Venta de propiedad ya no disponible
              break;
            case 602:
              this.isPropertyNotAvailable = 602; // Alquiler de propiedad ya no disponible
              break;
            case 603:
              this.isPropertyNotAvailable = 603; // Alquiler temporario de propiedad ya no disponible
              break;
            default:
              console.warn(response);
              break;
          }
        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
      });
  }

  private processListingData(unit: Listing, customAssociate: string) {
    this.listing = unit;
    this.setMetaTags(this.listing);
    let operationType: string;
    if (unit.operation?.value == 'sale') {
      operationType = 'buy';
    } else if (unit.operation?.value == 'rent') {
      operationType = 'rent';
    } else {
      operationType = 'rent';
    }
    this.breadcrumbOperationTypeText = 'agent-detail.properties';
    this.breadcrumbOperationTypeURL = '/listings/' + operationType;

    this.found = true;
    this.loading = false;

    if (customAssociate != null) {
      this.isCustomAgent = true;
      this._fetchDataAgentByInternalId(customAssociate);
    } else {
      if (unit.associate) {
        this.agent = unit.associate;
        this._fetchDataAuctioneer(unit.associate);
      }
    }

    this.handleBreadcrumb();
  }

  private async _fetchDataAgentByInternalId(customAssociateInternalId: string) {
    try {
      const result: IQrResponse<Associate> =
        await this.associateService.findByInternalId(customAssociateInternalId);

      if (result.code == 200) {
        this.agent = result.data;
        this._fetchDataAuctioneer(result.data);
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private _fetchDataAuctioneer(agent: Associate) {
    if (!this.auctioneers) {
      this.loadAuctioneerInfo(agent.office.id);
    }
  }

  private async loadAuctioneerInfo(id: string): Promise<void> {
    try {
      const result: IQrResponse<Associate[]> =
        await this.associateService.findByIdAuctioneerOffice(id);
      if (result.code == 200) {
        this.auctioneers = result.data;
        const index = this.auctioneers.indexOf(
          this.auctioneers.find(e => e.id === this.agent.id) as Associate
        );
        if (index > -1) {
          this.auctioneers.splice(index, 1);
        }
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
