import { Component, OnInit } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Constants } from '@base/src/app/core/constants/constants';
import { Auth } from 'aws-amplify';
import { CountriesPhoneListComponent } from '../countries-phone-list/countries-phone-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { QrButtonComponent } from '../qr-button/qr-button.component';
import { QrIconButtonComponent } from '../qr-icon-button/qr-icon-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';

import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-user-account-change-password',
  templateUrl: './user-account-change-password.component.html',
  styleUrls: ['./user-account-change-password.component.scss'],
  standalone: true,
  imports: [
    CountriesPhoneListComponent,
    MatProgressSpinnerModule,
    QrButtonComponent,
    QrIconButtonComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
})
export class UserAccountChangePasswordComponent implements OnInit {
  configButtonContinue = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Guardar cambios',
    isDisabled: true,
  };

  showPassword = false;
  showNewPassword = false;
  showRepeatNewPassword = false;
  errorMsg = '';
  errorServiceAWS = '';
  loading = false;
  successSave = false;

  configButtonSeePassword = {
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  myFormGroup: UntypedFormGroup;
  password = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.pattern(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/),
  ]);

  newPassword = new UntypedFormControl('', [
    Validators.required,
    Validators.minLength(6),
    Validators.pattern(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/),
  ]);

  repeatNewPassword = new UntypedFormControl('', [Validators.required]);

  constructor(fb: UntypedFormBuilder) {
    this.myFormGroup = fb.group({
      password: this.password,
      newPassword: this.newPassword,
      repeatNewPassword: this.repeatNewPassword,
    });
  }

  ngOnInit(): void {
    //Suscribirse a los cambios en el formulario
    this.myFormGroup.valueChanges.subscribe(() => {
      this.onchangesDetected();
    });
  }

  getMessageError(field: string): string {
    switch (field) {
      case 'password':
        if (this.password.hasError('required'))
          this.errorMsg = 'Ingresá tu contraseña actual';
        if (
          this.password.hasError('pattern') ||
          this.password.hasError('minlength')
        )
          this.errorMsg = 'Usá un mínimo de 6 caracteres con números y letras';
        break;
      case 'newPassword':
        if (this.newPassword.hasError('required'))
          this.errorMsg = 'Ingresá una contraseña';
        if (
          this.newPassword.hasError('pattern') ||
          this.newPassword.hasError('minlength')
        )
          this.errorMsg = 'Usá un mínimo de 6 caracteres con números y letras';
        break;

      case 'repeatNewPassword':
        if (this.repeatNewPassword.hasError('required'))
          this.errorMsg = 'Reingresá la misma contraseña';

        if (this.newPassword.value != this.repeatNewPassword.value) {
          this.errorMsg = 'Las contraseñas deben coincidir';
          this.repeatNewPassword.setErrors({
            notUnique: true,
          });
        }
        break;

      default:
        break;
    }
    return this.errorMsg;
  }

  //Campos correctos habilitan el botón de guardar
  onchangesDetected(): void {
    const correctPasswords =
      !this.password.hasError('pattern') &&
      !this.password.hasError('minlength') &&
      !this.password.hasError('required') &&
      !this.newPassword.hasError('pattern') &&
      !this.newPassword.hasError('minlength') &&
      !this.newPassword.hasError('required') &&
      !this.repeatNewPassword.hasError('required') &&
      this.newPassword.value == this.repeatNewPassword.value;

    this.configButtonContinue = {
      ...this.configButtonContinue,
      isDisabled: !correctPasswords, //true: desactivado(gris), false: habilitado
    };
  }

  //Botón guardar
  async onChangePassword(): Promise<void> {
    this.loading = true;
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(
      currentUser,
      this.password.value,
      this.newPassword.value
    )
      .then(() => {
        this.loading = false;
        this.successSave = true;
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(error => {
        console.log(error.message || JSON.stringify(error));
        const typeError = error.message || JSON.stringify(error);

        switch (typeError) {
          case 'Attempt limit exceeded, please try after some time.':
            this.errorServiceAWS =
              'Ha excedido el límite de intentos, intente más tarde';
            break;

          case 'Incorrect username or password.':
            this.errorServiceAWS = 'La contraseña ingresada es incorrecta';
            break;

          default:
            this.errorServiceAWS = 'Ha ocurrido un error';
            break;
        }
        this.loading = false;
      });
  }
}
