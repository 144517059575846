@if (viewMode !== LISTINGS_VIEW_MODE_MAP) {
  <div class="loader">
    <div class="loader__bar">
      <skeleton-loader class="loader__text"></skeleton-loader>
      <div class="loader__box">
        <skeleton-loader class="loader__dropdown"></skeleton-loader>
        <skeleton-loader class="loader__dropdown"></skeleton-loader>
      </div>
    </div>
    <div
      class="loader__results"
      [class.loader__results--grid]="viewMode === LISTINGS_VIEW_MODE_GRID">
      @for (item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]; track item) {
        <skeleton-loader
          class="loader__card"
          [class.loader__card--grid]="
            viewMode === LISTINGS_VIEW_MODE_GRID
          "></skeleton-loader>
      }
    </div>
  </div>
}

@if (viewMode === LISTINGS_VIEW_MODE_MAP) {
  <skeleton-loader class="loader__map"></skeleton-loader>
}
