export class QRBannerItau {
  docFolder: string | undefined;
  docName: string | undefined;
  docType: string | undefined;
  text: string | undefined;
  link: string | undefined;
  alt: string | undefined;
  className?: string;
  width?: string;
  height?: string;

  public static readonly CLASSNAME_BANNER_ITAU = 'banner__border--itau';
}
