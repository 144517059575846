<div id="hablemos-main-container">
  <div class="hablemos-wrapper">
    <div class="border red-back"></div>
    <div class="text-wrapper">
      <h1>
        <span class="blue"> Hablemos de </span><span class="red">VERDAD.</span>
      </h1>
      <p class="blue">
        Ciertos dirigentes del sector inmobiliario
        <span class="bold"
          >se sienten amenazados por el éxito comercial de las Oficinas
          adheridas a la red </span
        ><span class="bold red-light">RE</span><span class="bold blue">/</span
        ><span class="bold red-light">MAX.</span>
        Por eso nos atacan.
      </p>
      <p class="blue">
        Sí, nos persiguen simplemente<span class="bold">
          porque hace más de 16 años,</span
        >
        ustedes, nuestros Clientes,
        <span class="bold">nos eligen cada vez más.</span>
      </p>
      <p class="blue">
        Denuncias falsas, amenazas y ataques a nuestros equipos
        <span class="bold">no nos van a detener.</span>
      </p>
      <p class="red">
        Somos más de <span class="bold">5.000 laburantes,</span> en más de 40
        ciudades de todo el país.
      </p>
      <p class="red bottom">
        Somos <span class="bold">150 pymes argentinas.</span>
      </p>
      <p class="blue center">
        <span class="bold">DÉJENNOS TRABAJAR. </span>
      </p>
      <p class="red center">
        <span class="bold">Queremos seguir construyendo un país mejor.</span>
      </p>
      <p class="center">
        <span class="common">
          Los Corredores Inmobiliarios <br />
          que elegimos contratar <br />los servicios de </span
        ><span class="bold red-light">RE</span><span class="bold blue">/</span
        ><span class="bold red-light">MAX.</span>
      </p>
      <div class="img-container bottom"></div>
      <p class="center">
        <span class="bold blue">CADA VEZ MÁS </span
        ><span class="bold red">RE</span><span class="bold blue">/</span
        ><span class="red bold">MAX</span><span class="bold blue">.</span>
      </p>
      <p class="center">
        <span class="common small"
          >Cada oficina es de propiedad <br />
          y gestión independiente.</span
        >
      </p>
    </div>
    <div class="border blue-back"></div>
  </div>
</div>
