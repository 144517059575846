import queryString from 'query-string';
import { IQrFilterType } from '../enum/IQrFilterType';
import { QrFilterCustom } from './qr-filter-custom';
export class QrFilter {
  public sorts: string[];
  public filters: QrFilterCustom[];
  private _page: number;

  private _pageSize: number;

  private _currentQueryString = '';
  //private platformId: any;

  constructor(page = 0, pageSize = 300, filters = [], sorts = ['-createdAt']) {
    this._page = page;
    this._pageSize = pageSize;
    this.filters = filters;
    this.sorts = sorts;
    //	this.platformId = AppModule.injector.get(PLATFORM_ID);
  }

  get currentQueryString() {
    return this._currentQueryString;
  }

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    if (value >= 0) {
      this._page = value;
    }
  }

  get pageSize(): number {
    return this._pageSize;
  }

  set pageSize(value: number) {
    if (value > 0) {
      this._pageSize = value;
    }
  }

  public fromQueryString(query: string): void {
    const parsed = queryString.parse(query);

    if (parsed['sorts'] !== undefined)
      (parsed['sorts'] as Array<string>).forEach(a => {
        this.sorts.push(a);
      });
    if (parsed['pageSize'] !== undefined)
      this._pageSize = parseInt(parsed['pageSize'] as string);

    if (parsed['page'] !== undefined)
      this._page = parseInt(parsed['page'] as string);
  }

  addFilter(value: QrFilterCustom): void {
    if (
      //	value.field === undefined ||
      value.type === undefined ||
      value.value === undefined ||
      //	value.field === null ||
      value.type === null ||
      value.value === null
    ) {
      throw 'ERROR QrFilterCustom ' + value.toString();
    }

    this.filters.push(value);
  }

  removeFiler(value: QrFilterCustom): void {
    this.filters = this.filters.filter(item => item !== value);
  }

  removeFilterByField(field: string): void {
    this.filters = this.filters.filter(item => item.field !== field);
  }

  ressetFilters(): void {
    this.filters = [];
  }

  // Utilizada por SearchDomain para poner en la URL
  public toQueryStringFe(): string {
    const target = Object.assign(
      {},
      { page: this._page },
      { pageSize: this._pageSize },
      { sort: this.sorts }
      //	{ filters: this.filters }
    );

    let response: string = queryString.stringify(target, {
      skipEmptyString: true,
      skipNull: true,
    });
    response += this.filterToStringifyFe();

    this._currentQueryString = response;
    return this._currentQueryString;
  }

  //utilizada por findAllObservable que va a la api
  public toQueryStringBe() {
    const target = Object.assign(
      {},
      { page: this._page },
      { pageSize: this._pageSize },
      { sort: this.sorts }
      //	{ filters: this.filters }
    );

    let response: string = queryString.stringify(target, {
      skipEmptyString: true,
      skipNull: true,
    });
    response += this.filterToStringifyBe();
    this._currentQueryString = response;
    return this._currentQueryString;
  }

  private filterToStringifyFe(): string {
    let filters = '';
    this.filters.forEach(function (filter: QrFilterCustom) {
      if (filter.value != null && filter.value != undefined) {
        if (filter.field != null && filter.field != undefined) {
          if (filter.type == IQrFilterType.withinarea) {
            filters +=
              '&' +
              IQrFilterType[filter.type] +
              '=' +
              filter.field.toString().trim() +
              ':' +
              filter.value.toString().trim();
          } else if (filter.type == IQrFilterType.locations) {
            filters +=
              '&' +
              IQrFilterType[filter.type] +
              '=' +
              filter.field.toString().trim() +
              ':' +
              filter.value.toString().trim();
            filter.value.toString().trim();
          } else if (filter.type == IQrFilterType.pricein) {
            filters +=
              '&' +
              IQrFilterType[filter.type] +
              '=' +
              filter.field.toString().trim() +
              ':' +
              filter.value.toString().trim();
          } else {
            filters +=
              '&' +
              IQrFilterType[filter.type] +
              ':' +
              filter.field.toString().trim() +
              '=' +
              filter.value.toString().trim();
          }
        } else {
          filters +=
            '&' +
            IQrFilterType[filter.type] +
            '=' +
            filter.value.toString().trim();
        }
      }
    });
    return filters;
  }

  private filterToStringifyBe(): string {
    //qrFilter.sorts = ['+id', '-description'];
    let filters = '';
    this.filters.forEach(function (filter: QrFilterCustom) {
      if (filter.value != null && filter.value != undefined) {
        if (filter.field != null && filter.field != undefined) {
          filters +=
            '&' +
            IQrFilterType[filter.type] +
            '=' +
            filter.field.toString().trim() +
            ':' +
            filter.value.toString().trim();
        } else {
          filters +=
            '&' +
            IQrFilterType[filter.type] +
            '=' +
            filter.value.toString().trim();
        }
      }
    });
    return filters;
  }
}
