import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpUtilsService } from '../core/services/http-utils.service';

@Injectable({ providedIn: 'root' })
export class ListingAndEntrepreneurshipByIdService extends HttpUtilsService {
  constructor(public override http: HttpClient) {
    super(http, 'listings/findWithEntrepreneurshipById');
  }
}
