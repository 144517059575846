<a [href]="url">
  <div id="office-member-container" class="flex-col flex-center-center">
    @if (data) {
      <div class="img-container">
        <picture-source
          [config]="officesResizedImages"
          [src]="resizedImageWebp"
          alt="Foto"></picture-source>
      </div>

      <div class="title-name flex-center-center">
        @if (data.license) {
          <h3>
            {{ this.data.name }}
          </h3>
          <p class="data-licence">
            {{ this.data.license }}
          </p>
        } @else {
          <!-- MOBILE  -->
          <h3 class="low-font hide-gt-sm">
            {{ this.data.name }}
          </h3>
          <!-- DESKTOP  -->
          <h3 class="hide-lt-md">
            {{ this.data.name }}
          </h3>
        }
      </div>
    }
  </div>
</a>
