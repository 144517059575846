import { Component } from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { FavoritesService } from '@app/services/favorites.service';
import { QrButtonComponent } from '../qr-button/qr-button.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-user-account-delete',
  templateUrl: './user-account-delete.component.html',
  styleUrls: ['./user-account-delete.component.scss'],
  standalone: true,
  imports: [
    QrButtonComponent,
    MatProgressSpinnerModule,
    FormsModule,
    MatCheckboxModule,
  ],
})
export class UserAccountDeleteComponent {
  configButtonCancel = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Cancelar',
  };

  configButtonDelete = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Sí, eliminar',
    isDisabled: true,
  };

  checkbox = false;
  successDelete = false;
  errorService = '';
  loading = false;

  constructor(
    private router: Router,
    private favoritesService: FavoritesService
  ) {}

  confirmDelete(): void {
    this.configButtonDelete.isDisabled = this.checkbox ? false : true;
  }

  async onDeleteUser(): Promise<void> {
    this.loading = true;
    this.favoritesService.deleteAccountFavorites();
    await Auth.deleteUser()
      .then(() => {
        this.successDelete = true;
        this.loading = false;
        setTimeout(() => {
          this.router.navigate(['']).then(() => {
            window.location.reload();
          });
        }, 2000);
      })
      .catch(error => {
        this.errorService = 'error';
        console.log(error);
        this.loading = false;
      });
  }

  onNavigate(): void {
    this.router.navigate(['/cuenta/datos']);
  }
}
