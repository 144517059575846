import { Associate } from './associate.model';
import { Location } from './location.model';
import { ListingOportunity } from './listing-oportunity.model';
import { ListingStatus } from './listing-status.model';
import { Currency } from './currency.model';
import { Operation } from './operation.model';
import { BillingFrequency } from './billing-frequency.model';
import { ListingPhoto } from './listing-photo.model';
import { ListingPhoto360 } from './listing-photo360.model';
import { Feature } from './feature.model';
import { Condition } from './condition.model';
import { GeoSearch } from '@app/components/geo-search/geo-search.model';
export class Listing {
  id?: string;
  title?: string;
  slug?: string;
  description?: string;
  location: Location | undefined;
  // Rooms
  totalRooms?: number;
  bedrooms?: number;
  bathrooms?: number;
  toilets?: number;
  floors?: number;
  totalUnits?: number;
  parkingSpaces?: number;
  // General
  video?: string;
  yearBuilt?: number;
  price?: number;
  currency?: Currency;
  expensesPrice?: number;
  expensesCurrency?: Currency;
  professionalUse?: boolean;
  pozo?: boolean;
  commercialUse?: boolean;
  remaxCollection?: boolean;
  financing?: boolean;
  aptCredit?: boolean;
  inPrivateCommunity?: boolean;
  internalId?: string;
  displayAddress?: string;
  virtualTours?: any[];
  // Dimension
  dimensionLand?: number;
  dimensionTotalBuilt?: number;
  dimensionCovered?: number;
  dimensionUncovered?: number;
  dimensionSemicovered?: number;
  //Foreigns
  associate?: Associate;

  //
  associatePhoto?: string;
  associateRawPhoto?: string;
  //

  type?: any; // TODO?: Volver a usar ListingType cuando no tenga conflictos con emprendimientos
  operation?: Operation;
  billingFrequency?: BillingFrequency;
  status?: ListingStatus;
  opportunity?: ListingOportunity;
  photos?: ListingPhoto[];
  photos360?: ListingPhoto360[];
  conditions?: Condition[];
  features?: Feature[];
  geo?: GeoSearch;
  addressInfo?: any;
  //Favorito
  isFavorite?: boolean;
  //Grupo de Brokers
  listBroker?: Associate[];
  listingStatus?: ListingStatus;
  //Banners Lendar Garantor
  showLendarBanner?: boolean;
  quotes?: number;
  feeQuotes?: number;
  reducedMobility?: boolean;

  minBathrooms?: number;
  maxBathrooms?: number;
  minDimension?: number;
  minDimensionCovered?: number;
  minDimensionLand?: number;
  minDimensionSemicovered?: number;
  minDimensionUncovered?: number;
  minParkingSpaces?: number;
  minPrice?: number;
  minCurrency?: any;
  minToiletrooms?: number;
  minTotalRooms?: number;

  maxDimension?: number;
  maxDimensionCovered?: number;
  maxDimensionLand?: number;
  maxDimensionSemicovered?: number;
  maxDimensionUncovered?: number;
  maxParkingSpaces?: number;
  maxPrice?: number;
  maxCurrency?: any;
  maxToiletrooms?: number;
  maxTotalRooms?: number;
  priceExposure?: boolean;
}

export class AssociatePhones {
  primary?: boolean;
  type?: string;
  value?: string;
}

export class AssociateEmails {
  primary?: boolean;
  value?: string;
}
