<!-- Navbar -->
<qr-list-listing-sub-navbar
  [isEntrepreneurship]="false"
  (onChangeView)="changeView($event)"></qr-list-listing-sub-navbar>

<!-- Skeleton loader -->
@if (isResultsLoading || isMapResultsLoading) {
<listing-list-loader [viewMode]="searchDomain.viewMode"></listing-list-loader>
} @else { @if (searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST ||
searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID) {
<div
  [ngClass]="(searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST || searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID )? 'results-container' : 'results-container-map'">
  <div id="search-filters-text">
    @if (searchDomain.reportResult) {
    <div>
      <h1 [innerHtml]="searchDomain.reportResult.titleText"></h1>
    </div>
    }
  </div>
  <div class="flex">
    @if( country !== "ec"){
    <qr-button-with-icon
      [config]="saveSearchButtons"
      (click)="openSaveSearch()">
    </qr-button-with-icon>
    }
    <div id="order-by">
      @if (dataOrderBy$ | async; as data) {
      <div>
        <mat-form-field appearance="outline" class="background-white">
          <mat-label
            >{{ dropdownSelectOrderBy.placeholder | translate:locale.language
            }}</mat-label
          >
          <mat-select
            [(value)]="orderBySelectedOption"
            (selectionChange)="orderByDropdownChanged($event)"
            floatlLabel="never">
            @for (item of data; track item) {
            <mat-option [value]="item">
              {{ 'list.' + item.value | translate:locale.language | titlecase }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      }
    </div>
  </div>
</div>
}
<!------------------------->
<!-- Modo de vista: Listado y Grilla  -->
<!------------------------->
@if (searchDomain.listingAndEntrepreneurship.length > 0) {
<div>
  @if (searchDomain.viewMode !== LISTINGS_VIEW_MODE_MAP) {
  <div
    class="container"
    [ngClass]="searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST ? 'viewListTemplate': 'viewGridTemplate'">
    @for (prop of searchDomain.listingAndEntrepreneurship; track prop) {
    <qr-card-property
      [isImageSwiper]="true"
      [data]="prop"
      [isViewList]="searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST"
      [isViewGrid]="searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID"
      [isFavoriteBtnVisible]="!loadingFavorites"
      (cardChange)="cardPropertyChange($event)"></qr-card-property>
    }
  </div>
  <div id="pagination">
    <qr-pagination
      [actualPage]="searchDomain.page"
      [itemsArray]="searchDomain.totalItems"
      [pageSize]="searchDomain.pageSize"
      (onPagination)="pagination($event)">
    </qr-pagination>
  </div>
  }
  <!------------------------->
  <!-- Modo de vista: Mapa -->
  <!------------------------->
  @if (searchDomain.viewMode === LISTINGS_VIEW_MODE_MAP) { @if (isBrowser) {
  <div id="viewMapTemplate">
    @if (isDesktop) {
    <div id="card-container">
      @for (prop of searchDomain.listingAndEntrepreneurship; track prop) {
      <qr-card-property
        id="card"
        [isImageSwiper]="true"
        [data]="prop"
        [isViewList]="searchDomain.viewMode === LISTINGS_VIEW_MODE_LIST"
        [isViewGrid]="searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID"
        [isFavoriteBtnVisible]="!loadingFavorites"
        (cardChange)="cardPropertyChange($event)"></qr-card-property>
      }
      <div id="pagination">
        <qr-pagination
          [actualPage]="searchDomain.page"
          [itemsArray]="searchDomain.totalItems"
          [pageSize]="searchDomain.pageSize"
          (onPagination)="pagination($event)">
        </qr-pagination>
      </div>
    </div>
    }
    <div id="map">
      <qr-map-google
        [propHoverID]="propHoverID"
        [coords]="coords"
        [center]="mapCenter"
        [zoom]="zoom"
        (onclickmarker)="onclickmarker($event)"
        (polygonCoordinatesChanged)="polygonCoordinatesChanged($event)"></qr-map-google>
    </div>
    @if (statusCardPropMapMode.isVisible && cardPropMapModeSelected) {
    <qr-card-property
      [data]="cardPropMapModeSelected"
      [isOverlayMode]="true"
      [status]="statusCardPropMapMode"
      [isImageSwiper]="false"
      [isViewList]="true"
      (cardChange)="cardPropertyChange($event)"></qr-card-property>
    }
  </div>
  } }
</div>
} @else {
<div
  class="no-results-container"
  fxLayoutAlign="center center"
  fxLayout="column">
  <div id="no-results-illustration"></div>
  <p class="no-results" id="first">
    No hay propiedades que coincidan con tu búsqueda.
  </p>
  <p class="no-results">
    Modifica o elimina tus filtros para encontrar más propíedades
  </p>
  <div id="clean-filter-button">
    <qr-button
      (click)="cleanFilters()"
      [config]="configButtonCleanFilter"></qr-button>
  </div>
</div>
} }

<!-- No hay resultados  -->

<!-- Diálogo: Creá tu cuenta -->
<qr-dialog [data]="dialogCreateAccount"></qr-dialog>
<qr-dialog [data]="dialogDataSaveSearch"></qr-dialog>
<qr-dialog [data]="dialogDataNotSaveSearch"></qr-dialog>
