<h2 class="subheading-03-bold">Eliminar cuenta</h2>
<p class="text mrg">Tu cuenta será eliminada de forma permanente.</p>
<div class="checkbox-container">
  <mat-checkbox
    color="primary"
    [(ngModel)]="checkbox"
    (ngModelChange)="confirmDelete()">
  </mat-checkbox>
  <p class="text">Confirmo que se eliminará mi cuenta por completo</p>
</div>
@if (loading) {
  <div class="loader-container">
    <mat-spinner diameter="24"></mat-spinner>
  </div>
}
<div class="buttons-box">
  <div class="cancel-btn">
    <qr-button
      [config]="this.configButtonCancel"
      (click)="onNavigate()"></qr-button>
  </div>
  <div class="delete-btn">
    <qr-button
      [config]="this.configButtonDelete"
      (click)="onDeleteUser()"></qr-button>
  </div>
</div>
@if (this.errorService) {
  <p class="text-error service-error">Ha ocurrido un error.</p>
}
@if (successDelete) {
  <div class="snackbar">Su cuenta se ha eliminado con éxito ✅</div>
}
