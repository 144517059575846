<div id="container" class="flex-row flex-center-center hide-gt-sm">
  <button
    class="first"
    [class]="this.style[0]"
    [style.height]="this.config.height"
    (click)="this.clickFirst()"
    [disabled]="this.config.isDisabled"
    matRipple
    aria-label="Quiero comprar">
    {{ 'home.' + this.config.text[0].small | translate: locale.language }}
  </button>
  <button
    class="middle"
    [class]="this.style[1]"
    [style.height]="this.config.height"
    (click)="this.clickSecond()"
    [disabled]="this.config.isDisabled"
    matRipple
    aria-label="Quiero alquilar">
    {{ 'home.' + this.config.text[1].small | translate: locale.language }}
  </button>
  <button
    class="last"
    [class]="this.style[2]"
    [style.height]="this.config.height"
    (click)="this.clickThird()"
    [disabled]="this.config.isDisabled"
    matRipple
    aria-label="Quiero vender">
    {{ 'home.' + this.config.text[2].small | translate: locale.language }}
  </button>
  @if (
    this.config.selected &&
    this.config.selected.length === 4 &&
    country === 'ar'
  ) {
    <button
      class="last"
      [class]="this.style[3]"
      class="entre-mobile"
      [style.height]="this.config.height"
      (click)="this.clickFourth()"
      [disabled]="this.config.isDisabled"
      matRipple
      aria-label="Emprendimientos">
      {{ 'home.' + this.config.text[3].small | translate: locale.language }}
    </button>
  }
</div>

<div class="flex-row flex-center-center hide-lt-md">
  <button
    [ngClass]="{
      entrepreneurship: this.config.selected.length === 4 && country === 'ar',
    }"
    class="desktop"
    [class]="this.style[0]"
    [style.height]="this.config.height"
    (click)="this.clickFirst()"
    [disabled]="this.config.isDisabled"
    matRipple
    aria-label="Quiero comprar">
    {{ 'home.' + this.config.text[0].big | translate: locale.language }}
  </button>
  <button
    [ngClass]="{
      entrepreneurship: this.config.selected.length === 4 && country === 'ar',
    }"
    class="desktop"
    [class]="this.style[1]"
    [style.height]="this.config.height"
    (click)="this.clickSecond()"
    [disabled]="this.config.isDisabled"
    matRipple
    aria-label="Quiero alquilar">
    {{ 'home.' + this.config.text[1].big | translate: locale.language }}
  </button>
  <button
    [ngClass]="{
      entrepreneurship: this.config.selected.length === 4 && country === 'ar',
      'margin-right-0': this.config.selected.length === 3,
    }"
    class="desktop"
    [class]="this.style[2]"
    [style.height]="this.config.height"
    (click)="this.clickThird()"
    [disabled]="this.config.isDisabled"
    matRipple
    aria-label="Quiero vender">
    {{ 'home.' + this.config.text[2].big | translate: locale.language }}
  </button>
  @if (
    this.config.selected &&
    this.config.selected.length === 4 &&
    country === 'ar'
  ) {
    <button
      [ngClass]="{
        entrepreneurship: this.config.selected.length === 4 && country === 'ar',
        'margin-right-0': this.config.selected.length === 4,
      }"
      class="desktop"
      [class]="this.style[3]"
      [style.height]="this.config.height"
      (click)="this.clickFourth()"
      [disabled]="this.config.isDisabled"
      matRipple
      aria-label="Emprendimientos">
      {{ 'home.' + this.config.text[3].big | translate: locale.language }}
    </button>
  }
</div>
