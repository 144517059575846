import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { environment } from '@base/environments/environment';
import { QrButtonComponent } from '@app/components/qr-button/qr-button.component';
import { Constants } from '@base/src/app/core/constants/constants';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { ContactForm } from '@app/core/models/contact-form.model';
import { ContactsFormService } from '@app/services/contacts-form.service';
import { UtilsService } from '@app/services/utils.service';
import { filter } from 'rxjs';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [
    QrButtonComponent,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.scss',
})
export class ContactPage implements OnInit {
  configButtonSend = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Enviar',
    isDisabled: true,
  };

  receiveCopy: boolean = false;

  isFormCorrectlySent = false;

  form: UntypedFormGroup;
  fullName = new UntypedFormControl('', Validators.required);
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  phone = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('^[0-9]*$'),
  ]);

  ubication = new UntypedFormControl('', Validators.required);
  comments = new UntypedFormControl('', Validators.required);
  categoryType = new UntypedFormControl('', Validators.required);

  optionsCategory = [
    'Oportunidades de franquicias',
    'Buscar una oficina o agente',
    'Comprar o vender una propiedad',
    'Comentarios sobre el sitio web',
    'Más información',
    'Solicitud por The RE/MAX Collection',
  ];

  isBrowser: boolean;

  constructor(
    fb: UntypedFormBuilder,
    @Inject(PLATFORM_ID) private platformId: any,
    private contactsFormService: ContactsFormService,
    private utils: UtilsService
  ) {
    this.form = fb.group({
      fullName: this.fullName,
      email: this.email,
      phone: this.phone,
      ubication: this.ubication,
      comments: this.comments,
      categoryType: this.categoryType,
      receiveCopy: false,
    });
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (environment.node == Constants.NODE_ECUADOR) {
      this.optionsCategory = [
        'Oportunidades de franquicias',
        'Buscar una oficina o agente',
        'Comprar o vender una propiedad',
        'Comentarios sobre el sitio web',
        'Más información',
      ];
    }
    // Metatags SEO

    const title = 'Envíenos un mensaje | RE/MAX';
    const description =
      'Completá el formulario para contactarnos sobre cualquier pregunta o comentario que tengas y nos contactaremos a la brevedad.';

    this.utils.setPageMetaTags(title, description);

    this.form.statusChanges
      .pipe(
        filter((status: string) => {
          if (status == 'VALID') this.configButtonSend.isDisabled = false;
          else this.configButtonSend.isDisabled = true;
          return this.form.valid;
        })
      )
      .subscribe();
  }

  getErrorMessage(field: string): string {
    let errorMsg = '';
    switch (field) {
      case 'fullName':
        if (this.fullName.hasError('required'))
          errorMsg = 'Ingrese un nombre y apellido';
        break;
      case 'email':
        if (this.email.hasError('required'))
          errorMsg = 'Ingrese un email valido';
        if (this.email.hasError('email'))
          errorMsg = 'Ingrese un email con formato valido';
        break;
      case 'phone':
        if (this.phone.hasError('required')) errorMsg = 'Ingrese un teléfono';
        if (this.phone.hasError('pattern')) errorMsg = 'Ingrese solo números';
        break;
      case 'ubication':
        if (this.ubication.hasError('required'))
          errorMsg = 'Ingrese una ubicación';
        break;
      case 'propertyType':
        if (this.categoryType.hasError('required'))
          errorMsg = 'Seleccione como nos conociste';
        break;
      case 'rooms':
        if (this.comments.hasError('required'))
          errorMsg = 'Ingrese un comentario';
        break;
      default:
        errorMsg = 'error';
        break;
    }
    return errorMsg;
  }

  onCheck(event: any) {
    this.receiveCopy = event.checked;
  }

  async onSubmitFormContact(): Promise<void> {
    try {
      const contactForm: ContactForm = new ContactForm();

      contactForm.name = this.form.get('fullName')?.value;
      contactForm.phone = this.form.get('phone')?.value;
      contactForm.email = this.form.get('email')?.value;
      contactForm.locality = this.form.get('ubication')?.value;
      contactForm.category = this.form.get('categoryType')?.value;
      contactForm.comments = this.form.get('comments')?.value;
      contactForm.receiveCopy = this.receiveCopy;

      const result: IQrResponse<any> =
        await this.contactsFormService.addGet(contactForm);

      if (result.code == 200) {
        this.isFormCorrectlySent = true;
      } else {
        alert('contactBeAgent.onSubmit: ERROR ' + result.message);
      }
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    } catch (error) {
      alert('contactBeAgent.onSubmit: ERROR ' + error);
    }
  }

  getHeroClassRegion(): string {
    return environment.node;
  }
}
