import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '@base/src/app/core/constants/constants';
import { Title, Meta } from '@angular/platform-browser';
import { environment } from '@base/environments/environment';
import {
  IButtonConfig,
  QrButtonComponent,
} from '@app/components/qr-button/qr-button.component';
import { CommonModule, DOCUMENT } from '@angular/common';
import { LandingTotalResultService } from './../../../services/landing-total-result.service';
import {
  LandingListSEO,
  TotalResultsSEO,
} from '@app/core/models/total-results-seo.model';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { QrIconButtonComponent } from '@app/components/qr-icon-button/qr-icon-button.component';

@Component({
  selector: 'app-landing',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, QrButtonComponent, QrIconButtonComponent],
})
export class LandingPage implements OnInit {
  title: string = '';
  subtitle: string = '';
  city: string = '';
  keywords: string = '';
  bannerImg1: string = '';
  bannerImg2: string = '';
  bannerImg3: string = '';
  urlBanner1 = '';
  urlBanner2 = '';
  urlBanner3 = '';

  node = environment.node;
  totalResult = 0;

  configButtonExpand = {
    style: Constants.BUTTON_COLOR_GREY_BASIC,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  isSectionOneOpen = false;
  isSectionTwoOpen = false;
  isSectionThreeOpen = false;
  isSectionFourOpen = false;
  isRosario = false;
  isMontevideo = false;

  // Config Botones
  hasButtons = true; // esconder/mostrar contenedor de botones
  configHouseSale = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: '',
    url: '',
  };

  configHouseRent = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: '',
    url: '',
  };

  configAptSale = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: '',
    url: '',
  };

  configAptRent = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: '',
    url: '',
  };

  configLand = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: '',
    url: '',
  };

  configLandSale = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: '',
    url: '',
  };

  // Botón: Ver propiedades
  configButtonViewProperties: IButtonConfig = {
    id: 'view-properties',
    style: Constants.BUTTON_COLOR_PRIMARY_BORDER,
    height: Constants.BUTTON_HEIGHT_56PX,
    text: 'home.view-properties',
    selected: false,
    isDisabled: false,
    changeStyleOnSelected: false,
    styleOnSelected: null,
  };

  text: any[] = [];
  isFullButtons = true;

  disclaimer = '';

  getURLLocation: Location | null = null;

  isDinamicButtonViewPropertyEnabled?: boolean = false; // Nuevos banners dinámicos
  document: any;

  constructor(
    @Inject(DOCUMENT) private dom: any,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    private landingTotalResultService: LandingTotalResultService
  ) {}

  async ngOnInit() {
    let country = '';
    switch (environment.node) {
      case Constants.NODE_ECUADOR:
        country = 'Ecuador';
        break;
      case Constants.NODE_URUGUAY:
        country = 'Uruguay';
        break;
      default:
        country = 'Argentina';
    }

    this.getURLLocation = this.dom.location;
    const path = this.route.snapshot.url[0]?.path;
    const url =
      this.getURLLocation?.protocol +
      '//' +
      this.getURLLocation?.host +
      '/' +
      path;

    this.updateCanonicalUrl(url);

    // Armado del contenido de las landings
    switch (path) {
      // PROPIEDADES LA PLATA

      case 'propiedades-en-la-plata':
        this.isFullButtons = true;
        this.city = 'La Plata';
        this.title = 'Propiedades en La Plata';
        this.bannerImg1 = '/assets/images/landing/depto-lp.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-lp.jpg';
        this.urlBanner1 = '/departamentos-en-la-plata';
        this.urlBanner2 = '/casas-en-la-plata';
        this.configHouseSale.text = 'Casas en venta en La Plata';
        this.configHouseSale.url = '/casas-en-venta-en-la-plata';
        this.configHouseRent.text = 'Casas en alquiler en La Plata';
        this.configHouseRent.url = '/casas-en-alquiler-en-la-plata';
        this.configAptSale.text = 'Departamentos en venta en La Plata';
        this.configAptSale.url = '/departamentos-en-venta-en-la-plata';
        this.configAptRent.text = 'Departamentos en alquiler en La Plata';
        this.configAptRent.url = '/departamentos-en-alquiler-en-la-plata';
        this.configLand.text = 'Terrenos en La Plata';
        this.configLand.url = '/terrenos-en-la-plata';
        this.configLandSale.text = 'Terrenos en venta en La Plata';
        this.configLandSale.url = '/terrenos-en-venta-en-la-plata';
        this.disclaimer =
          'Si querés mudarte, las oficinas RE/MAX cuentan con variadas opciones de Propiedades en La Plata  y nuestro equipo especializado podrá ayudarte a encontrar la propiedad de tus sueños.';
        this.text = [
          {
            title: 'La Plata, la ciudad de las diagonales.',
            text: [
              'La Plata, la capital de la provincia de Buenos Aires, se ubica 56 km al sudeste de la Ciudad de Buenos Aires. Es la cuarta ciudad más poblada de Argentina, detrás de Buenos Aires, Córdoba y Rosario. La Plata es una ciudad que fue planificada antes de su fundación en el siglo XIX. Un claro ejemplo de esto son sus famosas avenidas diagonales que permiten acceder, rápidamente, a cualquier punto de la ciudad. Esta característica es muy valorada por sus vecinos, ya que las propiedades en La Plata  se ven beneficiadas por estar ubicadas en una traza urbana completamente organizada.',
            ],
          },
          {
            title: 'Una ciudad verde.',
            text: [
              'Las propiedades en La Plata se encuentran realmente rodeadas de espacios verdes. La ciudad cuenta con 24 metros cuadrados de espacio verde por habitante. Cada 6 cuadras, hay una plaza, cada 12, un parque, sus calles arboladas regulan la temperatura y brindan sombra a sus pobladores.',
            ],
          },
          {
            title: 'Vivir en La Plata.',
            text: [
              'La Plata es una ciudad con alto valor arquitectónico, urbanístico y cultural. Cuenta con importantes universidades -la Nacional, la Tecnológica y la Católica- lo que la convierte en una de las ciudades universitarias más importantes y populosas del país.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN MAR DEL PLATA

      case 'propiedades-en-mar-del-plata':
        this.isFullButtons = true;
        this.city = 'Mar del Plata';
        this.title = 'Propiedades en Mar del Plata';
        this.bannerImg1 = '/assets/images/landing/depto-mdq.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-mdq.jpg';
        this.urlBanner1 = '/departamentos-en-mar-del-plata';
        this.urlBanner2 = '/casas-en-mar-del-plata';
        this.configHouseSale.text = 'Casas en venta en Mar del Plata';
        this.configHouseSale.url = '/casas-en-venta-en-mar-del-plata';
        this.configHouseRent.text = 'Casas en alquiler en Mar del Plata';
        this.configHouseRent.url = '/casas-en-alquiler-en-mar-del-plata';
        this.configAptSale.text = 'Departamentos en venta en Mar del Plata';
        this.configAptSale.url = '/departamentos-en-venta-en-mar-del-plata';
        this.configAptRent.text = 'Departamentos en alquiler en Mar del Plata';
        this.configAptRent.url = '/departamentos-en-alquiler-en-mar-del-plata';
        this.configLand.text = 'Terrenos en Mar del Plata';
        this.configLand.url = '/terrenos-en-mar-del-plata';
        this.configLandSale.text = 'Terrenos en venta en Mar del Plata';
        this.configLandSale.url = '/terrenos-en-venta-en-mar-del-plata';
        this.disclaimer =
          'Si estás interesado en encontrar propiedades en Mar del Plata, nuestros equipos especializados podrán ayudarte.';
        this.text = [
          {
            title: 'Mar del Plata, a orillas del Atlántico.',
            text: [
              'Mar del Plata se encuentra ubicada en la Provincia de Buenos Aires, a orillas del océano Atlántico, a 400 km de la Ciudad de Buenos Aires. Llamada la Ciudad Feliz, Mar del Plata es la principal ciudad balnearia y turística de la Argentina, conjugando la belleza de sus recursos naturales con la infraestructura de una gran ciudad. Al recibir anualmente a más de 8.000.000 turistas, la oferta de propiedades en Mar del Plata es muy variada: desde departamentos con vista al mar en pleno centro hasta enormes casas rodeadas de bosque.',
            ],
          },
          {
            title: 'Mar del Plata, vivir rodeado de verde y mar.',
            text: [
              'Para aquellos que desean vivir cerca del mar, Mar del Plata es la ciudad ideal: sus playas extensas, hermosas bahías, imponentes acantilados, espectaculares bosques, sierras, arroyos y campos son parte de su paisaje, siempre acompañado de la infraestructura y servicios de una gran ciudad.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN SANTA FE

      case 'propiedades-en-santa-fe':
        this.city = 'Santa Fe';
        this.isFullButtons = true;
        this.title = 'Propiedades en Santa Fe';
        this.bannerImg1 = '/assets/images/landing/depto-sta-fe.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-sta-fe.jpg';
        this.urlBanner1 = '/departamentos-en-santa-fe';
        this.urlBanner2 = '/casas-en-santa-fe';
        this.configHouseSale.text = 'Casas en venta en Santa Fe';
        this.configHouseSale.url = '/casas-en-venta-en-santa-fe';
        this.configHouseRent.text = 'Casas en alquiler en Santa Fe';
        this.configHouseRent.url = '/casas-en-alquiler-en-santa-fe';
        this.configAptSale.text = 'Departamentos en venta en Santa Fe';
        this.configAptSale.url = '/departamentos-en-venta-en-santa-fe';
        this.configAptRent.text = 'Departamentos en alquiler en Santa Fe';
        this.configAptRent.url = '/departamentos-en-alquiler-en-santa-fe';
        this.configLand.text = 'Terrenos en Santa Fe';
        this.configLand.url = '/terrenos-en-santa-fe';
        this.configLandSale.text = 'Terrenos en venta en Santa Fe';
        this.configLandSale.url = '/terrenos-en-venta-en-santa-fe';
        this.disclaimer =
          'A lo largo de los años, el desarrollo de las propiedades en Santa Fe ha ido acompañando la creciente demanda de su población y también las renovadas necesidades de los turistas.';
        this.text = [
          {
            title: 'Santa Fe, producción agropecuaria y negocios.',
            text: [
              'Santa Fe está ubicada en la región centro-este de la República Argentina. Se destaca por un alto nivel de producción agropecuaria y un desarrollo industrial en plena expansión. Cuenta con infraestructura para el transporte tanto fluvial como terrestre. Santa Fe está muy bien preparada para la recepción del turismo de negocios. Exposiciones, congresos y conferencias, seminarios y rondas de negocios son algunas de las actividades por las cuales esta gran ciudad recibe muchísmos visitantes. Por tal motivo, las propiedades en Santa Fe son muy demandadas, tanto con finalidad residencial, como para radicar negocios.',
            ],
          },
          {
            title: 'Vivir en Santa Fe.',
            text: [
              'Las propiedades en Santa Fe se encuentran ubicadas en un entorno muy natural. A pocos pasos de la ciudad, encontramos arroyos, lagunas, ríos, hermosos parques y playas que brindan un ambiente de relax y tranquilidad. Contactate con nuestro equipo especializado para que pueda asesorarte y ayudarte en la compra, venta o alquiler de tu propiedad.',
            ],
          },
          {
            title: 'Santa Fe, turística.',
            text: [
              'Santa Fe cuenta con un gran abanico de opciones tanto en hotelería como en gastronomía, lo que permite a sus visitantes encontrar siempre una alternativa para sentirse más que a gusto en la ciudad. Los paseos costeros, con sus vistas al emblemático puente colgante, son una postal de la ciudad. Shoppings, comercios de productos regionales, teatros y bares completan la gran variedad de ofertas que esta maravillosa ciudad tiene para sus vecinos y visitantes.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN SALTA

      case 'propiedades-en-salta':
        this.city = 'Salta';
        this.isFullButtons = true;
        this.title = 'Propiedades en Salta';
        this.bannerImg1 = '/assets/images/landing/depto-salta.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-salta.jpg';
        this.urlBanner1 = '/departamentos-en-salta';
        this.urlBanner2 = '/casas-en-salta';
        this.configHouseSale.text = 'Casas en venta en Salta';
        this.configHouseSale.url = '/casas-en-venta-en-salta';
        this.configHouseRent.text = 'Casas en alquiler en Salta';
        this.configHouseRent.url = '/casas-en-alquiler-en-salta';
        this.configAptSale.text = 'Departamentos en venta en Salta';
        this.configAptSale.url = '/departamentos-en-venta-en-salta';
        this.configAptRent.text = 'Departamentos en alquiler en Salta';
        this.configAptRent.url = '/departamentos-en-alquiler-en-salta';
        this.configLand.text = 'Terrenos en Salta';
        this.configLand.url = '/terrenos-en-salta';
        this.configLandSale.text = 'Terrenos en venta en Salta';
        this.configLandSale.url = '/terrenos-en-venta-en-salta';
        this.disclaimer =
          'Si estás buscando alquilar temporalmente en este mágico lugar, tenemos varias propiedades en Salta que te pueden interesar. ¡Contactate con el Equipo RE/MAX!';
        this.text = [
          {
            title: 'Salta, La Linda, un lugar que enamora.',
            text: [
              'Ubicada en la cordillera de los Andes, al noroeste de la República Argentina, Salta se encuentra a 1.600 km de la ciudad de Buenos Aires y cuenta con una población de aproximadamente 1.300.000 habitantes. Conocida como “Salta, la Linda”, esta ciudad se encuentra a 1.187 metros sobre el nivel del mar, en el Valle de Lerma, al pie del Cerro San Bernardo. Su cultura vibrante, su arquitectura colonial muy bien preservada, sus hermosos y variados paisajes atraen a turistas de todo el mundo, por lo que la oferta de propiedades en Salta es muy variada: desde departamentos en el centro de la ciudad a encantadoras chacras en las afueras.',
            ],
          },
          {
            title: 'Salta, vivir entre montañas.',
            text: [
              'Con una temperatura promedio anual que oscila los 25º, Salta es una ciudad sumamente amena para vivir, conectarse con la naturaleza y la calidez de sus habitantes. Si estás pensando que es tu lugar, te acompañamos en la búsqueda de propiedades en Salta con nuestro equipo especializado.',
            ],
          },
          {
            title: 'Salta, mágica y turística.',
            text: [
              'Salta es mágica por donde se la mire. Tanto que nos permite llegar a las nubes. Sí, gracias a su famoso Tren de Las Nubes, uno de los más altos del mundo, que a su paso permite ir descubriendo los maravillosos paisajes salteños: desde la puna a las yungas, pasando por hermosos valles rodeados de montañas. Además, La Linda cuenta con un circuito turístico-temático de 200 km, conocido como La Ruta del Vino, en el que se pueden visitar los viñedos de algunas de las bodegas más reconocidas de la Argentina, degustando sus exquisitos vinos y un amplio abanico de posibilidades gourmet.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN MENDOZA

      case 'propiedades-en-mendoza':
        this.city = 'Mendoza';
        this.isFullButtons = true;
        this.title = 'Propiedades en Mendoza';
        this.bannerImg1 = '/assets/images/landing/depto-mendoza.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-mendoza.jpg';
        this.urlBanner1 = '/departamentos-en-mendoza';
        this.urlBanner2 = '/casas-en-mendoza';
        this.configHouseSale.text = 'Casas en venta en Mendoza';
        this.configHouseSale.url = '/casas-en-venta-en-mendoza';
        this.configHouseRent.text = 'Casas en alquiler en Mendoza';
        this.configHouseRent.url = '/casas-en-alquiler-en-mendoza';
        this.configAptSale.text = 'Departamentos en venta en Mendoza';
        this.configAptSale.url = '/departamentos-en-venta-en-mendoza';
        this.configAptRent.text = 'Departamentos en alquiler en Mendoza';
        this.configAptRent.url = '/departamentos-en-alquiler-en-mendoza';
        this.configLand.text = 'Terrenos en Mendoza';
        this.configLand.url = '/terrenos-en-mendoza';
        this.configLandSale.text = 'Terrenos en venta en Mendoza';
        this.configLandSale.url = '/terrenos-en-venta-en-mendoza';
        this.disclaimer =
          'Si te interesa mudarte a esta gran metrópoli regional, encontrarás numerosas opciones de propiedades en Mendoza contactándote con nuestras Oficinas RE/MAX, para que nuestro equipo especializado te ayude con la compra o venta de tu propiedad.';
        this.text = [
          {
            title: 'Mendoza, metrópoli regional.',
            text: [
              'Mendoza es la ciudad más importante de la región de cuyo. Posee un amplio desarrollo turístico, comercial, industrial y cultural. Cuenta con importantes museos, monumentos históricos y reconocidas universidades a las que cada año concurren a formarse cientos de estudiantes. Posee una importante infraestructura hotelera y un gran desarrollo del comercio gastronómico. También se destacan sus shoppings, cines, teatros y galerías de arte. El crecimiento de la ciudad ha provocado el florecimiento de diferentes alternativas de propiedades en Mendoza.',
            ],
          },
          {
            title: 'Mendoza, turística.',
            text: [
              'Mendoza es zona vitivinícola por excelencia, con numerosas bodegas para la producción de vinos de alta calidad que son exportados a todo el mundo. La Ruta del Vino es uno de los atractivos más añejos y renovados que tiene la ciudad. Los visitantes pueden recorrer distintas bodegas, degustando sus vinos y disfrutando de las más variadas opciones gastronómicas. Su cercanía a la cordillera de los Andes, la convierte en un destino inmejorable para el turismo aventura: trekking, rafting, montañismo y espectaculares paisajes para realizar safaris fotográficos, entre otras actividades. Otras posibilidades para el turista son la práctica del esquí en invierno y el termalismo en cualquier época del año. Las propiedades en Mendoza están pensadas para satisfacer las distintas necesidades del turismo, sea urbano o de aventura. Las inmobiliarias en Mendoza se adaptan a dichas necesidades, tanto para residentes, como para visitantes.',
            ],
          },
          {
            title: 'Vivir entre montañas.',
            text: [
              'La ciudad de Mendoza tiene mucho para ofrecer. Sus calles arboladas se benefician del agua de deshielo y lluvia mediante su centenaria red de acequias. Su clima agradable, templado y con escasas lluvias, hace de Mendoza un lugar muy placentero para vivir.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN ROSARIO

      case 'propiedades-en-rosario':
        this.city = 'Rosario';
        this.isFullButtons = true;
        this.title = 'Propiedades en Rosario';
        this.bannerImg1 = '/assets/images/landing/depto-rosario.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-rosario.jpg';
        this.urlBanner1 = '/departamentos-en-rosario';
        this.urlBanner2 = '/casas-en-rosario';
        this.configHouseSale.text = 'Casas en venta en Rosario';
        this.configHouseSale.url = '/casas-en-venta-en-rosario';
        this.configHouseRent.text = 'Casas en alquiler en Rosario';
        this.configHouseRent.url = '/casas-en-alquiler-en-rosario';
        this.configAptSale.text = 'Departamentos en venta en Rosario';
        this.configAptSale.url = '/departamentos-en-venta-en-rosario';
        this.configAptRent.text = 'Departamentos en alquiler en Rosario';
        this.configAptRent.url = '/departamentos-en-alquiler-en-rosario';
        this.configLand.text = 'Terrenos en Rosario';
        this.configLand.url = '/terrenos-en-rosario';
        this.configLandSale.text = 'Terrenos en venta en Rosario';
        this.configLandSale.url = '/terrenos-en-venta-en-rosario';
        this.disclaimer = '';
        this.text = [
          {
            title: 'Rosario, ubicación privilegiada.',
            text: [
              'Con casi 1 millón de habitantes, Rosario es una de las ciudades más pobladas y elegidas de Argentina para vivir. ¿Por qué? Cuenta con grandes universidades, una variedad gastronómica impresionante y amplios espacios verdes para el esparcimiento de sus habitantes: 24 parques, 124 plazas, 51 plazoletas, 24 paseos y otros 228 espacios abiertos. Por esto, cada vez hay más demanda de alquileres y casas o departamentos para comprar. Sin embargo, asesorarse mediante las <strong>inmobiliarias en Rosario</strong> es fundamental para elegir el mejor espacio entre tantas opciones. ',
              'Seguramente, si estás pensando en mudarte a la ciudad donde se encuentra el Monumento Nacional a la Bandera Argentina, hayas hecho una búsqueda preliminar de “inmobiliarias rosario” en Google. Sin embargo, podemos decirte que estás en el lugar indicado, ya que RE/MAX es mucho más que una red de inmobiliarias. Una frase que nos caracteriza siempre es: <strong>“No somos un negocio de propiedades, sino de relaciones personales"</strong>.',
              'En todos los casos, la misión de RE/MAX es ayudar a los clientes que se acercan a encontrar lo que están buscando y asesorarlos para que puedan tomar la mejor decisión para sus vidas, ya que consideramos que elegir un nuevo hogar o una nueva oficina no es la tarea más fácil ni rápida de asumir y concretar. Por eso, al conocer los deseos de los clientes, sus necesidades, brindarles contención y acompañamiento durante todo el proceso que implica una decisión tan importante en sus vidas, creemos que podemos generar grandes logros.',
              'Por eso “mudate a la vida que querés” para nosotros es mucho más que un slogan al punto que muchos clientes se han enamorado de esta pasión por el servicio y pasaron “de este lado del mostrador” para formar parte de la red RE/MAX.',
              'Así que, no lo dudes, si necesitás asesoramiento para encontrar tu próximo hogar, estás en el lugar indicado. ',
            ],
          },
          {
            title: 'RE/MAX en Rosario',
            text: [
              'Sabemos que Rosario es una de las ciudades con más necesidad de adquirir viviendas, ya que año a año son muchas las personas y familias que por diferentes razones desean mudarse a esa ciudad. Por eso, también estamos en Rosario ayudando a todas las familias, parejas y personas que necesitan de nuestra asistencia en esta tarea que tanto nos apasiona.',
            ],
          },
          {
            title: 'Por qué vivir en Rosario',
            text: [
              'Ubicada al sureste de la provincia de Santa Fe, Rosario es la ciudad más poblada de la provincia y la tercera del país, después de la ciudad de Buenos Aires y de la ciudad de Córdoba.',
              'Rosario se caracteriza por ser una ciudad portuaria muy importante. De hecho, el puerto se desarrolla sobre una superficie de 140 hectáreas y se destaca por su posición geográfica privilegiada, en el centro del corredor bi oceánico y de la hidrovía Paraguay-Paraná. Esto le otorga una gran ventaja logística, posicionándose estratégicamente por su salida al mundo.',
              'Para los jóvenes, también hay una amplia oferta de carreras universitarias que pueden elegir en las universidades locales, para desarrollarse personal y profesionalmente en una ciudad que logra complementar la magia de la ciudad con la naturaleza. En este sentido, es también un lugar elegido por jóvenes de otras partes del país que desean mudarse para recibir educación universitaria.',
              'Por otro lado y sin dudas, en cuanto a la vida nocturna y recreativa, nadie tiene de qué preocuparse: Rosario es una ciudad con mucho movimiento, que logra entretener a toda la población. Incluso, es muy visitada durante todo el año, especialmente los fines de semana, por turistas tanto nacionales como internacionales.',
              'Estas son solo algunas razones por las cuales Rosario es cada vez más elegida, tanto por jóvenes como por adultos. Por eso, si tu idea es mudarte a esta ciudad, ya sabés que en RE/MAX podés encontrar una amplia variedad de propiedades en Rosario: desde departamentos y casas, hasta oficinas y propiedades en los barrios privados más exclusivos.',
              'No dudes en contactarte con una oficina RE/MAX para la venta, compra o alquiler de una propiedad. Nuestro equipo está altamente capacitado para ayudarte. Ya lo sabés, si lo querés es mudarte a la mejor opción: Preguntá por nuestras inmobiliarias en Rosario, vas a tener la mejor y única experiencia.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN CORDOBA

      case 'propiedades-en-cordoba':
        this.isDinamicButtonViewPropertyEnabled = true;
        this.city = 'Córdoba';
        this.isFullButtons = true;
        this.title = 'Propiedades en Córdoba';
        this.bannerImg1 = '/assets/images/landing/depto-cordoba.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-cordoba.jpg';
        this.urlBanner1 = '/departamentos-en-cordoba';
        this.urlBanner2 = '/casas-en-cordoba';
        this.configHouseSale.text = 'Casas en venta en Córdoba';
        this.configHouseSale.url = '/casas-en-venta-en-cordoba';
        this.configHouseRent.text = 'Casas en alquiler en Córdoba';
        this.configHouseRent.url = '/casas-en-alquiler-en-cordoba';
        this.configAptSale.text = 'Departamentos en venta en Córdoba';
        this.configAptSale.url = '/departamentos-en-venta-en-cordoba';
        this.configAptRent.text = 'Departamentos en alquiler en Córdoba';
        this.configAptRent.url = '/departamentos-en-alquiler-en-cordoba';
        this.configLand.text = 'Terrenos en Córdoba';
        this.configLand.url = '/terrenos-en-cordoba';
        this.configLandSale.text = 'Terrenos en venta en Córdoba';
        this.configLandSale.url = '/terrenos-en-venta-en-cordoba';
        this.disclaimer =
          'Las oficinas RE/MAX en Córdoba cuentan con una amplia cartera de departamentos en venta y alquiler como también casas en venta y alquiler. No dudes en comunicarte con nosotros. Nuestros equipos de corredores públicos y agentes especializados te ayudarán a encontrar la propiedad ideal que se acomode a tus necesidades.';
        this.text = [
          {
            title: 'Córdoba, una ciudad ideal para vivir.',
            text: [
              'Rodeada de sierras, ríos y hermosa vegetación, Córdoba es una ciudad muy elegida para vivir. Brinda múltiples opciones a la hora de alquilar o comprar departamentos y casas, tanto en pleno centro como en las afueras de la ciudad. El perfil arquitectónico de Córdoba varía según los barrios, lo que hace que la oferta de departamentos en alquiler y departamentos en venta sea muy amplia. Lo mismo ocurre con la oferta de casas, por eso, si estás buscando casas en alquiler o casas en venta en Córdoba, podrás contar con una amplia variedad de estilos y precios.',
            ],
          },
          {
            title: 'Paisajes serranos a pasos de la ciudad.',
            text: [
              'Si estás pensando en mejorar tu calidad de vida viviendo en un entorno más verde y natural pero sin alejarte de la ciudad, Córdoba te permite hacerlo porque se encuentra rodeada de localidades muy cercanas con bellísimos paisajes serranos, en las que podrás encontrar casas en alquiler o casas en venta para mudarte a la vida que soñás. Algunas localidades alejadas de la ciudad también cuentan con departamentos en alquiler y departamentos en venta. ',
            ],
          },
        ];
        break;

      // PROPIEDADES EN BARILOCHE

      case 'propiedades-en-bariloche':
        this.city = 'Bariloche';
        this.isFullButtons = false;
        this.title = 'Propiedades en Bariloche';
        this.bannerImg1 = '/assets/images/landing/depto-bariloche.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-bariloche.jpg';
        this.urlBanner1 = '/departamentos-en-bariloche';
        this.urlBanner2 = '/casas-en-bariloche';
        this.configHouseSale.text = 'Casas en venta en Bariloche';
        this.configHouseSale.url = '/casas-en-venta-en-bariloche';
        this.configHouseRent.text = 'Casas en alquiler en Bariloche';
        this.configHouseRent.url = '/casas-en-alquiler-en-bariloche';
        this.configAptSale.text = 'Departamentos en venta en Bariloche';
        this.configAptSale.url = '/departamentos-en-venta-en-bariloche';
        this.configAptRent.text = 'Departamentos en alquiler en Bariloche';
        this.configAptRent.url = '/departamentos-en-alquiler-en-bariloche';
        this.disclaimer =
          'Si querés mudarte a esta encantadora ciudad, podés encontrar una amplia variedad de propiedades en Bariloche. No dudes en contactarte con una oficina RE/MAX para la venta, compra o alquiler de una propiedad. Nuestro equipo está altamente capacitado para ayudarte.';
        this.text = [
          {
            title: 'Bariloche, Patagonia Argentina.',
            text: [
              'Esta ciudad se encuentra al sudoeste de la provincia de Río Negro, a los pies de la majestuosa cordillera de los Andes. Su belleza es inigualable. Rodeada de naturaleza y ubicada dentro del Parque Nacional Nahuel Huapi, “la capital del chocolate”, se eleva entre bosques milenarios, lagos cristalinos y altísimas montañas. Las propiedades en Bariloche se funden en este entorno natural único.',
            ],
          },
          {
            title: 'Bariloche, turística todo el año.',
            text: [
              'La oferta de propiedades en Bariloche, sobre todo la dedicada a la actividad turística, es muy amplia, ya que la ciudad recibe a miles de turistas durante todo el año, tanto por vía terrestre, como por vía aérea, gracias a su aeropuerto internacional. Bariloche cuenta con una gran variedad de actividades para disfrutar: excursiones por sus bosques, paseos lacustres, nieve, gastronomía, tranquilidad y una gran agenda cultural. El mayor centro de esquí de Latinoamérica, por su gran cantidad de superficie esquiable, se encuentra en Bariloche: el Cerro Catedral. También se encuentra en la ciudad el centro de ski del Cerro Otto. Ambos centros ofrecen excelentes pistas de esquí para disfrutar a pleno este deporte. También el Cerro López, Cerro Campanario, Cerro Tronador, Cerro Challhuaco, Cerro Runge y Cerro Los Leones ofrecen variadas actividades para el turismo aventura: cabalgatas, canopy, trekking, travesías 4x4, caminatas con raquetas, mountain bike, senderismo, cayak y trineos, culminando las travesías en exclusivos restaurantes que ofrecen un rico chocolate caliente, acompañado de una variedad de delicias regionales.',
            ],
          },
          {
            title: 'Propiedades entre lagos, bosques y montañas.',
            text: [
              'La mayoría de las propiedades en Bariloche se destaca por su estilo clásico de montaña y su construcción en piedra y madera. El entorno en el que se encuentran es muy atractivo: bosques, lagos, montañas. En verano, se puede apreciar la gran variedad de vegetación y fauna de la región. Se destacan las aguas cristalinas de sus lagos y ríos, con sus laderas con frutillas silvestres y rosa mosqueta. Si querés mudarte a Bariloche, no dudes en contactarte con nuestras Oficinas RE/MAX para que te ayuden y asesoren en la compra, venta o alquiler de la propiedad de tus sueños.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN PALERMO

      case 'propiedades-en-palermo':
        this.city = 'Palermo';
        this.isFullButtons = false;
        this.title = 'Propiedades en Palermo';
        this.bannerImg1 = '/assets/images/landing/depto-palermo.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-palermo.jpg';
        this.urlBanner1 = '/departamentos-en-palermo';
        this.urlBanner2 = '/casas-en-palermo';
        this.configHouseSale.text = 'Casas en venta en Palermo';
        this.configHouseSale.url = '/casas-en-venta-en-palermo';
        this.configHouseRent.text = 'Casas en alquiler en Palermo';
        this.configHouseRent.url = '/casas-en-alquiler-en-palermo';
        this.configAptSale.text = 'Departamentos en venta en Palermo';
        this.configAptSale.url = '/departamentos-en-venta-en-palermo';
        this.configAptRent.text = 'Departamentos en alquiler en Palermo';
        this.configAptRent.url = '/departamentos-en-alquiler-en-palermo';
        this.disclaimer =
          'Todo esto es lo que impulsa a las inmobiliarias en Palermo a conocer los secretos de uno de los barrios más prolíficos de la Ciudad de Buenos Aires. Por todo esto, quienes busquen inmobiliarias en Palermo deben saber que en nuestras oficinas encontrarán agentes capacitados para ayudar, guiar y asesorar en la búsqueda de propiedades.';
        this.text = [
          {
            title: 'Lugares de interés en Palermo',
            text: [
              'De esta serie de parques, el Parque Tres de Febrero, con sus lagos, canchas de tenis y un jardín de rosas es el más grande de todos. En dicho parque se encuentra el Museo de Arte Moderno Eduardo Sívori. Al este de ambos parques se encuentra el Planetario, el cual posee en su entrada, una muestra de varios e impresionantes meteoritos de Campo del Cielo. El Jardín Botánico Municipal, es otra de las mayores atracciones de la zona. En 1902, Thays diseñó los jardines y sus diferentes áreas las que representan varias regiones de Argentina con sus especímenes característicos; son particularmente interesantes los árboles nativos de las diferentes provincias del país. También encontramos la Sociedad Rural Argentina, donde todos los años, en el mes de julio, se lleva a cabo la Exposición Rural; el Hipódromo Argentino. Cerca de allí se ubican varios clubes de deportes. Los parques están rodeados hacia el norte por el Aeroparque Jorge Newbery, el aeropuerto de la ciudad.',
            ],
          },
          {
            title: 'Gastronomía y entretenimiento en Palermo',
            text: [
              'En los últimos años ha habido un notable desarrollo de toda la zona. La gran cantidad de emprendimientos gastronómicos ha llevado a las inmobiliarias en Palermo a ampliar su cartera de propiedades. La vía del ferrocarril que corre paralela a la Avenida Juan B. Justo es el límite entre lo que la modernidad ha denominado Palermo Soho y Palermo Hollywood, nombre que se le da por la presencia de estudios de cine y televisión, productoras y radios. La zona se distingue además por sus bares y restaurantes.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN CABALLITO

      case 'propiedades-en-caballito':
        this.city = 'Caballito';
        this.isFullButtons = false;
        this.title = 'Propiedades en Caballito';
        this.bannerImg1 = '/assets/images/landing/depto-caballito.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-caballito.jpg';
        this.urlBanner1 = '/departamentos-en-caballito';
        this.urlBanner2 = '/casas-en-caballito';
        this.configHouseSale.text = 'Casas en venta en Caballito';
        this.configHouseSale.url = '/casas-en-venta-en-caballito';
        this.configHouseRent.text = 'Casas en alquiler en Caballito';
        this.configHouseRent.url = '/casas-en-alquiler-en-caballito';
        this.configAptSale.text = 'Departamentos en venta en Caballito';
        this.configAptSale.url = '/departamentos-en-venta-en-caballito';
        this.configAptRent.text = 'Departamentos en alquiler en Caballito';
        this.configAptRent.url = '/departamentos-en-alquiler-en-caballito';
        this.disclaimer =
          'Quienes busquen inmobiliarias en Caballito ya sea para alquiler, compra o venta de casas en Caballito deben saber que en nuestras oficinas encontrarán agentes capacitados para ayudar, guiar y asesorar en la búsqueda.';
        this.text = [
          {
            title: 'Historia de Caballito.',
            text: [
              'En el inicio del siglo XIX, el paisaje que hoy forma el barrio de Caballito era un gran descampado apenas poblado por quintas de frutas y verduras, algunos hornos de ladrillos y tambos que abastecían a la todavía lejana ciudad de Santísima Trinidad. La actual Av. Rivadavia, conocida antes como “Camino Real” era la vía central por la cual se accedía a la pequeña aldea que era Buenos Aires. Y la actual Av. Martín Gainza en ese entonces llamado “Camino al Polvorín” porque llevaba a los depósitos de pólvora situados en el Actual Parque Chacabuco. El centro geográfico de la Ciudad de Buenos Aires debe su nombre a una famosa pulpería de 1804: la "pulpería del caballito", reconocida por su típica veleta en forma de caballito, situada en la punta de un mástil, en la puerta de la pulpería.',
            ],
          },
          {
            title: 'Caballito, el corazón de la ciudad.',
            text: [
              'Las calles empedradas de Caballito se tropiezan con la gran cantidad de edificios y residencias de estuco blanco. Hoy en día se destaca por ser un barrio muy solicitado en términos residenciales y comerciales. Las zonas de casas y PHs conservan el ambiente barrial y familiar que lo caracteriza, al mismo tiempo crecen las zonas comerciales. Lo que ha convertido a Caballito en un punto de atracción para nuevos emprendimientos inmobiliarios, incrementando la venta y alquiler de casas y departamentos en Caballito. Todo esto hace que existan inmobiliarias en Caballito preparadas para adaptarse a las necesidades de residentes y comerciantes, a estar al tanto de los detalles de la zona perfectamente. Por eso, quienes nos dedicamos desde hace tiempo a la venta de casas en Caballito sabemos lo importante que es conocer las particularidades de un barrio que posee desde enormes y populares parques y plazas (Parque Centenario, Parque Rivadavia, Plaza Irlanda) hasta renombrados hospitales (Durand, Álvarez, Hospital de Quemados, Instituto L. Pasteur, Hospital Naval y el Policlínico Bancario). ',
            ],
          },
        ];
        break;

      // PROPIEDADES EN CABA

      case 'propiedades-en-ciudad-de-buenos-aires':
        this.city = 'Ciudad de Buenos Aires';
        this.isFullButtons = false;
        this.title = 'Propiedades en Capital Federal';
        this.bannerImg1 = '/assets/images/landing/depto-caba.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-caba.jpg';
        this.urlBanner1 = '/departamentos-en-ciudad-de-buenos-aires';
        this.urlBanner2 = '/casas-en-ciudad-de-buenos-aires';
        this.configHouseSale.text = 'Casas en venta en Ciudad de Buenos Aires';
        this.configHouseSale.url = '/casas-en-venta-en-ciudad-de-buenos-aires';
        this.configHouseRent.text =
          'Casas en alquiler en Ciudad de Buenos Aires';
        this.configHouseRent.url =
          '/casas-en-alquiler-en-ciudad-de-buenos-aires';
        this.configAptSale.text =
          'Departamentos en venta en Ciudad de Buenos Aires';
        this.configAptSale.url =
          '/departamentos-en-venta-en-ciudad-de-buenos-aires';
        this.configAptRent.text =
          'Departamentos en alquiler en Ciudad de Buenos Aires';
        this.configAptRent.url =
          '/departamentos-en-alquiler-en-ciudad-de-buenos-aires';
        this.disclaimer =
          'Para aquellos que disfrutan la noche, la vida nocturna de Buenos Aires, reconocida en todo el mundo, es tan variada como intensa. Cientos de restaurantes y miles de bares abiertos hasta altas horas de la noche así lo demustran. Las opciones son inabarcables: cervecerías artesanales, bares sofisticados, discos, noches de jazz o múscica electrónica a cargo de un reconocido DJ internacional son sólo algunas de las alternativas que ofrece la famosa noche porteña. Por todo esto, el abanico de propiedades en la Capital Federal es muy amplio.';
        this.text = [
          {
            title: 'La Capital Federal, un lugar increíble para vivir.',
            text: [
              'La Ciudad Autónoma de Buenos Aires es la Capital Federal de la República Argentina, sede de su Gobierno Nacional. Albergando a más de 9 millones de personas, entre residentes permanentes, personas que ingresan a la ciudad diariamente a trabajar y turistas, Buenos Aires es una ciudad increíble, sin duda, una de las más cosmopolitas de Latinoamérica. Anualmente, recibe más de 100.000 estudiantes de todo el mundo. Sin duda, Buenos Aires brinda una oportunidad única para conocer gente de todo el mundo y descubrir las diversas culturas que conviven en ella. Es por ello que las propiedades en la Capital Federal tienen una gran demanda, no sólo a nivel local sino internacional.',
            ],
          },
          {
            title: 'Ubicación de la Capital Federal.',
            text: [
              'Buenos Aires está situada en la región centro-este del país, sobre la orilla occidental del Río de la Plata, en la región Pampeana. Su tejido urbano se asemeja a un abanico que limita al sur, oeste y norte con la provincia de Buenos Aires y al este con el Río de la Plata. Oficialmente, la ciudad está dividida en 48 barrios, en los que RE/MAX se encuentra presente con 3x Oficinas, que cuentan con innumerables propiedades en la Capital Federal.',
            ],
          },
          {
            title: 'Capital Federal, un lugar a pleno turismo.',
            text: [
              'La Capital Federal ofrece la posibilidad de realizar infinitas actividades y disfrutar de sus variados y reconocidos atractivos, tanto para quienes residen en ella, como para aquellos turistas que año a año la visitan. La arquitectura de la Ciudad despliega todo su abanico de influencias culturales en estilos que se animan a mezclarse. Sus palacios se erigen como memoria viva de la historia de Buenos Aires, dentro del paisaje moderno que hoy impone la ciudad. Buenos Aires cuenta con un amplio abanico de museos en los que pueden descubrirse maravillosas colecciones y muestras de las más diversas disciplinas y épocas, tanto de artistas nacionales como internacionales. En lo que respecta al ámbito teatral, cuenta con una enorme variedad y oferta de espectáculos, tanto en el circuito oficial, como en el circuito under.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN PILAR

      case 'propiedades-en-pilar':
        this.city = 'Pilar';
        this.isFullButtons = false;
        this.title = 'Propiedades en Pilar';
        this.bannerImg1 = '/assets/images/landing/depto-pilar.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-pilar.jpg';
        this.urlBanner1 = '/departamentos-en-pilar';
        this.urlBanner2 = '/casas-en-pilar';
        this.configHouseSale.text = 'Casas en venta en Pilar';
        this.configHouseSale.url = '/casas-en-venta-en-pilar';
        this.configHouseRent.text = 'Casas en alquiler en Pilar';
        this.configHouseRent.url = '/casas-en-alquiler-en-pilar';
        this.configAptSale.text = 'Departamentos en venta en Pilar';
        this.configAptSale.url = '/departamentos-en-venta-en-pilar';
        this.configAptRent.text = 'Departamentos en alquiler en Pilar';
        this.configAptRent.url = '/departamentos-en-alquiler-en-pilar';
        this.disclaimer =
          'En la actualidad, Pilar y sus alrededores cuentan con una infraestructura muy desarrollada: colegios, universidades, centros de salud, bancos, entre otros servicios, facilitan la comodidad de quienes compran o alquilan propiedades en Pilar.';
        this.text = [
          {
            title: 'Pilar, vivir entre árboles, muy cerca de la ciudad.',
            text: [
              'Pilar se encuentra a 54 km. de la ciudad de Buenos Aires, por el Acceso Norte, ramal Pilar, Ruta 8. En los años 90, comenzaron a desarrollarse countries y barrios cerrados, cuyo principal uso era el de residencias de fin de semana, pero con el transcurso de los años se han convertido en lugares de vivienda permanente. Por sus vistas verdes y sus paisajes de árboles añosos, las propiedades en Pilar se han convertido en unas de las favoritas de quienes desean vivir rodeados de naturaleza, apartados de la ciudad, pero no muy lejos de ella. ',
            ],
          },
          {
            title: 'Pilar, en constante desarrollo.',
            text: [
              'La proliferación de estos grandes emprendimientos inmobiliarios trajo aparejados el crecimiento y el desarrollo general de la zona de Pilar: complejos y edificios corporativos, condominios de departamentos y dormis, mejoras en las vías de acceso, instalación de paseos y centros comerciales, variadas opciones de locales gastronómicos y de entretenimiento, así lo demuestran. ',
            ],
          },
        ];
        break;

      // PROPIEDADES EN BELGRANO

      case 'propiedades-en-belgrano':
        this.city = 'Belgrano';
        this.isFullButtons = false;
        this.title = 'Propiedades en Belgrano';
        this.bannerImg1 = '/assets/images/landing/depto-belgrano.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-belgrano.jpg';
        this.urlBanner1 = '/departamentos-en-belgrano';
        this.urlBanner2 = '/casas-en-belgrano';
        this.configHouseSale.text = 'Casas en venta en Belgrano';
        this.configHouseSale.url = '/casas-en-venta-en-belgrano';
        this.configHouseRent.text = 'Casas en alquiler en Belgrano';
        this.configHouseRent.url = '/casas-en-alquiler-en-belgrano';
        this.configAptSale.text = 'Departamentos en venta en Belgrano';
        this.configAptSale.url = '/departamentos-en-venta-en-belgrano';
        this.configAptRent.text = 'Departamentos en alquiler en Belgrano';
        this.configAptRent.url = '/departamentos-en-alquiler-en-belgrano';
        this.disclaimer =
          'Por todo lo antes mencionado hace de Belgrano un barrio muy solicitado para la venta y alquiler de casas o departamentos.';
        this.text = [
          {
            title: 'Historia del Barrio Belgrano',
            text: [
              'Desde aquellos tiempos, Belgrano fue uno de los barrios más residenciales y pintorescos de la Ciudad. Posee gran cantidad de viejas e importantes casonas señoriales, la mayoría típicas de fines del siglo XIX. En contraste con estas construcciones, se han edificado modernos, sofisticados, espectaculares y confortables edificios. Este cambio en el mercado inmobiliario ha convertido la venta y alquiler de casas y departamentos en Belgrano, en una gran oportunidad de negocio. Belgrano, un barrio repleto de cultura, tradición e historia. Al recorrer sus calles se pueden apreciar monumentos, museos, bibliotecas y magníficas plazas. El barrio se divide en tres zonas bien definidas y con características totalmente distintas: Bajo Belgrano, Belgrano Central y Belgrano R. Bajo Belgrano, es la más cercana al río y al Hipódromo, llega desde la estación Belgrano “C” del ferrocarril Mitre hasta la Av. Figueroa Alcorta. Belgrano Central tiene la activa y concurrida Av. Cabildo, con numerosos centros comerciales, confiterías, cines y numerosos sitios de entretenimiento. Por último la zona de Belgrano R, la más selecta, allí encontramos las grandes residencias con hermosos jardines y los tradicionales “petit-hoteles” que aún perduran de aquellas épocas, dando al lugar un estilo muy señorial.',
            ],
          },
          {
            title:
              'Belgrano es un barrio residencial con historia, museos, parques y comercios.',
            text: [
              'La proliferación de estos grandes emprendimientos inmobiliarios trajo aparejados el crecimiento y el desarrollo general de la zona de Pilar: complejos y edificios corporativos, condominios de departamentos y dormis, mejoras en las vías de acceso, instalación de paseos y centros comerciales, variadas opciones de locales gastronómicos y de entretenimiento, así lo demuestran. ',
            ],
          },
        ];
        break;

      // PROPIEDADES EN VICENTE LOPEZ

      case 'propiedades-en-vicente-lopez':
        this.city = 'Vicente Lopez';
        this.isFullButtons = false;
        this.title = 'Propiedades en Vicente Lopez';
        this.bannerImg1 = '/assets/images/landing/depto-vte-lopez.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-vte-lopez.jpg';
        this.urlBanner1 = '/departamentos-en-vicente-lopez';
        this.urlBanner2 = '/casas-en-vicente-lopez';
        this.configHouseSale.text = 'Casas en venta en Vicente Lopez';
        this.configHouseSale.url = '/casas-en-venta-en-vicente-lopez';
        this.configHouseRent.text = 'Casas en alquiler en Vicente Lopez';
        this.configHouseRent.url = '/casas-en-alquiler-en-vicente-lopez';
        this.configAptSale.text = 'Departamentos en venta en Vicente Lopez';
        this.configAptSale.url = '/departamentos-en-venta-en-vicente-lopez';
        this.configAptRent.text = 'Departamentos en alquiler en Vicente Lopez';
        this.configAptRent.url = '/departamentos-en-alquiler-en-vicente-lopez';
        this.disclaimer =
          'La oferta de propiedades en Vicente López es muy variada, desde casonas con grandes parques a departamentos de tamaños y comodidades diversas.';
        this.text = [
          {
            title: 'Vicente López, un poco de historia.',
            text: [
              'El partido de Vicente López se encuentra a 13,7 km del centro de la ciudad de Buenos Aires. Forma parte del aglomerado urbano conocido como Gran Buenos Aires, ubicándose al norte del mismo y está conformado por los barrios de Florida, Florida Oeste, Olivos, La Lucila, Vicente López, Munro, Villa Martelli, Carapachay y Villa Adelina. Su nombre se debe al escritor, abogado y político argentino, autor de la letra del Himno Nacional Argentino, Vicente López y Planes. Antiguamente, las propiedades en Vicente López eran grandes chacras rodeadas de bellos bosques, juncales, arroyos, lagunas e incluso, algunas de ellas, con playas sobre el Río de la Plata que, durante décadas, sirvieron de balneario para miles de personas.',
            ],
          },
          {
            title: 'Vicente López, inspira tranquilidad.',
            text: [
              'Vicente López respira verde, sus calles arboladas inspiran la tranquilidad de un espacio que aún no ha perdido su esencia. Vicente López, Olivos y La Lucila son las zonas más bonitas de este partido. Sus costas al Río de la Plata son lugares de encuentro de deportistas para ejercitarse, familias para disfrutar el aire libre y amigos para pasar el día entre mates y guitarras. Su reserva natural, en donde aún se conserva vegetación y fauna autóctona, es uno de los atractivos naturales que posee Vicente López. Ubicada entre las avenidas Maipú y del Libertador, a metros del Tren de la Costa, se encuentra la Quinta Presidencial Los Olivos, el lugar de residencia de todos los presidentes del país, desde 1941.',
            ],
          },
        ];
        break;

      // PROPIEDADES EN NORDELTA

      case 'propiedades-en-nordelta':
        this.city = 'Nordelta';
        this.isFullButtons = false;
        this.title = 'Propiedades en Nordelta';
        this.bannerImg1 = '/assets/images/landing/depto-nordelta.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-nordelta.jpg';
        this.urlBanner1 = '/departamentos-en-nordelta';
        this.urlBanner2 = '/casas-en-nordelta';
        this.configHouseSale.text = 'Casas en venta en Nordelta';
        this.configHouseSale.url = '/casas-en-venta-en-nordelta';
        this.configHouseRent.text = 'Casas en alquiler en Nordelta';
        this.configHouseRent.url = '/casas-en-alquiler-en-nordelta';
        this.configAptSale.text = 'Departamentos en venta en Nordelta';
        this.configAptSale.url = '/departamentos-en-venta-en-nordelta';
        this.configAptRent.text = 'Departamentos en alquiler en Nordelta';
        this.configAptRent.url = '/departamentos-en-alquiler-en-nordelta';
        this.disclaimer =
          'Por todo esto, quienes busquen inmobiliarias en Nordelta deben saber que en nuestras oficinas encontrarán agentes capacitados para ayudar, guiar y asesorar en la búsqueda de propiedades.';
        this.text = [
          {
            title: 'Infraestructura de Nordelta.',
            text: [
              'Nordelta es una ciudad pueblo ubicada en el partido de Tigre, donde se conjugan barrios residenciales. Las propiedades en Nordelta presentan distintos estilos y características. Además de centros de salud, educativos, shoppings, supermercados, proveedurías, clubes y lugares de esparcimiento, preservando el río, el aire puro y los espacios verdes, con la calidez y la seguridad propia de un pueblo. Nordelta presenta una completa infraestructura a nivel macro, que se replica en cada barrio. Quienes tengan propiedades en Nordelta, encontrarán en cada zona pequeños centros de abastecimiento, con el fin de facilitar las necesidades cotidianas de sus habitantes.',
            ],
          },
          {
            title: 'Barrios y emprendimientos inmobiliarios en Nordelta.',
            text: [
              'Nordelta tiene una oferta sumamente variada de barrios como viviendas, desde un lote para construir la casa de tus sueños hasta un condominio con departamentos de 1 a 4 ambientes, un townhouse o una oficina. En Nordelta podés encontrar opciones con importantes costas al lago central, con lago propio, con cancha de golf o con salida al río Luján desde el jardín de tu casa, también hay barrios exclusivos de lotes y otros de condominios o de uso apto profesional y comercial. Esta diversidad y distinción, impulsa a las inmobiliarias en Nordelta a conocer los secretos de uno de los nuevos emprendimientos inmobiliarios en la zona norte de la provincia de Buenos Aires. ',
            ],
          },
        ];
        break;

      // PROPIEDADES EN SAN ISIDRO

      case 'propiedades-en-san-isidro':
        this.city = 'San Isidro';
        this.isFullButtons = false;
        this.title = 'Propiedades en San Isidro';
        this.bannerImg1 = '/assets/images/landing/depto-san-isidro.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-san-isidro.jpg';
        this.urlBanner1 = '/departamentos-en-san-isidro';
        this.urlBanner2 = '/casas-en-san-isidro';
        this.configHouseSale.text = 'Casas en venta en San Isidro';
        this.configHouseSale.url = '/casas-en-venta-en-san-isidro';
        this.configHouseRent.text = 'Casas en alquiler en San Isidro';
        this.configHouseRent.url = '/casas-en-alquiler-en-san-isidro';
        this.configAptSale.text = 'Departamentos en venta en San Isidro';
        this.configAptSale.url = '/departamentos-en-venta-en-san-isidro';
        this.configAptRent.text = 'Departamentos en alquiler en San Isidro';
        this.configAptRent.url = '/departamentos-en-alquiler-en-san-isidro';
        this.disclaimer =
          'Si estás interesado en la venta o alquiler de propiedades en San Isidro, no dejes de contactarte con nuestras Oficinas RE/MAX para que nuestro equipo especializado pueda ayudarte y asesorarte en la búsqueda de la casa de tus sueños.';
        this.text = [
          {
            title: 'San Isidro, estilo único.',
            text: [
              'San Isidro se ubica en la costa norte de la Provincia de Buenos Aires. El partido de San Isidro, comprende las localidades de Acassuso, Beccar, Boulogne, Martínez, San Isidro y Villa Adelina. Por tratarse de uno de los partidos más bellos de la Provincia de Buenos Aires, las propiedades en San Isidro son muy demandadas. Sus amplios espacios verdes, la ribera del río y su enérgica vida social son sólo algunas de las características que lo hacen único.',
            ],
          },
          {
            title: 'Atractivos de San Isidro.',
            text: [
              'Su casco histórico con núcleo en la Plaza Mitre, su catedral neo-gótica, su característica feria artesanal, la Quinta Los Ombúes y el Museo Brigadier Gral. Juan Martín de Pueyrredón son parte de la historia de San Isidro. Su Hipódromo, considerado entre los mejores de América, es uno de los grandes atractivos de este partido. En él se disputan carreras tantos los días de semana, como los de fin de semana. También se realizan exhibiciones ecuestres y grande eventos sociales y corporativos. San Isidro se caracteriza por la vida al aire libre. Por este motivo, mucha gente busca propiedades en San Isidro. Su zona costera, sobre el margen del Río de la Plata, es escenario para la práctica de deportes náuticos y acuáticos. También se destaca la actividad gastronómica de San Isidro. Sus pubs y restaurantes de gran nivel son muy concurridos, tanto por vecinos, como por turistas. ',
            ],
          },
          {
            title: 'Vivir en San Isidro',
            text: [
              'Su estilo, sus atractivos, su vida social, sumados a su tranquilidad y a su entorno colmado de espacios verdes, hacen que las propiedades en San Isidro sean muy codiciadas. La variedad de propiedades en San Isidro es muy amplia, tanto en lo que se refiere a mercado residencial, como a mercado comercial y corporativo. Desde propiedades cercanas al río o a la autopista, pasando por comercios, oficinas, casas, PHs y departamentos, hasta lujosas mansiones señoriales. ',
            ],
          },
        ];
        break;

      // PROPIEDADES EN TIGRE

      case 'propiedades-en-tigre':
        this.city = 'Tigre';
        this.isFullButtons = false;
        this.title = 'Propiedades en Tigre';
        this.bannerImg1 = '/assets/images/landing/depto-tigre.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-tigre.jpg';
        this.urlBanner1 = '/departamentos-en-tigre';
        this.urlBanner2 = '/casas-en-tigre';
        this.configHouseSale.text = 'Casas en venta en Tigre';
        this.configHouseSale.url = '/casas-en-venta-en-tigre';
        this.configHouseRent.text = 'Casas en alquiler en Tigre';
        this.configHouseRent.url = '/casas-en-alquiler-en-tigre';
        this.configAptSale.text = 'Departamentos en venta en Tigre';
        this.configAptSale.url = '/departamentos-en-venta-en-tigre';
        this.configAptRent.text = 'Departamentos en alquiler en Tigre';
        this.configAptRent.url = '/departamentos-en-alquiler-en-tigre';
        this.disclaimer =
          'Para aquellos que sólo quieren ir de paseo, Tigre presenta una gran variedad de mercados artesanales y de diseño, lugares históricos, museos, actividades de aventura y la posibilidad de realizar diversos paseos en embarcaciones a través de arroyos, ríos, riachos y canales.';
        this.text = [
          {
            title: 'Tigre, a pasos del Delta del Paraná.',
            text: [
              'Tigre está ubicado al norte de la ciudad de Buenos Aires a tan sólo 34 km. de esta. Es una vía de acceso a los ríos y pantanos del extenso delta del Paraná. La porción del Delta que le corresponde al partido de Tigre, tiene una superficie 220 km. de islas, conocida como primera sección. Con un escenario tan variado y cercano a la ciudad, permite tener un abanico de posibilidades a la hora de buscar propiedades en Tigre.',
            ],
          },
          {
            title: 'Tigre, un poco de historia.',
            text: [
              'En el 1800, el ex presidente Domingo Faustino Sarmiento impulsó a los inmigrantes italianos y españoles a construir y sembrar a cambio de tierras. Así, el famoso Puerto de Frutos pasó a ser un importante abastecedor de frutas, madera, mimbre y miel del Gran Buenos Aires. Hoy en día, es considerado un paseo clásico, que combina el antiguo puerto con modernos locales de decoración para la casa. Remontarse a las décadas del 20 y 30 es remontarse a un delta de clases privilegiadas que pudieron darse el gusto de construir sus casas de veraneo con bellos jardines, dejando, hoy en día, un legado de palacetes estilo anglo-francés y clubes de remo. En los 90, Tigre vuelve a tomar color con la creación de los nuevos barrios cerrados, como Isla Santa Mónica e Isla del Este y con el desarrollo de grandes establecimientos para alojar a turistas, tanto argentinos, como extranjeros, aumentando la oferta de propiedades en Tigre. ',
            ],
          },
          {
            title: 'Vivir en Tigre, un lugar turístico.',
            text: [
              'Un contexto de naturaleza agreste, ríos, muelles de madera y jardines hacen que Tigre sea un escenario único para vivir y visitar. El mercado inmobiliario en Tigre está sumando cada vez más adeptos gracias a su ampio abanico de ofertas: casas, departamentos, barrios cerrados y complejos turísticos. Es por eso que las propiedades en Tigre tienen una gran demanda.  ',
            ],
          },
        ];
        break;

      // PROPIEDADES EN NEUQUÉN

      case 'propiedades-en-neuquen':
        this.isDinamicButtonViewPropertyEnabled = true;
        this.city = 'Neuquén';
        this.isFullButtons = false;
        this.title = 'Propiedades en Neuquén';
        this.bannerImg1 = '/assets/images/landing/deptos-neuquen.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-neuquen.jpg';
        this.urlBanner1 = '/departamentos-en-neuquen';
        this.urlBanner2 = '/casas-en-neuquen';
        this.configHouseSale.text = 'Casas en venta en Neuquén';
        this.configHouseSale.url = '/casas-en-venta-en-neuquen';
        this.configHouseRent.text = 'Casas en alquiler en Neuquén';
        this.configHouseRent.url = '/casas-en-alquiler-en-neuquen';
        this.configAptSale.text = 'Departamentos en venta en Neuquén';
        this.configAptSale.url = '/departamentos-en-venta-en-neuquen';
        this.configAptRent.text = 'Departamentos en alquiler en Neuquén';
        this.configAptRent.url = '/departamentos-en-alquiler-en-neuquen';
        this.disclaimer =
          'A lo largo de los años, el desarrollo de las propiedades en Neuquén ha ido acompañando la creciente demanda de su población y también las renovadas necesidades de los turistas.';
        this.text = [
          {
            title: 'Propiedades En Neuquén',
            text: [
              'Neuquén está ubicada en la región centro-este de la República Argentina. Se destaca por un alto nivel de producción agropecuaria y un desarrollo industrial en plena expansión. Cuenta con infraestructura para el transporte tanto fluvial como terrestre. Neuquén está muy bien preparada para la recepción del turismo de negocios. Exposiciones, congresos y conferencias, seminarios y rondas de negocios son algunas de las actividades por las cuales esta gran ciudad recibe muchísmos visitantes. Por tal motivo, las propiedades en Neuquén son muy demandadas, tanto con finalidad residencial, como para radicar negocios.',
            ],
          },
          {
            title: 'Vivir en Neuquén.',
            text: [
              'Las propiedades en Neuquén se encuentran ubicadas en un entorno muy natural. A pocos pasos de la ciudad, encontramos arroyos, lagunas, ríos, hermosos parques y playas que brindan un ambiente de relax y tranquilidad. Contactate con nuestro equipo especializado para que pueda asesorarte y ayudarte en la compra, venta o alquiler de tu propiedad. ',
            ],
          },
          {
            title: 'Neuquén, turística.',
            text: [
              'Neuquén cuenta con un gran abanico de opciones tanto en hotelería como en gastronomía, lo que permite a sus visitantes encontrar siempre una alternativa para sentirse más que a gusto en la ciudad. Los paseos costeros, con sus vistas al emblemático puente colgante, son una postal de la ciudad. Shoppings, comercios de productos regionales, teatros y bares completan la gran variedad de ofertas que esta maravillosa ciudad tiene para sus vecinos y visitantes.',
            ],
          },
        ];
        break;

      // LANDING PUNTA DEL ESTE

      case 'propiedades-en-punta-del-este':
        this.city = 'Punta del Este';
        this.isFullButtons = false;
        this.title = 'Propiedades en Punta del Este';
        this.bannerImg1 = '/assets/images/landing/depto-punta.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-punta.jpg';
        this.urlBanner1 = '/apartamentos-en-punta-del-este';
        this.urlBanner2 = '/casas-en-punta-del-este';
        this.configHouseSale.text = 'Casas en venta en Punta del Este';
        this.configHouseSale.url = '/casas-en-venta-en-punta-del-este';
        this.configHouseRent.text = 'Casas en alquiler en Punta del Este';
        this.configHouseRent.url = '/casas-en-alquiler-en-punta-del-este';
        this.configAptSale.text = 'Apartamentos en venta en Punta del Este';
        this.configAptSale.url = '/apartamentos-en-venta-en-punta-del-este';
        this.configAptRent.text = 'Apartamentos en alquiler en Punta del Este';
        this.configAptRent.url = '/apartamentos-en-alquiler-en-punta-del-este';
        this.disclaimer =
          'Si estás buscando alquileres en Punta del Este, venta de apartamentos en Punta del Este o venta de casas en Punta del Este, no dudes en contactarnos. Nuestras oficinas cuentan con un equipo de Agentes especializados que podrán asesorarte y acompañarte para encontrar la propiedad que estás buscando.';
        this.text = [
          {
            title: 'Punta del Este, natural y exclusiva.',
            text: [
              'El paraíso Uruguayo se ubica a tan sólo una hora y media de Montevideo, la capital del país. Las propiedades en Punta del Este conviven con un entorno natural, radiantes bosques, médanos, lagunas, sierras, islas y bellas playas de arena blanca.',
            ],
          },
          {
            title: 'Verano en Punta del Este',
            text: [
              'Considerado uno de los principales balnearios de América, en verano, el este de Uruguay colma sus playas de turistas internacionales. Más de setecientos mil visitantes por año llegan a estas codiciadas playas esteñas de América de Sur. En el este, nos encontramos con una playa de mar abierto y fuerte oleaje conocida como La Brava y, en el oeste, con una playa amplia, de aguas calmas, conocida como La Mansa, con vista a una pequeña y bella isla. Durante el verano, la oferta es muy amplia en lo que respecta a alquileres en Punta del Este. Desde casas rodeadas de bosques en los barrios más selectos, a apartamentos lujosos en pleno centro de la ciudad.',
            ],
          },
        ];
        break;

      // LANDING MONTEVIDEO

      case 'propiedades-en-montevideo':
        this.city = 'Montevideo';
        this.isFullButtons = false;
        this.title = 'Propiedades en Montevideo';
        this.bannerImg1 = '/assets/images/landing/depto-montevideo.jpg';
        this.bannerImg2 = '/assets/images/landing/casas-montevideo.jpg';
        this.urlBanner1 = '/apartamentos-en-montevideo';
        this.urlBanner2 = '/casas-en-montevideo';
        this.configHouseSale.text = 'Casas en venta en Montevideo';
        this.configHouseSale.url = '/casas-en-venta-en-montevideo';
        this.configHouseRent.text = 'Casas en alquiler en Montevideo';
        this.configHouseRent.url = '/casas-en-alquiler-en-montevideo';
        this.configAptSale.text = 'Apartamentos en venta en Montevideo';
        this.configAptSale.url = '/apartamentos-en-venta-en-montevideo';
        this.configAptRent.text = 'Apartamentos en alquiler en Montevideo';
        this.configAptRent.url = '/apartamentos-en-alquiler-en-montevideo';

        this.disclaimer = '';
        this.text = [
          {
            title: 'Viví en Montevideo',
            text: [
              'Con una población de casi 2 millones de personas, Montevideo es una de las ciudades uruguayas con más tránsito de turistas durante todo el año. Obviamente, también es la más poblada para vivir. Por este motivo, conocer sobre <strong>alquileres en Montevideo</strong> y asesorarse para conseguir la mejor opción nunca está de más. ',
            ],
          },
          {
            title: 'Alquilar en Montevideo: lo que tenés que saber',
            text: [
              'Lo más importante a tener en cuenta a la hora de buscar alquileres en Montevideo es que al ser una ciudad muy poblada, llena de estudiantes, trabajadores, familias y turistas, es más que fundamental hacer una búsqueda organizada y con margen de tiempo.',
              'Quizás durante los meses de temporada baja hay más opciones y posibilidades para concretar una mudanza con tranquilidad. Sin embargo, para los que buscan un alquiler de veraneo, alquilar con tiempo es clave para conseguir buenas opciones y con mejor precio.',
              'Por todos los motivos que te mencionamos anteriormente, contar con una buena inmobiliaria de confianza y con agentes que asesoren, escuchen y ayuden al cliente, es fundamental para alquilar en este destino.',
            ],
          },
          {
            title: 'RE/MAX: mucho más que una inmobiliaria',
            text: [
              'En RE/MAX, nuestro concepto es que somos más que un negocio de propiedades, somos un negocio de relaciones personales. Es decir, nosotros nos dedicamos a brindar servicios complementarios a la actividad inmobiliaria con un fuerte foco en el cliente. Por eso no lo dudes: acercate a RE/MAX en Montevideo.',
              'Nuestra misión es clara: tenemos como objetivo brindar a los clientes la mayor calidad de servicio posible, a través de una atención cálida y personalizada que sólo nosotros sabemos generar. ',
              'Por ese motivo, todo el equipo que conforma RE/MAX está altamente capacitado para ayudar al cliente a encontrar lo que está buscando y asesorarlo para que pueda tomar la mejor decisión en base a sus posibilidades y necesidades. Nada está librado al azar en RE/MAX. ',
              'En este sentido, para nuestro equipo conocer los deseos y necesidades de los clientes y acompañarlo durante todo el proceso que implica una decisión tan importante en sus vidas, como es la búsqueda de un nuevo hogar, o la búsqueda de un espacio para pasar las vacaciones, es tan importante como concretar una venta o alquiler. ',
              'Por eso “Mudate a la vida que querés” para nosotros es mucho más que un simple slogan. Es una realidad. Entonces, si estás buscando un nuevo hogar o una casa de veraneo, o incluso vender una propiedad en la capital de Uruguay no lo dudes: Preguntá por nuestras inmobiliarias, con gusto estaremos disponibles para asesorarte y ayudarte a encontrar el espacio que más se ajusta a vos. ',
            ],
          },
          {
            title: 'Alquilamos y vendemos en Montevideo',
            text: [
              'Montevideo, capital de Uruguay, brinda múltiples opciones a la hora de alquilar o comprar una casa o un apartamento: apartamentos modernos, apartamentos de estilo, amplios, pequeños, en plena ciudad o con vistas a la costa. Casas céntricas y en las afueras de la ciudad.  Es decir, hay opciones para estudiantes, para solteros, para parejas o para familias que buscan mudarse a una vida nueva, o alquilar un inmueble para pasar una temporada.',
            ],
          },
          {
            title: 'Alquileres en Montevideo',
            text: [
              'Según la ubicación, los alquileres de apartamentos en Montevideo varían sus precios, siendo los costeros los más codiciados y costosos. Por eso, es fundamental definir un presupuesto de alquiler (mensual o anual) ya que el importe en gran medida condicionará la ubicación. ',
              'Los estudiantes o parejas jóvenes que buscan apartamentos en alquiler, suelen elegir los barrios Centro, Cordón y Palermo, tanto por su cercanía a universidades, como por sus precios más acomodados respecto de otros barrios de la ciudad de Montevideo. Las familias, sin embargo, buscan casas en barrios más tranquilos, como Pocitos, Carrasco o Malvín.',
            ],
          },
          {
            title: 'Venta de inmuebles Montevideo',
            text: [
              'La venta de viviendas en Montevideo no la podemos dejar de lado. La misma está orientada principalmente a la vivienda y la inversión, por ello contamos con una amplia oferta distribuida por todos los barrios de esta gran ciudad. Recordá que en RE/MAX podemos ayudarte a encontrar lo que estás buscando.',
              'Nuestra cartera de apartamentos en venta y apartamentos en alquiler en Montevideo es muy amplia por eso, no dudes en comunicarte con nosotros. Nuestros equipos de agentes especializados te ayudarán a encontrar el apartamento ideal que se acomode a tus necesidades, gustos y presupuestos. Preguntá por RE/MAX en Montevideo, no te vas a arrepentir. ',
            ],
          },
        ];
        break;

      case 'propiedades-en-posadas':
        this.city = 'Posadas';
        this.isFullButtons = false;
        // h1
        this.title =
          'Propiedades en Posadas. Compra, venta y alquiler de inmuebles.';
        this.subtitle =
          'Encontrá en la Red RE/MAX el inmueble o propiedad que soñás en el lugar que querés.';
        // Banners img - // ⛔COMENTADO HASTA QUE PASEN LAS IMÁGENES⛔
        // this.bannerImg1 = '/assets/images/landing/depto-montevideo.jpg';
        // this.bannerImg2 = '/assets/images/landing/casas-montevideo.jpg';
        // this.urlBanner1 = '/apartamentos-en-montevideo';
        // this.urlBanner2 = '/casas-en-montevideo';
        // Botones
        this.configHouseSale.text = 'Casas en venta en Posadas';
        this.configHouseSale.url = '/casas-en-venta-en-posadas';
        this.configHouseRent.text = 'Casas en alquiler en Posadas';
        this.configHouseRent.url = '/casas-en-alquiler-en-posadas';
        this.configAptSale.text = 'Departamentos en venta en Posadas';
        this.configAptSale.url = '/departamentos-en-venta-en-posadas';
        this.configAptRent.text = 'Departamentos en alquiler en Posadas';
        this.configAptRent.url = '/departamentos-en-alquiler-en-posadas';
        this.configLandSale.text = 'Terrenos en venta en Posadas';
        this.configLandSale.url = '/terrenos-en-venta-en-posadas';

        this.disclaimer =
          'Con más de 150 oficinas en Argentina y Equipos especializados haremos que la experiencia de mudarte sea única.';
        this.text = [
          {
            title: 'Posadas, una ciudad a orillas del Paraná.',
            text: [
              'Posadas, es capital de la provincia de Misiones y está ubicada sobre el margen izquierdo del río Paraná al sudoeste de la provincia. Posadas es la ciudad más poblada de la provincia y es una urbe con plena actividad y crecimiento.',
              'Su naturaleza, paisajes extraordinarios, sitios históricos y actividades de ecoturismo, hacen de Posadas un lugar único para vivir.',
              'La oferta de propiedades en Posadas es muy amplia: casas y departamentos en plena ciudad y casas en lugares más alejados y tranquilos.',
            ],
          },
          {
            title: 'Posadas, en el ranking de ciudades para vivir.',
            text: [
              'Posadas se encuentra en el ranking de las 10 mejores ciudades de Argentina para vivir. Vivir en Posadas ofrece la posibilidad de conseguir empleo, vivir con seguridad, tener acceso a la educación y cultura. Cumplir el sueño de acceder a una propiedad en Posadas, es posible.',
              'Si estás buscando propiedades en Posadas, contactate con la oficina adherida a RE/MAX más cercana a tu barrio y el equipo RE/MAX te ayudará a encontrar lo que estás buscando.',
              'La Red RE/MAX cuenta con alquileres en posadas y te ayuda en el proceso de compra y venta de inmuebles en Posadas.',
              'En la Red RE/MAX encontrarás las mejores propiedades en alquiler en posadas y la atención que necesitás en todo el proceso de compra y venta de inmuebles en Posadas.',
            ],
          },
        ];
        break;

      case 'inmuebles':
        if (environment.node == 'ar') {
          this.city = '';
          this.isFullButtons = false;
          // h1
          this.title = 'Inmuebles y propiedades en Argentina';
          this.subtitle =
            'Encontrá en remax.com.ar el inmueble o propiedad que soñás en el lugar que querés.';
          // Banners img
          this.bannerImg1 = '/assets/images/landing/deptos-arg.jpg';
          this.bannerImg2 = '/assets/images/landing/casas-arg.jpg';
          this.bannerImg3 = '/assets/images/landing/terrenos-arg.jpg';
          this.urlBanner1 = '/departamentos';
          this.urlBanner2 = '/casas';
          this.urlBanner3 = '/terrenos';
          // Botones
          this.configHouseSale.text = 'Casas en venta';
          this.configHouseSale.url = '/casas-en-venta';
          this.configHouseRent.text = 'Casas en alquiler';
          this.configHouseRent.url = '/casas-en-alquiler';
          this.configAptSale.text = 'Departamentos en venta';
          this.configAptSale.url = '/departamentos-en-venta';
          this.configAptRent.text = 'Departamentos en alquiler';
          this.configAptRent.url = '/departamentos-en-alquiler';

          this.disclaimer =
            'Con más de 150 oficinas en Argentina y Equipos especializados haremos que la experiencia de mudarte sea única.';
          this.text = [
            {
              title: 'Venta de inmuebles en Argentina.',
              text: [
                'Sabemos que tomar la decisión de comprar una propiedad es un proceso delicado y de muchas expectativas, además de ser una de las decisiones más importantes que podemos tomar en nuestras vidas.',
                'Primero debemos tener en claro qué tipo de inmueble queremos y que se adapte a nuestras necesidades y presupuesto.',
                'Luego lo ideal es hacer la búsqueda de inmuebles en venta con tranquilidad, para poder evaluar y comparar las propiedades que cumplan con nuestras expectativas.',
                'Los Equipos <b>RE/MAX</b> tienen la propiedad en venta que buscás: casa, departamento, local comercial, terreno o propiedades premium.',
                'Consultá en la oficina de la Red <b>RE/MAX</b> más cercana a tu barrio por la propiedad en venta que querés, así un Equipo <b>RE/MAX</b> te asesorará y acompañará en todo en el proceso hasta encontrar el imbueble que buscás.',
              ],
            },
            {
              title: 'Alquiler de inmuebles en Argentina',
              text: [
                '¿Estás pensando en mudarte a una casa o a un departamento? ¿Necesitás más espacio para tu área de trabajo? En la Red de oficinas adheridas a <b>RE/MAX</b> podés encontrar innumerables inmuebles en alquiler en todo el territorio Argentino.',
                'Algunos consejos para la búsqueda de un inmueble:',
                'Para encontrar la propiedad que se adapte a tus necesidades lo más importante es tener en claro: el presupuesto con el que contás, el tipo de inmueble que querés y las necesidades básicas que necesitás de la propiedad.',
                'Hacé una lista de prioridades, ¡esto te permitirá una búsqueda más certera!.',
                'En la Red <b>RE/MAX</b> vas a encontrar el respaldo de Equipos especializados en servicios inmobiliarios. Contactate con una oficina adherida a <b>RE/MAX</b> para que un Equipo te ayude a encontrar el inmueble en alquiler que soñás.',
              ],
            },
          ];
        } else if (environment.node == 'uy') {
          this.city = '';
          this.isFullButtons = false;
          // h1
          this.title = 'Inmuebles y propiedades en Uruguay';
          this.subtitle =
            'Encontrá en <b>RE/MAX</b> el inmueble o propiedad que soñás en el lugar que querés.';
          // Banners img
          this.bannerImg1 = '/assets/images/landing/apartamentos-uy.jpg';
          this.bannerImg2 = '/assets/images/landing/casas-uy.jpg';
          this.bannerImg3 = '/assets/images/landing/terrenos-uy.jpg';
          this.urlBanner1 = '/apartamentos';
          this.urlBanner2 = '/casas';
          this.urlBanner3 = '/terrenos';
          // Botones
          this.configHouseSale.text = 'Casas en venta';
          this.configHouseSale.url = '/casas-en-venta';
          this.configHouseRent.text = 'Casas en alquiler';
          this.configHouseRent.url = '/casas-en-alquiler';
          this.configAptSale.text = 'Apartamentos en venta';
          this.configAptSale.url = '/apartamentos-en-venta';
          this.configAptRent.text = 'Apartamentos en alquiler';
          this.configAptRent.url = '/apartamentos-en-alquiler';

          this.disclaimer =
            'Con oficinas en Montevideo y Punta del Este y Agentes inmobiliarios especializados, haremos que la experiencia de mudarte sea única.';
          this.text = [
            {
              title: 'Venta de inmuebles en Uruguay',
              text: [
                'Cada vez más personas eligen Uruguay, tanto para vivir como para emprender un negocio y en <b>RE/MAX</b> tenemos inmuebles en venta que se adaptan a todas las necesidades: casas, apartamentos, locales comerciales, oficinas, terrenos y propiedades premium.',
                'Comprar un inmueble además de ser una de las decisiones más importantes que podemos tomar en nuestra vida, es un proceso delicado y de mucha expectativa. Primero debemos tener en claro qué tipo de propiedad queremos, que se adapte a nuestro presupuesto y a nuestras necesidades.',
                'Comenzar la búsqueda de propiedades en venta requiere de tiempo y tranquilidad, así vas a poder evaluar y comparar los inmuebles que te interesan.',
                'Consultá en la oficina <b>RE/MAX</b> más cercana por el inmueble en venta que estás buscando, así un Agente <b>RE/MAX</b> te asesorará y acompañará en todo el proceso hasta alcanzar de la casa que soñás.',
              ],
            },
            {
              title: 'Alquiler de inmuebles en Uruguay',
              text: [
                '¿Estás pensando en mudarte a una casa o apartamento? ¿Te gustaría agrandar tu espacio de trabajo? ¿Querés vacacionar en Uruguay?',
                'Cada vez son más los inmuebles en alquiler que tenemos para ofrecerte, encontrá en <b>RE/MAX</b>: casas, apartamentos, locales comerciales, oficinas, terrenos y propiedades Premium.',
                'Algunos consejos para la búsqueda de un inmueble: Para encontrar la propiedad que se adapte a tus necesidades lo más importante es tener en claro el presupuesto disponible, el tipo de inmueble que querés y las necesidades básicas que necesitás de la propiedad.',
                'Hacé una lista de prioridades, esto permitirá una búsqueda más certera.',
                'Consultá en la oficina <b>RE/MAX</b> más cercana por el inmueble en alquiler que estás buscando para que un Agente <b>RE/MAX</b> te asesore en todo el proceso de alquiler y puedas concretar el sueño de mudarte.',
              ],
            },
          ];
        }
        break;
      case 'oficinas':
        this.city = '';
        this.isFullButtons = false;
        // h1
        this.title = 'Oficinas comerciales en venta o alquiler en Argentina';
        this.subtitle =
          'Encontrá en la Red de oficinas adheridas a <b>RE/MAX</b> la oficina comercial que estás buscando. Hay oficinas en alquiler, oficinas en venta u oficinas temporales.';
        // Banners img
        this.bannerImg1 = '/assets/images/landing/oficinas-en-alquiler.jpg';
        this.bannerImg2 = '/assets/images/landing/oficinas-en-venta.jpg';
        this.bannerImg3 = '/assets/images/landing/oficinas-temporables.jpg';
        this.urlBanner1 = '/oficinas-en-alquiler';
        this.urlBanner2 = '/oficinas-en-venta';
        this.urlBanner3 = '/oficinas-temporales';
        this.hasButtons = false;
        this.disclaimer =
          'Con más de 150 oficinas en Argentina y Equipos especializados haremos que la experiencia de mudarte a tu nueva oficina sea única.';
        this.text = [
          {
            title: 'Oficinas en venta en Argentina',
            text: [
              '¿Estás buscando una oficina en venta para mudar tu negocio?',
              'Los Equipos <b>RE/MAX</b> tienen gran variedad de oficinas en venta para ofrecerte.',
              'Sabemos que la oficina es un espacio fundamental de trabajo, es tan importante para la empresa como para el equipo de trabajo. ¡La Red <b>RE/MAX</b> tiene la oficina en venta ideal para vos!',
            ],
          },
          {
            title: 'Oficinas en alquiler en Argentina',
            text: [
              '¿Sabías que el concepto de oficina está cambiando?',
              'Hoy en día, el espacio físico de trabajo se ha transformado, es cada vez más flexible y necesita de otras características para cumplir con su funcionalidad.',
              'Si buscás alquilar una oficina, la Red <b>RE/MAX</b> tiene la oficina en alquiler que necesitás.',
              'Si estás pensando en transformar el espacio de tu negocio y buscás oficinas en venta u oficinas en alquiler tenemos una oficina adherida a <b>RE/MAX</b> cerca tuyo con Equipos especializados que te asesorarán en todo el proceso de compra o alquiler.',
            ],
          },
        ];
        break;
      case 'locales-comerciales':
        this.city = '';
        // h1
        this.title =
          'Locales comerciales en venta o alquiler en Montevideo y todo Uruguay.';
        this.subtitle =
          'Encontrá en <b>RE/MAX</b> el local comercial que estás buscando. Tenemos locales en alquiler y locales en venta.';
        // Banners img
        this.bannerImg1 = '/assets/images/landing/locales-en-alquiler.jpg';
        this.bannerImg2 = '/assets/images/landing/locales-en-venta.jpg';
        this.urlBanner1 = '/locales-comerciales-en-alquiler';
        this.urlBanner2 = '/locales-comerciales-en-venta';
        this.hasButtons = false;
        this.isFullButtons = false;
        this.disclaimer = '';
        this.text = [
          {
            title: 'Locales comerciales en Uruguay',
            text: [
              'Cada vez más personas eligen Uruguay para emprender un negocio y en <b>RE/MAX</b> tenemos locales comerciales en alquiler y venta que se adaptan a las necesidades de pequeñas, medianas y grandes empresas.',
              'Uno de los puntos claves a la hora de elegir el local comercial para tu empresa es la ubicación, es por ello que los locales comerciales en alquiler en Montevideo son los más buscados. Tenemos locales comerciales en alquiler y en venta en Montevideo y todo Uruguay para que puedas emprender el sueño de mudar tu empresa.',
              'Si estás pensando en trasformar el espacio de tu negocio y buscás locales comerciales en alquiler o venta tenemos un <b>RE/MAX</b> cerca de tuyo con Equipos de profesionales especializados que te asesorarán en todo el proceso de compra o alquiler.',
            ],
          },
          {
            title: 'Locales comerciales, una buena inversión',
            text: [
              '¿Sabías que los locales comerciales están dentro de los más elegidos para inversiones inmobiliarias? Si estás pensando en tener una inversión rentable, en <b>RE/MAX</b> podemos ofrecerte locales comerciales en venta en Uruguay.',
              'Consultá en la oficina <b>RE/MAX</b> más cercana por el local comercial en venta que estás buscando, así un Agente <b>RE/MAX</b> te asesorará y acompañará en todo el proceso.',
            ],
          },
        ];
        break;
      default:
        break;
    }

    // Armado de metatags para las landings
    switch (path) {
      case 'propiedades-en-rosario':
        this.isRosario = true;
        this.isMontevideo = false;
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_ROSARIO_TOTAL_RESULTS,
          'Propiedades en Rosario: Venta y alquiler de inmuebles RE/MAX',
          'Tenemos',
          'propiedades e inmuebles disponibles en Rosario. Propiedades con 2 o más ambientes. Venta y alquiler de propiedades en Rosario.'
        );
        break;
      case 'propiedades-en-la-plata':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_LA_PLATA_TOTAL_RESULTS,
          'Propiedades en La Plata. Venta y alquiler La Plata | RE/MAX',
          'Tenemos',
          'propiedades e inmuebles disponibles en La Plata. Propiedades con 2 o más ambientes. Encontrá tu próxima propiedad con nosotros.',
          'Propiedades en la plata, permuta de propiedades en la plata, permutas de propiedades en la plata'
        );
        break;
      case 'propiedades-en-mendoza':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_MENDOZA_TOTAL_RESULTS,
          'Propiedades en Mendoza. Venta y alquiler en Mendoza | RE/MAX',
          'Tenemos',
          'propiedades e inmuebles disponibles en Mendoza. Propiedades con 2 o más ambientes. Venta y alquiler en Mendoza Capital y San Rafael.',
          'propiedades en mendoza, propiedades en san rafael mendoza, propiedades en mendoza capital, propiedades en mendoza argentina'
        );
        break;
      case 'propiedades-en-santa-fe':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_SANTA_FE_TOTAL_RESULTS,
          'Propiedades en venta y alquiler en Santa Fe Capital | RE/MAX',
          'Tenemos',
          'propiedades e inmuebles disponibles en Santa Fe Capital. Propiedades con 2 o más ambientes. Venta y alquiler de propiedades en Santa Fe.',
          'propiedades en santa fe, precio de propiedades en santa fe, venta de propiedades en santa fe, propiedades en santa fe capital '
        );
        break;
      case 'propiedades-en-san-isidro':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_SAN_ISIDRO_TOTAL_RESULTS,
          'Encontrá Propiedades en San Isidro Buenos Aires | RE/MAX',
          'Tenemos',
          'propiedades en San Isidro. Venta y alquiler de propiedades. Comprá propiedades en San Isidro Buenos Aires. Tu próxima propiedad en RE/MAX',
          'propiedades en san isidro, propiedades en venta san isidro, propiedades en venta en san isidro, propiedades en venta en san isidro buenos aires,alquiler de propiedades en san isidro,venta de propiedades en boulogne san isidro, propiedades en venta bajo de san isidro,venta de propiedades en san isidro bajo,propiedades en alquiler en san isidro, venta de propiedades en san isidro,propiedades en venta en beccar san isidro, venta de propiedades en villa adelina san isidro'
        );
        break;
      case 'propiedades-en-tigre':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_SAN_ISIDRO_TOTAL_RESULTS,
          'Propiedades en Tigre | Venta y alquiler de propiedades | RE/MAX',
          'Encontrá',
          'propiedades en Tigre. Venta y alquiler de propiedades. Compra tu próxima propiedad en Tigre Centro. Tu próxima casa o departamento en RE/MAX',
          'propiedades en tigre,propiedades en venta en tigre,propiedades en tigre centro,propiedades en tigre venta,propiedades en el delta del tigre,propiedades en venta en tigre centro,venta de propiedades en isla/ delta de tigre'
        );
        break;
      case 'propiedades-en-montevideo':
        const service = await this.generateTotalResults(
          LandingListSEO.MONTEVIDEO_TOTAL_RESULTS
        );
        if (service) {
          this.totalResult = service.data.totalResult;
        }

        this.isMontevideo = true;
        this.isRosario = false;
        this.titleService.setTitle(
          'Propiedades Montevideo: Venta y alquiler de inmuebles RE/MAX'
        );
        this.metaTagService.updateTag({
          name: 'description',
          content:
            'Tenemos ' +
            this.totalResult +
            ' propiedades e inmuebles disponibles en Montevideo. Propiedades con 2 o más dormitorios. Venta y alquiler de propiedades en Montevideo.',
        });
        this.metaTagService.updateTag({
          name: 'keywords',
          content:
            'Propiedades montevideo, venta de propiedades en montevideo, inmobiliaria propiedades montevideo, remates de propiedades en montevideo, alquiler de apartamentos y casas en montevideo ',
        });

        this.metaTagService.updateTag({
          property: 'og:title',
          content:
            'Propiedades Montevideo: Venta y alquiler de inmuebles RE/MAX',
        });
        this.metaTagService.updateTag({
          property: 'og:description',
          content:
            'Tenemos ' +
            this.totalResult +
            ' propiedades e inmuebles disponibles en Montevideo. Propiedades con 2 o más dormitorios. Venta y alquiler de propiedades en Montevideo.',
        });
        break;
      case 'propiedades-en-cordoba':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_CORDOBA_TOTAL_RESULTS,
          'Propiedades en Córdoba: Venta y alquiler de inmuebles RE/MAX',
          'Tenemos',
          'propiedades e inmuebles disponibles en Córdoba. Propiedades con 2 o más ambientes. Venta y alquiler de propiedades en Córdoba Capital.'
        );
        break;
      case 'propiedades-en-ciudad-de-buenos-aires':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_CABA_TOTAL_RESULTS,
          'Propiedades en Venta y Alquiler en Capital Federal | RE/MAX',
          'Tenemos',
          'propiedades e inmuebles disponibles en Ciudad de Buenos Aires. Explorá propiedades en Venta y Alquiler con 1, 2 o 3 ambientes. RE/MAX'
        );
        break;
      case 'propiedades-en-palermo':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_PALERMO_TOTAL_RESULTS,
          'Propiedades Palermo. Venta y alquiler de propiedades | RE/MAX',
          'Contamos con',
          'propiedades e inmuebles en Palermo. Propiedades en Palermo Chico, Soho y Del Valle. Encontrá tu próxima propiedad con nosotros. ',
          'propiedades en palermo, propiedades en palermo soho, propiedades en palermo chico, propiedades del valle en palermo'
        );
        break;
      case 'propiedades-en-posadas':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_POSADAS_TOTAL_RESULTS,
          'Propiedades en Posadas. Compra, venta y alquiler | RE/MAX',
          'Tenemos',
          'propiedades e inmuebles disponibles en Posadas, Misiones | Casas, departamentos y terrenos. Compra y venta en Misiones.'
        );
        break;
      case 'inmuebles':
        if (environment.node == 'ar') {
          this.generateMetatags(
            LandingListSEO.PROPIEDADES_ARGENTINA_TOTAL_RESULTS,
            'Inmuebles y propiedades en venta y alquiler | RE/MAX',
            '',
            'Inmuebles y propiedades disponibles en Argentina, Córdoba, CABA y más. Explorá inmuebles en Venta y Alquiler. RE/MAX.'
          );
        } else if (environment.node == 'uy') {
          this.generateMetatags(
            LandingListSEO.PROPIEDADES_ARGENTINA_TOTAL_RESULTS,
            'Inmuebles en venta y alquiler | RE/MAX Uruguay',
            '',
            'Inmuebles y propiedades disponibles en Uruguay, Montevideo, Punta del Este y más. Explorá inmuebles en Venta y Alquiler.'
          );
        }
        break;
      case 'oficinas':
        this.titleService.setTitle(
          'Oficinas comerciales en venta o alquiler | RE/MAX'
        );
        this.metaTagService.updateTag({
          name: 'description',
          content:
            'Contamos con Oficinas en venta y alquiler en 45 ciudades de Argentina. En RE/MAX encontrá oficinas comerciales que se adapten a tu necesidad.',
        });
        break;
      case 'locales-comerciales':
        this.generateMetatags(
          LandingListSEO.PROPIEDADES_ARGENTINA_TOTAL_RESULTS, // Este conteo no se puede hacer, queda pendiente
          'Locales comerciales en venta o alquiler | RE/MAX',
          'Tenemos',
          'locales en alquiler o venta en Uruguay. Encontrá locales comerciales amplios, pequeños, industriales en el Centro, Montevideo y más.'
        );
        break;

      default:
        this.isRosario = false;
        this.isMontevideo = false;
        this.titleService.setTitle(
          'Propiedades en ' + this.city + ': Venta y Alquiler | RE/MAX'
        );
        this.metaTagService.updateTag({
          name: 'description',
          content:
            'Visitá nuestra página web y descubrí las mejores propiedades en ' +
            this.city +
            '. En RE/MAX ' +
            country +
            'Tenemos casas, departamentos terrenos y más.',
        });
        this.metaTagService.updateTag({
          name: 'keywords',
          content:
            'propiedades en ' +
            this.city +
            ', venta, casas, alquiler, departamentos, ' +
            this.city,
        });

        this.metaTagService.updateTag({
          name: 'og:description',
          content:
            'Visitá nuestra página web y descubrí las mejores propiedades en ' +
            this.city +
            '. En RE/MAX ' +
            country +
            'tenemos casas, departamentos terrenos y más.',
        });
        this.metaTagService.updateTag({
          name: 'og:title',
          content:
            'Propiedades en ' +
            this.city +
            ': venta y alquiler de casas y departamentos',
        });
        break;
    }
  }

  generateTotalResults(id: string) {
    // const id = 'en-montevideo';
    return this.landingTotalResultService.get(id);
  }

  toggleSections(id: number): void {
    switch (id) {
      case 1:
        this.isSectionOneOpen = !this.isSectionOneOpen;
        break;
      case 2:
        this.isSectionTwoOpen = !this.isSectionTwoOpen;
        break;
      case 3:
        this.isSectionThreeOpen = !this.isSectionThreeOpen;
        break;
      case 4:
        this.isSectionFourOpen = !this.isSectionFourOpen;
        break;
      default:
        break;
    }
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement =
      this.dom.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  async generateMetatags(
    TOTAL_RESULTS_PARAMETER: string,
    title: string,
    predescription: string,
    description: string,
    keywords?: string
  ): Promise<void> {
    const totalResultsData: IQrResponse<TotalResultsSEO> =
      await this.landingTotalResultService.get(TOTAL_RESULTS_PARAMETER);
    let totalResults = 0;
    if (totalResultsData) {
      totalResults = totalResultsData.data.totalResult;
    }

    // setea url canonical
    this.getURLLocation = this.document?.location;
    const url =
      this.getURLLocation?.protocol +
      '//' +
      this.getURLLocation?.host +
      '/' +
      this.route.snapshot.url[0]?.path;

    this.updateCanonicalUrl(url);

    this.titleService.setTitle(title);
    this.metaTagService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaTagService.updateTag({
      name: 'description',
      content: predescription + ' ' + totalResults + ' ' + description,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: predescription + ' ' + totalResults + ' ' + description,
    });
    if (keywords) {
      this.metaTagService.updateTag({
        name: 'keywords',
        content: keywords,
      });
    }
  }
}
