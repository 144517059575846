<form [formGroup]="myFormGroup">
  <h2 class="subheading-03-bold">Cambiar contraseña</h2>
  <p class="subtitle">Ingresá tu contraseña actual</p>
  <div class="input-box">
    <mat-form-field class="qr-form-field" appearance="outline">
      <mat-label>Contraseña</mat-label>
      <input
        matInput
        formControlName="password"
        name="password"
        [type]="showPassword ? 'text' : 'password'" />
      <mat-error>{{ getMessageError('password') }}</mat-error>
      <div class="see-password">
        <qr-icon-button
          [icon]="showPassword ? 'eye-cross-out' : 'eye'"
          (click)="showPassword = !showPassword"
          [config]="configButtonSeePassword"></qr-icon-button>
      </div>
    </mat-form-field>
  </div>

  <h2 class="subheading-03-bold mrg-top">Contraseña nueva</h2>
  <p class="subtitle">
    Ingresá una contraseña nueva. Debe tener al menos 6 caracteres entre letras
    y números.
  </p>
  <div class="input-box">
    <mat-form-field class="qr-form-field" appearance="outline">
      <mat-label>Contraseña nueva</mat-label>
      <input
        matInput
        formControlName="newPassword"
        name="newPassword"
        [type]="showNewPassword ? 'text' : 'password'" />
      <mat-error>{{ getMessageError('newPassword') }}</mat-error>
      <div class="see-password">
        <qr-icon-button
          [icon]="showNewPassword ? 'eye-cross-out' : 'eye'"
          (click)="showNewPassword = !showNewPassword"
          [config]="configButtonSeePassword"></qr-icon-button>
      </div>
    </mat-form-field>
  </div>
  <!-- <div class="text-warning">Usá de 6 a 20 caracteres</div> -->

  <div class="input-box mrg-top">
    <mat-form-field class="qr-form-field" appearance="outline">
      <mat-label>Repetir contraseña nueva</mat-label>
      <input
        matInput
        formControlName="repeatNewPassword"
        name="repeatNewPassword"
        [type]="showRepeatNewPassword ? 'text' : 'password'" />
      <mat-error>{{ getMessageError('repeatNewPassword') }}</mat-error>
      <div class="see-password">
        <qr-icon-button
          [icon]="showRepeatNewPassword ? 'eye-cross-out' : 'eye'"
          (click)="showRepeatNewPassword = !showRepeatNewPassword"
          [config]="configButtonSeePassword"></qr-icon-button>
      </div>
    </mat-form-field>
  </div>
  <div class="button-box">
    <qr-button
      [config]="this.configButtonContinue"
      (click)="onChangePassword()"></qr-button>
    @if (this.errorServiceAWS) {
      <p class="text-error service-error">
        {{ this.errorServiceAWS }}
      </p>
    }
  </div>
  @if (loading) {
    <div class="loader-container">
      <mat-spinner diameter="24"></mat-spinner>
    </div>
  }
  @if (successSave) {
    <div class="snackbar">Su contraseña se ha guardado con éxito ✅</div>
  }
</form>
