<button
  class="button"
  aria-hidden="true"
  aria-label="Swipper"
  [ngClass]="config.hierarchy"
  matRipple
  [style.height.px]="config.size"
  [style.width.px]="config.iconOnly ? config.size : 'inherit'"
  [class.button--disabled]="isDisabled"
  [class.button--only-icon]="config.iconOnly"
  [matTooltip]="config.iconTooltipOnly ?? ''">
  @if (config.iconOnly) {
    @if (config.icon) {
      <qr-icon class="button__icon" [config]="icon"></qr-icon>
    }
  }
  @if (!config.iconOnly) {
    @if (config.icon) {
      <qr-icon class="button__icon" [config]="icon"></qr-icon>
    }
    @if (!config.iconOnly) {
      {{ config.value }}
    }
  }
</button>
