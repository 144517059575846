import { Component, Inject, Input, OnChanges } from '@angular/core';
import { Constants } from '@base/src/app/core/constants/constants';
import { Tab } from '@app/core/models/qr-tab.model';
import { L10nLocale, L10N_LOCALE, L10nTranslationModule } from 'angular-l10n';
import { Listing } from '@app/core/models/listing.model';
import { QRTabsComponent } from '../ui/qr-tabs/qr-tabs.component';
import { CommonModule } from '@angular/common';
import { QrCardDetailsEmprendimientosItemComponent } from '../qr-card-details-emprendimientos-item/qr-card-details-emprendimientos-item.component';
import { QrButtonComponent } from '../qr-button/qr-button.component';

@Component({
  selector: 'qr-card-details-emprendimientos',
  templateUrl: './qr-card-details-emprendimientos.component.html',
  styleUrls: ['./qr-card-details-emprendimientos.component.scss'],
  standalone: true,
  imports: [
    QrCardDetailsEmprendimientosComponent,
    QRTabsComponent,
    CommonModule,
    L10nTranslationModule,
    QrCardDetailsEmprendimientosItemComponent,
    QrButtonComponent,
  ],
})
export class QrCardDetailsEmprendimientosComponent implements OnChanges {
  @Input() units: any;
  unitsKeyValue: any[] = [];

  buttonOptions = {
    style: Constants.BUTTON_COLOR_PRIMARY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: '',
  };

  buttonListUnidades = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: '',
  };

  tabHouses: Tab = {
    tabs: [],
    size: Tab.SIZE_48_PX,
    tabSelected: 0,
  };

  tabDeptos: Tab = {
    tabs: [],
    size: Tab.SIZE_48_PX,
    tabSelected: 0,
  };

  showMenu = false;

  listingDeptos: any[] = [];
  listingHouses: any[] = [];

  unitsButtonList: any[] = [];

  dataFormat: any = {};

  mobileList = [];

  constructor(@Inject(L10N_LOCALE) public locale: L10nLocale) {}

  //   ngOnInit(): void {}

  ngOnChanges(): void {
    this.dataFormat[Constants.PROPERTY_TYPE_PH] = 'PH';
    this.dataFormat[Constants.PROPERTY_TYPE_HOTEL] = 'Hotel';
    this.dataFormat[Constants.PROPERTY_TYPE_EDIFICIO] = 'Edificio';
    this.dataFormat[Constants.PROPERTY_TYPE_OTROS_INMUEBLES] = 'Otros';
    this.dataFormat[Constants.PROPERTY_TYPE_OFICINA] = 'Oficina';
    this.dataFormat[Constants.PROPERTY_TYPE_LOCAL] = 'Local';
    this.dataFormat[Constants.PROPERTY_TYPE_TERRENOS_Y_LOTES] =
      'Terrenos y Lotes';
    this.dataFormat[Constants.PROPERTY_TYPE_CAMPO] = 'Campo';
    this.dataFormat[Constants.PROPERTY_TYPE_FONDO_DE_COMERCIO] =
      'Fondo de comercio';
    this.dataFormat[Constants.PROPERTY_TYPE_COCHERA] = 'Cochera';
    this.dataFormat[Constants.PROPERTY_TYPE_GALPON] = 'Galpón';
    this.dataFormat[Constants.PROPERTY_TYPE_QUINTA] = 'Quinta';
    this.dataFormat[Constants.PROPERTY_TYPE_CONSULTORIO] = 'Consultorio';
    this.dataFormat[Constants.PROPERTY_TYPE_CHACRA] = 'Chacra';
    this.dataFormat[Constants.PROPERTY_TYPE_DEPOSITO] = 'Depósito';
    if (this.units) {
      this.generateUnitsKeyValue(this.units);
      this.generateTabs(this.units);
      this.generateListHouses(this.units.casas);
      this.generateListDeptos(this.units.departamentos);
      this.generateButtonsMobile(this.units);
    }
  }

  generateUnitsKeyValue(units: any) {
    for (const key in units) {
      this.unitsKeyValue.push({
        key: key,
        value: units[key],
      });
    }
  }

  generateUnitsList(list: any): void {
    if (this.unitsButtonList && this.unitsButtonList.length > 0) {
      if (this.buttonOptions.text.includes('Departamentos')) {
        const totalRooms = this.getAmbientes();
        this.mobileList = list.departamentos.filter(
          (listing: Listing) => listing.totalRooms == totalRooms
        );
      } else if (this.buttonOptions.text.includes('Casas')) {
        const totalRooms = this.getAmbientes();
        this.mobileList = list.casas.filter(
          (listing: Listing) => listing.totalRooms == totalRooms
        );
      } else {
        for (const key in this.dataFormat) {
          if (this.dataFormat[key] == this.buttonOptions.text) {
            this.mobileList = list[key];
          }
        }
      }
    }
  }

  getAmbientes(): number {
    const split = this.buttonOptions.text.split(' - ');
    const split2 = split[1].split(' ');
    if (split2) {
      return Number(split2[0]);
    } else {
      return 0;
    }
  }

  generateButtonsMobile(list: any): void {
    let listDetailedHouses: any[] = [];
    let listDetailedDeptos: any[] = [];

    if (list.casas && list.casas.length > 0) {
      listDetailedHouses = this.createButtonsUnitsForMobile(
        list.casas,
        'Casas'
      );
    }

    if (list.departamentos && list.departamentos.length > 0) {
      listDetailedDeptos = this.createButtonsUnitsForMobile(
        list.departamentos,
        'Departamentos'
      );
    }

    listDetailedDeptos.forEach((element: any) => {
      const button = Object.assign({}, this.buttonListUnidades);
      button.text = element;
      this.unitsButtonList.push(button);
    });

    listDetailedHouses.forEach((element: any) => {
      const button = Object.assign({}, this.buttonListUnidades);
      button.text = element;
      this.unitsButtonList.push(button);
    });

    for (const key1 in list) {
      if (key1 != 'casas' && key1 != 'departamentos' && list[key1].length > 0) {
        const button = Object.assign({}, this.buttonListUnidades);
        button.text = this.dataFormat[key1];
        this.unitsButtonList.push(button);
      }
    }

    if (this.unitsButtonList && this.unitsButtonList.length > 0) {
      this.buttonOptions.text = this.unitsButtonList[0]?.text;
    }

    this.generateUnitsList(this.units);
  }

  createButtonsUnitsForMobile(array: [], type: string): string[] {
    const result: string[] = [];
    array.forEach((listing: Listing) => {
      const exists = result.find((text: string) =>
        text.includes(listing.totalRooms?.toString() || '')
      );
      if (!exists) {
        const finalText = listing.totalRooms == 1 ? ' ambiente' : ' ambientes';
        result.push(type + ' - ' + listing.totalRooms?.toString() + finalText);
      }
    });
    result.sort((a, b) => {
      if (a > b) {
        return 1;
      } else {
        return -1;
      }
    });
    return result;
  }

  generateListHouses(list: []): void {
    this.listingHouses = list.filter(
      (item: Listing) =>
        item.totalRooms == this.tabHouses.tabs[this.tabHouses.tabSelected].id
    );
    //     this.listingHouses = Object.assign([], this.listingHouses);
  }

  generateListDeptos(list: []): void {
    this.listingDeptos = list.filter(
      (item: Listing) =>
        item.totalRooms == this.tabDeptos.tabs[this.tabDeptos.tabSelected].id
    );
  }

  generateTabs(obj: any) {
    for (const key in obj) {
      if (
        key == Constants.PROPERTY_TYPE_CASAS &&
        obj[key] &&
        obj[key].length > 0
      ) {
        this.makeTab(this.tabHouses, obj[key]);
      } else if (
        key == Constants.PROPERTY_TYPE_DEPARTAMENTOS &&
        obj[key] &&
        obj[key].length > 0
      ) {
        this.makeTab(this.tabDeptos, obj[key]);
      }
    }
  }

  makeTab(qrTab: Tab, listingArray: []): void {
    listingArray.forEach((listing: any) => {
      const exists = qrTab.tabs.find((tab: any) =>
        tab.text.includes(listing.totalRooms.toString())
      );
      if (!exists) {
        const text = listing.totalRooms == 1 ? 'ambiente' : 'ambientes';
        qrTab.tabs.push({
          text: listing.totalRooms + ' ' + text,
          id: listing.totalRooms,
        });
        qrTab.tabSelected = 0;
      }
    });
    qrTab.tabs.sort((a, b) => {
      if ((a.text || '') > (b.text || '')) {
        return 1;
      } else {
        return -1;
      }
    });
    qrTab = Object.assign({}, qrTab);
  }

  onChangeListingsType(event: any): void {
    this.buttonOptions.text = event.text;
    this.generateUnitsList(this.units);
    this.showMenu = false;
  }

  openMenu(): void {
    this.showMenu = true;
  }

  closeMenu(): void {
    this.showMenu = false;
  }

  tabChangeHouse(tabSelected: any): void {
    this.tabHouses.tabSelected = tabSelected;
    this.generateListHouses(this.units.casas);
  }

  tabChangeDepto(tabSelected: number): void {
    this.tabDeptos.tabSelected = tabSelected;
    this.generateListDeptos(this.units.departamentos);
  }
}
