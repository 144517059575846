import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { QRDropdown } from '@app/core/models/qr-dropdown.model';
import { QRIcon } from '@app/core/models/qr-icon.model';
import { QRIconComponent } from '../qr-icon/qr-icon.component';

@Component({
  selector: 'qr-dropdown',
  templateUrl: './qr-dropdown.component.html',
  styleUrls: ['./qr-dropdown.component.scss'],
  standalone: true,
  imports: [
    MatOptionModule,
    MatRippleModule,
    FormsModule,
    MatSelectModule,
    QRIconComponent,
  ],
})
export class QRDropdownComponent {
  @Input() config?: QRDropdown;
  @Input() selected: string | string[] | undefined;
  @Input() hasError: boolean = false;
  @ViewChild('matSelect') matSelect: any;
  @Input() isDisabled?: boolean = false;
  @Output() onChange: EventEmitter<string | string[] | undefined> =
    new EventEmitter<string | string[] | undefined>();

  iconArrow: QRIcon = {
    name: QRIcon.NAME_ARROW_DROP_DOWN,
    color: QRIcon.COLOR_BLACK,
  };

  selectChange(): void {
    this.onChange.emit(this.selected);
  }

  open(): void {
    if (!this.isDisabled) {
      this.matSelect.trigger.nativeElement.click();
    }
  }
}
