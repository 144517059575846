import { Component, Input } from '@angular/core';

@Component({
  selector: 'qr-certificates-card',
  templateUrl: './qr-certificates-card.component.html',
  styleUrls: ['./qr-certificates-card.component.scss'],
  standalone: true,
  imports: [],
})
export class QrCertificatesCardComponent {
  @Input() certificates: string[] = [];
}
