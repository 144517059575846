<label
  [for]="config?.id"
  class="input-group__label"
  [ngClass]="{
    'status-error': hasError,
    'status-ok': isValid,
    'status-disabled': isDisabled,
  }"
  >{{ config?.label }}</label
>
<div class="input-group">
  <input
    [type]="config?.type ?? ''"
    [name]="config?.name ?? ''"
    [id]="config?.id"
    [(ngModel)]="visibleMaskValue"
    (ngModelChange)="modelChange($event)"
    (keydown)="keydownChange($event)"
    (paste)="pasteChange($event)"
    [placeholder]="config?.placeholder ? config?.placeholder : ''"
    [ngClass]="{
      'status-error': hasError,
      'status-ok': isValid,
      'status-disabled': isDisabled,
    }"
    [attr.disabled]="isDisabled ? true : null"
    (blur)="blurChange.emit(true)"
    #input />
  @if (config?.hint && !hasError) {
    <small
      class="input-group__hint"
      [ngClass]="{
        'status-error': hasError,
        'status-ok': isValid,
        'status-disabled': isDisabled,
      }"
      >{{ config?.hint }}</small
    >
  }
  @if (config?.error && hasError) {
    <small class="input-group__hint status-error">
      {{ config?.error }}
    </small>
  }
  @if (isValid) {
    <qr-icon [config]="iconCheck" class="input-group__icon"></qr-icon>
  }
  @if (hasError) {
    <qr-icon [config]="iconError" class="input-group__icon"></qr-icon>
  }
</div>
