<!-- Diálogo: Tipo de propiedad -->
<qr-dialog
  [data]="dialogPropertyType"
  (onButtonLeft)="dialogPropertyTypeButtonLeft()"
  (onButtonRight)="dialogPropertyTypeButtonRight()"></qr-dialog>

<section
  id="collection"
  [style.background-image]="'url(' + imageCloudfrontAndCountry + '/assets/media/webp/collection/bg-remax-collection.webp' + ')'">
  <div id="collection-wrapper">
    <div class="search-slogan">
      <h1>{{ 'collection.intro' | translate:locale.language }}</h1>
    </div>
    <div id="search-bar">
      <qr-searchbar
        [style]="styleSearchBar"
        [isSynonymousSearchEnabled]="true"
        (onSearch)="search()">
      </qr-searchbar>
    </div>
  </div>
</section>

<section class="section">
  <div
    class="section-container"
    fxLayoutAlign="center center"
    fxLayout="column">
    <p class="section-p" fxLayoutAlign="center center">
      Encontrá inmuebles premium a través de equipos liderados por profesionales
      matriculados, <br />
      especializados y comprometidos en superar las expectativas de servicio de
      sus clientes.
    </p>

    <p class="section-p" fxLayoutAlign="center center">
      Cuando elegís trabajar con un equipo adherido a The RE/MAX Collection©
      elegís la confianza y el profesionalismo <br />
      que se vincula con la experiencia y el entrenamiento preciso del mercado
      de inmuebles premium.
    </p>
    <p class="section-p mb-0" fxLayoutAlign="center center">
      Abrazá el nivel extraordinario de propiedades premium con The RE/MAX
      Collection©.
    </p>

    <!-- Disclaimer -->
    @if (node === 'ar') {
    <qr-disclaimer type="home" theme="collection"></qr-disclaimer>
    }
  </div>
</section>
