import { Component, Input, OnInit } from '@angular/core';
import { QRButton } from '@app/core/models/qr-button.model';
import { QRIcon } from '@app/core/models/qr-icon.model';
import { QRIconComponent } from '../qr-icon/qr-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatRippleModule } from '@angular/material/core';

@Component({
  selector: 'qr-button',
  templateUrl: './qr-button.component.html',
  styleUrls: ['./qr-button.component.scss'],
  standalone: true,
  imports: [QRIconComponent, MatTooltipModule, CommonModule, MatRippleModule],
})
export class QRButtonComponent implements OnInit {
  @Input() config!: QRButton;
  icon: QRIcon = { name: QRIcon.NAME_PLUS, color: QRIcon.COLOR_BLACK };
  @Input() isDisabled: boolean = false;

  ngOnInit(): void {
    if (this.icon) {
      this.icon = { ...this.icon, name: this.config.icon ?? '' };
    }

    switch (this.config.hierarchy) {
      case QRButton.HIERARCHY_PRIMARY_SOLID:
        this.icon = { ...this.icon, color: QRIcon.COLOR_WHITE };
        break;
      case QRButton.HIERARCHY_TONAL_BORDER:
      case QRButton.HIERARCHY_TONAL:
      case QRButton.HIERARCHY_PRIMARY_BORDER:
        this.icon = { ...this.icon, color: QRIcon.COLOR_PRIMARY };
        break;
      case QRButton.HIERARCHY_GREY_BORDER:
      case QRButton.HIERARCHY_GREY:
        this.icon = { ...this.icon, color: QRIcon.COLOR_GREY_WOLF };
        break;
      case QRButton.HIERARCHY_GREEN_SOLID:
        this.icon = { ...this.icon, color: QRIcon.COLOR_WHITE };
        break;
      default:
        this.icon = { ...this.icon, color: QRIcon.COLOR_BLACK };
        break;
    }
    if (this.isDisabled) {
      this.icon = { ...this.icon, color: QRIcon.COLOR_GREY_IRON };
    }
  }
}
