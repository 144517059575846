<div class="saved-searches container" id="save-searches">
  <h3 class="saved-searches__title">Búsquedas guardadas</h3>
  <p class="saved-searches__results">{{ this.totalItems }} resultados</p>

  @for (search of userSaveSearch; track search) {
    <div class="saved-searches__item">
      <div class="search-card">
        <div class="search-card__info">
          <h3 class="search-card__title">{{ search.name }}</h3>
          <p class="search-card__date">
            Guardado el
            {{ search.createdAt | date: 'dd/MM/yyyy' }}
          </p>
        </div>
        <div class="search-card__actions">
          <a [href]="search.formattedUrl">
            <qr-button-x [config]="buttonTonalWithIcon"></qr-button-x>
          </a>
        </div>
      </div>
      <div class="search-card__content">
        <div class="search-card__tags">
          @for (tag of search.tags; track tag) {
            <qr-tag-x [config]="TAG_PRIMARY_BORDER">{{ tag }}</qr-tag-x>
          }
        </div>
        <div class="search-card__action">
          <div class="search-card__button">
            <a [href]="search.formattedUrl">
              <qr-button-x [config]="buttonTonalWithIcon"></qr-button-x>
            </a>
          </div>
          <div class="search-card__buttons">
            <div class="search-card__edit">
              <qr-button-x
                [config]="buttonDrawIcon"
                (click)="openSaveSearch(search)"></qr-button-x>
            </div>
            <div class="search-card__delete">
              <qr-button-x
                [config]="buttonDeleteIcon"
                (click)="openDeleteModal(search)"></qr-button-x>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  <!-- Paginación -->
  @if (this.visiblePagination) {
    <div id="pagination">
      <qr-pagination
        [disabled$]="isLoading"
        [actualPage]="this.page"
        [itemsArray]="this.totalItems"
        [pageSize]="this.pageSize"
        [dinamicComponent]="true"
        (onPagination)="pagination($event)">
      </qr-pagination>
    </div>
  }

  <qr-dialog [data]="dialogData" (onButtonRight)="update($event)"></qr-dialog>
  <qr-dialog [data]="dialogDelete" (onButtonRight)="onDelete()"></qr-dialog>
</div>
