import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { register } from 'swiper/element/bundle';
import { AppComponent } from './app/app.component';
import { Amplify } from 'aws-amplify';
import aws_exports from './aws-exports-custom';

Amplify.configure(aws_exports);

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
register();
