import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { MenuItem } from '../../core/models/menu.model';
import { MENU } from '../menu';
import { Constants } from '../../core/constants/constants';

import {
  L10nConfig,
  L10nLocale,
  L10nTranslationService,
  L10N_CONFIG,
  L10N_LOCALE,
  L10nTranslationModule,
} from 'angular-l10n';
import { isPlatformBrowser } from '@angular/common';
import { UtilsService } from '../../services/utils.service';
import { environment } from '@base/environments/environment';
import { QRMediaImage } from '@app/core/models/qr-media-img';
import { SearchDomain } from '../../domain/search.domain';
import { QrMediaImgComponent } from '../../components/qr-media-img/qr-media-img.component';
import { QrIconButtonComponent } from '../../components/qr-icon-button/qr-icon-button.component';

@Component({
  selector: 'app-collection-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [L10nTranslationModule, QrMediaImgComponent, QrIconButtonComponent],
})
export class CollectionNavbarComponent implements OnInit {
  @Output() onOpenSideNav: EventEmitter<any> = new EventEmitter();
  node: string = environment.node;
  public menuItems: MenuItem[] = [];
  baseURL: string = '';
  languageSelected = 'local';
  optionsLanguage: any[] = [];
  pathSelected = '';

  // SVG Logo RE/MAX Collection
  logoTheREMAXCollectionSVG: QRMediaImage = {
    text: 'The RE/MAX Collection',
    baseURL: '',
    docFolder: 'assets/logos',
    docName: 'logo-the-remax-collection-white',
    docType: Constants.DOCTYPE_SVG,
    alt: 'The RE/MAX Collection',
    width: 'auto',
    height: '38px',
    loading: QRMediaImage.LOADING_LAZY,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  // Botón: Menú (Mobile)
  configButtonMenu = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    @Inject(PLATFORM_ID) private platformId: any,
    private translation: L10nTranslationService,
    private utilsService: UtilsService,
    public searchDomain: SearchDomain
  ) {}

  ngOnInit(): void {
    this.collectionMenuFilter();

    if (isPlatformBrowser(this.platformId)) {
      this.pathSelected = window.location.href;
    }
    this.languageSelected = this.l10nConfig.defaultLocale.language;
    this.l10nConfig.schema.forEach(e => {
      this.optionsLanguage.push({
        value: e.locale.language,
        text: this.utilsService.getFrendlyLanguageName(e.locale.language),
      });
    });

    this.translation.onError().subscribe({
      next: (error: any) => {
        if (error) console.log(error);
      },
    });
  }

  public openSideNav(): void {
    this.onOpenSideNav.emit();
  }

  public applyFilterAndRedirect(): void {
    this.searchDomain.filterRemaxCollection = true;
    this.searchDomain.windowLocationHref();
  }

  onChangeLang(lang: string): void {
    this.languageSelected = lang;
    for (let i = 0; i < this.l10nConfig.schema.length; i++) {
      if (lang == this.l10nConfig.schema[i].locale.language) {
        this.translation.setLocale(this.l10nConfig.schema[i].locale);
      }
    }
  }

  private collectionMenuFilter(): void {
    this.menuItems = MENU.filter(item => {
      const isNodeEcuador = this.node === Constants.NODE_ECUADOR;
      const isNodeUruguay = this.node === Constants.NODE_URUGUAY;
      const isMenuEntrepreneurship = item.label === 'home.emprendimientos';
      const isMenuBlog = item.label === 'home.blog';
      const isMenuRent = item.label === 'home.rent';
      const isHome = item.label === 'home.home';
      return (
        !(isNodeEcuador && (isMenuBlog || isMenuEntrepreneurship)) &&
        !(isNodeUruguay && isMenuEntrepreneurship) &&
        !isMenuRent &&
        !isHome
      );
    });
  }
}
