import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { filter } from 'rxjs/operators';
import { Constants } from '@base/src/app/core/constants/constants';
import { IQrPage } from '@app/core/models/IQrPage';
import { ListingType } from '@app/core/models/listing-types.model';
import { ListingTypesSellService } from '@app/services/listing-types-sell.service';
import { ContactSell } from '@app/core/models/contact-sell.model';
import { ContactsSellService } from '@app/services/contacts-sell.service';
import { L10N_LOCALE, L10nLocale, L10nTranslatePipe } from 'angular-l10n';
import { isPlatformBrowser } from '@angular/common';
import { Subscription } from 'rxjs';
import { environment } from '@base/environments/environment';
import { UtilsService } from '@app/services/utils.service';
import { QrFilter } from '@app/core/models/qr-filter';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { QrButtonComponent } from '@app/components/qr-button/qr-button.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { QrGoogleAutocompleteComponent } from '@app/components/qr-google-autocomplete/qr-google-autocomplete.component';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-sell',
  standalone: true,
  imports: [
    QrButtonComponent,
    L10nTranslatePipe,
    MatSelectModule,
    QrGoogleAutocompleteComponent,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  templateUrl: './sell.component.html',
  styleUrl: './sell.component.scss',
})
export class SellPage implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  fullName = new UntypedFormControl('', Validators.required);
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  phone = new UntypedFormControl('', [Validators.required]);
  ubication = new UntypedFormControl('', Validators.required);
  rooms = new UntypedFormControl('');
  propertyType = new UntypedFormControl('', Validators.required);
  description = new UntypedFormControl('');
  public roomsTypes: ListingType[] = [];

  isFormCorrectlySent = false;

  // Botón: Enviar
  configButtonSend = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Enviar',
    isDisabled: true,
  };

  isBrowser: boolean;

  node = environment.node;

  constantsEc = Constants.NODE_ECUADOR;

  propertyTypeSubscription: Subscription | undefined;

  isRoomsInputsVisible?: boolean = false;

  public propertyTypesSell: ListingType[] = []; // Listado de tipos de propiedad

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private fb: UntypedFormBuilder,
    private contactsSellService: ContactsSellService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private propertyTypesSellService: ListingTypesSellService,
    private utils: UtilsService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    this.form = fb.group({
      fullName: this.fullName,
      email: this.email,
      phone: this.phone,
      ubication: this.ubication,
      rooms: this.rooms,
      propertyType: this.propertyType,
      description: this.description,
    });
  }

  ngOnInit(): void {
    this.loadPropertySellTypes();
    this.loadRoomsTypes();
    this.form.statusChanges
      .pipe(
        filter((status: string) => {
          status == 'VALID'
            ? (this.configButtonSend.isDisabled = false)
            : (this.configButtonSend.isDisabled = true);
          return this.form.valid;
        })
      )
      .subscribe();

    this.propertyTypeSubscription = this.form
      .get('propertyType')
      ?.valueChanges.subscribe((propertyType: ListingType) => {
        if (
          propertyType.value == 'departamento' ||
          propertyType.value == 'casa' ||
          propertyType.value == 'ph'
        ) {
          this.isRoomsInputsVisible = true;
        } else {
          this.form.get('rooms')?.setValue(null);
          this.isRoomsInputsVisible = false;
        }
      });

    // Metatags SEO

    const title = 'Vendé tu propiedad';
    const description =
      'Completá el formulario y una oficina adherida a la red RE/MAX se pondrá en contacto con vos para continuar con el proceso';

    this.utils.setPageMetaTags(title, description);
  }

  ngOnDestroy(): void {
    this.propertyTypeSubscription?.unsubscribe;
  }

  /*
          loadPropertySellTypes: Cargar listado de tipos de propiedad
          */
  async loadPropertySellTypes(): Promise<void> {
    try {
      const qrFilter: QrFilter = new QrFilter();
      qrFilter.sorts = ['+order'];
      const result: IQrResponse<IQrPage> =
        await this.propertyTypesSellService.findAll(qrFilter);
      if (result.code == 200) {
        const qrPage: IQrPage = result.data;
        this.propertyTypesSell = qrPage.data;
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  loadRoomsTypes(): void {
    this.roomsTypes = [
      { id: 1, value: 'one-room', translate: '' },
      { id: 1, value: 'two-rooms', translate: '' },
      { id: 1, value: 'three-rooms', translate: '' },
      { id: 1, value: 'four-or-more-rooms', translate: '' },
    ];
  }

  getErrorMessage(field: string): string {
    let errorMsg = '';
    switch (field) {
      case 'fullName':
        if (this.fullName.hasError('required'))
          errorMsg = 'Ingrese un nombre y apellido';
        break;
      case 'email':
        if (this.email.hasError('required')) errorMsg = 'Ingrese un email';
        if (this.email.hasError('email'))
          errorMsg = 'Ingrese un email válido. Ej: tuemail@gmail.com';
        break;
      case 'phone':
        if (this.node != Constants.NODE_ECUADOR) {
          if (this.phone.hasError('required'))
            errorMsg =
              'Ingrese un N° con código de área. Ej: 112345678 (CABA) o 3511234567 (Cdad. de Córdoba)';
        } else if (this.node == Constants.NODE_ECUADOR) {
          if (this.phone.hasError('required'))
            errorMsg =
              'Ingrese un N° completo con código de área. Ej: (4) 1234567';
        }

        break;
      case 'ubication':
        if (this.node != Constants.NODE_ECUADOR) {
          if (this.ubication.hasError('required'))
            errorMsg = 'Seleccione la localidad/provincia de la propiedad';
        } else if (this.node == Constants.NODE_ECUADOR) {
          if (this.ubication.hasError('required'))
            errorMsg = 'Seleccione la parroquia/cantón de la propiedad';
        }

        break;
      case 'propertyType':
        if (this.propertyType.hasError('required'))
          errorMsg = 'Seleccione un tipo de propiedad';
        break;
      default:
        errorMsg = 'error';
        break;
    }
    return errorMsg;
  }

  onlyNumbers(event: KeyboardEvent): void {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  getLocationValue(value: string): string {
    const locationParsed = value.replace('display_address: ', '');
    const locationParsed2 = locationParsed.split(' ,lat:');
    return locationParsed2[0];
  }

  inputPhoneChange(): void {
    // Validación y limpieza de campo teléfono
    let valueInput = this.form.get('phone')?.value;
    if (
      !Number.isInteger(this.form.get('phone')?.value) &&
      valueInput &&
      valueInput.length > 0
    ) {
      valueInput = Number(valueInput.replace(/\D/g, ''));
      this.form.get('phone')?.setValue(valueInput);
    }
  }

  inputPhoneKeydown(e: KeyboardEvent): void {
    if (
      e.key === 'Tab' || // Permitir: Tab
      e.key === 'Backspace' || // Permitir: Backspace
      e.key === 'Delete' || // Permitir: Delete
      e.key == 'ArrowLeft' || // Permitir: Flecha izquierda
      e.key == 'ArrowRight' // Permitir: Flecha derecha
    ) {
      return; // habilitar, no hago nada
    } else {
      if (this.form.get('phone')?.value?.toString().length >= 15) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  inputPhoneFocusOut(): void {
    if (this.form.get('phone')?.value?.toString().length < 8) {
      this.form.controls['phone'].setErrors({ required: true });
    }
  }

  parseRoomsValue(value: string): number {
    let roomID: number;
    switch (value) {
      case this.roomsTypes[0].value: // Monoambiente
        roomID = 1;
        break;
      case this.roomsTypes[1].value: // 2 ambientes
        roomID = 2;
        break;
      case this.roomsTypes[2].value: // 3 ambientes
        roomID = 3;
        break;
      case this.roomsTypes[3].value: // 4 o más ambientes
        roomID = 4;
        break;

      default:
        roomID = 0;
        break;
    }
    return roomID;
  }

  inputKeydown(e: KeyboardEvent, name: string, maxlength: number): void {
    if (
      (e.key === 'a' && e.ctrlKey === true) || // Permitir: Ctrl+A
      (e.key === 'c' && e.ctrlKey === true) || // Permitir: Ctrl+C
      (e.key === 'x' && e.ctrlKey === true) || // Permitir: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Permitir+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Permitir: Cmd+C (Mac)
      (e.key === 'x' && e.metaKey === true) || // Permitir: Cmd+X (Mac)
      e.key === 'Tab' || // Permitir: Tab
      e.key === 'Backspace' || // Permitir: Backspace
      e.key === 'Delete' || // Permitir: Delete
      e.key == 'ArrowLeft' || // Permitir: Flecha izquierda
      e.key == 'ArrowRight' // Permitir: Flecha derecha
    ) {
      return; // habilitar, no hago nada
    } else {
      if (this.form.get(name)?.value?.toString().length >= maxlength) {
        e.preventDefault();
        e.stopPropagation();
      }
    }
  }

  inputPaste(event: ClipboardEvent, name: string, maxlength: number): void {
    if (event && event.clipboardData) {
      event.preventDefault();
      const pastedInput: string =
        this.form.get(name)?.value?.toString() +
        event.clipboardData.getData('text/plain');
      if (pastedInput.length >= maxlength) {
        const clipboardSubstring = pastedInput.substring(0, maxlength);
        this.form.get(name)?.setValue(clipboardSubstring);
      } else {
        this.form.get(name)?.setValue(pastedInput);
      }
    }
  }

  async onSubmit(): Promise<void> {
    try {
      const contactSell: ContactSell = new ContactSell();
      contactSell.name = this.form.get('fullName')?.value;
      contactSell.phone = this.form.get('phone')?.value;
      contactSell.email = this.form.get('email')?.value;
      contactSell.ubication = this.getLocationValue(
        this.form.get('ubication')?.value
      );
      contactSell.typeSell = this.form.get('propertyType')?.value.id
        ? this.form.get('propertyType')?.value.id
        : '';

      contactSell.rooms = this.form.get('rooms')?.value
        ? this.parseRoomsValue(this.form.get('rooms')?.value.value)
        : 0;
      contactSell.description =
        this.form.get('description')?.value !== null
          ? this.form.get('description')?.value
          : '';

      const result: IQrResponse<any> =
        await this.contactsSellService.addGet(contactSell);

      if (result.code != 200) {
        alert('SellPage.onSubmit: ERROR ' + result.message);
      }

      document.getElementsByClassName('remax-logo')[0].scrollIntoView();
      this.isFormCorrectlySent = true;
    } catch (error) {
      alert('SellPage.onSubmit: ERROR ' + error);
    }
  }

  // TODO: WTF este nombre del método?
  getHeroClassRegion(): string {
    return environment.node;
  }
}
