<div
  class="user-favorite-item-container"
  [ngClass]="{ 'on-delete': deletedItem }"
  matRipple>
  <div class="checkbox-container">
    <mat-checkbox
      color="primary"
      [(ngModel)]="favorite.checkToDelete"
      (ngModelChange)="onChecked()">
    </mat-checkbox>
  </div>
  <!-- Foto de favorito -->
  <a (click)="onNavigateProp()" aria-hidden="true">
    <div
      class="preview-container"
      [ngClass]="{ clickeable: favorite.published }">
      @if (favorite.photo !== 'empty') {
        <img [src]="favorite.photo" alt="Favorite" loading="lazy" />
      }
      @if (favorite.photo === 'empty') {
        <qr-media-img [config]="favoritePhotoNotFoundSVG"></qr-media-img>
      }
    </div>
  </a>
  <!-- Título y precio -->
  <a
    (click)="onNavigateProp()"
    class="text-container"
    aria-hidden="true"
    [ngClass]="{ clickeable: favorite.published }">
    <div>
      <p class="address">{{ favorite.title }}</p>
      <div class="price">
        @if (favorite.published) {
          {{ favorite.price | qrNumber }} {{ favorite.currency }}
        }
        @if (!favorite.published) {
          Publicación finalizada
        }
        <span class="expenses"></span>
      </div>
    </div>
  </a>
  <div class="btn-container">
    <qr-button-with-icon
      [config]="buttonDeleteMobile"
      class="delete-mobile"
      (click)="onDelete()">
    </qr-button-with-icon>
    <qr-icon-button
      icon="delete-outline"
      (click)="onDelete()"
      [config]="buttonDelete"></qr-icon-button>
  </div>
</div>
