export class QRIcon {
  // Color
  static readonly COLOR_BLACK: string = 'icon--black';
  static readonly COLOR_WHITE: string = 'icon--white';
  static readonly COLOR_PRIMARY: string = 'icon--primary';
  static readonly COLOR_GREY_WOLF: string = 'icon--grey-wolf';
  static readonly COLOR_GREY_IRON: string = 'icon--grey-iron';
  static readonly COLOR_RED: string = 'icon--red';
  // Nombre
  static readonly NAME_PLUS: string = 'plus';
  static readonly NAME_CHECK: string = 'check';
  static readonly NAME_ERROR: string = 'error';
  static readonly NAME_ARROW_DROP_DOWN: string = 'arrow-drop-down';
  name?: string;

  static readonly NAME_FAVORITE: string = 'favorite-outlined';
  color: string = '';

  static readonly NAME_WHATSAPP: string = 'whatsapp-outlined';
  static readonly NAME_PHONE: string = 'phone-outlined';
  static readonly NAME_EMAIL: string = 'email-outlined';
  static readonly NAME_ARROW_LEFT: string = 'arrow-left';
  static readonly NAME_ARROW_RIGHT: string = 'arrow-right';
  static readonly NAME_CLOSE: string = 'close';
  static readonly NAME_MODE_VIEW_LIST: string = 'mode-view-list';
  static readonly NAME_MODE_VIEW_GRID: string = 'mode-view-grid';
  static readonly NAME_MODE_VIEW_MAP: string = 'mode-view-map';
  static readonly NAME_OFFICE: string = 'office';
}
