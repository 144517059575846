import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable } from 'rxjs';
import { QrFilterButtonsComponent } from '../qr-filter-buttons/qr-filter-buttons.component';
import { QrFilterPriceNumberRangeComponent } from '../qr-filter-price-number-range/qr-filter-price-number-range.component';
import { QrFilterPropertyTypeComponent } from '../qr-filter-property-type/qr-filter-property-type.component';
import { Constants } from '@base/src/app/core/constants/constants';
import { QrButtonComponent } from '../qr-button/qr-button.component';
import { QrFilterPrice } from '../../core/models/qr-filter-price';

@Component({
  selector: 'qr-dropdown',
  templateUrl: './qr-dropdown.component.html',
  styleUrls: ['./qr-dropdown.component.scss'],
  standalone: true,
  imports: [QrButtonComponent],
})
export class QrDropdownComponent implements OnChanges {
  @Input() options: any; // Data que viene del componente padre
  @Input() config: any; // Configuración del desplegable que viene del componente padre
  @Input() selected$: Observable<any> = new Observable();

  isOpen = false; // Define si el desplegable esta abierto o cerrado
  positionX: number = 0; // Posición X en donde se mostrara el desplegable
  positionY: number = 0; // Posición Y en donde se mostrara el desplegable
  padding: number = 0; // Padding del contenedor del desplegable
  paddingTopBottom: number = 0; // Padding top y bottom del contenedor del desplegable
  width: number = 0; // Width del contenedor del desplegable
  height: number = 0; // Height del contenedor del desplegable

  @Output() onClose = new EventEmitter<any>(); // Evento para el componente padre: al cerrar el componente
  @Output() onchange: EventEmitter<any> = new EventEmitter<any>(); // Evento para el componente padre: para enviar data por otro lado que no sea cerrar

  // Componente dinamico
  @ViewChild('dinamicComponent', { read: ViewContainerRef })
  target: ViewContainerRef | null = null;

  configButtonApply: any = {
    style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Aplicar filtro',
  };

  private componentRef: ComponentRef<any> | null = null;
  private valueSelected: any = null;

  // Configuración del botón aplicar

  constructor(
    private cdRef: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  apply(): void {
    this.isOpen = false;
    this.config.isOpen = false;
    this.onClose.emit(this.valueSelected);
  }

  close(): void {
    this.isOpen = false;
    this.config.isOpen = false;
  }

  /*
	ngOnChanges: Detecta que hubo un cambio en el componente
	*/
  ngOnChanges(): void {
    if (this.config) {
      if (this.config.referenceDivPosition) {
        // Calcular posición del desplegable basandose en el elemento de referencia que lo abre
        const referenceDivPosition = document
          .getElementById(this.config.referenceDivPosition)
          ?.getBoundingClientRect();
        if (referenceDivPosition) {
          this.positionX = referenceDivPosition.x + window.scrollX; // Posicion X
          this.positionY =
            referenceDivPosition.y +
            window.scrollY +
            referenceDivPosition.height; // Posicion Y
        }
      }

      // this.config.padding ? this.padding = this.config.padding : this.padding = 0;

      if (this.config.padding) {
        this.padding = this.config.padding;
        this.paddingTopBottom = 8;
      } else {
        this.padding = 0;
        this.paddingTopBottom = 0;
      }

      this.config.height
        ? (this.height = this.config.height)
        : (this.height = 0);
      this.config.width ? (this.width = this.config.width) : (this.width = 0);

      this.isOpen = this.config.isOpen;
      if (this.isOpen) this.manageDinamicContent();
    }
  }

  /*
	manageDinamicContent: Manejar la carga del componente dinamico  
	*/
  manageDinamicContent(): void {
    switch (this.config.name) {
      /* AGREGAR ACA LOS NUEVOS COMPONENTES DINAMICOS */

      // Filtro: Tipo de propiedad (Listado de resultados)
      case 'filter-property-type':
        this.addComponentDynamically(QrFilterPropertyTypeComponent);
        break;
      // Filtro: Operación (Listado de resultados)
      case 'filter-operation':
        this.addComponentDynamically(QrFilterButtonsComponent);
        break;
      // Filtro: Etapa (Listado de resultados)
      case 'filter-stage':
        this.addComponentDynamically(QrFilterButtonsComponent);
        break;
      // Filtro: Price (Listado de resultados)
      case 'filter-price':
        this.addComponentDynamically(QrFilterPriceNumberRangeComponent);
        break;

      case 'filter-orderBy':
        this.addComponentDynamically(QrFilterButtonsComponent);
        break;
      default:
        break;
    }
  }

  /*
	addComponentDynamically: Agrega el componente de forma dinámica
	*/
  addComponentDynamically(componentToAdd: any) {
    this.cdRef.detectChanges(); // Ejecutar detección de cambios inmediatamente
    this.target?.clear(); // Limpiar contenedor de componentes dinámicos
    const childComponent =
      this.componentFactoryResolver.resolveComponentFactory(componentToAdd);
    this.componentRef = this.target?.createComponent(childComponent) || null;

    /* --------------------------------------------------------*/
    /* Comunicación entre componentes padres e hijos */
    /* --------------------------------------------------------*/

    // Filtro: Tipo de propiedad (Listado de resultados)
    if (this.config.name == 'filter-property-type') {
      // Enviar data a la variable "data" del child dinámico
      if (this.componentRef !== null) {
        this.componentRef.instance.options = this.options;
        this.componentRef.instance.selected$ = this.selected$;
        this.componentRef.instance.onchange.subscribe((value: any[]) => {
          this.valueSelected = value;
          this.onchange.emit(this.valueSelected);
        });
      }
    }

    // Filtro: Operación (Listado de resultados)
    if (this.config.name == 'filter-operation') {
      // Enviar data a la variable "data" del child dinámico
      if (this.componentRef !== null) {
        this.componentRef.instance.options = this.options;
        this.componentRef.instance.isMultipleSelection = false;
        this.componentRef.instance.selected$ = this.selected$;
        this.componentRef.instance.oneColumn = true;
        this.componentRef.instance.onchange.subscribe((value: any[]) => {
          this.valueSelected = value;
        });
      }
    }

    // Filtro: Stage (Listado de resultados)
    if (this.config.name == 'filter-stage') {
      // Enviar data a la variable "data" del child dinámico
      if (this.componentRef !== null) {
        this.componentRef.instance.options = this.options;
        this.componentRef.instance.isMultipleSelection = true;
        this.componentRef.instance.selected$ = this.selected$;
        this.componentRef.instance.oneColumn = true;
        // Si existen valores seleccionados inicialmente los tiene en cuenta
        this.selected$.subscribe((selectedValues: any[]) => {
          if (selectedValues && selectedValues.length > 0) {
            this.valueSelected = selectedValues;
          }
        });
        this.componentRef.instance.onchange.subscribe((value: any[]) => {
          this.valueSelected = value;
        });
      }
    }

    // Filtro: Order By (Listado de resultados)
    if (this.config.name == 'filter-orderBy') {
      if (this.componentRef !== null) {
        // Enviar data a la variable "data" del child dinámico
        this.componentRef.instance.options = this.options;
        this.componentRef.instance.isMultipleSelection = false;
        this.componentRef.instance.selected$ = this.selected$;
        this.componentRef.instance.oneColumn = true;
        this.componentRef.instance.onchange.subscribe((value: any[]) => {
          this.valueSelected = value;
        });
      }
    }

    // Filtro: Precio (Listado de resultados)
    if (this.config.name == 'filter-price') {
      if (this.componentRef !== null) {
        // Enviar data a la variable "data" del child dinámico
        this.componentRef.instance.currencies = this.options;
        this.componentRef.instance.selected$ = this.selected$;
        // Escuchar el @Output "dataForParent" del child dinámico
        this.componentRef.instance.onchange.subscribe(
          (value: QrFilterPrice) => {
            this.valueSelected = value;
          }
        );
      }
    }
  }
}
