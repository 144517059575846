import { Component, Inject, Input, OnInit } from '@angular/core';
import { L10nLocale, L10N_LOCALE, L10nTranslatePipe } from 'angular-l10n';
import { Constants } from '@base/src/app/core/constants/constants';
import { environment } from '@base/environments/environment';
import {
  ButtonWithIconConfig,
  QrButtonWithIconComponent,
} from '@app/components/qr-button-with-icon/qr-button-with-icon.component';
import { QRMediaImage } from '@app/core/models/qr-media-img';
import { UtilsService } from '../../services/utils.service';
import { QrIconButtonComponent } from '../../components/qr-icon-button/qr-icon-button.component';
import { CommonModule } from '@angular/common';
import { QrMediaImgComponent } from '../../components/qr-media-img/qr-media-img.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    L10nTranslatePipe,
    QrButtonWithIconComponent,
    QrIconButtonComponent,
    CommonModule,
    QrMediaImgComponent,
  ],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: number = new Date().getFullYear();
  companyName = environment.companyName;
  node = environment.node;
  readonly imageCloudfront: string = environment.imageCloudfront;
  readonly imageCountry: string = environment.node;
  readonly imageCloudfrontAndCountry: string =
    this.imageCloudfront + '/' + this.imageCountry;

  // SVG Logo RE/MAX BALLOON
  logoREMAXBalloonSVG: QRMediaImage = {
    text: 'Logo RE/MAX Balloon',
    baseURL: '',
    docFolder: 'assets/logos',
    docName: 'logo-remax-balloon',
    docType: Constants.DOCTYPE_SVG,
    alt: 'Logo RE/MAX Balloon',
    width: '80px',
    height: '96px',
    loading: QRMediaImage.LOADING_EAGER,
    decoding: QRMediaImage.DECODING_ASYNC,
  };

  @Input() isCollection = false;

  // Botón: Red social
  configButtonSocialMedia = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  // Botón: Ver más (Encontrá más propiedades)
  viewMoreFindMoreProperties: ButtonWithIconConfig = {
    text: 'Ver más',
    icon: 'arrowDropDown',
    height: 32,
    style: 'button-color-grey-nero icon-color-grey no-horizontal-align',
    paddingHorizontal: 16,
    paddingVertical: 0,
  };

  // Botón: Ver más (Alquilar)
  viewMoreRent: ButtonWithIconConfig = {
    text: 'Ver más',
    icon: 'arrowDropDown',
    height: 32,
    style: 'button-color-grey-nero icon-color-grey no-horizontal-align',
    paddingHorizontal: 16,
    paddingVertical: 0,
  };

  // Botón: Ver más (Comprar)
  viewMoreBuy: ButtonWithIconConfig = {
    text: 'Ver más',
    icon: 'arrowDropDown',
    height: 32,
    style: 'button-color-grey-nero icon-color-grey no-horizontal-align',
    paddingHorizontal: 16,
    paddingVertical: 0,
  };

  // Relaciones institucionales
  configSwiperInstitutionalRelations = {
    navigation: {
      nextEl: '.swiper-button-next2',
      prevEl: '.swiper-button-prev2',
    },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slidesPerView: 'auto',
    spaceBetween: 56,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: {
      lazyLoadingInPrevNext: true,
      loadPrevNextAmount: 1,
      loadOnTransitionStart: true,
    },
  };

  institutionalRelationsURLS: string[] = [
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/camara-de-comercio-de-espana.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/investba.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/aamf.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/endeavor-argentina.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/national-association-of-realtors.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/sepyme.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/enbraci-2017.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/fundacion-florencio-perez.webp',
    this.imageCloudfrontAndCountry +
      '/assets/media/webp/home/relaciones-institucionales/embajada-eeuu-argentina.webp',
  ];

  urlFranchiseGlobal = UtilsService.urlFranchiseGlobal(this.node);

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    if (this.isCollection) {
      this.viewMoreFindMoreProperties.style =
        this.viewMoreFindMoreProperties.style.replace(
          'button-color-grey-nero',
          'button-color-remax-collection'
        );
      this.viewMoreRent.style = this.viewMoreRent.style.replace(
        'button-color-grey-nero',
        'button-color-remax-collection'
      );
      this.viewMoreBuy.style = this.viewMoreBuy.style.replace(
        'button-color-grey-nero',
        'button-color-remax-collection'
      );
      this.configButtonSocialMedia.style =
        Constants.BUTTON_COLOR_REMAX_COLLECTION;
    }
  }

  toggleViewLinksList(id: string): void {
    let button: ButtonWithIconConfig;
    switch (id) {
      case 'find-more-properties':
        button = this.viewMoreFindMoreProperties;
        break;
      case 'rent':
        button = this.viewMoreRent;
        break;

      case 'buy':
      default:
        button = this.viewMoreBuy;
        break;
    }
    const element = document.getElementById(id);
    if (!element) return;

    element.classList.toggle('expanded');
    const rotateIconClass = 'icon-rotate-180';
    if (!button.style.includes(rotateIconClass)) {
      button.text = 'Ver menos';
      button.style = button.style + ' ' + rotateIconClass;
    } else {
      button.text = 'Ver más';
      button.style = button.style.replace(rotateIconClass, '');
    }
  }
}
