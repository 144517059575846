import { Injectable } from '@angular/core';
import { HttpService } from '../core/services/http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class PrefooterService extends HttpService {
  constructor(http: HttpClient) {
    super(http, 'listings/prefooter/');
  }
}
