import { QRIcon } from './qr-icon.model';

export class QRDropdown {
  // Tamaños
  public static SIZE_56_PX = 56;
  size!: number;
  placeholder: string = '';
  options!: string[];
  icon?: QRIcon;
  isMultiple?: boolean = false;
  label?: string;
}
