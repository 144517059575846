<div id="qr-biography-container">
  <div id="title">
    <h2>{{ title | translate: locale.language | titlecase }}</h2>
  </div>
  <div id="bio" [ngClass]="{ 'full-height': showExtraDescription }">
    <p>{{ data | translate: locale.language }}</p>
  </div>
  @if (!showExtraDescription && !hideButton) {
    <div class="button-container">
      <qr-button-with-icon
        (click)="toggleExtraDescription()"
        [config]="button"
        id="view-more-button">
      </qr-button-with-icon>
    </div>
  }
</div>
