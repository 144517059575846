import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withFetch } from '@angular/common/http';
import {
  provideL10nIntl,
  provideL10nTranslation,
  provideL10nValidation,
} from 'angular-l10n';
import {
  l10nConfig,
  AppStorage,
  HttpTranslationLoader,
  LocaleValidation,
} from './l10n-config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideAnimationsAsync(),
    provideHttpClient(withFetch()),
    provideL10nTranslation(l10nConfig, {
      storage: AppStorage,
      translationLoader: HttpTranslationLoader,
    }),
    provideL10nIntl(),
    provideL10nValidation({ validation: LocaleValidation }),
  ],
};
