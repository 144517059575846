import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { Constants } from '../../../core/constants/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { AssociateService } from '../../../services/associate.service';
import { PrefooterService } from '../../../services/prefooter.service';
import { CommonModule, isPlatformBrowser, DOCUMENT } from '@angular/common';
import {
  DomSanitizer,
  SafeResourceUrl,
  TransferState,
  Title,
  Meta,
} from '@angular/platform-browser';
import { SearchDomain } from '@app/domain/search.domain';
import { environment } from '@base/environments/environment';
import { HttpClient } from '@angular/common/http';
import {
  PhotoPanorama,
  QrPhotoPanoramaComponent,
} from '@app/components/qr-photo-panorama/qr-photo-panorama.component';
import {
  L10nLocale,
  L10nTranslationService,
  L10N_LOCALE,
  L10nTranslationModule,
} from 'angular-l10n';
import { FavoritesService } from '@app/services/favorites.service';
import { Auth } from 'aws-amplify';
import {
  QrDialogComponent,
  QRDialogConfig,
} from '@app/components/qr-dialog/qr-dialog.component';
import { QRBanner } from '@app/core/models/qr-banner.model';
import { UtilsService } from '@app/services/utils.service';
import { Button } from '@app/core/models/button.model';
import { Icon } from '@app/core/models/icon.model';
import { BreadcrumbService } from '@app/services/breadcrumb.service';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { QrDisclaimerComponent } from '@app/components/qr-disclaimer/qr-disclaimer.component';
import { ButtonComponent } from '@app/components/ui/button/button.component';
import { QrCardPropComponent } from '@app/components/qr-card-prop/qr-card-prop.component';
import { QrCardInfoOfficeComponent } from '@app/components/qr-card-info-office/qr-card-info-office.component';
import { CardContactDetailsComponent } from '@app/components/card-contact-details/card-contact-details.component';
import { BannerLendarGarantorComponent } from '@app/components/banners/lendar-garantor/banner-lendar-garantor.component';
import { QrCardDetailsPropComponent } from '@app/components/qr-card-details-prop/qr-card-details-prop.component';
import { QrCardInfoPropComponent } from '@app/components/qr-card-info-prop/qr-card-info-prop.component';
import { QrVideoContainerComponent } from '@app/components/qr-video-container/qr-video-container.component';
import { QrPhotosDetailComponent } from '@app/components/qr-photos-detail/qr-photos-detail.component';
import { QrMultimediaButtonsComponent } from '@app/components/qr-multimedia-buttons/qr-multimedia-buttons.component';
import { QrIconButtonComponent } from '@app/components/qr-icon-button/qr-icon-button.component';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from '@app/directive/swiper.directive';
import { QrMapGoogleComponent } from '@app/components/qr-map-google/qr-map-google.component';
import { Listing } from '@base/src/app/core/models/listing.model';
import { Associate } from '@base/src/app/core/models/associate.model';
import { Subject, takeUntil } from 'rxjs';
import { QRBannerItau } from '@base/src/app/core/models/qr-banner-itau.model';
import { QrFilter } from '@base/src/app/core/models/QrFilter';
import { IQrFilterType } from '@base/src/app/core/enum/IQrFilterType';
import { FavoriteItem } from '@base/src/app/core/interfaces/favorite-item.interface';
import { FavoritesUtilsService } from '@base/src/app/services/favorites-utils.service';
import { ListingsByInternalIdService } from '@base/src/app/services/listing-by-internal-id.service';
import { ListingsBySlugService } from '@base/src/app/services/listing-by-slug.service';
import { QrFilterCustom } from '@base/src/app/core/models/QrFilterCustom';
import { SaveSearchComponent } from '@base/src/app/components/dialogs-content/search-save/search-save.component';
import { NotSaveSearchComponent } from '@base/src/app/components/dialogs-content/not-save-search/not-save-search.component';
import { IQrPage } from '@base/src/app/core/models/IQrPage';

@Component({
  selector: 'app-listing-detail',
  templateUrl: './listing-detail.page.html',
  styleUrls: ['./listing-detail.page.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    L10nTranslationModule,
    CommonModule,
    QrDialogComponent,
    QrDisclaimerComponent,
    ButtonComponent,
    QrCardPropComponent,
    QrCardInfoOfficeComponent,
    CardContactDetailsComponent,
    BannerLendarGarantorComponent,
    QrCardDetailsPropComponent,
    QrCardInfoPropComponent,
    QrPhotoPanoramaComponent,
    QrVideoContainerComponent,
    QrPhotosDetailComponent,
    QrMultimediaButtonsComponent,
    QrIconButtonComponent,
    SwiperDirective,
    QrMapGoogleComponent,
    SaveSearchComponent,
    NotSaveSearchComponent,
    QrIconButtonComponent,
  ],
})
export class ListingDetailPage implements OnInit, OnDestroy {
  public multimediaSelected: string = 'photo';
  public isBrowser: boolean;
  public nodeUruguay: string = Constants.NODE_URUGUAY;
  public wasFound: boolean = false;
  public isPropertyNotAvailable: number = 0;
  public placeholder: string = '';
  public isBannerLendarVisible: boolean = false;
  public listing!: Listing;
  public agent!: Associate;
  public isStaticMapModeEnabled: boolean = true;
  public staticMapHeight: number | undefined = 0;
  public sanitizeMapStaticURL: SafeResourceUrl | null = null;
  public sanitizeMapEmbedURL: SafeResourceUrl = '';
  public country: string = environment.node;
  public isForcedAgent: boolean = false;
  public isAgentLoading: boolean = true;
  public isPrefooterVisible = true;
  public isFavorite: boolean = false;
  public userLogged: boolean = false;

  readonly imageCloudfront: string = environment.imageCloudfront;
  readonly imageCountry: string = environment.node;

  configButtonExpand = {
    style: Constants.BUTTON_COLOR_GREY_BASIC,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  multimediaStyle = {
    photo: 'selected',
    video: 'unselected',
    p360: 'disabled',
    tour: 'disabled',
  };

  // BANNER ITAÚ
  bannerItau: QRBannerItau = {
    text: 'Itaú',
    docFolder: 'assets/media/svg/banners/itau',
    docName: 'logo-itau',
    docType: Constants.DOCTYPE_SVG,
    link: '',
    alt: 'Banner Itaú',
    className: QRBannerItau.CLASSNAME_BANNER_ITAU,
    height: '48',
    width: '48',
  };

  // BANNER LENDAR WEBM
  bannerLendar: QRBanner = {
    text: 'Lendar',
    docFolder: 'assets/media/svg/banners/lendar',
    docName: 'lendar-isologotipo',
    docType: Constants.DOCTYPE_SVG,
    link: 'https://www.lendar.com.ar/remax',
    alt: 'Banner Lendar',
    className: QRBanner.CLASSNAME_BANNER_LENDAR,
  };

  // BANNER GARANTOR WEBM
  bannerGarantor: QRBanner = {
    text: 'Garantor',
    docFolder: 'assets/media/svg/banners/garantor',
    docName: 'garantor-isologotipo',
    docType: Constants.DOCTYPE_SVG,
    link: 'https://garantor.com.ar/',
    alt: 'Banner Garantor',
    className: QRBanner.CLASSNAME_BANNER_GARANTOR,
  };

  photos360Config: PhotoPanorama = {
    isOpen: false,
    photos: [],
  };

  configButtonShere = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
  };

  configButtonFavorite = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    iconTooltipOnly: '',
  };

  dialogData: QRDialogConfig = {
    isDialogOpen: false,
    titleFilter: '¡Bienvenido!',
    configButtonLeft: {
      style: Constants.BUTTON_COLOR_PRIMARY_BASIC,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Cancelar',
      id: 'clean-filters',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    configButtonRight: {
      style: Constants.BUTTON_COLOR_PRIMARY_SOLID,
      height: Constants.BUTTON_HEIGHT_48PX,
      text: 'Crear mi cuenta',
      id: 'apply',
      selected: false,
      isDisabled: false,
      changeStyleOnSelected: false,
      styleOnSelected: '',
    },
    dinamicComponent: 'invitation-to-register',
    isTopbarVisible: true,
    isBotbarVisible: false,
  };

  operationType: string | undefined;
  filterOperation: string | undefined;

  OPERATION_TYPE_SALE = 'operation_type_sale';
  OPERATION_TYPE_RENT = 'operation_type_rent';
  OPERATION_TYPE_TEMPORAL = 'operation_type_temporal';
  OPERATION_TYPE_BUY = 'operation_type_buy';
  OPERATION_TYPE_RENT_TEMPORAL = 'operation_type_rent_temporal';

  isProduction = environment.production;

  relatedProperties: Listing[] = [];
  isRelatedPropertiesLoading = true;

  // PREFOOTER SWIPER
  swiperPrefooter = {
    navigation: {
      nextEl: '.swiper-button-next2',
      prevEl: '.swiper-button-prev2',
    },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slidesPerView: 4,
    grabCursor: true,
  };

  swiperConfig: SwiperOptions = {
    navigation: {
      nextEl: '.btn-nav--next',
      prevEl: '.btn-nav--prev',
    },
    watchSlidesProgress: true,
    slidesPerView: 'auto',
    speed: 400,
    grabCursor: true,
    spaceBetween: 16,
    breakpoints: {
      '600': {
        slidesPerView: 2,
      },
      '840': {
        slidesPerView: 3,
      },
    },
  };

  buttonLeftNavigationSwiper: Button = {
    id: 'btn-nav--left',
    hierarchy: Button.HIERARCHY_GREY_BORDER,
    size: Button.SIZE_48_PX,
    icon: Icon.NAME_ARROW_LEFT,
    iconOnly: true,
  };

  buttonRightNavigationSwiper: Button = {
    id: 'btn-nav--right',
    hierarchy: Button.HIERARCHY_GREY_BORDER,
    size: Button.SIZE_48_PX,
    icon: Icon.NAME_ARROW_RIGHT,
    iconOnly: true,
  };

  URL_SLUG: string = this.route.snapshot.params['slug']; // Slug de la URL ej: 'departamento-vicente-lopez-3-amb-balcon-cochera' BASE_URL/listings/SLUG
  URL_MLSID: string = this.route.snapshot.params['mlsid']; // MLSID de URL ej: 420851115-89, se puede navegar una propiedad mediante su MLS-ID BASE_URL/MLSID
  URL_ASSOCIATE_PARAMETER: string =
    this.route.snapshot.queryParams['associate']; // Parametro associate con el ID del agente

  private destroy$: Subject<void> = new Subject<void>();
  private replaceFolderFavorites: string = '';
  private favoriteResizedImageWebp: string = '';
  private url: string = '';
  private auctioneers: Associate[] = [];
  private slugToRedirect: string = '';
  private coords: any[] | undefined;
  private centerMap: any;
  private virtualTourLink: string | undefined;
  private dataToShare = { title: '', text: '', url: '' };

  constructor(
    private route: ActivatedRoute,
    private state: TransferState,
    private associateService: AssociateService,
    public searchDomain: SearchDomain,
    private titleService: Title,
    private metaTagService: Meta,
    private domSanitize: DomSanitizer,
    private http: HttpClient,
    private utils: UtilsService,
    private translation: L10nTranslationService,
    private router: Router,
    private favoritesService: FavoritesService,
    private favoritesUtilsService: FavoritesUtilsService,
    public utilsService: UtilsService,
    private prefooterService: PrefooterService,
    public breadcrumbService: BreadcrumbService,
    private renderer: Renderer2,
    private listingsByInternalIdService: ListingsByInternalIdService,
    private listingsBySlugService: ListingsBySlugService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    if (!this.listing) {
      // Server side
      if (this.URL_SLUG) {
        this.loadBySlug(this.URL_SLUG, this.URL_ASSOCIATE_PARAMETER);
      } else {
        this.loadByMLSID(this.URL_MLSID, this.URL_ASSOCIATE_PARAMETER);
      }
    } else {
      // Client/Browser side
      this.generateResources();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  generateMultimedia(): void {
    if (
      this.listing.photos != null &&
      this.listing.photos != undefined &&
      this.listing.photos.length > 0
    ) {
      // Fotos
      this.multimediaStyle.photo = 'selected';
    } else {
      this.multimediaStyle.photo = 'disabled';
    }

    if (
      this.listing.video != null &&
      this.listing.video != undefined &&
      this.listing.video.length > 0
    ) {
      // Video
      if (this.multimediaStyle.photo == 'disabled') {
        this.multimediaStyle.video = 'selected';
      } else {
        this.multimediaStyle.video = 'unselected';
      }
    } else {
      this.listing.video = '';
      this.multimediaStyle.video = 'disabled';
    }

    if (
      this.listing.virtualTours != null &&
      this.listing.virtualTours != undefined &&
      this.listing.virtualTours.length > 0
    ) {
      this.multimediaStyle.tour = 'unselected';
      if (this.listing.virtualTours.length > 1) {
        for (let index = 0; index < this.listing.virtualTours.length; index++) {
          if (this.listing.virtualTours[index].type != 'youtube') {
            this.virtualTourLink = this.listing.virtualTours[index].value;
          }
        }
      } else {
        this.virtualTourLink = this.listing.virtualTours[0].value;
      }
    } else {
      this.multimediaStyle.tour = 'disabled';
    }

    if (
      this.listing.photos360 != null &&
      this.listing.photos360 != undefined &&
      this.listing.photos360.length > 0
    ) {
      this.listing.photos360.forEach(s => {
        this.photos360Config.photos.push(
          environment.sitemapUrl + '/' + s.value
        );
      });
      this.multimediaStyle.p360 = 'unselected';
    } else {
      this.multimediaStyle.p360 = 'disabled';
    }
  }

  generateBrowserData(): void {
    if (this.listing) {
      this.dataToShare.title = this.listing.title ?? '';
      this.dataToShare.text = this.listing.description ?? '';
      this.dataToShare.url = this.url;
    }
  }

  multimediaSelect(event: string): void {
    if (event == '360') {
      this.photos360Config.isOpen = true;
      return;
    }
    this.multimediaSelected = event;
    if (this.multimediaSelected == 'tour' && this.virtualTourLink) {
      window.open(this.virtualTourLink, '_blank');
    }
  }

  /*
  backToListings: Volver al listado (Mobile)
  */
  backToListings(): void {
    if ('referrer' in document) {
      const urlDocumentReferrer: string = document.referrer; // URL anterior
      const urlLocationOrigin: string = window.location.origin; // URl actual base sin secciones o parametros
      if (urlDocumentReferrer.includes(urlLocationOrigin)) {
        /*
        * Si la URL anterior es una URL de RE/MAX deberia ser la de listado
        de resultados con los filtros, por lo que se usa esa para ir para atras
        */
        history.back();
      } else {
        /*
         * No hay una pagina para ir para atras que sea de RE/MAX.
         * Esto puede ser cuando se llega desde Google o entrando directamente al enlance
         * Por lo que se envia a una pagina de listado de resultados con el tipo de operación y tipo de propiedad
         */
        if (this.listing.geo) {
          window.location.href = this.breadcrumbService.urls.propertyType ?? '';
        } // Similar a clickear en desktop en el breadcrumb de tipo de propiedad
      }
    }
  }

  generatePropertyText(text: string): string {
    let t: string;
    if (text.includes('departamento')) {
      t = 'departamento';
    } else if (text.includes('casa')) {
      t = 'casa';
    } else {
      t = text;
    }
    return t;
  }

  /*
  generatePropertyIDs: Breadcrumbs (Desktop) sirve para cuando es del subtipo de Departamento o 
  Casa lograr que la URL tenga todos los ids del tipo padre y no solo del subtipo
  */
  generatePropertyIDs(id: number): string {
    let ids: string;
    if (id >= 1 && id <= 8) {
      // Subtipo de departamento
      ids = '1,2,3,4,5,6,7,8';
    } else if (id >= 9 && id <= 11) {
      ids = '9,10,11';
    } else {
      ids = id.toString();
    }
    return ids;
  }

  /*
  upperCaseFirstLetters: Transforma a mayúscula la primer letra de cada palabra de un string
  */
  upperCaseFirstLetters(s: string): string {
    return s.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
  }

  /*
  buildMapCoords: Se generan los datos de la marca donde esta la propiedad y el centrado del mapa dinámico/estático
  */
  buildMapCoords(): void {
    this.coords = [
      {
        lng: this.listing.location?.coordinates[0],
        lat: this.listing.location?.coordinates[1],
        id: this.listing.id,
      },
    ];
    this.centerMap = {
      lng: this.listing.location?.coordinates[0],
      lat: this.listing.location?.coordinates[1],
    };
  }

  /*
  getSanitizeMapStaticURL: Generar URL del mapa estático
  */
  getSanitizeMapStaticURL(): void {
    if (
      this.listing &&
      this.listing.location &&
      this.listing.location.coordinates[0] &&
      this.listing.location.coordinates[1]
    ) {
      const url: string =
        'https://maps.googleapis.com/maps/api/staticmap?size=800x400&maptype=roadmap' +
        '&markers=color:red|' +
        this.listing.location.coordinates[1] +
        ',' +
        this.listing.location.coordinates[0] +
        '&zoom=16&scale=2&key=' +
        environment.googleAPIKey;

      this.http
        .post('/signing-google-maps', {
          url: url,
        })
        .subscribe((res: any) => {
          this.sanitizeMapStaticURL =
            this.domSanitize.bypassSecurityTrustResourceUrl(res.url);
        });
    } else {
      console.log('Error: generating sanitizeMapStaticURL');
    }
  }

  /*
  enableEmbedMap: Ocultar mapa estático y mostrar mapa embed
  */
  enableEmbedMap(): void {
    this.isStaticMapModeEnabled = false;
    this.staticMapHeight =
      document.getElementById('map-static-img')?.offsetHeight; // Obtener altura del mapa estático que se le aplicara al mapa embed
  }

  /*
  getSanitizeMapURL: Generar URL del mapa embed
  */
  getSanitizeMapEmbedURL(): void {
    if (
      this.listing &&
      this.listing.location &&
      this.listing.location.coordinates[0] &&
      this.listing.location.coordinates[1]
    ) {
      this.sanitizeMapEmbedURL =
        this.domSanitize.bypassSecurityTrustResourceUrl(
          'https://www.google.com/maps/embed/v1/place?q=' +
            this.listing.location.coordinates[1] +
            ',' +
            this.listing.location.coordinates[0] +
            '&key=' +
            environment.googleAPIKey
        );
    } else {
      console.log('Error: generatin static map');
    }
  }

  setMetaTags(data: Listing): void {
    this.generateCanonicalUrl();
    let titleFormat =
      (this.translation.translate('property-type.' + data.type.value) ||
        data.type.value) +
      ' en ' +
      (this.translation.translate('operations.' + data.operation?.value) ||
        data.operation?.value) +
      ' ' +
      data.totalRooms +
      [data.totalRooms! > 1 ? ' ambientes' : ' ambiente'] +
      [data.displayAddress ? ' en ' + data.displayAddress : ''] +
      [data.geo && data.geo.label ? ', ' + data.geo.label : ''];

    //Especificación para SEO
    if (data.type.value == Constants.PROPERTY_TYPE_DEPARTAMENTO_ESTANDAR) {
      titleFormat = titleFormat.replace(' estandar', '');
    }

    const descriptionFormat = `${data.description?.substring(0, 151)}...`;

    this.titleService.setTitle(titleFormat);
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Mirá esta propiedad en RE/MAX: ' + descriptionFormat,
    });

    if (data.photos && data.photos.length > 0) {
      this.metaTagService.updateTag({
        property: 'og:image',
        content: this.utils.generateImageWithSize(
          data.photos[0].value,
          '360x200'
        ),
      });
    }
    this.metaTagService.updateTag({
      property: 'og:title',
      content: titleFormat,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: 'Mirá esta propiedad en RE/MAX: ' + descriptionFormat,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: this.document.location.href,
    });
    if (data.geo && data.geo.label) {
      this.metaTagService.updateTag({
        name: 'keywords',
        content:
          data.title + ', ' + data.displayAddress + ', ' + data.geo.label,
      });
    } else {
      this.metaTagService.updateTag({
        name: 'keywords',
        content: data.title + ', ' + data.displayAddress,
      });
    }
  }

  generateCanonicalUrl(): void {
    let countryDomain = '';
    let URL = '';

    switch (environment.node) {
      case Constants.NODE_URUGUAY:
        countryDomain = 'uy';
        break;
      case Constants.NODE_ECUADOR:
        countryDomain = 'ec';
        break;
      default:
        countryDomain = 'ar';
        break;
    }

    if (this.route.snapshot.params['slug']) {
      // URL Slug
      URL =
        'https://www.remax.com.' +
        countryDomain +
        '/listings/' +
        this.route.snapshot.params?.['slug'];
    } else if (this.route.snapshot.params?.['mlsid']) {
      // URL MLS-ID
      URL =
        'https://www.remax.com.' +
        countryDomain +
        '/' +
        this.route.snapshot.params['mlsid'];
    }
    this.updateCanonicalUrl(URL);
  }

  updateCanonicalUrl(url: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    let element: any =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }

  public addFavorite(): void {
    if (this.userLogged) {
      this.isFavorite = !this.isFavorite;
      this.updateFavoriteButtonConfig();

      if (this.isFavorite) {
        this.resizeFavoritePhoto();

        const FAVORITE_ITEM: FavoriteItem = {
          listingId: this.listing.id ?? '',
          photo: this.favoriteResizedImageWebp,
          slug: this.listing.slug ?? '',
          title: this.createFavoriteTitle(),
          address: this.listing.displayAddress ?? '',
          currency: this.listing.currency?.value ?? '',
          price: this.listing.price ?? 0,
        };

        this.favoritesService.addFavorite(FAVORITE_ITEM);
      } else {
        const FAVORITES = {
          listingIds: [this.listing.id],
        };
        this.favoritesService.deleteFavoritesByListingId(FAVORITES);
      }
    } else {
      this.dialogData = { ...this.dialogData, isDialogOpen: true };
      this.dialogData = Object.assign({}, this.dialogData);
    }
  }

  generateBanners(): void {
    if (this.listing.operation?.value == 'sale') {
      this.operationType = this.OPERATION_TYPE_SALE;
      this.isBannerLendarVisible = this.listing.showLendarBanner ?? false;
    } else if (this.listing.operation?.value == 'rent') {
      this.operationType = this.OPERATION_TYPE_RENT;
      this.isBannerLendarVisible = this.listing.showLendarBanner ?? false;
    } else {
      this.operationType = this.OPERATION_TYPE_TEMPORAL;
    }
  }

  generatePrefooter(): void {
    // const newUbication = this.listing.geo?.slug; // nueva ubicación por slug

    const coordinatesLatitud = this.listing.location?.coordinates[0];
    const coordinatesLongitud = this.listing.location?.coordinates[1];
    const radioProperty = '3000';

    const NEW_UBICATION =
      'withinradius-' +
      coordinatesLatitud +
      ':' +
      coordinatesLongitud +
      ':' +
      radioProperty;

    const listingType = this.listing.type.value.replace('_', '-');

    let typeOperation = '';
    switch (this.listing.operation?.value) {
      case 'sale':
        typeOperation = 'venta';
        break;
      case 'rent':
        typeOperation = 'alquiler';
        break;
      default:
        typeOperation = 'alquiler-temporario';
    }

    // Tomamos la cantidad de ambientes de la propiedad
    const totalRoomSwitch = this.listing.totalRooms?.toString();
    let totalRoomsFilter = '';
    switch (totalRoomSwitch) {
      case '1':
        totalRoomsFilter = 'de-1-ambiente-de-2-ambientes';
        break;
      case '2':
        totalRoomsFilter = 'de-1-ambiente-de-2-ambientes-de-3-ambientes';
        break;
      case '3':
        totalRoomsFilter = 'de-2-ambientes-de-3-ambientes-de-4-ambientes';
        break;
      case '4':
        totalRoomsFilter = 'de-3-ambientes-de-4-ambientes-de-5-ambientes';
        break;
      case '5':
        totalRoomsFilter = 'de-4-ambientes-de-5-ambientes-de-mas-6-ambientes';
        break;
      default:
        if (totalRoomSwitch != '0') {
          totalRoomsFilter = 'de-mas-6-ambientes';
        }
        break;
    }

    // Tomamos el precio de la propiedad
    const CURRENCY_PRICE = this.listing.currency?.value.toLocaleLowerCase();
    const ACTUAL_PRICE = this.listing.price ?? 0;

    const LESS_PRICE = ACTUAL_PRICE * 0.85;
    const MORE_PRICE = ACTUAL_PRICE * 1.15;

    const LESS_PRICE_ROUNDED = Math.ceil(LESS_PRICE);
    const MORE_PRICE_ROUNDED = Math.ceil(MORE_PRICE);

    let prefooterResultService =
      listingType +
      '-en-' +
      typeOperation +
      '-' +
      totalRoomsFilter +
      '-de-precio-' +
      CURRENCY_PRICE +
      '-' +
      LESS_PRICE_ROUNDED +
      '-' +
      MORE_PRICE_ROUNDED;

    // Sanitizamos la url y reemplazamos los caracteres especiales por "guiones medios"
    prefooterResultService = prefooterResultService.replace(/ /g, '-');
    prefooterResultService = prefooterResultService.replace(
      // eslint-disable-next-line no-useless-escape
      /[ !@#$%^&*()_+{}\[\];<>,?~\\\/'"`|]/g,
      '-'
    );
    prefooterResultService = prefooterResultService.replace(/--/g, '-');
    prefooterResultService = prefooterResultService.replace(/-y-/g, '-'); // Terrenos y lotes
    prefooterResultService += '-en-' + NEW_UBICATION;

    this.prefooterService
      .get(prefooterResultService)
      .then(response => {
        this.relatedProperties = response.data.data;
        const elementoAEliminar = this.route.snapshot.params['slug']; // Supongamos que deseas eliminar el elemento con valor 40
        this.relatedProperties = this.relatedProperties.filter(
          objeto => objeto.slug !== elementoAEliminar
        );
        this.isRelatedPropertiesLoading = false;
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleBreadcrumb(): void {
    this.breadcrumbService.publication = this.listing;
    this.breadcrumbService.publicationType =
      Constants.PUBLICATION_TYPE_LISTINGS;
    this.breadcrumbService.generateAllURLs().then(() => {
      const script = this.renderer.createElement('script');
      script.type = 'application/ld+json';
      script.setAttribute('id', BreadcrumbService.SEO_BREADCRUMB_SCRIPT_ID);
      script.textContent = this.breadcrumbService.scriptCode;
      this.renderer.appendChild(this.document.head, script);
    });
  }

  private generateResources(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.handleSlugURL();
      this.setMetaTags(this.listing);
      this.getSanitizeMapStaticURL();
      this.getSanitizeMapEmbedURL();
      this.generateBrowserData();
      this.generateMultimedia();
      this.generateBanners();
      this.loadFavorites();
      this.generatePrefooter();
      this.handleBreadcrumb();
    }
  }

  private handleSlugURL(): void {
    if (!this.URL_SLUG && this.slugToRedirect) {
      this.utilsService.updateURLWithSlug(
        this.slugToRedirect,
        this.URL_ASSOCIATE_PARAMETER
      );
    }
  }

  private loadFavorites(): void {
    if (
      this.country == Constants.NODE_ARGENTINA ||
      this.country == Constants.NODE_URUGUAY
    ) {
      // Mostrar botón de favoritos solo si está logueado
      Auth.currentSession()
        .then(() => {
          this.userLogged = true;
          // Carga si es favorito o no
          if (this.userLogged) {
            const FILTER: QrFilter = new QrFilter(0, 99999, [], []);
            const LISTING_FILTER: QrFilterCustom = new QrFilterCustom(
              IQrFilterType.eq,
              IQrFilterType.listingsIds,
              this.listing.id
            );
            FILTER.addFilter(LISTING_FILTER);
            const QUERY: string = FILTER.toQueryStringBe();
            this.favoritesUtilsService
              .getParams(QUERY)
              .pipe(takeUntil(this.destroy$))
              .subscribe({
                next: (response: IQrResponse<IQrPage>) => {
                  if (response?.data?.data && response.data.data.length > 0) {
                    response.data.data.forEach(e => {
                      if (e.listingId.includes(this.listing.id)) {
                        this.isFavorite = true;
                        this.updateFavoriteButtonStyle();
                      }
                    });
                  }
                },
                error: (error: any) => {
                  console.error('Error occurred:', error);
                },
              } as any);
          }
        })
        .catch(error => {
          this.userLogged = false;
          console.error(error);
        });
    }
  }

  private updateFavoriteButtonStyle(): void {
    this.configButtonFavorite = {
      ...this.configButtonFavorite,
      style: this.isFavorite
        ? Constants.BUTTON_COLOR_PRIMARY_SOLID
        : Constants.BUTTON_COLOR_GREY_BORDER,
    };
  }

  private loadByMLSID(mlsid: string, customAssociate: string): void {
    this.listingsByInternalIdService
      .getData<Listing>(mlsid)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (result: IQrResponse<Listing>) => {
          if (result.code === 200) {
            this.slugToRedirect = result.data.slug ?? '';
            this.processListingData(result.data, customAssociate);
          } else if (result.code === 500) {
            this.router.navigateByUrl('/');
          } else {
            console.warn(result);
          }
        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
      });
  }

  private loadBySlug(slug: string, customAssociate: string): void {
    this.listingsBySlugService
      .getData<Listing>(slug)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (resultListing: IQrResponse<Listing>) => {
          switch (resultListing.code) {
            case 200:
              this.processListingData(resultListing.data, customAssociate);
              break;
            case 500:
              this.router.navigateByUrl('/');
              break;
            case 601:
              this.isPropertyNotAvailable = 601; // Venta de propiedad ya no disponible
              break;
            case 602:
              this.isPropertyNotAvailable = 602; // Alquiler de propiedad ya no disponible
              break;
            case 603:
              this.isPropertyNotAvailable = 603; // Alquiler temporario de propiedad ya no disponible
              break;
            default:
              console.warn(resultListing);
              break;
          }
        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
      });
  }

  private processListingData(listing: Listing, customAssociate: string) {
    this.listing = listing;
    this.setMetaTags(listing);
    this.wasFound = true;
    if (customAssociate != null) {
      this._fetchDataAgentByInternalId(customAssociate);
    } else {
      this.agent = { ...listing.associate! };
      this._fetchDataAuctioneer(listing.associate!);
      this.isAgentLoading = false;
    }
    this.generateResources();
  }

  private async _fetchDataAgentByInternalId(customAssociateInternalId: string) {
    try {
      const result: IQrResponse<Associate> =
        await this.associateService.findByInternalId(customAssociateInternalId);
      if (result.code == 200) {
        this.isForcedAgent = true;
        this.agent = { ...result.data };
        this.isAgentLoading = false;
        this._fetchDataAuctioneer(this.agent);
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private _fetchDataAuctioneer(agent: Associate) {
    if (!this.auctioneers) {
      this.loadAuctioneerInfo(agent.office.id);
    }
  }

  private async loadAuctioneerInfo(id: string): Promise<void> {
    try {
      const result: IQrResponse<Associate[]> =
        await this.associateService.findByIdAuctioneerOffice(id);
      if (result.code == 200) {
        this.auctioneers = result.data;

        /*
         * Se tomó la decisión desde el negocio en la cual si es USER ROLE = STAFF se reemplaze como AGENT
         */

        for (let i = 0; i < this.auctioneers.length; i++) {
          const auctioneer = this.auctioneers[i];
          if (auctioneer.role == Constants.USER_ROLE_STAFF) {
            auctioneer.role = Constants.USER_ROLE_AGENT;
          }
        }

        const index = this.auctioneers.indexOf(
          this.auctioneers.find(e => e.id === this.agent.id) as Associate
        );
        if (index > -1) {
          this.auctioneers.splice(index, 1);
        }
        this.isAgentLoading = false;
      } else {
        console.warn(result);
      }
    } catch (error) {
      console.error(error);
    }
  }

  private resizeFavoritePhoto(): void {
    const PHOTO: string =
      this.listing.photos && this.listing.photos.length > 0
        ? this.listing.photos[0].value
        : 'empty';

    this.replaceFolderFavorites = this.utilsService.generateImageWithSize(
      PHOTO,
      '160x120'
    );

    const FOLDER_COMBINED_FAVORITES_AND_LISTINGS =
      Constants.FOLDER_FAVORITES + '/' + Constants.FOLDER_LISTINGS; // Creamos una constante que diga 'favorites/listings'
    this.favoriteResizedImageWebp = this.replaceFolderFavorites.replace(
      Constants.FOLDER_LISTINGS,
      FOLDER_COMBINED_FAVORITES_AND_LISTINGS
    );
  }

  private updateFavoriteButtonConfig(): void {
    this.configButtonFavorite = {
      ...this.configButtonFavorite,
      style: this.isFavorite
        ? Constants.BUTTON_COLOR_PRIMARY_SOLID
        : Constants.BUTTON_COLOR_GREY_BORDER,
      iconTooltipOnly: this.isFavorite
        ? 'Quitar de favoritos'
        : 'Agregar a favoritos',
    };
  }

  private createFavoriteTitle(): string {
    return `${this.translation.translate(
      'property-type.' + this.listing.type.value
    )} 
            ${this.translation.translate('property-type.for')} 
            ${this.translation.translate(
              'operations.' + this.listing.operation?.value
            )} 
            ${this.translation.translate('listing-detail.en')} 
            ${this.translation.translate(
              this.listing.displayAddress + ', ' + this.listing.addressInfo
            )}`;
  }
}
