<div class="contact-container">
  <div class="contact-wrapper">
    @if (!isFormCorrectlySent) {
      <div class="contact-wrapper__title">
        <h2 class="contact-wrapper__heading">
          {{ 'sell.title' | translate: locale.language }}
        </h2>
        <p class="contact-wrapper__description mat-typography">
          {{ 'sell.subtitle' | translate: locale.language }}
        </p>
      </div>

      <div class="contact-wrapper__form-container">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="contact-form">
          <!-- Nombre y apellido -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Nombre y apellido</mat-label>
            <input
              matInput
              formControlName="fullName"
              required
              name="fullName"
              (keydown)="inputKeydown($event, 'fullName', 120)"
              (paste)="inputPaste($event, 'fullName', 120)" />
            @if (fullName.invalid) {
              <mat-error>{{ getErrorMessage('fullName') }}</mat-error>
            }
          </mat-form-field>
          <!-- Email -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Email</mat-label>
            <input
              type="email"
              matInput
              formControlName="email"
              required
              name="email"
              (keydown)="inputKeydown($event, 'email', 120)"
              (paste)="inputPaste($event, 'email', 120)" />
            @if (email.invalid) {
              <mat-error>{{ getErrorMessage('email') }}</mat-error>
            }
          </mat-form-field>
          <!-- Teléfono -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Teléfono</mat-label>
            <input
              type="text"
              matInput
              formControlName="phone"
              required
              name="phone"
              (input)="inputPhoneChange()"
              (keydown)="inputPhoneKeydown($event)"
              (focusout)="inputPhoneFocusOut()" />
            @if (phone.invalid) {
              <mat-error>{{ getErrorMessage('phone') }}</mat-error>
            }
            @if (this.node !== this.constantsEc) {
              <mat-hint
                >Ingrese un N° con código de área. Ej: 112345678 (CABA) o
                3511234567 (Cdad. de Córdoba)</mat-hint
              >
            } @else {
              <mat-hint
                >Ingrese un N° completo con código de área. Ej: (4)
                1234567</mat-hint
              >
            }
          </mat-form-field>
          <!-- Ubicación -->
          <!-- Argentina - Uruguay  -->

          @defer (on idle) {
            @if (this.node !== this.constantsEc) {
              <qr-google-autocomplete
                formControlName="ubication"
                placeholder="Localidad/provincia"
                [required]="true"
                [errorText]="
                  getErrorMessage('ubication')
                "></qr-google-autocomplete>
            } @else {
              <!-- Ecuador -->
              <qr-google-autocomplete
                formControlName="ubication"
                placeholder="Parroquia/Cantón"
                [required]="true"
                [errorText]="
                  getErrorMessage('ubication')
                "></qr-google-autocomplete>
            }
          }
          <!-- Tipo de propiedad -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Tipo de propiedad</mat-label>
            <mat-select
              formControlName="propertyType"
              placeholder="Tipo de propiedad"
              required>
              @for (pType of propertyTypesSell; track pType.value) {
                <mat-option [value]="pType">
                  {{
                    'property-type.' + pType.value | translate: locale.language
                  }}
                </mat-option>
              }
            </mat-select>
            @if (propertyType.invalid) {
              <mat-error>{{ getErrorMessage('propertyType') }}</mat-error>
            }
          </mat-form-field>
          <!-- Ambientes -->
          @if (isRoomsInputsVisible) {
            <mat-form-field class="contact-form__field" appearance="outline">
              <mat-label>Ambientes</mat-label>
              <mat-select formControlName="rooms" placeholder="Ambientes">
                @for (room of roomsTypes; track room.value) {
                  <mat-option [value]="room">
                    {{
                      'rooms-type.' + room.value | translate: locale.language
                    }}
                  </mat-option>
                }
              </mat-select>
              @if (rooms.invalid) {
                <mat-error>{{ getErrorMessage('rooms') }}</mat-error>
              }
            </mat-form-field>
          }
          <!-- Comentarios -->
          <mat-form-field class="contact-form__field" appearance="outline">
            <mat-label>Comentarios</mat-label>
            <textarea
              matInput
              formControlName="description"
              name="description"
              id="description"
              cols="430"
              rows="5"
              maxlength="255"
              (keydown)="inputKeydown($event, 'description', 250)"
              (paste)="inputPaste($event, 'description', 250)"></textarea>
            <mat-hint>{{
              this.form.get('description')?.value.toString().length + '/250'
            }}</mat-hint>
          </mat-form-field>
          <!-- Botón: Enviar -->
          <div class="contact-form__button-container">
            <qr-button [config]="this.configButtonSend"></qr-button>
          </div>
          <!-- Disclaimer: Terminos y condiciones -->
          <div class="contact-form__terms-and-conditions">
            <p>
              Al enviar se están aceptando los
              <a href="/terminos-y-condiciones" target="blank"
                >Términos y Condiciones de Uso</a
              >
              y la
              <a href="/politica-de-privacidad" target="blank"
                >Política de Privacidad</a
              >
            </p>
          </div>
        </form>
      </div>
    } @else {
      <!-- Formulario: enviado correctamente -->
      <div class="contact-wrapper__form-sent">
        <div class="contact-wrapper__check">
          <div class="contact-wrapper__img"></div>
        </div>
        <p>{{ 'sell.form-correctly-sent' | translate: locale.language }}</p>
      </div>
    }
  </div>
  <div class="contact-container__img">
    <div id="img" [class]="getHeroClassRegion()"></div>
  </div>
</div>
