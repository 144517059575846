import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
} from '@angular/core';
import {
  PhotosFullScreen,
  QrPhotosFullscreenComponent,
} from '../qr-photos-fullscreen/qr-photos-fullscreen.component';
import { environment } from '@base/environments/environment';
import { Constants } from '@base/src/app/core/constants/constants';
import { UtilsService } from '@app/services/utils.service';
import { QrSkeletonLoaderComponent } from '../qr-skeleton-loader/qr-skeleton-loader.component';
import { SwiperDirective } from '../../directive/swiper.directive';
import { SwiperOptions } from 'swiper/types';
import { imageFix } from '../../core/models/image-fix.model';
import { ResizedImage } from '../../core/models/resized-photo.model';
import { ListingPhoto } from '../../core/models/listing-photo.model';
import { SkeletonLoaderComponent } from '../ui/skeleton-loader/skeleton-loader.component';

@Component({
  selector: 'qr-photos-detail',
  templateUrl: './qr-photos-detail.component.html',
  styleUrls: ['./qr-photos-detail.component.scss'],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
  imports: [
    QrSkeletonLoaderComponent,
    QrPhotosFullscreenComponent,
    SwiperDirective,
    SkeletonLoaderComponent,
  ],
})
export class QrPhotosDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('photosSwiper') public photosSwiper?: ElementRef;
  @ViewChild('thumbnailSwiper') public thumbnailsSwiper?: ElementRef;
  @Input() photos: ListingPhoto[] | undefined = [];
  public resizedImages: ResizedImage[] = [];
  public readonly SWIPER_PHOTOS: SwiperOptions = {
    spaceBetween: 0,
    centeredSlides: true,
    centeredSlidesBounds: true,
    navigation: {
      nextEl: '.swiper__next',
      prevEl: '.swiper__prev',
    },
    watchSlidesProgress: true,
    slidesPerView: 1,
    speed: 800,
    grabCursor: true,
  };

  public readonly SWIPER_THUMBNAIL: SwiperOptions = {
    spaceBetween: 10,
    slidesPerView: 3,
    watchSlidesProgress: true,
    direction: 'vertical',
    speed: 800,
  };

  public configPhotosFullscreen: PhotosFullScreen = {
    isOpen: false,
    indexSelected: 0,
  };

  constructor(private utils: UtilsService) {}

  ngOnInit(): void {
    this.resizePhotos();
  }

  ngAfterViewInit(): void {
    if (this.photos && this.photos.length > 0) {
      this.galleryIndexChange(this.configPhotosFullscreen.indexSelected);
    }
  }

  /*
  galleryIndexChange: Notifica a que index se movio el gallery y mueve el
  swiper de thumbnails a ese index
  */
  public galleryIndexChange(indexToMove: number) {
    if (this.thumbnailsSwiper !== undefined) {
      this.configPhotosFullscreen.indexSelected = indexToMove;

      this.thumbnailsSwiper.nativeElement.swiper.slideTo(
        this.configPhotosFullscreen.indexSelected
      );
      this.updateSelectedThumbnail();
    }
  }

  public thumbnailsIndexChange(indexToMove: number) {
    this.configPhotosFullscreen.indexSelected = indexToMove;
    this.photosSwiper?.nativeElement.swiper.slideTo(
      this.configPhotosFullscreen.indexSelected
    );
    this.updateSelectedThumbnail();
  }

  /*
  slideTo: Se ejecuta al clickear una thumbnails y mueve el swiper de gallery
  a ese index
  */
  public slideTo(indexToMove: number) {
    this.configPhotosFullscreen.indexSelected = indexToMove;
    this.photosSwiper?.nativeElement.swiper.slideTo(
      this.configPhotosFullscreen.indexSelected
    );
    this.updateSelectedThumbnail();
  }

  public openFullscreen(): void {
    this.configPhotosFullscreen = {
      isOpen: true,
      indexSelected: this.configPhotosFullscreen.indexSelected,
    };
  }

  public photosFullscreenChanged(e: number) {
    this.configPhotosFullscreen.indexSelected = e;
    this.galleryIndexChange(this.configPhotosFullscreen.indexSelected);
    setTimeout(() => {
      const THUMBNAIL_SELECTED = document.getElementById(
        'thumbnail-' + this.configPhotosFullscreen.indexSelected
      );
      if (THUMBNAIL_SELECTED) THUMBNAIL_SELECTED.classList.add('selected');
    }, 100);
  }

  public handleImageError(imgElement: any) {
    imgElement.src = imageFix.property; // Replace 'fallback.jpg' with your actual fallback image URL or an empty string to remove the image
  }

  /*
  updateSelectedThumbnail: Actualiza el estilo de la thumbnail seleccionada
  */
  private updateSelectedThumbnail(): void {
    const ELEMENTS = Array.from(document.querySelectorAll('.selected'));
    for (const element of ELEMENTS) {
      element.classList.remove('selected');
    }
    const THUMBNAIL_SELECTED = document.getElementById(
      'thumbnail-' + this.configPhotosFullscreen.indexSelected
    );
    if (THUMBNAIL_SELECTED) THUMBNAIL_SELECTED.classList.add('selected');
  }

  private resizePhotos(): void {
    if (this.photos) {
      const isWatermarkAdded: boolean =
        environment.node == Constants.NODE_ECUADOR;
      this.resizedImages = this.photos.map((photo: ListingPhoto) => {
        return {
          jpgXS: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.SMALL,
            Constants.DOCTYPE_JPG,
            isWatermarkAdded
          ),
          webpXS: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.SMALL,
            Constants.DOCTYPE_WEBP,
            isWatermarkAdded
          ),
          jpgS: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.LARGE,
            Constants.DOCTYPE_JPG,
            isWatermarkAdded
          ),
          webpS: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.LARGE,
            Constants.DOCTYPE_WEBP,
            isWatermarkAdded
          ),
          jpgL: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.LARGE,
            Constants.DOCTYPE_JPG,
            isWatermarkAdded
          ),
          webpL: this.utils.generateImageWithSize(
            photo.rawValue,
            ResizedImage.LARGE,
            Constants.DOCTYPE_WEBP,
            isWatermarkAdded
          ),
        };
      });
    }
  }
}
