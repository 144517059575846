<div id="agent-detail-container">
  <div id="banner">
    <div id="banner-photo"></div>
  </div>
  @if (associate) {
  <div class="container">
    <div class="container-desktop">
      <!-- Agente -->

      <qr-card-info-agent id="agent" [data]="associate"></qr-card-info-agent>

      <!-- Corredor público inmobiliario -->
      @if (node!=='ec') { @if (auctioneers && auctioneers.length > 0) {
      <div>
        <h2 id="broker-title">
          {{ 'agentDetail.associateOffice' | translate:locale.language |
          titlecase }}
        </h2>
        @if (auctioneers.length > 1) {
        <div id="broker-card-desktop">
          <swiper-container
            [config]="configSwiperFeatured"
            appSwiper
            init="false">
            @for (item of auctioneers; track item) {
            <swiper-slide>
              <a
                id="agent-link"
                [href]="'/' + item.role + '/' + item.slug"
                role="button">
                <qr-card-info-broker
                  [data]="item"
                  [isImgTop]="true"></qr-card-info-broker>
              </a>
            </swiper-slide>
            }
          </swiper-container>
          <!-- Navegación de swiper -->
          <div class="button-prev" matRipple></div>
          <div class="button-next" matRipple></div>
        </div>
        } @else { @if (auctioneers.length > 0) {
        <div class="broker-card">
          @for (item of auctioneers; track item) {
          <a
            id="agent-link"
            [href]="'/' + item.role + '/' + item.slug"
            role="button">
            <qr-card-info-broker
              [data]="auctioneers[0]"
              [isImgTop]="true"></qr-card-info-broker>
          </a>
          }
        </div>
        } }
      </div>
      } }
      <!-- Disclaimer -->
      @if (node === 'ar') {
      <qr-biography-card
        title="agentDetail.disclaimer"
        data="disclaimer.agent"
        [hideButton]="true"
        class="bio-container"></qr-biography-card>
      }
      <!-- Biografía (Uruguay) -->
      @if (associate.biography && node === 'uy') {
      <qr-biography-card
        title="agentDetail.biography"
        [data]="associate.biography"
        [hideButton]="true"
        class="bio-container"></qr-biography-card>
      } @if (associate.certificates) {
      <div class="col-padding certificates-container">
        <qr-certificates-card
          [certificates]="associate.certificates"></qr-certificates-card>
      </div>
      }
    </div>
    <!-- Listado de propiedades -->
    <div id="profile-content">
      @if (!isOfficeProperties) {
      <!-- Listado de propiedades -->
      <div id="tabs">
        <qr-tabs
          [config]="tabWithIcon"
          (tabChange)="tabChange($event)"></qr-tabs>
      </div>
      <h4 id="profile-content-subtitle">
        {{ totalItems }} {{ tabWithIcon.tabSelected === 0 ? (totalItems === 1 ?
        'propiedad publicada' : 'propiedades publicadas') : (totalItems === 1 ?
        'emprendimiento publicado' : 'emprendimientos publicados') }}
      </h4>
      } @if (isOfficeProperties) {
      <!-- Propiedades de oficina -->
      <h3 id="profile-content-title">
        Propiedades de {{ associate.office.name }}
      </h3>
      <h4 id="profile-content-subtitle">
        {{ this.totalItems }} publicadas por la oficina
      </h4>
      } @if (!isListingsAndEntrepreneurshipLoading) {
      <div id="cards-props">
        @for (prop of listingsAndEntrepreneurship; track prop; let i = $index) {
        <qr-card-property
          [data]="prop"
          [isImageSwiper]="true"
          [isViewList]="searchDomain.viewMode === LISTINGS_VIEW_MODE_GRID"></qr-card-property>
        }
      </div>
      } @else {
      <div class="loader">
        @for (card of [0, 1, 2, 3, 4, 5]; track card) {
        <skeleton-loader class="loader__card"></skeleton-loader>
        }
      </div>
      }
      <ng-template #skeletonLoader>
        <div class="loader">
          @for (card of [0, 1, 2, 3, 4, 5]; track card) {
          <skeleton-loader class="loader__card"></skeleton-loader>
          }
        </div>
      </ng-template>
      <!-- Botón: Ver más -->
      @if (!isOfficeProperties) { @if (actualPage < (totalPages - 1)) {
      <qr-button
        id="view-more"
        [config]="configButtonViewMore"
        (eventClick)="viewMore()">
      </qr-button>
      } } @if (isOfficeProperties) {
      <div id="properties-view-more">
        <qr-button
          [config]="configButtonViewMoreProperties"
          (eventClick)="goToListingByOfficeid()"></qr-button>
      </div>
      }
    </div>
  </div>
  }
</div>
