@if (this.config?.isOpen) {
  <div id="container">
    <!-- Botón: Cerrar -->
    <qr-icon-button
      (click)="close()"
      icon="close"
      [config]="this.configButtonClose"
      id="close"
      matTooltip="Cerrar">
    </qr-icon-button>
    <!-- Galería de fotos -->
    <div id="swiper-container">
      <div id="swiper-gallery-wrapper">
        <swiper-container
          appSwiper
          init="false"
          [config]="swiperConfig"
          (indexChange)="galleryIndexChange($event)">
          @for (photo of this.config?.photos; track photo) {
            <swiper-slide>
              <iframe
                width="100%"
                height="100%"
                allowfullscreen
                style="border-style: none"
                [src]="this.sanitizeURL(photo)"></iframe>
            </swiper-slide>
          }
        </swiper-container>
      </div>
    </div>
    <!-- Paginación -->
    <div id="pagination">
      <p>{{ this.indexSelected + 1 }}/{{ this.config?.photos?.length }}</p>
    </div>
    <!-- Navegación de swiper -->
    <div id="prev-container">
      <div class="prev" matRipple matTooltip="Anterior"></div>
    </div>
    <div id="next-container">
      <div class="next" matRipple matTooltip="Siguiente"></div>
    </div>
  </div>
}
