<qr-dialog [data]="this.dialogData"> </qr-dialog>

<div id="more-than-moving-container" fxFlex="100">
  <div id="img-title">
    <h1>Mucho más que mudarte</h1>
  </div>
  <div id="title">
    <h1>
      En RE/MAX estamos trabajando para que, desde casa, podamos seguir
      alcanzando sueños juntos.
    </h1>
  </div>
  <div class="card-container mobile">
    <qr-card-information
      [data]="dataPublishProp"
      (click)="this.openFilters('publish-prop')"></qr-card-information>
  </div>

  <div class="card-container mobile">
    <qr-card-information
      [data]="dataDigitalFirm"
      (click)="this.openDigitalFirm()"></qr-card-information>
  </div>

  <div class="card-wrapper-desktop">
    <div class="info-card">
      <div class="padding-60">
        <h1>Publicá tu Propiedad</h1>
        <h2>
          Si querés vender o alquilar tu propiedad, contá con un equipo RE/MAX,
          estamos para ayudarte.
        </h2>
        <div class="btn-prop">
          <qr-button
            (click)="this.openFilters('publish-prop')"
            [config]="configButtonProperty"></qr-button>
        </div>
      </div>
    </div>
    <div class="img-card"></div>
  </div>
  <div id="electronic-sign" class="card-wrapper-desktop">
    <div class="img-card" id="electronic-sign-img"></div>
    <div class="info-card">
      <div class="padding-60">
        <h1>Firma electrónica RE/MAX</h1>
        <h2>
          Ya estamos operando con el nuevo sistema de firma electrónica de
          documentos.
        </h2>
        <div class="btn-prop">
          <a href="https://www.youtube.com/watch?v=GiP_iV3PMT0" target="_blank">
            <qr-button [config]="this.configButtonOffers"> </qr-button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="card-wrapper">
    <div class="card-container">
      <qr-card-information
        [data]="dataVirtualVisits"
        (click)="this.openFilters('virtual-visit')"></qr-card-information>
    </div>
    <div class="card-container">
      <qr-card-information
        [data]="dataPropValue"
        (click)="this.openFilters('prop-value')"></qr-card-information>
    </div>
  </div>
  <div class="card-container mobile">
    <qr-card-information
      [data]="dataVirtualOffer"
      (click)="this.openFilters('virtual-offer')"></qr-card-information>
  </div>
  <div class="card-wrapper-desktop">
    <div class="img-card" id="vt"></div>
    <div class="info-card">
      <div class="padding-60">
        <h1>Reservas / Ofertas Virtuales</h1>
        <h2>
          Hoy, vía e-mail, podés reservar tu próxima casa con un equipo RE/MAX.
        </h2>
        <div class="btn-prop">
          <qr-button
            [config]="this.configButtonOffers"
            (click)="this.openFilters('virtual-offer')">
          </qr-button>
        </div>
      </div>
    </div>
  </div>
  <div class="card-wrapper">
    <div class="card-container">
      <qr-card-information [data]="dataFranchise"></qr-card-information>
    </div>
    <div class="card-container">
      <qr-card-information [data]="dataBlog"></qr-card-information>
    </div>
  </div>

  <div id="find-prop-container">
    <h1>No dejes de soñar.</h1>
    <h1>Encontrá tu próxima propiedad.</h1>
    <h2>No dejes de soñar. Encontrá tu próxima propiedad.</h2>
    <div id="btn">
      <a href="/" target="_blank">
        <qr-button [config]="this.configButton"></qr-button
      ></a>
    </div>
  </div>
  <div id="social-wrapper">
    <div class="social-container">
      <a href="https://www.facebook.com/remaxargoficial" target="_blank">
        <div class="icon-container">
          <div class="icon" id="facebook"></div>
        </div>
        <h1>/remaxargoficial</h1></a
      >
    </div>
    <div class="social-container">
      <a
        href="https://www.instagram.com/remaxargoficial/?hl=es-la"
        target="_blank">
        <div class="icon-container">
          <div class="icon" id="instagram"></div>
        </div>
        <h1>&#64;remaxargoficial</h1></a
      >
    </div>
    <div class="social-container">
      <a href="https://twitter.com/remaxargoficial" target="_blank">
        <div class="icon-container">
          <div class="icon" id="twitter"></div>
        </div>
        <h1>&#64;remaxargoficial</h1></a
      >
    </div>
    <div class="social-container">
      <a href="https://www.youtube.com/user/remaxargentina" target="_blank">
        <div class="icon-container">
          <div class="icon" id="youtube"></div>
        </div>
        <h1>/remaxargentina</h1></a
      >
    </div>
  </div>
  <div id="disclaimer-container">
    <h1>
      Para ejercer la actividad inmobiliaria se debe cumplir con los
      requerimientos de la ley de corretaje dentro de su jurisdicción. Todas las
      operaciones son objeto de intermediación y conclusión por un corredor
      matriculado. Los Agentes no pueden realizar los actos que, por
      disposiciones legales, se encuentran reservados exclusivamente a los
      corredores públicos matriculados. Cada Oficina es de gestión y propiedad
      independiente.
    </h1>
  </div>
</div>
