import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  CUSTOM_ELEMENTS_SCHEMA,
  PLATFORM_ID,
} from '@angular/core';
import {
  DomSanitizer,
  SafeResourceUrl,
  Title,
  Meta,
} from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  L10nLocale,
  L10nTranslationService,
  L10N_LOCALE,
  L10nTranslationModule,
} from 'angular-l10n';
import { Constants } from '@base/src/app/core/constants/constants';
import { Associate } from '@app/core/models/associate.model';
import { AssociateService } from '@app/services/associate.service';
import { IQrResponse } from '@app/core/models/IQrResponse';
import { Office } from '@app/core/models/office.model';
import { OfficesService } from '@app/services/offices.services';
import { environment } from '@base/environments/environment';
import { SearchDomain } from '@app/domain/search.domain';
import {
  IButtonConfig,
  QrButtonComponent,
} from '@app/components/qr-button/qr-button.component';
import { IQrPage } from '@app/core/models/IQrPage';
import { HttpClient } from '@angular/common/http';
import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Subject, Subscription } from 'rxjs';
import { ListingAndEntrepreneurshipService } from '@app/services/listing-and-entrepreneurship.service';
import { takeUntil } from 'rxjs/operators';
import { SwiperOptions } from 'swiper/types';
import { QrFilter } from '@app/core/models/qr-filter';
import { QrFilterCustom } from '@app/core/models/qr-filter-custom';
import { QrCardPropertyComponent } from '@base/src/app/components/qr-card-property/qr-card-property.component';
import { QrCardOfficeMemberComponent } from '@base/src/app/components/qr-card-office-member/qr-card-office-member.component';
import { QrBiographyCardComponent } from '@base/src/app/components/qr-biography-card/qr-biography-card.component';
import { QrCardInfoOfficeComponent } from '@base/src/app/components/qr-card-info-office/qr-card-info-office.component';
import { SwiperDirective } from '@base/src/app/directive/swiper.directive';
import { ListingAndEntrepreneurship } from '@base/src/app/core/interfaces/listing-and-entrepreneurship.interface';
import { IQrFilterType } from '@base/src/app/core/enum/IQrFilterType';
import { Tab } from '@base/src/app/core/models/qr-tab.model';
import { QRTabsComponent } from '@base/src/app/components/ui/qr-tabs/qr-tabs.component';
import { Icon } from '@base/src/app/core/models/icon.model';

@Component({
  selector: 'app-office-detail',
  templateUrl: './office-detail.page.html',
  styleUrls: ['./office-detail.page.scss'],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    QrButtonComponent,
    QrCardPropertyComponent,
    QrCardOfficeMemberComponent,
    QrBiographyCardComponent,
    SwiperDirective,
    L10nTranslationModule,
    QrCardInfoOfficeComponent,
    QRTabsComponent,
  ],
})
export class OfficeDetailPage implements OnInit, OnDestroy {
  public found: boolean = false;
  public staffs: Associate[] = [];
  public teams: Associate[] = [];
  public teamsShort: Associate[] = [];
  public seeTeamsShort: boolean = true;
  public auctioneerAssociates: Associate[] = [];
  public auctioneerOffices: Associate[] = [];
  public node: string = environment.node;
  public listingsAndEntrepreneurship: ListingAndEntrepreneurship[] = [];
  public auctioneers: Associate[] = [];
  public associate: Associate = new Associate();
  public actualPage: number = 0;
  public totalPages: number = 0;
  public totalItems: number = 0;
  public isListingsAndEntrepreneurshipLoading: boolean = true;
  public isOfficeProperties: boolean = false;
  public office: Office = new Office();
  public isStaticMapModeEnabled: boolean = true;
  public staticMapHeight: number | undefined = 0;
  public sanitizeMapStaticURL: SafeResourceUrl | null = null;
  public sanitizeMapEmbedURL: SafeResourceUrl = '';
  readonly LISTINGS_VIEW_MODE_GRID = Constants.LISTINGS_VIEW_MODE_GRID;

  readonly ICON_HOUSE: Icon = {
    name: Icon.NAME_HOUSE,
    color: Icon.COLOR_GREY_WOLF,
  };

  readonly ICON_APARTMENTS_IN_BUILDING: Icon = {
    name: Icon.NAME_APARTMENTS_IN_BUILDING,
    color: Icon.COLOR_GREY_WOLF,
  };

  mockBiography =
    'Todas las propiedades que figuran en mi perfil se encuentran a cargo del profesional matriculado de la oficina, la intermediación y la conclusión de las operaciones serán llevadas exclusivamente por él.';

  configButtonAllFeatured = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: 'Ver todos',
  };

  configSwiperFeatured: any = {
    paginationClickable: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    slidesPerView: 'auto',
    spaceBetween: 12,
    watchOverflow: true,
  };

  swiperProperties: SwiperOptions = {
    spaceBetween: 24,
    slidesPerView: 'auto',
    grabCursor: true,
    navigation: {
      nextEl: '.button-next-1',
      prevEl: '.button-prev-1',
    },
  };

  public tabWithIcon: Tab = {
    tabs: [],
    size: Tab.SIZE_48_PX,
    tabSelected: 0,
  };

  public configButtonViewMore: IButtonConfig = {
    style: Constants.BUTTON_COLOR_GREY_BORDER,
    height: Constants.BUTTON_HEIGHT_48PX,
    text: this.translation.translate('general.view-more'),
    id: 'viewMoreProperties',
    selected: false,
    isDisabled: false,
    changeStyleOnSelected: false,
    styleOnSelected: null,
  };

  coords: { lng: any; lat: any; id: string }[] = [];
  centerMap: { lng: any; lat: any } = { lng: 0, lat: 0 };
  isBrowser: boolean;
  private translationSubscription: Subscription | undefined;
  private hasEntrepreneurships: boolean = true;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private officesService: OfficesService,
    private associatesService: AssociateService,
    private domSanitize: DomSanitizer,
    public searchDomain: SearchDomain,
    private translation: L10nTranslationService,
    private http: HttpClient,
    private titleService: Title,
    private metaTagService: Meta,
    private router: Router,
    private listingAndEntrepreneurshipService: ListingAndEntrepreneurshipService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit(): void {
    this.load();
    if (this.office) {
      this.generateResources();
    }
  }

  ngOnDestroy(): void {
    this.translationSubscription?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  public tabChange(tabSelected: number): void {
    this.tabWithIcon.tabSelected = tabSelected;
    this.actualPage = 0;
    this.loadPropertiesAndEntrepreneurshipByOfficeID(this.office.id);
  }

  public goToListingByOfficeid(): void {
    if (this.office.id !== null) {
      this.setSearchDomain();
      this.searchDomain.windowLocationHref();
    }
  }

  public onClickSeeAll(): void {
    this.seeTeamsShort = !this.seeTeamsShort;
    this.configButtonAllFeatured.text = this.seeTeamsShort
      ? 'Ver todos'
      : 'Ver menos';
  }

  /*
  enableEmbedMap: Ocultar mapa estático y mostrar mapa embed
  */
  enableEmbedMap(): void {
    this.isStaticMapModeEnabled = false;
    this.staticMapHeight =
      document.getElementById('map-static-img')?.offsetHeight; // Obtener altura del mapa estático que se le aplicara al mapa embed
  }

  public getSanitizeMapEmbedURL(): void {
    if (
      this.office?.location?.coordinates?.length > 0 &&
      this.office.location.coordinates[0] &&
      this.office.location.coordinates[1]
    ) {
      this.sanitizeMapEmbedURL =
        this.domSanitize.bypassSecurityTrustResourceUrl(
          'https://www.google.com/maps/embed/v1/place?q=' +
            this.office.location.coordinates[1] +
            ',' +
            this.office.location.coordinates[0] +
            '&key=' +
            environment.googleAPIKey
        );
    } else {
      console.log('Error: generatin static map');
    }
  }

  private updateTabsConfig(): void {
    const updatedTabWithIcon: Tab = {
      ...this.tabWithIcon,
      tabs: [
        { text: 'Propiedades', icon: this.ICON_HOUSE },
        ...(this.hasEntrepreneurships
          ? [
              {
                text: 'Emprendimientos',
                icon: this.ICON_APARTMENTS_IN_BUILDING,
              },
            ]
          : []),
      ],
      size: Tab.SIZE_48_PX,
      tabSelected: 0,
    };

    this.tabWithIcon = { ...updatedTabWithIcon };
  }

  private load(): void {
    const AGENT_SLUG: string | undefined = this.route.snapshot.params['id'];
    if (AGENT_SLUG === undefined) {
      return;
    }
    this.officesService
      .findBySlug(AGENT_SLUG)
      ?.then((response: IQrResponse<Office>) => {
        if (response.code == 200) {
          this.setMetaTags(response.data);
          this.office = response.data;
          this.checkHasEntrepreneurships(this.office.id);
          this.loadPropertiesAndEntrepreneurshipByOfficeID(this.office.id);
          this.getOfficeStaff(this.office.id);
          this.getOfficeTeam(this.office.id);
          this.getOfficeAuctioneerAssociate(this.office.id);
          this.getOfficeAuctioneerOffice(this.office.id);
          this.generateCoords();
          this.generateResources();
          this.translationChange();
        } else if (response.code == 500) {
          this.router.navigateByUrl('/');
        }
      })
      .catch(error => {
        console.log(error);
      });
  }

  private generateResources(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getSanitizeMapStaticURL();
      this.getSanitizeMapEmbedURL();
    }
  }

  private translationChange(): void {
    this.translationSubscription = this.translation.onChange().subscribe({
      next: () => {
        this.configButtonViewMore = {
          style: Constants.BUTTON_COLOR_GREY_BORDER,
          height: Constants.BUTTON_HEIGHT_48PX,
          text: this.translation.translate('general.view-more'),
          id: 'viewMoreProperties',
          selected: false,
          isDisabled: false,
          changeStyleOnSelected: false,
          styleOnSelected: null,
        };
      },
    });
  }

  private setSearchDomain(): void {
    this.searchDomain.resetAll();
    this.searchDomain.filterOperation = [1, 2, 3]; // trae venta y alquiler
    this.searchDomain.filterOfficeId = this.office.id;
    this.searchDomain.officeName = this.office.name;
  }

  private checkHasEntrepreneurships(officeID: string): void {
    const FILTER: QrFilter = new QrFilter(this.actualPage, 6, [], []);
    const OFFICE_FILTER: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      IQrFilterType.officeId,
      officeID
    );
    const ENTREPRENEURSHIP_FILTER: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      'entrepreneurship',
      true
    );
    FILTER.addFilter(OFFICE_FILTER);
    FILTER.addFilter(ENTREPRENEURSHIP_FILTER);
    const QUERY: string = FILTER.toQueryStringBe();
    this.listingAndEntrepreneurshipService
      .getParams<string, IQrResponse<IQrPage>>(QUERY)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<IQrPage>) => {
          this.hasEntrepreneurships =
            response.code === 200 && response.data.totalItems > 0;
          this.updateTabsConfig();
        },
        error: error => {
          console.error('Error occurred:', error);
        },
      });
  }

  private loadPropertiesAndEntrepreneurshipByOfficeID(officeID: string) {
    const FILTER: QrFilter = new QrFilter(this.actualPage, 6, [], []);
    const OFFICE_FILTER: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      IQrFilterType.officeId,
      officeID
    );
    const ENTREPRENEURSHIP_FILTER: QrFilterCustom = new QrFilterCustom(
      IQrFilterType.eq,
      'entrepreneurship',
      false
    );
    FILTER.sorts = ['-createdAt'];
    FILTER.addFilter(OFFICE_FILTER);
    FILTER.addFilter(ENTREPRENEURSHIP_FILTER);
    const QUERY: string = FILTER.toQueryStringBe();
    if (this.tabWithIcon.tabSelected === 0) {
      this.listingAndEntrepreneurshipService
        .getParams<string, IQrResponse<IQrPage>>(QUERY)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: IQrResponse<IQrPage>) => {
            this.handleResultsResponse(response);
          },
          error: error => {
            console.error('Error occurred:', error);
          },
        });
    } else {
      const FILTER: QrFilter = new QrFilter(this.actualPage, 6, [], []);
      const OFFICE_FILTER: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.eq,
        IQrFilterType.officeId,
        officeID
      );
      const ENTREPRENEURSHIP_FILTER: QrFilterCustom = new QrFilterCustom(
        IQrFilterType.eq,
        'entrepreneurship',
        true
      );
      FILTER.sorts = ['-createdAt'];
      FILTER.addFilter(OFFICE_FILTER);
      FILTER.addFilter(ENTREPRENEURSHIP_FILTER);
      const QUERY: string = FILTER.toQueryStringBe();
      this.listingAndEntrepreneurshipService
        .getParams<string, IQrResponse<IQrPage>>(QUERY)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (response: IQrResponse<IQrPage>) => {
            this.handleResultsResponse(response);
          },
          error: (error: any) => {
            console.error('Error occurred:', error);
          },
        });
    }
  }

  private handleResultsResponse(response: IQrResponse<IQrPage>) {
    if (response.code == 200) {
      this.isListingsAndEntrepreneurshipLoading = false;
      this.totalPages = response.data.totalPages;
      this.totalItems = response.data.totalItems;
      const RESULTS: IQrPage = response.data;
      if (this.actualPage === 0) {
        this.listingsAndEntrepreneurship = RESULTS.data;
      } else {
        this.listingsAndEntrepreneurship = [
          ...this.listingsAndEntrepreneurship,
          ...RESULTS.data,
        ];
      }
    } else {
      console.warn(response);
    }
  }

  private getOfficeStaff(id: string): void {
    this.associatesService
      .findByOfficeIdStaff(id)
      .then((response: IQrResponse<Associate[]>) => {
        if (response.code === 200) {
          this.staffs = response.data;
        }
      });
  }

  private getOfficeTeam(id: string): void {
    this.associatesService
      .findByOfficeIdTeam(id)
      .then((response: IQrResponse<Associate[]>) => {
        if (response.code === 200) {
          this.teams = response.data;
          if (this.teams?.length > 3) {
            this.teamsShort = [];
            for (let index = 0; index < 4; index++) {
              this.teamsShort.push(this.teams[index]);
            }
          } else {
            this.teamsShort = [];
          }
        }
      });
  }

  private getOfficeAuctioneerAssociate(id: string): void {
    this.associatesService
      .findByOfficeIdAuctioneerAssociate(id)
      .then((response: IQrResponse<Associate[]>) => {
        if (response.code === 200) {
          this.auctioneerAssociates = response.data;
        }
        this.isListingsAndEntrepreneurshipLoading = false;
      });
  }

  private getOfficeAuctioneerOffice(id: string): void {
    this.associatesService
      .findByOfficeIdAuctioneerOffice(id)
      .then((response: IQrResponse<Associate[]>) => {
        if (response.code === 200) {
          this.auctioneerOffices = response.data;
          this.found = true;
        }
        this.isListingsAndEntrepreneurshipLoading = false;
      });
  }

  private generateCoords(): void {
    this.coords = [
      {
        lng: this.office.location?.coordinates[0] || 0,
        lat: this.office.location?.coordinates[1] || 0,
        id: this.office.id,
      },
    ];

    this.centerMap = {
      lng: this.office.location?.coordinates[0] || 0,
      lat: this.office.location?.coordinates[1] || 0,
    };
  }

  private getSanitizeMapStaticURL(): void {
    if (
      this.office?.location?.coordinates?.length > 0 &&
      this.office.location.coordinates[0] &&
      this.office.location.coordinates[1]
    ) {
      const url: string =
        'https://maps.googleapis.com/maps/api/staticmap?size=800x400&maptype=roadmap' +
        '&markers=color:red|' +
        this.office.location.coordinates[1] +
        ',' +
        this.office.location.coordinates[0] +
        '&zoom=16&scale=2&key=' +
        environment.googleAPIKey;

      this.http
        .post('/signing-google-maps', {
          url: url,
        })
        .subscribe((res: any) => {
          this.sanitizeMapStaticURL =
            this.domSanitize.bypassSecurityTrustResourceUrl(res.url);
        });
    } else {
      console.log('Error: generating sanitizeMapStaticURL');
    }
  }

  private setMetaTags(data: any): void {
    const linkCanonical: HTMLLinkElement = this.document.createElement('link');
    linkCanonical.setAttribute('rel', 'canonical');
    this.document.head.appendChild(linkCanonical);
    linkCanonical.setAttribute('href', this.document.URL);

    const link = <HTMLLinkElement>(
      this.document.head.querySelector("link[rel='canonical']")
    );
    if (link != undefined && link != null) {
      link.remove();
    }
    let country = '';

    switch (environment.node) {
      case Constants.NODE_ARGENTINA:
        country = 'Argentina';
        break;

      case Constants.NODE_URUGUAY:
        country = 'Uruguay';
        break;

      case Constants.NODE_ECUADOR:
        country = 'Ecuador';
        break;

      default:
        break;
    }

    const title = data.name + ' - ' + data.address + '.';
    const description =
      'Visitá ' + data.name + ' en ' + data.address + ', en ' + country + '.';
    this.titleService.setTitle(title);
    this.metaTagService.updateTag({
      name: 'description',
      content: description,
    });
    this.metaTagService.updateTag({
      property: 'og:image',
      content: data.photo,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: this.document.location.href,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: description,
    });

    this.metaTagService.updateTag({
      property: 'og:title',
      content: data.name,
    });

    this.metaTagService.updateTag({
      name: 'robots',
      content: 'index, follow',
    });
  }
}
