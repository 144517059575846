import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@base/environments/environment';
import { IQrResponse } from '@base/src/app/core/models/IQrResponse';
import { ReportByIdService } from '@base/src/app/services/report-by-id.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-indice-remax-single',
  templateUrl: './detail.page.html',
  styleUrls: ['./detail.page.scss'],
})
export class IndiceRemaxSinglePage implements OnInit, OnDestroy {
  data: any;
  private destroy$: Subject<void> = new Subject<void>();
  constructor(
    private reportByIdService: ReportByIdService,
    private route: ActivatedRoute,
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit(): void {
    const url = this.route.snapshot.params['id'];
    const value = url.split('-');
    const id = value[value.length - 1];
    this.loadReports(id);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadReports(id: string): void {
    this.reportByIdService
      .getData<Report>(id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: IQrResponse<Report>) => {
          this.data = response.data;
          this.setMetaTags();
        },
        error: (error: any) => {
          console.error('Error occurred:', error);
        },
      });
  }

  setMetaTags(): void {
    const url =
      environment.host +
      '/' +
      this.data.month +
      '-' +
      this.data.year +
      '-' +
      this.data.id;
    const title = this.data.title;
    const description =
      'El Índice Re/Max presenta el precio promedio por m2 de un inmueble en Buenos Aires, Argentina permitiendo comparar su precio de venta en el mercado. El siguiente informe corresponde al período ' +
      this.data.month +
      '/' +
      this.data.year +
      '.';

    this.titleService.setTitle(title);

    this.metaTagService.updateTag({
      name: 'description',
      content: description,
    });

    this.metaTagService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaTagService.updateTag({
      property: 'og:description',
      content: description,
    });
    this.metaTagService.updateTag({
      property: 'og:url',
      content: url,
    });

    this.updateCanonicalUrl(url);
  }

  updateCanonicalUrl(url: string): void {
    const head = this.document.getElementsByTagName('head')[0];
    let element: HTMLLinkElement | null =
      this.document.querySelector(`link[rel='canonical']`) || null;
    if (element == null) {
      element = this.document.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', url);
  }
}
