@if (isOverlayMode && status?.isVisible) {
  <qr-button-x
    id="btn-close__card-map"
    [config]="configButtonClose"
    (click)="close()"></qr-button-x>
}

<a [href]="this.linkdetail" [target]="this.isDesktop ? '_blank' : ''">
  <div
    class="card-remax"
    [ngClass]="{
      viewList: isViewList,
      viewGrid: !isViewList,
      isOverlayMode: isOverlayMode,
    }">
    <div class="card-remax__image">
      @if (!isPropertyPhotosLoading) {
        @if (
          !isEntrepreneurship &&
          data.listingStatus.value !== LISTING_STATUS_ACTIVE
        ) {
          <qr-tag
            text="{{
              'list.' + data.listingStatus.value | translate: locale.language
            }}"
            [color]="statusClassTags"></qr-tag>
        }
        @if (isBrowser && hasPhotos) {
          <div class="image-property__swiper">
            <swiper-container
              class="swiper-qr-card swiper-container"
              appSwiper
              init="false"
              [config]="swiperConfig">
              @for (photo of propertyPhotos; track photo; let i = $index) {
                <swiper-slide class="swiper-slide">
                  <picture-source
                    [config]="propertyPhotosPictureSource"
                    [src]="photo.rawValue"
                    [alt]="'Foto ' + i + 1"></picture-source>
                </swiper-slide>
              }
            </swiper-container>
            <!-- Navegación de swiper -->
            <qr-button-x
              class="{{
                'image-property__btn-nav image-property__btn-nav--prev button-prev' +
                  this.randomNumber
              }}"
              [config]="buttonPrev"></qr-button-x>
            <qr-button-x
              class="{{
                'image-property__btn-nav image-property__btn-nav--next button-next' +
                  this.randomNumber
              }}"
              [config]="buttonNext"></qr-button-x>
          </div>
        } @else {
          <div class="image-property__not-found">
            <img
              src="assets/images/listings/property-photo-not-found.svg"
              alt="La propiedad no tiene fotos." />
          </div>
        }
      }
      <!-- Fotos no encontradas -->
    </div>
    <div class="card-remax__container">
      <div class="card-remax__header-body">
        <div class="card__header">
          @if (!isEntrepreneurship) {
            <div class="card__price-and-expenses">
              @if (data?.priceExposure === false) {
                <p class="card__price">
                  {{
                    'listing-detail.request-price' | translate: locale.language
                  }}
                </p>
              } @else {
                <p class="card__price">
                  {{ data.price || 0 | qrNumber }}
                  <span> {{ data.currency?.value }}</span>
                  <!-- @if (data.billingFrequency && data?.operation?.id === 3) {
                     <span class="card__billing-frequency">
                      /
                      {{
                        'billingFrequencies.' + data.billingFrequency?.value
                          | translate: locale.language
                      }}
                    </span>
                  } -->
                </p>
                @if (data.expensesPrice) {
                  <p class="card__expenses">
                    + {{ data.expensesPrice | qrNumber }}
                    <span>{{ data.expensesCurrency?.value }}</span>
                    {{
                      'list.expenses' | translate: locale.language | lowercase
                    }}
                  </p>
                }
              }
            </div>
          }
          @if (isEntrepreneurship) {
            <div class="tags-container">
              <qr-tag
                text="{{
                  'entrepreneurship.' + data.estage?.value
                    | translate: locale.language
                }}"
                [color]="stageClassTags">
              </qr-tag>
              @if (isDesktop && isViewList) {
                <qr-tag
                  [text]="
                    'Entrega estimada: ' +
                    ('months.' + data.ecompletedMonth
                      | translate: locale.language
                      | titlecase) +
                    ' ' +
                    data.ecompletedYear
                  "
                  color="grey"></qr-tag>
              }
              @if (isDesktop && !isViewList) {
                <qr-tag
                  [text]="
                    'Entrega : ' +
                    ('months.' + data.ecompletedMonth
                      | translate: locale.language
                      | titlecase) +
                    ' ' +
                    data.ecompletedYear
                  "
                  color="grey"></qr-tag>
              }
              @if (!isDesktop) {
                <qr-tag
                  [text]="
                    'Entrega: ' +
                    (getShortMonth('months.' + data.ecompletedMonth)
                      | titlecase) +
                    '. ' +
                    data.ecompletedYear
                  "
                  color="grey"></qr-tag>
              }
            </div>
          }
          @if (!isEntrepreneurship) {
            @if (utilsService.isVisibleUserRegister(node)) {
              <div class="card__favorite">
                @if (isFavoriteBtnVisible) {
                  <qr-icon-button
                    [icon]="
                      this.isFavorite ? 'favorite-filled' : 'favorite-outlined'
                    "
                    [config]="configButtonFavorite"
                    (click)="addFavorite($event)"></qr-icon-button>
                }
              </div>
            }
          }
        </div>
        <div class="card__body">
          @if (isEntrepreneurship) {
            <div class="card__value-entrepreneurship">
              <div class="card__value-entrepreneurship--toPrice">
                <div class="card__text--toPrice">Desde</div>
                <div class="card__number--toPrice">
                  {{ data.eminPrice || 0 | qrNumber }}
                  <span> {{ data.currency?.value }}</span>
                </div>
              </div>
              @if (isDesktop && isViewList) {
                <div class="card__value-entrepreneurship--fromPrice">
                  <div class="card__text--fromPrice">Hasta</div>
                  <div class="card__number--fromPrice">
                    {{ data.emaxPrice || 0 | qrNumber }}
                    <span> {{ data.currency?.value }}</span>
                  </div>
                </div>
              }
            </div>
          }
          <div class="card__ubication-and-address">
            <p class="card__address">
              {{ data.displayAddress }}
            </p>
            @if (!isEntrepreneurship) {
              <p class="card__ubication">
                {{ data.addressInfo }}
              </p>
            }
          </div>
          <div class="card__feature">
            @if (data.dimensionLand && !isEntrepreneurship) {
              <div class="card__m2total-and-m2cover">
                <div class="card__feature--item" data-info="dimensionLand">
                  <img
                    src="assets/icons/total-surface.svg"
                    alt="/// IMAGEN TAG ///"
                    width="24px" />
                  @if (
                    data.type.value !== PROPERTY_TYPE_CAMPO &&
                    data.type.value !== PROPERTY_TYPE_CHACRA
                  ) {
                    <p>
                      <span>{{ data.dimensionLand | qrNumber }}</span>
                      {{
                        'listing-detail.metric_land'
                          | translate: locale.language
                      }}
                    </p>
                  }
                  @if (
                    data.type.value === PROPERTY_TYPE_CAMPO ||
                    data.type.value === PROPERTY_TYPE_CHACRA
                  ) {
                    <p>
                      <span>{{
                        data.dimensionLand | metersToHectare: false | qrNumber
                      }}</span>
                      {{
                        'listing-detail.metric_land'
                          | translate: locale.language
                      }}
                    </p>
                  }
                </div>
              </div>
            }
            @if (data.dimensionTotalBuilt && !isEntrepreneurship) {
              <div class="card__m2total-and-m2cover">
                <div class="card__feature--item feature--m2total">
                  <img
                    src="assets/icons/total-surface.svg"
                    alt="/// IMAGEN TAG ///"
                    width="24px" />
                  @if (!isEntrepreneurship) {
                    @if (
                      data.type.value !== PROPERTY_TYPE_CAMPO &&
                      data.type.value !== PROPERTY_TYPE_CHACRA
                    ) {
                      <p>
                        <span>{{ data.dimensionTotalBuilt }}</span>
                        {{
                          'listing-detail.metric_total_built'
                            | translate: locale.language
                        }}
                      </p>
                    }
                    @if (
                      data.type.value === PROPERTY_TYPE_CAMPO ||
                      data.type.value === PROPERTY_TYPE_CHACRA
                    ) {
                      <p>
                        <span>{{
                          data.dimensionTotalBuilt | metersToHectare: false
                        }}</span>
                        {{ 'general.hectares' | translate: locale.language }}
                      </p>
                    }
                  }
                  <!-- @if (isEntrepreneurship) {
                    <p>
                      <span>{{ data.minDimension }}</span> a
                      <span>{{ data.maxDimension }}</span>
                      {{
                        'listing-detail.metric_total_built'
                          | translate: locale.language
                      }}
                    </p>
                  } -->
                </div>
                @if (data.dimensionCovered && !isEntrepreneurship) {
                  <div class="card__feature--item feature--m2cover">
                    <img
                      src="assets/icons/covered-surface.svg"
                      alt="/// IMAGEN TAG ///"
                      width="24px" />
                    @if (!isEntrepreneurship) {
                      <p>
                        <span>{{ data.dimensionCovered }}</span>
                        {{
                          'listing-detail.metric_covered'
                            | translate: locale.language
                        }}
                      </p>
                    }
                    <!-- @if (isEntrepreneurship) {
                      <p>
                        <span>{{ data.minDimensionCovered }}</span> a
                        <span>{{ data.maxDimensionCovered }}</span>
                        {{
                          'listing-detail.metric_covered'
                            | translate: locale.language
                        }}
                      </p>
                    } -->
                  </div>
                }
              </div>
            }
            <div class="card__rooms-and-bathroom-and-units">
              @if (
                data.totalRooms || data.eminTotalRooms || data.emaxTotalRooms
              ) {
                <div class="card__feature--item feature--ambientes">
                  <img
                    src="assets/icons/rooms.svg"
                    alt="/// IMAGEN TAG ///"
                    width="24px" />
                  @if (!isEntrepreneurship) {
                    <p>
                      <span>{{ data.totalRooms }}</span> ambientes
                    </p>
                  }
                  @if (isEntrepreneurship) {
                    <p>
                      <span>{{ data.eminTotalRooms }}</span> a
                      <span>{{ data.emaxTotalRooms }}</span> ambientes
                    </p>
                  }
                </div>
              }
              @if (!isEntrepreneurship && data.bathrooms) {
                <div class="card__feature--item feature--bathroom">
                  <img
                    src="assets/icons/bathrooms.svg"
                    alt="/// IMAGEN TAG ///"
                    width="24px" />
                  <p>
                    <span>{{ data.bathrooms }}</span>
                    {{
                      'listing-detail.feature_bathrooms'
                        | translate: locale.language
                    }}
                  </p>
                </div>
              }
              @if (isEntrepreneurship && data.etotalUnits) {
                <div class="card__feature--item feature--units">
                  <img
                    src="assets/icons/units.svg"
                    alt="/// IMAGEN TAG ///"
                    width="24px" />
                  <p>
                    <span>{{ data.etotalUnits }}</span>
                    {{
                      'listing-detail.entrepeneruship_units'
                        | translate: locale.language
                    }}
                  </p>
                </div>
              }
            </div>
          </div>
          <div class="card__description-and-brokers">
            <p class="card__description">
              {{ data.title }}
            </p>
            @if (isDesktop && utilsService.isVisibleAuctioneersOffice(node)) {
              @if (isShortInfoCard) {
                <p class="card__brokers">
                  Corredores responsables:
                  @if (data && data.listBroker && data.listBroker.length > 0) {
                    @for (
                      broker of data.listBroker;
                      track broker;
                      let i = $index
                    ) {
                      <span>
                        {{ broker.name }} {{ broker.license }}
                        @if (data.listBroker.length - 1 !== i) {
                          <span>,</span>
                        }
                      </span>
                    }
                  }
                </p>
              }
            }
          </div>
        </div>
      </div>
      @if (isShortInfoCard) {
        <div class="card__footer">
          <div class="contact-person">
            <div class="contact-person__image">
              @if (this.data.photos) {
                <div>
                  <picture-source
                    [config]="agentResizedImages"
                    [src]="resizedImageWebp"
                    [alt]="'Foto'"
                    height="56px"></picture-source>
                </div>
              } @else {
                <div id="agent-photo-not-found-illustration"></div>
              }
            </div>
            <div class="contact-person__info">
              @if (isEntrepreneurship || !isEntrepreneurship) {
                <!--!TODO: Revisar con el equipo de Front-end los nombres de los endpoints para chequear, uno es associateLicense y el otro es associate.license -->
                <p class="contact-person__info--name">
                  {{ data.associate.name }}
                </p>
                <p class="contact-person__info--office">
                  {{ data.associate.officeName }}
                </p>
                <p class="contact-person__info--license">
                  {{ data.associate.license }}
                </p>
              }
            </div>
          </div>
          <div class="contact-buttons">
            @if (isDesktop) {
              <div class="contact-buttons__whatsapp">
                <a
                  [href]="urlContactWhatsApp"
                  aria-label="Enviar WhatsApp"
                  target="_blank"
                  (mouseup)="countClicksContacts('counterWhatsApp', $event)">
                  <qr-button-x
                    [config]="buttonWhatsappSolidOnlyIcon"></qr-button-x>
                </a>
              </div>
            }
            @if (!isDesktop) {
              <div class="contact-buttons__whatsapp">
                <a
                  [href]="urlContactWhatsApp"
                  aria-label="Enviar WhatsApp"
                  target="_blank"
                  (mouseup)="countClicksContacts('counterWhatsApp', $event)">
                  <qr-button-x
                    [config]="buttonWhatsappSolidWithIcon"></qr-button-x>
                </a>
              </div>
            }
            <div class="contact-buttons__phone">
              <a
                [href]="urlContactPhone"
                aria-label="Llamar ahora"
                target="_blank"
                (mouseup)="countClicksContacts('counterPhone', $event)">
                <qr-button-x [config]="buttonPhoneSolidOnlyIcon"></qr-button-x>
              </a>
            </div>
            <div class="contact-buttons__email">
              <a
                [href]="urlContactEmail"
                aria-label="Enviar un email"
                target="_blank"
                (mouseup)="countClicksContacts('counterEmail', $event)">
                <qr-button-x [config]="buttonEmailSolidOnlyIcon"></qr-button-x>
              </a>
            </div>
          </div>
        </div>
      }
      @if (!isDesktop && utilsService.isVisibleAuctioneersOffice(node)) {
        <p class="card__brokers">
          Corredores responsables:
          @if (data && data.listBroker && data.listBroker.length > 0) {
            @for (broker of data.listBroker; track broker; let i = $index) {
              <span>
                {{ broker.name }} {{ broker.license }}
                @if (data.listBroker.length - 1 !== i) {
                  <span>,</span>
                }
              </span>
            }
          }
        </p>
      }
    </div>
  </div>
</a>

@if (isOverlayMode && status?.isVisible) {
  <div id="shadow" (click)="close()"></div>
}
